import React, { useContext, useState, useEffect, useRef } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { addOrUpdateEmergBannerNewValue, getLatestEBUpdate } from '../../api/publishLogs'
import './WebsitePageMain.css'
import './WebsitePageHoursEditForm.css'
import './WebsiteEmergencyBannerEditForm.css'
import { Context } from '../../../Store'
import Showdown from 'showdown'
import TurndownService from 'turndown'
import { bannerMessages } from '../../constants/messages/en'
import { cma } from '../../../../api/src/lib/contentful'
import { createContentfulLog } from 'src/api/userActionLogs'

import {
  setIsBannerUpdated,
  setUpdateBannerMsg,
  setShowBannerState,
  setBannerBackgroundColor,
  useGlobalState,
  setIsEBDeleted,
} from '../../hooks/state/state'
import { updateHours } from './helper'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'
import { MenuItem, TextField } from '@material-ui/core'
import Sidebar from 'src/components/Staff/Sidebar'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CustomSwitch from 'src/components/CustomSwitch/CustomSwitch'

const WebsitePageEmergencyBanner = ({ routingInfo, user }) => {
  const history = useHistory()
  const [iframeView, setIframeView] = useState('large')
  const {setIsAlertActive} = useContext(Context)
  const [toggle, setToggle] = useState()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isUpdated] = useGlobalState('isBannerUpdated')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [isEBDeleted] = useGlobalState('isEBDeleted')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [selectedSite] = useGlobalState('selectedSite')
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [emergencyBannerMessages] = useGlobalState('emergencyBannerMessages')
  const [heroCarouselCards] = useGlobalState('homePageHeroCards')
  const [bannerText, setBannerText] = useState(
    isUpdated ? descriptionUpdated : descriptionInitial.text
  )
  const [localCopy, setLocalCopy] = useState();
  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [message, setMessage] = useState(-1)
  const [editor, setEditor] = useState(null)
  const [saving, setSaving] = useState(false)
  const [yextEBModified, setYextEBModified] = useState(false);
  const [contentfulFieldsModified, setContentfulFieldsModified] = useState(false);
  const [environment, setEnvironment] = useState(null);
  const initialBannerState = useRef(showBannerState ? true : false)
  const initialBannerColor = useRef(bannerBackgroundColor)
  const [resetForm, setResetForm] = useState(false)

  const goBack = () => {
    history.push(
      `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
    )
  }

  /* format description */
  let converter = new Showdown.Converter()
  const turndownService = new TurndownService()
  const truncateNode = (paraElem, limit) => {
    if (paraElem.nodeType === Node.TEXT_NODE) {
      paraElem.textContent = paraElem.textContent.substring(0, limit)
      return limit - paraElem.textContent.length
    }
    paraElem.childNodes.forEach((child) => {
      limit = truncateNode(child, limit)
    })
    return limit
  }
  const getTruncatedHTMLOrMarkdown = (text, limit, returnMarkdown = false) => {
    if (text && limit) {
      let formattedText = converter.makeHtml(text)
      let docNode = new DOMParser().parseFromString(formattedText, 'text/html')
      let paraElem = docNode.body.firstElementChild
      truncateNode(paraElem, limit)
      if (paraElem) {
        if (returnMarkdown) {
          let dummyDiv = document.createElement('div')
          dummyDiv.appendChild(paraElem)
          let markdownText = turndownService.turndown(dummyDiv.innerHTML)
          return markdownText
        }
        return paraElem
      }
    }
    return ''
  }

  let description = isUpdated ? descriptionUpdated : descriptionInitial.text
  let trimmedMarkdown = getTruncatedHTMLOrMarkdown(description, 500, true)

  let initialValues = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    description: trimmedMarkdown.replace(/\\/g, ''),
  }

  const initial = isHoursUpdated ? updatedHours : initialHours

  const initialHoursValues = {
    mainHours: {
      hours: updateHours(initial.hours),
      additionalHoursText: initial.additionalHoursText,
    },
    extendedHours: {
      extendedHours: updateHours(initial.c_extendedHours),
      c_extendedAdditionalHoursText: initial.c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      typeof initial.c_extendedHours !== 'undefined' &&
      Object.keys(initial.c_extendedHours).length > 0,
  }

  window.onbeforeunload = function () {
    if (isFormDirty && !isEBDeleted) return ''
    else return undefined
  }

  useEffect(() => {
    setBannerStyles()
  }, [showBannerState])

  useEffect(() => {
    cma
      .getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE)
      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENV))
      .then((env) => setEnvironment(env))
      .catch((_)=>{
        alert("Connection with Contentul is not established");
      });
      setLocalCopy(isUpdated ? converter.makeHtml(descriptionUpdated) : converter.makeHtml(descriptionInitial.text));
  }, []);

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const msg = JSON.parse(JSON.stringify(jsonMsg))
    if (msg) {
      if (msg.content && msg.content.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(msg.content.description)
        let regex = /<\/?p[^>]*>/gi
        msg.content.description = formattedText.replace(regex, '')
        setBannerText(formattedText.replace(regex, ''))
      }
      const strMsg = JSON.stringify(msg)
      win.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsgOnToggle = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description: bannerText,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData })
      sendMsg(initialHoursValues)
    })
  }

  const sendOnLoadMsg = () => {
    // setTimeout(() => {
    let frame = document.getElementById('websitePreviewFrame')
    if (frame) {
      frame.style['pointer-events'] = 'auto'
      sendMsg({ type: 'EmergencyBanner', content: initialValues })
      sendMsg(initialHoursValues)
      sendMsg({
        type: 'HOME_CAROUSEL_CARDS_UPDATED',
        content: heroCarouselCards,
      })
    }
    // }, 500)
  }

  function setBannerStyles() {
    let textArea = document.querySelector('div > [contenteditable]')
    if (textArea && toggle) {
      textArea.blur()
      textArea.disabled = !toggle
      textArea.style.boxShadow = ''
    }

    return <div>{null}</div>
  }

  const addOrUpdateEmergBannerNewValueLog = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:');
      await addOrUpdateEmergBannerNewValue(query);
    } catch (e) {
      console.error('Exc caught while addOrUpdateEmergBannerNewValueLog():', e);
    }
  }

  const createContentfulLogRest = async (
    entryID,
    entryTitle,
    verbalDescription
  ) => {
    const input = {
      contentfulId: entryID,
      contentfulTitle: entryTitle,
      isPublished: 0,
      userId: user.id,
      verbalDescription,
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
      updateType: 'Emerg Banner',
      websiteId: selectedSite.siteId,
    }

    ReactGA.event({
      category: 'Content - Site',
      action: `User ${user.id} Made Changes to ShowEmergencyBanner`,
      label: `Value: ${verbalDescription}`,
    })

    try {
      const query = JSON.stringify(input).replace(/"([^"]+)":/g, '$1:')
      await createContentfulLog(query)
    } catch (e) {
      // setError(e)
      console.error(e)
    }
  }

  const updateBannerToggleOrColor = async (bannerVal, bannerColor) => {
    if(!contentfulFieldsModified){
      return;
    }
    if(environment===null){
      return;
    }
    if (!(bannerVal != undefined && descriptionInitial['contentfulEntry'])) return;
    let isbannerValChanged, isbannerColorChanged

    const entry = await environment.getEntry(descriptionInitial['contentfulEntry']);
    isbannerValChanged =
          bannerVal != entry.fields?.showEmergencyBanner?.['en-US'];
    isbannerColorChanged =
          bannerColor !=
          entry.fields?.['emergencyBannerBackgroundColour']?.['en-US'];
    entry.fields['showEmergencyBanner'] = { 'en-US': bannerVal };
    entry.fields['emergencyBannerBackgroundColour'] = { 'en-US': bannerColor };
    const entryUpdated = await entry.update();
    await entryUpdated.publish();
    await createContentfulLogRest(
          entry.sys.id,
          entry.fields.name['en-US'],
          `${
            isbannerValChanged ? `Toggle set to ${bannerVal}` : ''
          } ${
            isbannerColorChanged && isbannerValChanged ? 'and ' : ''
          }${
            isbannerColorChanged
              ? `Color changed to ${bannerColor}`
              : ''
          }`
        );
  }

  const onGoBack = async ()=>{
    if(isFormDirty){
      setResetForm(true)
      setShowBannerState(initialBannerState.current)
      setBannerBackgroundColor(initialBannerColor.current)
      setMessage(-1)

      setTimeout(() => {
        setLocalCopy(descriptionInitial.text)
      }, 1)

      sendMsg({
        type: 'EmergencyBanner',
        content: {
          description: descriptionInitial.text,
          websiteTheme,
          showBanner: initialBannerState.current,
          bannerBackgroundColor: initialBannerColor.current,
        },
      })
    }
  }

  const resetChanges = async () => {
    setResetForm(true)

    setIsFormDirty(false)
    setShowBannerState(initialBannerState.current)
    setBannerBackgroundColor(initialBannerColor.current)
    setMessage(-1)
    setYextEBModified(false);
    setContentfulFieldsModified(false);
    setTimeout(() => {
      setLocalCopy(descriptionInitial.text)
    }, 1)

    sendMsg({
      type: 'EmergencyBanner',
      content: {
        description: descriptionInitial.text,
        websiteTheme,
        showBanner: initialBannerState.current,
        bannerBackgroundColor: initialBannerColor.current,
      },
    })
  }

  const getTextFromHTMLstring = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  const getCurrentEBValue = () => {
    return yextResponse.c_websiteEmergencyBanner;
  }

  const saveYextUpdates = async () => {
    const newValue = localCopy.replace(/\\/g, '');
    const oldValue = getCurrentEBValue();
    if (!((oldValue !== newValue) || yextEBModified)) return;
    const input = {
      userId: routingInfo.user?.id,
      userEmail: routingInfo.account.userName,
      websiteId: selectedSite.siteId.toString(),
      yextId: yextResponse.meta.id.toString(),
      websiteName: selectedSite.name,
      updateType: 'Emerg Banner',
      savedUpdates: [
        {
          type: 'Emerg Banner',
          oldValue: oldValue,
          newValue: newValue,
        },
      ],
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
    };
    await addOrUpdateEmergBannerNewValueLog(input);
  }


  const saveChanges = async () => {
    setSaving(true)
    if(environment===null){
      alert("Connection with Contentul is not established");
      return;
    }
    try {

      await updateBannerToggleOrColor(showBannerState, bannerBackgroundColor)

      await saveYextUpdates();

      setIsEBDeleted(false)
      setIsFormDirty(false)
      setIsAlertActive(true)
      // setShowBannerState(formData.showBanner)
      setBannerBackgroundColor(bannerBackgroundColor)
      setUpdateBannerMsg(localCopy)
      setIsBannerUpdated(true)
      setSaving(false)

      ReactGA.event({
        category: 'Content - Banner',
        action: `User ${routingInfo.user?.id} saved Emergency Banner`,
        label: `Value: ${localCopy}`,
      })
    } catch (error) {
      console.error(error)
      setSaving(false)
    }
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && !isEBDeleted}
        message={() => bannerMessages.CONFIRM_LOSE_UNSAVED_BANNER}
      />
      {!toggle && (
        <style jsx>{`
          input[class^='BaseTextField-sc'] {
            background-color: darkgray !important;
            pointer-events: none;
            color: gray;
          }
        `}</style>
      )}
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          sendOnLoadMsg={sendOnLoadMsgOnToggle}
        />{' '}
        <PreviewFrame
          siteName={selectedSite.name}
          selectedSite={selectedSite.siteURL.split('#')[0]}
          // selectedSite="http://localhost:8000"
          sendOnLoadMsg={sendOnLoadMsg}
          iframeView={iframeView}
        />
      </div>
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={saving}
        saveButtonDisabled={!isFormDirty}
        onBackClick={async () => {
            await onGoBack()
          goBack()
        }}
        userGroups={user.groups}
        showResetButton
        resetChanges={resetChanges}
      >
        <div className="services-form">
          <div>
            <div id="group-title" style={{ marginBottom: '20px' }}>
              <h2 className="sidebar-header">Emergency Banner</h2>
              <p className="sidebar-para">
                {`Edit your emergency banner text below. This banner appears at the top of every page on your website.`}
              </p>
              {/* <p className="sidebar-para">
                {`For messages longer than 2 lines, the emergency banner will have an option to expand the banner and show the full message. Please include the most important information at the beginning of your message. You can add a hyperlink to your message by highlighting text and clicking on the hyperlink icon.`}
              </p> */}
            </div>

            <div
              id="switch-container"
              className="mb-9"
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
              }}
            >
              <CustomSwitch
                checked={showBannerState}
                onClick={() => {
                  setShowBannerState(!showBannerState)
                  setIsFormDirty(true)
                  setContentfulFieldsModified(true);
                  sendMsg({
                    type: 'EmergencyBanner',
                    content: {
                      description: localCopy,
                      websiteTheme,
                      showBanner: !showBannerState,
                      bannerBackgroundColor: bannerBackgroundColor,
                    },
                  })
                }}
              />
              <h6 className="text-lg font-semibold ml-1">
                Emergency Banner On/Off
              </h6>
            </div>
            <div id="banner-color-dropdown" className="mb-6">
              <h6 className="text-lg font-semibold mb-1">Background Color</h6>
              <TextField
                select
                fullWidth
                id="color-dropdown"
                value={bannerBackgroundColor}
                onChange={(e) => {
                  setBannerBackgroundColor(e.target.value)
                  setIsFormDirty(true)
                  setContentfulFieldsModified(true);
                  sendMsg({
                    type: 'EmergencyBanner',
                    content: {
                      description: localCopy,
                      websiteTheme,
                      showBanner: showBannerState,
                      bannerBackgroundColor: e.target.value,
                    },
                  })
                }}
                disableUnderline
                variant="outlined"
              >
                <MenuItem value={'Red'}>Red</MenuItem>
                <MenuItem value={'Grey'}>Grey</MenuItem>
                <MenuItem value={'Theme Color'}>Theme Color</MenuItem>
              </TextField>
            </div>
            <div id="banner-message-dropdown" className="mb-6">
              <h6 className="text-lg font-semibold mb-1">Message Templates</h6>
              <TextField
                select
                fullWidth
                id="message-dropdown"
                placeholder="Select a template"
                value={message}
                onChange={(e) => {
                  if (e.target.value !== -1) {
                    setMessage(e.target.value)
                    setLocalCopy(emergencyBannerMessages[e.target.value].fields.message)
                    editor &&
                      editor.setData(
                        emergencyBannerMessages[e.target.value].fields.message
                      )
                  }
                }}
                variant="outlined"
              >
                <MenuItem value={-1} key={-1}>
                  Select a template
                </MenuItem>
                {emergencyBannerMessages.map((item, idx) => (
                  <MenuItem value={idx} key={item.sys.id}>
                    {item.fields.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <h6 className="text-lg font-semibold mb-1">Customize Message</h6>
              <CKEditor
                editor={ClassicEditor}
                data={localCopy}
                config={{
                  toolbar: ['bold', 'italic', 'link'],
                }}
                onInit={(t) => setEditor(t)}
                onChange={(event, editor) => {
                  if (resetForm) {
                    setResetForm(false)
                  } else {
                    const data = editor.getData()
                    setLocalCopy(data)
                    setIsFormDirty(true)
                    setYextEBModified(true);
                    sendMsg({
                      type: 'EmergencyBanner',
                      content: {
                        description: data,
                        websiteTheme,
                        showBanner: showBannerState,
                        bannerBackgroundColor: bannerBackgroundColor,
                      },
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  )
}

export default WebsitePageEmergencyBanner
