import React, { useState } from 'react';
import { useGlobalState } from '../../hooks/state/state';
import LocationDropdown from '../../components/LocationDropdown/LocationDropdown';
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg';
import HoursIcon from '-!svg-react-loader?name=Icon!../../static/images/hours_icon.svg';
import AlertIcon from '-!svg-react-loader?name=Icon!../../static/images/alert_icon.svg';
import CarouselIcon from '-!svg-react-loader?name=Icon!../../static/images/carousel_icon.svg';
import ToolsIcon from '-!svg-react-loader?name=Icon!../../static/images/tools_icon.svg';
import PeopleIcon from '-!svg-react-loader?name=Icon!../../static/images/people_icon.svg'
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import PhoneIcon from '-!svg-react-loader?name=Icon!../../static/images/phone_icon.svg';

import {
  // HeadingText,
  SectionLink,
  Paragraph,
  ParagraphSmall,
} from './WebsitePageHoursEditForm.styles.js';


const Sidebar = ({
  setIsSidebarClosed,
  userGroups,
  user,
  servicesInfo,
  staffInfo
}) => {
  const [xPosition, setX] = useState(-100);
  const [selectedSite] = useGlobalState('selectedSite');
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin');
  const [isEmerBannerPresent] = useGlobalState('isEmerBannerPresent');
  const [headerBottomCoordinate] = useGlobalState('headerBottomCoordinate');
  const [isSelectedSiteUpdated] = useGlobalState('isSelectedSiteUpdated');
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo');
  const [failedYextData] = useGlobalState('failedYextData'); //NVAMSP-120 EE: Sites without a hero carousel/hours fail to load in EE
  const [orchestratorSiteInfo] = useGlobalState('orchestratorSiteInfo');

  const closeSidebar = () => {
    setX(-100);
    setIsSidebarClosed(true);
  }
  const activeSidebar = () => {
    setX(0);
    setIsSidebarClosed(false);
  }

  const toggleMenu = () => {
    if (xPosition < 0) {
      activeSidebar();
      return;
    }
    closeSidebar();
  }

  let siteId = isSelectedSiteUpdated
    ? updatedSelectedSite.siteId
    : selectedSite.siteId

  let siteName = isSelectedSiteUpdated
    ? updatedSelectedSite.name
    : selectedSite.name

  return (
    <div
      id="sidebar"
      className={`sidebar ${xPosition < 0 ? 'sidebar-closed' : 'sidebar-open'}`}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'block',
        background: 'white',
        height: '100%',
        width: 'var(--tina-sidebar-width)',
        margin: '0',
        padding: '0',
        border: '0 ',
        boxSizing: 'border-box',
        maxWidth: '425px',
        zIndex: '1',
      }}
    >
      <button
        onClick={() => toggleMenu()}
        style={{
          right: '-50px',
        }}
        aria-label="toggles cms sidebar"
        className="blue-button"
      >
        {xPosition < 0 ? (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M24.3324 8.96875C24.754 9.42578 25 9.95312 25 10.5859C25 11.2188 24.754 11.7461 24.3324 12.168L11.9634 24.543L7.85212 25C7.57101 25 7.36018 24.9297 7.21962 24.7188C7.04392 24.543 6.97365 24.332 7.00878 24.0508L7.46559 20.043L19.8346 7.66797C20.2562 7.24609 20.7833 7 21.4158 7C22.0483 7 22.5754 7.24609 23.0322 7.66797L24.3324 8.96875ZM11.1903 22.9258L20.3968 13.7148L18.2884 11.6055L9.08199 20.8164L8.80088 23.207L11.1903 22.9258ZM23.1376 10.9727C23.243 10.8672 23.3133 10.7266 23.3133 10.5859C23.3133 10.4453 23.243 10.3047 23.1376 10.1641L21.8375 8.86328C21.6969 8.75781 21.5564 8.6875 21.4158 8.6875C21.2753 8.6875 21.1347 8.75781 21.0293 8.86328L19.4832 10.4102L21.5915 12.5195L23.1376 10.9727Z"></path>
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 7.208L19.7346 6L10.5227 14.7939C9.82527 15.4597 9.82626 16.5412 10.5227 17.2061L19.7346 26L21 24.792L11.7901 16L21 7.208Z"></path>
          </svg>
        )}
      </button>
      <div className="max-h-full flex flex-col">
        <LocationDropdown groups={userGroups} />
        <div
          id="sidebar-form"
          className="sidebar-form overflow-y-auto max-h-full h-full p-0"
        >
          <div className="fp-2 m-2">
            {/* <HeadingText>{`Welcome back, ${
              user.nickname ?? user.name
            }`}</HeadingText> */}
            <Paragraph className="mt-0">
              {`Edit your website by selecting one of the options below. Remember, after saving all the changes you want to make, you need to select the Publish button above to make them live.`}
            </Paragraph>

            {orchestratorSiteInfo?.DeployMode === 'ISR_SITE' &&
            orchestratorSiteInfo?.scheduledProcessPending.length > 0 && (
              <>
              <Paragraph className="my-0 text-red-600">
                {'This site is scheduled to be published on:'}
                <ul className="list-disc pl-5">
                  {orchestratorSiteInfo?.scheduledProcessPending?.map((process, index) => {
                    return (
                      <li key={index}>
                        {new Date(process.TriggeredAt).toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles",
                          timeZoneName: "shortGeneric",
                          hour: "numeric",
                          minute: "numeric",
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour12: false,
                        })}
                      </li>
                    )
                  })}
                </ul>
              </Paragraph>
              <Paragraph className="mt-0 text-red-600">
                Please note that all drafted updates are made public either at the time of the scheduled publish or by manually pressing the Publish button.
              </Paragraph>
              </>
            )}
          </div>



          {(
            <div
              className={`m-2 ${
                failedYextData ? 'hidden' : ''
              }`}
              onClick={() =>
                ReactGA.event({
                  category: 'Navigation',
                  action: `User ${user.id} Navigated to Hours Page`,
                  label: `siteID: ${siteId}`,
                })
              }
            >
              <SectionLink>
                <Link
                  to={`/site/${siteId}/website-page/contactinfo/${siteName}`}
                  className="flex flex-col p-3 justify-between"
                >
                  <div className="flex justify-between items-center">
                    <PhoneIcon />
                    <h3
                      className="font-medium pl-3"
                      style={{ flexGrow: 1, fontSize: '1.2rem' }}
                    >
                      Contact Info
                    </h3>
                    <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                  </div>
                  <ParagraphSmall className="my-0">
                    Update your location’s phone, text and fax numbers.
                  </ParagraphSmall>
                </Link>
              </SectionLink>
            </div>
          )}

          <div
            className="m-2"
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Emergency Banner Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <SectionLink>
              <Link
                to={`/site/${siteId}/website-page/about/${siteName}`}
                className="flex flex-col p-3  justify-between"
              >
                <div className="flex justify-between items-center">
                  <AlertIcon />
                  <h3
                    className="font-medium pl-3"
                    style={{ flexGrow: 1, fontSize: '1.2rem' }}
                  >
                    Emergency Banner
                  </h3>
                  <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                </div>
                <ParagraphSmall className="my-0">
                  Add, edit or remove the banner at the top of your website.
                </ParagraphSmall>
              </Link>
            </SectionLink>
          </div>


          <div
            className={`m-2 ${
              selectedSiteInfo?.sitePages &&
              selectedSiteInfo.sitePages.some((page) => page.fields.pageType === 'Home Page') &&
              selectedSiteInfo.sitePages.find((page) => page.fields.pageType === 'Home Page')?.fields.hero?.fields.name?.toLowerCase().includes('hero carousel')
                ? ''
                : 'hidden'
            }`}
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Hero carousel`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <SectionLink>
              <Link
                to={`/site/${siteId}/website-page/home/${siteName}`}
                className="flex flex-col p-3  justify-between"
              >
                <div className="flex justify-between items-center">
                  <CarouselIcon />
                  <h3
                    className="font-medium pl-3"
                    style={{ flexGrow: 1, fontSize: '1.2rem' }}
                  >
                    Home Page Carousel
                  </h3>
                  <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                </div>
                <ParagraphSmall className="my-0">
                  Change the order of the slides on the carousel. You can also
                  add, remove and request new slides.
                </ParagraphSmall>
              </Link>
            </SectionLink>
          </div>

          <div
            className={`m-2 ${
              failedYextData ? 'hidden' : ''
            }`}
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Hours Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <SectionLink>
              <Link
                to={`/site/${siteId}/website-page/hours/${siteName}`}
                className="flex flex-col p-3 justify-between"
              >
                <div className="flex justify-between items-center">
                  <HoursIcon />
                  <h3
                    className="font-medium pl-3"
                    style={{ flexGrow: 1, fontSize: '1.2rem' }}
                  >
                    Hours of Operation
                  </h3>
                  <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                </div>
                <ParagraphSmall className="my-0">
                  Edit your hours of operation and any additional information
                  about service availability.
                </ParagraphSmall>
              </Link>
            </SectionLink>
          </div>

          {(
            <div
              className="m-2"
              onClick={() =>
                ReactGA.event({
                  category: 'Navigation',
                  action: `User ${user.id} Navigated to Services Page`,
                  label: `siteID: ${siteId}`,
                })
              }
            >
              <SectionLink>
                {servicesInfo?.length > 0 &&
                selectedSiteInfo.websiteType === 'General Practice' &&
                !selectedSiteInfo?.hideServices ? (
                  <>
                    <Link
                      to={`/site/${siteId}/website-page/services/${siteName}`}
                      className="flex flex-col p-3 justify-between"
                    >
                      <div className="flex justify-between items-center">
                        <ToolsIcon />
                        <h3
                          className="font-medium pl-3"
                          style={{ flexGrow: 1, fontSize: '1.2rem' }}
                        >
                          Services Information{' '}
                        </h3>
                        <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                      </div>
                      <ParagraphSmall className="my-0">
                        Add, edit and delete services and organize them into
                        groups.
                      </ParagraphSmall>
                    </Link>
                  </>
                ) : (
                  ''
                )}
              </SectionLink>
            </div>
          )}

          <div
            className="m-2"
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Staff Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <SectionLink>
              {staffInfo?.length > 0 ? (
                <>
                  <Link
                    to={`/site/${siteId}/website-page/staff/${siteName}`}
                    className="flex flex-col p-3  justify-between"
                  >
                    <div className="flex justify-between items-center">
                      <PeopleIcon />
                      <h3
                        className="font-medium pl-3"
                        style={{ flexGrow: 1, fontSize: '1.2rem' }}
                      >
                        Staff Information
                      </h3>
                      <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                    </div>
                    <ParagraphSmall className="my-0">
                      Add, edit, and delete staff members. Organize them into
                      groups and rearrange their display order.
                    </ParagraphSmall>
                  </Link>
                </>
              ) : (
                ''
              )}
            </SectionLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
