import { request } from '../utils/request'

/**
 * User Action Log REST queries
 */
const queries = {
  allLogs:
    '{userActionLogs{userId, userEmail, websiteId, websiteName, contentfulEntryId, isEntryPublished, updateType, createdAt, yextId, businessLine, divisionId, isEntryPublished}}',
  allpublishLogs:
    '{userActionLogs{divisionId, businessLine, idpublish_logs,userEmail,userId,websiteId,yextId,websiteName,verbalDescription,isEntryPublished,isSendInEmail,updateType,contentfulEntryId,createdAt,publishedAt,savedUpdates {idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}}}',
  allpublishLogsByDate:
    '{divisionId, businessLine, idpublish_logs,userEmail,userId,websiteId,yextId,websiteName,verbalDescription,isEntryPublished,isSendInEmail,updateType,contentfulEntryId,createdAt,publishedAt,savedUpdates {idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}}',
  logsByUserEmail:
    '{id, userID, userEmail, websiteID, contentfulEntryId, contentfulEntryName, isEntryPublished, createdAt }',
  logsById:
    '{id, userID, userEmail, contentfulEntryId, contentfulEntryName, websiteID, isEntryPublished, createdAt}',
  createUserActionLog:
    '{userID, userEmail, contentfulEntryId, contentfulEntryName, websiteID, websiteName, isEntryPublished}',
  getAllContentfulLogs:
    '{getAllContentfulLogs{logId, contentfulId, isPublished, createdAt, contentfulId, contentfulTitle, id, email, name, nickname, roleId, verbalDescription, updateType, businessLine, divisionId, yextId, websiteId, websiteName}}',
  createContentfulLog: '{logId}',
  updateContentfulLog: '{logId}',
  getAllContentfulLogsByDate:
    '{logId, contentfulId, isPublished, createdAt, contentfulId, contentfulTitle, id, email, name, nickname, roleId, verbalDescription, updateType, businessLine, divisionId, yextId, websiteId, websiteName}',
  getContentfulLogsByUserID:
  '{logId, contentfulId, isPublished, createdAt, contentfulId, email, updateType, websiteId}',
  getContentfulLogsByUserIDV2:
  '{logId, contentfulId, isPublished, createdAt, contentfulId, email, updateType, websiteId, path, verbalDescription}',
}

/**
 * Get all user action logs.
 */
export const getUserActionLogs = async () =>
  await request('get', queries.allpublishLogs)

export const getUserActionLogsByDate = async ({ start, end }) => {
  return await request(
    'get',
    `{userActionLogsByDate (start: "${start}", end: "${end}")${queries.allpublishLogsByDate}}`
  )
}

/**
 * Get all action logs related to user by email.
 * @param {string} email user's email
 */
export const getUserActionLogsByUser = async ({ email }) => {
  return await request(
    'get',
    `{userActionLogsByUser (userEmail: "${email}")${queries.logsByUserEmail}}`
  )
}
/**
 * Get all action logs related to user by email.
 * @param {ID} ID user's ID
 */
export const getUnpublishedContentfulLogsByUserID = async ({ ID }) => {
  const response = await request(
    'get',
    `{getContentfulLogsByUserID (fk_contentfullog_user: ${ID})${queries.getContentfulLogsByUserID}}`
  )
  const data = response?.data?.getContentfulLogsByUserID;
  if (data){
    return data;
  }
  console.log("Error trying to Get all action logs related to user by email", "getUnpublishedContentfulLogsByUserID");
  return [];
}

/**
 * Get all action logs related to user by user ID and website ID.
 * @param {ID} ID user's ID
 * @param {SiteID} SiteID website's ID
 */
export const getUnpublishedContentfulLogsByUserIDAndSiteId = async ({ ID, siteId }) => {
  const response = await request(
    'get',
    `{getContentfulLogsByUserIDAndSiteID (fk_contentfullog_user: ${ID}, websiteId: ${siteId})${queries.getContentfulLogsByUserID}}`
  );
  const data = response?.data?.getContentfulLogsByUserIDAndSiteID;
  if (data){
    return data;
  }
  console.log("Error trying to get all action logs related to user by user ID and website ID", "getContentfulLogsByUserIDAndSiteID");
  return [];
}

/**
 * Get all action logs related to user by user ID and website ID.
 * @param {ID} ID user's ID
 * @param {SiteID} SiteID website's ID
 */
export const getUnpublishedContentfulLogsByUserIDAndSiteIdV2 = async ({ ID, siteId }) => {
  const response = await request(
    'get',
    `{getContentfulLogsByUserIDAndSiteIDV2 (fk_contentfullog_user: ${ID}, websiteId: ${siteId})${queries.getContentfulLogsByUserIDV2}}`
  );
  const data = response?.data?.getContentfulLogsByUserIDAndSiteIDV2;
  if (data){
    return data;
  }
  console.log("Error trying to get all action logs related to user by user ID and website ID", "getContentfulLogsByUserIDAndSiteIDV2");
  return [];
}

export const getUserActionLogsByUserUnpublished = async ({ email }) => {
  return await request(
    'get',
    `{userActionLogsByUserUnpublished (userEmail: "${email}")${queries.logsByUserEmail}}`
  )
}

/**
 * Get all action logs related to user by id.
 * @param {string} email user's email
 */
export const getUserActionLogsById = async ({ id }) => {
  return await request(
    'get',
    `{userActionLogsByUser (userID: "${id}")${queries.logsById}}`
  )
}

/**
 * Create new action log.
 */
export const createUserActionLogs = async (query) => {
  return await request(
    'post',
    `mutation{createUserActionLogs (input: ${query}) ${queries.createUserActionLog}}`,
    true
  )
}

/**
 * Update action log.
 */
export const updateUserActionLogs = async (query) => {
  return await request(
    'post',
    `mutation{updateUserActionLogs (input: ${query}) ${queries.createUserActionLog}}`,
    true
  )
}

/**
 * Delete user action log.
 * @param {string} id action log id
 */
export const deleteActionLogById = async (id) => {
  return await request(
    'post',
    `mutation{deleteActionLogById (id: ${id}) {id}}`,
    true
  )
}

/**
 * Get all Contentful action logs.
 */
export const getAllContentfulLogs = async () =>
  await request('get', queries.getAllContentfulLogs)

export const getAllContentfulLogsByDate = async ({ start, end }) =>
  await request(
    'get',
    `{getAllContentfulLogsByDate (start: "${start}", end: "${end}")${queries.getAllContentfulLogsByDate}}`
  )

/**
 * Create new action log entry for Contentful.
 */
export const createContentfulLog = async (query) => {
  return await request(
    'post',
    `mutation{createContentfulLog (input: ${query}) ${queries.createContentfulLog}}`,
    true
  )
}

/**
 * Create new action log entry for Contentful with path.
 */
export const createContentfulLogV2 = async (query) => {
  return await request(
    'post',
    `mutation{createContentfulLogV2 (input: ${query}) ${queries.createContentfulLog}}`,
    true
  )
}

/**
 * Update new action log entry for Contentful.
 */
export const updateContentfulLog = async (query) => {
  return await request(
    'post',
    `mutation{updateContentfulLog (input: ${query}) ${queries.updateContentfulLog}}`,
    true
  )
}

/**
 * Update new action log entry for Contentful.
 */
export const updateContentfulLogAsPublishedBySiteID = async ({siteId}) => {
  return await request(
    'post',
    `mutation{updateContentfulLogAsPublishedBySiteID (siteId: ${siteId}) ${queries.updateContentfulLog}}`,
    true
  )
}

/**
 * Get Constants from the DB, by key.
 */
export const getConstantByKey = async (key) => {
  return await request(
    'get',
    `{getConstantByKey (key: "${key}") {value}}`,
    false
  )
}

/**
 * Get Constants from the DB
 */
export const getConstants = async () => {
  return await request(
    'get',
    `{getConstants {key, value}}`,
    false
  )
}

/**
 * Set Constants in the DB, by key.
 */
export const updateConstant = async ({ key, value }) => {
  return await request(
    'post',
    `mutation {updateConstant(input: {key: ${key}, value: "${value}"}){value}"}`,
    true
  )
}
