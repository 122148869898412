import React, { useState, useEffect } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import PawLoader from '../../PawLoader'
import SimpleDragItem from 'src/components/SimpleDragItem/SimpleDragItem'
import SimpleListItem from 'src/components/SimpleListtem/SimpleListItem'

const GroupDetails = (props) => {
  const [bodyText, setBodyText] = useState()
  const [availableServices, setAvailableServices] = useState(null)

  useEffect(() => {
    setBodyText(
      props.data[props.group].bodyText
        ? documentToHtmlString(props.data[props.group].bodyText.json)
        : ''
    )
  }, [])

  useEffect(() => {
    let masterLibrary = []

    const hiddenServices = props.data[props.group].services.filter(
      (s) => s.hideService
    )
    if (hiddenServices) masterLibrary = [...masterLibrary, ...hiddenServices]

    const masterServices = props.masterData?.services?.filter(
      (ms) =>
        !props.data[props.group]?.services?.map((s) => s.name).includes(ms.name)
    )
    if (masterServices) masterLibrary = [...masterLibrary, ...masterServices]

    setAvailableServices(masterLibrary)
  }, [props])

  var enterTarget = null;

  const onDragEnter = (ev) => {
    if (ev.target.closest(".drag-container")) {
      enterTarget = ev.target
      ev.target.closest(".drag-container").classList.add("showDropAreas-green")
    }
  }

  const onDragLeave = (ev) => {
    if (ev.target.closest(".drag-container") && enterTarget == ev.target.closest(".drag-container")) {
      ev.target.closest(".drag-container").classList.remove("showDropAreas-green")
    }
  }

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    ev.target.closest(".drag-container").classList.remove("showDropAreas-green")
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderService(sourceKey, destKey)
  }

  const moveOnClick = (sourceKey, destKey) => {
    if (destKey >= 0 && destKey < props?.data[props.group]?.services?.length) {
      props.reorderService(sourceKey, destKey)
    }
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  return props.data[props.group] ? (
    <div className="services-form">
      <div>
        <div id="group-name" style={{ marginBottom: '20px' }}>
          <div>
            <h2 className="sidebar-header">{`${
              props.data[props.group].groupName
            }`}</h2>
            <p className="sidebar-para mt-0">
              Use the menu or drag and drop to re-order your current selections.
            </p>
          </div>
          <span className="text-lg font-semibold mb-2 mt-6">
            Service Group Description
          </span>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: ['bold', 'italic'],
            }}
            data={bodyText}
            onChange={(event, editor) => {
              const data = editor.getData()
              props.updateGroupDescription(data)
            }}
          />
        </div>

        <div id="group-services" style={{ marginBottom: '40px' }}>
          <span className="text-lg font-semibold mb-2">
            Current Selections:
          </span>
          {(()=>{
            if(props?.addButtonDisabled) return <PawLoader />;
            if(!props?.data) return null;
            if(!props?.data[props?.group]?.services) return null;
            let serviceIndex = 0;
            return props.data[props.group].services.map((item, key) => {
              if(item.hideService) return null;
              const COMPONENT =
                <SimpleDragItem
                  key={key}
                  itemKey={key}
                  item={item}
                  title={item.internalName || item.name}
                  onDragOver={onDragOver}
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  sendMsg={sendMsg}
                  onEditClick={props.goStaff}
                  moveOnClick={moveOnClick}
                  lastItemKey={props.data[props.group].services?.filter((i) => !i.hideService).length - 1}
                  firstVisibleItemKey={props.data[
                    props.group
                  ].services?.findIndex((i) => !i.hideService)}
                  onHideClick={() =>
                    props.onServiceDelete(item.serviceEntryId)
                  }
                  keyIndex={serviceIndex}
                  hideRemove
                />;
              serviceIndex++;
              return COMPONENT;
            });
          })()}
        </div>

        <div id="group-services-available" style={{ marginBottom: '40px' }}>
          <span className="text-lg font-semibold mb-2">
            Available Services:
          </span>

          {availableServices
            ? availableServices.map((item, key) => {
                return (
                  <SimpleListItem
                    key={key}
                    itemKey={key}
                    item={item}
                    title={item.internalName || item.name}
                    sendMsg={sendMsg}
                    onClick={() =>
                      props.addServiceFromList(item?.serviceEntryId, item)
                    }
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default GroupDetails
