import { request } from '../utils/request'
/**
 * User REST queries
 */
const queries = {
  allUsers:
    '{users {id, createdAt, updatedAt, email, emailVerified, nickname, picture, roleId, lastIp, lastLogin, loginsCount, groups {yextId, id, name, siteId, siteURL}}}',
  userByEmail:
    '{email, id, createdAt, updatedAt, emailVerified, lastIp, lastLogin, loginsCount, name, nickname, userId, roleId }}',
  userByID:
    '{id, createdAt, updatedAt, email, emailVerified, lastIp, lastLogin, loginsCount, name, nickname, picture, userId, roleId, groups {id,name,yextId,siteId,siteURL,isMultiLocation,multiLocationObject} }}',
  createUser:
    '{email, updatedAt, emailVerified, lastLogin, name, nickname, userId, picture, roleId, groups { name } }',
  updateUser: '{name}',
}

/**
 * Get all users.
 */
export const getAllUsers = async () => await request('get', queries.allUsers)

/**
 * Get user by email.
 * @param {Object} data User email
 */
export const getUserByEmail = async ({ email }) => {
  return await request(
    'get',
    `{userByEmail (email: "${email}")${queries.userByEmail}`
  )
}

/**
 * Get user and its groups by email.
 * @param {Object} data User email
 */
export const getUserAndGroupsByEmail = async ({ email }) => {
  return await request(
    'get',
    `{userAndGroupsByEmail (email: "${email}")${queries.userByID}`
  )
}

/**
 * Get user by id.
 * @param {Object} data User id
 */
export const getUserById = async ({ id }) => {
  return await request('get', `{userByID (id: ${id})${queries.userByID}`)
}

/**
 * Create a new user.
 * @param {Object} query user create input
 */
export const createUser = async (query) => {
  return await request(
    'post',
    `mutation{createUser (input: ${query}) ${queries.createUser}}`,
    true
  )
}

/**
 * Delete a user.
 * @param {string} email user email
 */
export const deleteUserByEmail = async (email) => {
  return await request(
    'post',
    `mutation{deleteUserByEmail (email: \"${email}\") {email}}`,
    true
  )
}

export const updateUser = async (id, query) => {
  return await request(
    'post',
    `mutation{updateUser (id: ${id}, input: ${query}) ${queries.updateUser}}`,
    true
  )
}
