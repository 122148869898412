import React, { Fragment, useState } from 'react'
import SuccessModal from '../NewHeroCardForm/SuccessModal'
import * as Msal from 'msal'
import { msalConfig } from '../../../auth/authConfig'
import NewHeroCardForm from '../NewHeroCardForm/NewHeroCardForm'

const NewHeroCardModal = ({ setIsShowingModal }) => {
  const [emailSuccess, setEmailSuccess] = useState(false)
  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()

  return (
    <Fragment>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div
          className={
            emailSuccess ? `success-modal rw-modal` : `contact-modal rw-modal`
          }
        >
          {!emailSuccess ? (
            <div
              className="w-full overflow-y-auto"
              // style={{ maxHeight: '670px' }}
            >
              <NewHeroCardForm
                setEmailSuccess={setEmailSuccess}
                close={() => setIsShowingModal(false)}
              />
            </div>
          ) : (
            <SuccessModal
              senderEmail={account.userName}
              setIsShowingModal={setIsShowingModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default NewHeroCardModal
