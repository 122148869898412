// styles
import styled from 'styled-components'
import tw from 'twin.macro'

export const ActionsBar = styled.div`
  ${tw`pt-8 row`} margin-bottom: 15px;
`
export const Card = styled.div`
  ${tw`max-w-sm overflow-hidden`}
`
export const CardImage = styled.img`
  ${tw`object-contain object-cover w-full shadow-inner h-11`}
`
export const CardWrapper = styled.div`
  ${tw`w-full bg-white h-full`}
`
export const Details = styled.div`
  ${tw`px-6 py-4 bg-white`}
`
export const GridRow = styled.div`
  ${tw`grid grid-cols-4 gap-4`}
`
export const GridItem = styled.div`
  ${tw`transition duration-500 ease-in-out transform shadow-md hover:(flex scale-110 shadow-xl)`}
`
export const Input = styled.input`
  ${tw`h-10 px-5 pl-8 pr-10 text-sm font-thin placeholder-gray-500 bg-transparent border-t-0 border-b border-l-0 border-r-0 rounded-none border-jungle focus:outline-none`}
`
export const InputWrapper = styled.div`
  ${tw`col-6 focus:outline-none`}
`
export const Name = styled.span`
  ${tw`mb-2 font-bold`}
`
export const PublishDetails = styled.p`
  ${tw`pt-4 text-xs font-thin text-gray-400`}
`
export const SearchIcon = styled.div`
  ${tw`absolute z-10 flex items-center pl-4`}
`
export const UserGroupsCell = styled.div`
  ${tw`container px-2 mx-auto`}
`
export const YextId = styled.div`
  ${tw`pb-4 text-sm text-gray-500`}
`
