import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from '@material-ui/icons'

const MOBILE_BUTTON =
  'relative inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50'
const PAGE_BUTTON =
  'relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-offset-0'
const ACTIVE_PAGE_BUTTON =
  'bg-mainNVAcolor z-10 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative inline-flex items-center px-3 py-1 text-sm font-semibold'
const PREV_NEXT_BUTTON =
  'relative inline-flex items-center px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'

const Button = ({
  isActive,
  currentPage,
  page,
  totalPages,
  handleGoToPage,
}) => {
  if (
    // To render first ten pages
    (currentPage < 5 && page > 5 && page <= 10) ||
    // To render last ten pages
    (currentPage > totalPages - 5 && page >= totalPages - 10) ||
    // First 5, last 5 and middle pages
    (currentPage - 5 <= page && page <= currentPage + 5)
  ) {
    return (
      <a
        href={`?page=${page}`}
        className={
          isActive ? ACTIVE_PAGE_BUTTON : `${PAGE_BUTTON} hover:bg-gray-50`
        }
        onClick={(event) => handleGoToPage(event, page)}
      >
        {page}
      </a>
    )
  } else if (
    (page === 1 && currentPage > 6) ||
    (page === totalPages && currentPage <= totalPages - 6)
  ) {
    return <span className={PAGE_BUTTON}>...</span>
  }

  return null
}

const Pagination = ({
  totalPages,
  totalResults,
  currentPage,
  setCurrentPage,
}) => {
  const from = (currentPage - 1) * 10 + 1
  const to = from + 9 > totalResults ? totalResults : from + 9

  const handleGoToPage = (event, page) => {
    event.preventDefault()
    history.pushState({}, '', `?page=${page}`)

    // Scrollable element is probably added by Tina-CMS
    const scrollableElement = document.getElementsByClassName('sc-ckTRkR')[0]
    if (scrollableElement) {
      scrollableElement.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }
    setCurrentPage(page)
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href={`?page=${currentPage - 1}`}
          className={`${MOBILE_BUTTON}`}
          onClick={(event) => handleGoToPage(event, currentPage - 1)}
        >
          Previous
        </a>
        <a
          href={`?page=${currentPage + 1}`}
          className={`${MOBILE_BUTTON} ml-3`}
          onClick={(event) => handleGoToPage(event, currentPage + 1)}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between sm:flex-wrap">
        <div>
          <p className="text-sm text-gray-700">
            Showing {from} to {to} of {totalResults} results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex items-center -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {currentPage > 6 && (
              <a
                href={`?page=1`}
                className={`${PREV_NEXT_BUTTON} rounded-l-md`}
                onClick={(event) => handleGoToPage(event, 1)}
                title="First Page"
              >
                <span className="sr-only">First Page</span>
                <FirstPage />
              </a>
            )}
            {currentPage > 1 && (
              <a
                href={`?page=${currentPage - 1}`}
                className={`${PREV_NEXT_BUTTON} rounded-l-md`}
                onClick={(event) => handleGoToPage(event, currentPage - 1)}
                title="Previous Page"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeft />
              </a>
            )}

            {[...Array(totalPages)].map((page, index) => (
              <Button
                key={index}
                currentPage={currentPage}
                page={index + 1}
                totalPages={totalPages}
                isActive={currentPage === index + 1}
                handleGoToPage={handleGoToPage}
              />
            ))}

            {currentPage < totalPages && (
              <a
                href={`?page=${currentPage + 1}`}
                className={`${PREV_NEXT_BUTTON} rounded-r-md`}
                onClick={(event) => handleGoToPage(event, currentPage + 1)}
                title="Next Page"
              >
                <span className="sr-only">Next</span>
                <ChevronRight />
              </a>
            )}

            {currentPage < totalPages - 6 && (
              <a
                href={`?page=${totalPages}`}
                className={`${PREV_NEXT_BUTTON} rounded-r-md`}
                onClick={(event) => handleGoToPage(event, totalPages)}
                title="Last Page"
              >
                <span className="sr-only">Last Page</span>
                <LastPage />
              </a>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination
