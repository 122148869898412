import Showdown from 'showdown'

let converter = new Showdown.Converter()

const sendMsg = (jsonMsg) => {
  let win = window.frames['websitePreviewFrame']
  if (jsonMsg) {
    if (jsonMsg.author && jsonMsg.author.description) {
      // convert message to HTML to display in preview.
      let formattedText = converter.makeHtml(jsonMsg.author.description)
      let regex = /<\/?p[^>]*>/gi
      jsonMsg.author.description = formattedText.replace(regex, '')
    }
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }
}

export default sendMsg
