import UpdatedHeader from 'src/components/UpdatedHeader/UpdatedHeader'

import { MainStyles } from './UpdatedMainLayout.styles'

const UpdatedMainLayout = ({ children, user }) => {
  return (
    <>
      <UpdatedHeader user={user} />
      <MainStyles>
        <main className="main-content h-full">{children}</main>
      </MainStyles>
    </>
  )
}

export default UpdatedMainLayout
