import { Fragment, useState, useEffect, forwardRef } from 'react'
import * as _ from 'lodash'
import MaterialTable from 'material-table'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import HoursTable from '../UserActionLog/HoursTable'
import AdditionalText from '../UserActionLog/AdditionalText'
import EmergencyBanner from '../UserActionLog/EmergencyBanner'
import moment from 'moment'
import { CsvBuilder } from 'filefy'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import HaveExtendedHours from '../UserActionLog/HaveExtendedHours'

const UserActionLogs = ({ logs }) => {
  const [modLogs, setModLogs] = useState([])
  const today = new Date().toDateString().replace(/ /g, '_')
  useEffect(() => {
    setModLogs(logs)
  }, [logs])

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  return (
    <div className="rw-user-action-logs__container">
      <div>
        {logs && logs.length > 0 ? (
          <div className="rw-changes-tables">
            <MaterialTable
              icons={tableIcons}
              style={{ zIndex: 1 }}
              title={'Yext Changes Log'}
              options={{
                search: true,
                filtering: true,
                toolbar: true,
                headerStyle: { backgroundColor: '#c9cbd1' },
                pageSize: 10,
                sorting: true,
                pageSizeOptions: [10, 20, 100, 200],
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                exportCsv: (columns, data) => {
                  let formattedData = []
                  // creates the new source data to CSV download
                  // let newData =
                  data &&
                    data.map((item) => {
                      let newResults = {
                        Date:
                          item.date ??
                          moment
                            .utc(item.createdAt)
                            .local()
                            .format('MM/DD/YYYY h:mm a'),
                        'Item Update': item.update ?? item.updateType,
                        Email: item.email,
                        'User ID': item.userId ?? item.id,
                        Website: item.websiteName,
                        'Site ID': item.websiteId,
                        // 'Log ID': item.logId ?? item.id,
                        'Yext Id': item.yextId,
                        // 'Table Data': item.tableData && item.tableData.id,
                        'Business Line': item.businessLine,
                        'Division Id': item.divisionId,
                        'Old Hours': item.oldHours,
                        'Modified Hours': item.modifiedHours,
                        'Old Extended Additional Text':
                          item.oldExtendedAdditionalText,
                        'Modified Extended Additional Text':
                          item.modifiedExtendedAdditionalText,
                        'Additional Text': item.additionalText,
                        'Modified Additional Text': item.modifiedAdditionalText,
                        'Old Emergency Banner Message': item.oldEmergencyBanner,
                        'Modified Emergency Banner Message':
                          item.modifiedEmergencyBanner,
                        Changes: item.verbalDescription,
                        'Contentful Entity Id': item.contentfulId,
                        'Contentful Entity Title': item.contentfulTitle,
                        isEntryPublished: item.isEntryPublished,
                      }

                      formattedData.push(newResults)

                      return item
                    })

                  const builder = new CsvBuilder(
                    `Easy_Editor_YEXT_Log_${today}.csv`
                  )
                    .setColumns(Object.keys(formattedData[0]))
                    .addRows(formattedData.map((item) => Object.values(item)))
                    .exportFile()

                  return builder
                },
              }}
              columns={[
                {
                  title: 'DATE & TIME',
                  field: 'date',
                },
                {
                  title: 'UPDATE',
                  field: 'update',
                },
                {
                  title: 'UPDATED BY',
                  field: 'email',
                },
                {
                  title: 'User ID',
                  field: 'userId',
                },
                {
                  title: 'WEBSITE NAME',
                  field: 'websiteName',
                },
                {
                  title: 'WEBSITE ID',
                  field: 'websiteId',
                },
                {
                  title: 'Location ID',
                  field: 'yextId',
                },
                {
                  title: 'Business Line',
                  field: 'businessLine',
                },
                {
                  title: 'Division ID',
                  field: 'divisionId',
                },
                {
                  title: 'Is Published',
                  field: 'isEntryPublished',
                },
              ]}
              data={modLogs}
              detailPanel={[
                {
                  icon: () => <ArrowForwardIosIcon />,
                  render: (rowData) => {
                    return (
                      <Fragment>
                        {(rowData.update?.includes('Hours') ||
                          rowData.update?.substring(0, 6) == 'Hours,' ||
                          rowData.update == 'Hours of Operation') && (
                          <HoursTable
                            changes={rowData.savedUpdates.filter(
                              (item) =>
                                item.type == 'Hours' &&
                                (item.hoo_fieldName == 'status' ||
                                  item.hoo_fieldName == 'interval')
                            )}
                            title="Hours of Operation"
                          />
                        )}
                        {rowData.update?.includes('Ext Hours') && (
                          <HoursTable
                            changes={rowData.savedUpdates.filter(
                              (item) =>
                                item.type == 'Ext Hrs' &&
                                (item.hoo_fieldName == 'status' ||
                                  item.hoo_fieldName == 'interval')
                            )}
                            title="Extended Hours of Operation"
                          />
                        )}

                        {rowData.update?.includes('Hours Text') && (
                          <AdditionalText
                            changes={rowData.savedUpdates.filter(
                              (item) =>
                                item.type == 'Hours' &&
                                item.hoo_fieldName == 'text'
                            )}
                            title="Additional Text"
                          />
                        )}

                        {rowData.update?.includes('Ext Text') && (
                          <AdditionalText
                            changes={rowData.savedUpdates.filter(
                              (item) =>
                                item.type == 'Ext Hrs' &&
                                item.hoo_fieldName == 'text'
                            )}
                            title="Extended Additional Text"
                          />
                        )}

                        {rowData.update?.includes('Emerg Banner') && (
                          <EmergencyBanner
                            oldEmergencyBanner={
                              rowData.savedUpdates[0].oldValue
                            }
                            modifiedEmergencyBanner={
                              rowData.savedUpdates[0].newValue
                            }
                          />
                        )}

                        {rowData.update?.includes('Toggle Ext Hrs') && (
                          <HaveExtendedHours
                            changes={rowData.savedUpdates.filter(
                              (item) =>
                                item.type == 'Ext Hrs' &&
                                item.hoo_fieldName == 'toggle'
                            )}
                            title="Enable Extended Hours"
                          />
                        )}
                      </Fragment>
                    )
                  },
                },
              ]}
            />
          </div>
        ) : (
          <Fragment>No Logs Found</Fragment>
        )}
      </div>
    </div>
  )
}

export default UserActionLogs
