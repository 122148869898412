import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
// import UserCell from 'src/components/UserCell'
import { useParams } from 'react-router-dom'
import UserCellRest from 'src/components/UserCellRest'
import { Link } from 'react-router-dom'

const UserPage = ({ role, user }) => {
  let { id } = useParams()
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }

  const userId = parseInt(id)

  return (
    <UpdatedMainLayout user={user}>
      {/* <UserCell id={userId} /> */}
      <Link
        to={'/users'}
        className="bg-teal-500 text-white p-2 cursor-pointer px-4 py-1 ml-3"
      >Back</Link>
      <UserCellRest id={userId} />
    </UpdatedMainLayout>
  )
}

export default UserPage
