import React, { useState, useEffect, useRef } from 'react'
import * as Msal from 'msal'
import { msalConfig } from '../../../../auth/authConfig'
import ChevronDown from '-!svg-react-loader?name=Icon!../../../static/images/new_chevron_icon.svg'
import ProfileIcon from '-!svg-react-loader?name=Icon!../../../static/images/profile_icon.svg'

let UserDropDown = () => {
  const myMSALObj = new Msal.UserAgentApplication(msalConfig)
  const account = myMSALObj.getAccount()
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownContainer = useRef(null)
  const buttonContainerRef = useRef(null)
  const chevronIconRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', handleOnClick)

    return () => {
      window.removeEventListener('click', handleOnClick)
    }
  }, [])

  let signOut = () => {
    if (sessionStorage.getItem('isVideoViewed')) {
      sessionStorage.removeItem('isVideoViewed')
    }
    if (account) myMSALObj.logout()
  }

  const handleOnClick = (event) => {
    const chevronClicked = !!getParentByRef(event.target, chevronIconRef)
    const browserWidth = window.innerWidth
    const overScreen =
      event.clientX + dropdownContainer.current?.offsetWidth / 2 > browserWidth
    let width = dropdownContainer.current
      ? dropdownContainer.current.offsetWidth
      : 0
    dropdownContainer.current &&
      dropdownContainer.current.setAttribute(
        'style',
        `left:${
          overScreen
            ? width / 2
            : (chevronClicked
                ? buttonContainerRef.current.offsetWidth
                : event.offsetX) -
              width / 2
        }px`
      )
    var el = event.target
    var div = getParentByClass(el, 'user-dropdown-button')
    !div && setShowDropdown(false)
  }

  const getParentByRef = (el, ref) => {
    do {
      if (el == ref.current) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  const getParentByClass = (el, className) => {
    do {
      if (el.classList.contains(className)) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  return (
    <div className="relative my-auto" ref={buttonContainerRef}>
      <button
        className="flex flex-row text-white focus:outline-none gap-1 items-center user-dropdown-button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <ProfileIcon />
        {account && account.name ? account.name : ''}
        <div className="pl-2" ref={chevronIconRef}>
          <ChevronDown />
        </div>
      </button>
      {showDropdown ? (
        <div
          className="absolute flex flex-col bg-white shadow-2xl user-dropdown-contents z-10"
          ref={dropdownContainer}
        >
          <a
            className="py-2 px-6 cursor-pointer dropdown-item"
            onClick={async () => signOut()}
          >
            {account ? 'Log Out' : 'Log In'}
          </a>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default UserDropDown
