import { useAuth } from '@redwoodjs/auth'
import React from 'react'
import Header from '../Header/Header'
import './UpdatedHeader.css'
// eslint-disable-next-line no-unused-vars
const UpdatedHeader = ({ children, user }) => {
  const { loading } = useAuth()

  if (loading) return null

  //TODO: change that code to put all nav items under the same div
  return (
    <>
      <Header user={user} />
    </>
  )
}

export default UpdatedHeader
