import React, { useState, useEffect } from 'react'
import './RadioButton.css'

const RadioButton = ({
  label,
  options,
  indexSelected = 0,
  updateSelection,
}) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setSelected(indexSelected)
  }, [])

  return (
    <div className="radio-button-container">
      <div>{label}</div>
      <div className="radio-button-itens flex gap-14">
        {options.map((item, idx) => {
          return (
            <div key={idx} className="flex flex-col">
              {item.icon && (
                <div
                  style={{ minWidth: '49px', minHeight: '49px' }}
                  className="mb-2"
                >
                  {item.icon}
                </div>
              )}
              <label className="container">
                {item.label}
                <input
                  type="checkbox"
                  checked={idx === selected ? 'checked' : null}
                  onClick={() => {
                    setSelected(idx)
                    updateSelection(item.value)
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioButton
