export const ParseServiceCard = (card) => {
  let serviceDetailCard = card.fields.serviceDetails.fields
  const serviceImage = serviceDetailCard.gallery[0].fields.image.fields.file.url

  const getInternalNameFromMaster = (name) => {

    let internalName = name
      .replace("SERVICE DETAILS CARD - NVA - MASTER-EE -", '')
      .replace("SERVICE DETAILS CARD - NVA -", '');

    if(internalName.startsWith(" ")){
      internalName = internalName.substring(1);
    }
    return internalName;
  }
  let internalName = card.fields.name;
  if(internalName.includes("MASTER-EE")){
    internalName = getInternalNameFromMaster(internalName);
  }else{
    internalName = null;
  }

  let localCopy = { json: card.fields.localCopy }
  let serviceUrl = serviceDetailCard.serviceUrl
  let serviceOverview = {
    json: serviceDetailCard.serviceOverview,
  }
  let serviceDetails = {
    json: serviceDetailCard.serviceDetails,
  }
  let name = serviceDetailCard.serviceName && serviceDetailCard.serviceName
  let serviceEntryId = card.sys.id
  const hideService = card.fields.hideService

  return {
    serviceEntryId,
    name,
    serviceOverview,
    serviceDetails,
    localCopy,
    serviceUrl,
    hideService,
    serviceImage,
    internalName,
  }
}

export const ParseServiceGroup = (groups) => {
  let serviceArray = []
  groups.map((entry) => {
    if (entry && entry.fields == null) {
      return null
    }
    // if the entry is not a service group, add it to the array and return
    if (!(entry && entry.fields && entry.fields.serviceTile)) {
      serviceArray.push({
        key: entry?.sys?.id,
        isService: false,
      });
      return null;
    }
    entry.fields.serviceTile.map((card) => {
      let bladeSection =
        entry.fields.sectionHeadline && entry.fields.sectionHeadline

      let bladeDescription = entry.fields.bodyText && {
        json: entry.fields.bodyText,
      }

      let bladeSectionId = entry.sys && entry.sys.id

      let bExists = false
      for (let i = 0; i < serviceArray.length; i++) {
        if (serviceArray[i].key === bladeSectionId) {
          bExists = true
          // adding staff object in this group object
          let serviceItem = ParseServiceCard(card)
          serviceArray[i].services.push(serviceItem)
        }
      }
      if (!bExists) {
        // creating the group object
        let group = {
          key: bladeSectionId,
          groupName: bladeSection,
          bodyText: bladeDescription,
          services: [ParseServiceCard(card)],
          hideBlade: entry.fields.hideBlade,
          isMaster: entry.fields.name?.includes('MASTER'),
          isService: true,
        }
        serviceArray.push(group)
      }
    })
  })

  return serviceArray
}
