import moment from 'moment-timezone'

/**
 * Parse logs into a readable format for table to display.
 * @param {array`} data logs
 */
export const prepareLogHelper = (data) => {
  const { createdAt, userEmail, updateType } = data

  // look through update string to see what the user is updating
  // const regex = /(?<=([$%]))(?:hours|additionalText|extendedHours|newmsg|newExtendedmsg|modifiedEmergencyBanner|haveExtendedHours)/gi

  // return array of updated fields
  // const typesOfUpdatesArray = contentfulEntryName.match(regex)

  // convert array to set to eliminate dups
  // const typesOfUpdatesSet = new Set(typesOfUpdatesArray)

  // convert to string and modify updates to human readable text
  // const update = Array.from(typesOfUpdatesSet)
  //   .map((update) => {
  //     if (update === 'hours') return 'Hours'
  //     else if (update === 'newmsg') return 'Text'
  //     else if (update === 'extendedHours') return 'Ext. Hours'
  //     else if (update === 'newExtendedmsg') return 'Ext. Text'
  //     else if (update === 'modifiedEmergencyBanner') return 'Emergency Banner'
  //     else if (update === 'haveExtendedHours') return 'On/Off Ext. Hrs'
  //   })
  //   .join(', ')

  let oldHours, newHours

  if (
    updateType?.includes('Hours') ||
    updateType?.substring(0, 6) == 'Hours,' ||
    updateType == 'Hours of Operation'
  ) {
    let hours = data.savedUpdates?.filter(
      (item) =>
        (item.type == 'Hours' || item.type == 'Ext Hrs') &&
        (item.hoo_fieldName == 'status' || item.hoo_fieldName == 'interval')
    )
    oldHours = hours
      .map((i) => {
        return `${i.hoo_day}: ${
          i.hoo_fieldName == 'status'
            ? i.oldValue
            : i.openIntervals
                .map((oi) => `${oi.oldStart} - ${oi.oldEnd}`)
                .join(',')
        }`
      })
      .join(' | ')
    newHours = hours
      .map((i) => {
        return `${i.hoo_day}: ${
          i.hoo_fieldName == 'status'
            ? i.newValue
            : i.openIntervals
                .map((oi) => `${oi.newStart} - ${oi.newEnd}`)
                .join(',')
        }`
      })
      .join(' | ')
  }
  let oldHoursText, newHoursText
  if (updateType?.includes('Hours Text')) {
    let hoursText = data.savedUpdates?.filter(
      (item) => item.type == 'Hours' && item.hoo_fieldName == 'text'
    )

    oldHoursText = hoursText.map((i) => i.oldValue).join(' | ')
    newHoursText = hoursText.map((i) => i.newValue).join(' | ')
  }
  let oldExtHoursText, newExtHoursText
  if (updateType?.includes('Ext Text')) {
    let hoursText = data.savedUpdates?.filter(
      (item) => item.type == 'Ext Hrs' && item.hoo_fieldName == 'text'
    )

    oldExtHoursText = hoursText.map((i) => i.oldValue).join(' | ')
    newExtHoursText = hoursText.map((i) => i.newValue).join(' | ')
  }
  let oldBannerText, newBannerText
  if (updateType?.includes('Emerg Banner')) {
    oldBannerText = data.savedUpdates.map((i) => i.oldValue).join(' | ')
    newBannerText = data.savedUpdates.map((i) => i.newValue).join(' | ')
  }

  // format the date
  let formattedDate = ''
  if (createdAt) {
    formattedDate = moment
      .utc(createdAt)
      .tz('America/Los_Angeles')
      .format('MM/DD/YYYY h:mm a')
  }

  // construct table row
  return {
    date: formattedDate,
    update: updateType,
    email: userEmail,
    isEntryPublished: data.isEntryPublished == 1 ? 'Yes' : 'No',
    modifiedHours: newHours,
    oldHours,
    modifiedAdditionalText: newHoursText,
    additionalText: oldHoursText,
    modifiedExtendedAdditionalText: newExtHoursText,
    oldExtendedAdditionalText: oldExtHoursText,
    modifiedEmergencyBanner: newBannerText,
    oldEmergencyBanner: oldBannerText,
  }
}

export const prepareContentfulLogHelper = (data) => {
  const { createdAt, updateType } = data

  let formattedDate = ''
  if (createdAt) {
    formattedDate = moment
      .utc(createdAt)
      .tz('America/Los_Angeles')
      .format('MM/DD/YYYY h:mm a')
  }

  return {
    date: formattedDate,
    isEntryPublished: data.isPublished == 1 ? 'Yes' : 'No',
    update: updateType,
  }
}
