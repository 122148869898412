import React, { useState, useEffect } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import PawLoader from '../../PawLoader'
import RadioButton from 'src/components/RadioButton/RadioButton'
import SimpleDragItem from 'src/components/SimpleDragItem/SimpleDragItem'
import SimpleListItem from 'src/components/SimpleListtem/SimpleListItem'
import DogRectPng from '../../../static/images/dog_rect.png'
import DogCirclePng from '../../../static/images/dog_circle.png'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ChevronDown from '-!svg-react-loader?name=Icon!../../../static/images/dropdown_icon.svg'

const GroupDetails = (props) => {
  const [bodyText, setBodyText] = useState(
    documentToHtmlString(props.data[props.group]?.bodyText?.json)
  )

  // useEffect(() => {
  //   setBodyText(
  //     props.data[props.group].bodyText
  //       ? documentToHtmlString(props.data[props.group].bodyText.json)
  //       : ''
  //   )
  // }, [])

  var enterTarget = null

  const onDragEnter = (ev) => {
    if (ev.target.closest('.drag-container')) {
      enterTarget = ev.target
      ev.target.closest('.drag-container').classList.add('showDropAreas-green')
    }
  }

  const onDragLeave = (ev) => {
    if (
      ev.target.closest('.drag-container') &&
      enterTarget == ev.target.closest('.drag-container')
    ) {
      ev.target
        .closest('.drag-container')
        .classList.remove('showDropAreas-green')
    }
  }

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    ev.target.closest('.drag-container').classList.remove('showDropAreas-green')
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderStaff(sourceKey, destKey)
  }

  const moveOnClick = (sourceKey, destKey) => {
    if (
      destKey >= 0 &&
      destKey < props?.data[props.group]?.staffMembers?.length
    ) {
      props.reorderStaff(sourceKey, destKey)
    }
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  const parseGroupName = (groupName)=>{
    return groupName && groupName == ' ' ? '' : groupName
  }

  return props.data[props.group] ? (
    <div className="staff-form">
      <div>
        <div id="group-name" style={{ marginBottom: '20px' }}>
          <div>
            <h2 className="sidebar-header">Staff Group Detail</h2>
            <p className="sidebar-para mt-0">
              {`Name and describe the staff group below. You can select the style of the photos for the group here as well. The group description text is optional.`}
            </p>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ChevronDown />}>
              Group Information
            </AccordionSummary>
            <AccordionDetails>
              <div id="group-name" className="h-70 mb-5">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Group Name</span>
                </div>
                <input
                  type="text"
                  defaultValue={parseGroupName(props.data[props.group].groupName)}
                  placeholder="Enter Group Name"
                  onChange={() => props.updateGroupName(event.target.value)}
                  maxLength="50"
                />
                <p
                  style={{
                    color: 'unset',
                    fontSize: 12,
                    marginTop: 0,
                    marginLeft: 10,
                  }}
                >{`${
                  parseGroupName(props.data[props.group]?.groupName)?.length ?? 0
                }/50 Characters`}</p>
              </div>

              <div id="group-description" className="h-70 mb-5">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="mb-2"
                >
                  <span>Group Description</span>
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ['bold', 'italic'],
                  }}
                  data={bodyText}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    props.updateGroupDescription(data)
                  }}
                />
              </div>

              <div id="group-display" className="h-70 mb-5">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="mb-2"
                >
                  <span>Photo Style</span>
                </div>
                <RadioButton
                  label={''}
                  options={[
                    {
                      label: 'Circle image with short bio',
                      value: 'Variation-A',
                      icon: <img src={DogCirclePng} />,
                    },
                    {
                      label: 'Rectangle image, no short bio',
                      value: 'Variation-B',
                      icon: <img src={DogRectPng} />,
                    },
                  ]}
                  updateSelection={props.updateVariation}
                  indexSelected={
                    props.data[props.group]?.variation === 'Variation-A' ? 0 : 1
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <p className="sidebar-para">
            Select a staff member to edit their profile. Drag and drop the staff
            members to reorder on the page.
          </p>
        </div>

        <div id="group-add-staff" style={{ marginBottom: '20px' }}>
          <SimpleListItem
            key={0}
            itemKey={0}
            item={null}
            title={'Add Staff Member'}
            onClick={props.addButtonDisabled ? null : () => props.addStaff()}
            isDisabled={props.addButtonDisabled}
          />
        </div>

        <div id="group-staffs" style={{ marginBottom: '40px' }}>
          {props.addButtonDisabled ? (
            <PawLoader />
          ) : props.data ? (
            props.data[props.group].staffMembers.map((item, key) => {
              if (!item.deleted) {
                return (
                  <SimpleDragItem
                    key={key}
                    itemKey={key}
                    id={item.staffEntryId}
                    item={item}
                    title={item.name}
                    description={item.title}
                    onDragOver={onDragOver}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    sendMsg={sendMsg}
                    onEditClick={props.goStaff}
                    moveOnClick={moveOnClick}
                    lastItemKey={
                      props.data[props.group].staffMembers?.length - 1
                    }
                    firstVisibleItemKey={0}
                    // onHideClick={() =>
                    //   props.onServiceDelete(item.serviceEntryId)
                    // }
                    hideHide
                    onRemoveClick={() => {
                      props.deleteStaff(
                        props.data[props.group].staffMembers?.length == 1,
                        item.staffEntryId
                      )
                    }}
                  />
                )
              }
            })
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignContent: 'flex-end',
        }}
      >
        <button
          onClick={() => props.deleteGroup()}
          disabled={
            props.data[props.group].new ||
            props.data[props.group].preventDeletion
          }
          className={
            props.data[props.group].new ||
            props.data[props.group].preventDeletion
              ? 'disabled'
              : ''
          }
        >
          <span
            style={{
              display: 'inline-block',
              fontSize: '14px',
              color: '#006F85',
            }}
          >
            Delete staff group
          </span>
        </button>
      </div>
    </div>
  ) : (
    ''
  )
}

export default GroupDetails
