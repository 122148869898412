import react, { useState, useEffect, useCallback } from 'react'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { getConstantByKey, updateConstant } from '../../api/publishLogs'
import CustomSwitch from 'src/components/CustomSwitch/CustomSwitch'
import { useGlobalState, setMaintenanceMode } from 'src/hooks/state/state'

const SettingsPage = ({ role, user }) => {
  const [eeEmergencyBanner, setEeEmergencyBanner] = useState('')
  const [saving, setSaving] = useState(false)
  const [maintenanceStatus, setMaintenanceStatus] = useState('Loading...')
  const [maintenanceStatusColor, setMaintenanceStatusColor] = useState('text-grey-400')
  const [maintenanceMode] = useGlobalState('maintenanceMode');
  const [isDisabled, setIsDisabled] = useState(true)

  const getConstants = (async () => {
    const responseEE = await getConstantByKey('ee_emergency_banner')
    const responseMaintenance = await getConstantByKey('maintenance')
    let eeConstant = responseEE.data.getConstantByKey
    let maintenanceConstant = responseMaintenance.data.getConstantByKey
    setEeEmergencyBanner(eeConstant[0].value)
    setMaintenanceMode(maintenanceConstant[0].value)
    setMaintenanceStatus('')
    setIsDisabled(false)
  })

  useEffect(() => {
    getConstants();
  }, [])

  const saveEmBanner = async () => {
    setSaving(true)
    const data = { key: 'ee_emergency_banner', value: eeEmergencyBanner }
    await updateConstant(data)
      .then(() => {
        setSaving(false)
        document.location.reload()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleInputChange = (e) => {
    setEeEmergencyBanner(e.target.value)
  }

  const handleInputKeyUp = (e) => {
    // If enter key is pressed, save banner
    if (e.keyCode === 13) saveEmBanner()
  }

  const handleToggleMaintenance = async () => {
    const data = { key: 'maintenance', value: !maintenanceMode ? 'on' : '' }
    setMaintenanceStatus('Saving...')
    setMaintenanceStatusColor('text-gray-400');
    await updateConstant(data)
    .then(() => {
      setMaintenanceMode(!maintenanceMode);
      setMaintenanceStatus(`Maintenance Mode has been updated (${!maintenanceMode ? 'enabled' : 'disabled'})`)
      setMaintenanceStatusColor('text-green-500');
    })
    .catch((error) => {
      console.error('Error while updating Maintenance Mode:', error)
      alert('Error while updating Maintenance Mode');
    })
  }

  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      <div className="container px-6 mx-auto grid py-5">
        <h2 className="text-2xl pb-10 font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Easy Editor Settings
        </h2>
        <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
          <span className="text-gray-700 dark:text-gray-400">
            Global Emergency Banner
          </span>

          <div className="flex">
            <label className="block text-sm flex items-center mr-3">
              <span className="helpText text-xs text-gray-400">
                Deleting the text will remove the banner completely.
              </span>
            </label>
            <input
              className="special-input appearance-none block bg-transparent text-sm font-hairline border-white border-b p-2 leading-tight focus:outline-none "
              name="ctaLink"
              placeholder="Type here the message you want to display on the banner?"
              value={eeEmergencyBanner}
              onChange={(e) => handleInputChange(e)}
            ></input>
            <button
              className="inline-flex items-center inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-teal-600 border border-transparent rounded-r-md active:bg-teal-600 hover:bg-teal-700 focus:outline-none focus:shadow-outline-teal"
              onClick={() => saveEmBanner()}
            >
              {saving && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              {saving ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>

        <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="flex justify-between items-center">
            <h3 className="text-xl text-gray-700 dark:text-gray-400">
              Maintenance Mode?
            </h3>
            <span className={`ml-auto mr-3 text-xs ${maintenanceStatusColor}`}>
              {maintenanceStatus}
            </span>
            <CustomSwitch
              checked={maintenanceMode}
              onClick={handleToggleMaintenance}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </UpdatedMainLayout>
  )
}

export default SettingsPage
