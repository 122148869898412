import { Link, routes } from '@redwoodjs/router'
import catImage from '../../static/images/cat_dev.png'
const MaintenancePage = () => {
  return (
    <>
      <div className="loader" id="MaintenancePage">
        <img src={catImage} alt="Animated image of dog walking" />
        <article>
          ​<h1>Easy Editor is under maintenance</h1>
          <div>
            <p>
            Our developers (and their furry assistants) are currently working on making Easy Editor function better for you.
              <br />
              <br />
              Once completed, you will be able to make changes to your website as usual. If you were in process of making changes to your site, your changes have been saved.
              Please check back to continue your edits and publish the changes.
              <br />
              <br />
              We ask for your patience while work is conducted. Please hold on creating a Retriever ticket for your changes and check back here shortly to make your changes.
            </p>
          </div>
        </article>
      </div>
      ​
    </>
  )
}

export default MaintenancePage
