import { request } from '../utils/request'

/**
 * Groups REST queries
 */
const queries = {
  allGroups: '{groups {id, name, yextId, siteId, siteURL}}',
  groupsByUserId: '{id, name, yextId, siteURL, siteId }',
  createGroup: '{name, yextId, siteId, siteURL }',
  groupByGroupId: '{id, name, yextId, siteId, siteURL }',
  updateGroup: '{id}',
}

/**
 * Get all Groups.
 */
export const getAllGroups = async () => await request('get', queries.allGroups)

/**
 * Get groups related to user by id.
 * @param {string} id user's id
 */
export const getGroupsByUserId = async ({ id }) => {
  return await request(
    'get',
    `{groupsByUserId (id: ${id})${queries.groupsByUserId}}`
  )
}

export const createGroup = async (query) => {
  return await request(
    'post',
    `mutation{createGroup (input: ${query}) ${queries.createGroup}}`,
    true
  )
}

export const updateGroup = async (id, query) => {
  return await request(
    'post',
    `mutation{updateGroup (id: ${id}, input: ${query}) ${queries.updateGroup}}`,
    true
  )
}

export const getGroupByGroupId = async ({ id }) => {
  return await request(
    'get',
    `{groupByID (id: ${id})${queries.groupByGroupId}}`
  )
}
