import React, { useState, useEffect, useRef } from 'react'
import Modal from '../ContactModal'
import { ModalContext } from '../../context/ModalContext'
import useModal from '../../hooks/useModal'
import VideoModal from '../VideoModal'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/new_chevron_icon.svg'
import QuestionIcon from '-!svg-react-loader?name=Icon!../../static/images/question_icon.svg'
import { drop, truncate } from 'lodash'

const SupportDropDown = ({ userId }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [isShowing, setIsShowingModal] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)
  const dropdownContainer = useRef(null)
  const buttonContainerRef = useRef(null)
  const chevronIconRef = useRef(null)

  let showSupportModal = () => {
    window.open(process.env.CONTACT_SUPPORT_FORM_LINK, '_blank').focus();
    // LINES COMENTED TO DEPRECATE THE REQUEST FORM: NVAS-1885
    // setIsShowingModal(true)
    // setEmailSuccess(false)
  }

  const handleOnClick = (event) => {
    const chevronClicked = !!getParentByRef(event.target, chevronIconRef)
    let width = dropdownContainer.current
      ? dropdownContainer.current.offsetWidth
      : 0
    dropdownContainer.current &&
      dropdownContainer.current.setAttribute(
        'style',
        `left:${
          (chevronClicked
            ? buttonContainerRef.current.offsetWidth
            : event.offsetX) -
          width / 2
        }px`
      )
    var el = event.target
    var div = getParentByClass(el, 'support-dropdown-button')
    !div && setShowDropdown(false)
  }

  const getParentByRef = (el, ref) => {
    do {
      if (el == ref.current) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  const getParentByClass = (el, className) => {
    do {
      if (el.classList.contains(className)) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  useEffect(() => {
    window.addEventListener('click', handleOnClick)

    return () => {
      window.removeEventListener('click', handleOnClick)
    }
  }, [])

  return (
    <div className="relative my-auto" ref={buttonContainerRef}>
      <button
        className="flex flex-row text-white focus:outline-none gap-1 items-center support-dropdown-button"
        onClick={() => {
          setShowDropdown(!showDropdown)
        }}
      >
        <QuestionIcon />
        Support
        <div className="pl-2" ref={chevronIconRef}>
          <ChevronDown />
        </div>
      </button>
      {showDropdown ? (
        <div
          className="absolute flex flex-col bg-white shadow-2xl support-dropdown-contents z-10"
          ref={dropdownContainer}
        >
          <a
            className="font-light"
            target="_blank"
            rel="noreferrer"
            href={process.env.FAQ_URL}
          >
            <div className="py-2 px-6 cursor-pointer dropdown-item">
              Easy Editor FAQ
            </div>
          </a>
          <div
            className="py-2 px-6 cursor-pointer dropdown-item"
            onClick={() => setShowVideo(!showVideo)}
          >
            <span className=" font-light">Training Video</span>
          </div>
          <div
            className="py-2 px-6 cursor-pointer dropdown-item"
            onClick={showSupportModal}
          >
            <span className="font-light">Contact Support</span>
          </div>
        </div>
      ) : (
        ''
      )}
      {isShowing ? (
        <Modal
          setIsShowingModal={setIsShowingModal}
          emailSuccess={emailSuccess}
          setEmailSuccess={setEmailSuccess}
        />
      ) : (
        ''
      )}
      {showVideo ? (
        <VideoModal setShowVideo={setShowVideo} userid={userId} />
      ) : (
        ''
      )}
    </div>
  )
}

export default SupportDropDown
