import LoaderIcon from '../../static/images/pawLoader.svg'
import DogWithBall from '../../static/images/dog_with_ball.svg'

const PawLoader = ({ isBigVersion }) => {
  return (
    <div
      className={`flex w-full px-6 py-5 items-center ${
        isBigVersion ? 'flex-col h-full justify-center' : 'justify-center'
      }`}
      style={isBigVersion ? { transform: 'scale(0.5)' } : null}
    >
      <div className={isBigVersion ? '' : 'inline-block  h-14 w-14 mr-4'}>
        {isBigVersion ? <DogWithBall /> : <LoaderIcon />}
      </div>
      <div style={{ fontSize: isBigVersion ? '64px' : '16px' }}>
        {isBigVersion
          ? `We’re fetching this page`
          : `We're fetching the information...`}
      </div>
    </div>
  )
}

export default PawLoader
