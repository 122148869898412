import React, { useContext, useEffect, useState } from 'react'
import { useFlash } from '@redwoodjs/web'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import TurndownService from 'turndown'
import { client, cma } from '../../../../api/src/lib/contentful'
import Groups from '../../components/Staff/Groups'
import GroupDetails from '../../components/Staff/GroupDetails'
import StaffDetails from '../../components/Staff/StaffDetails'
// import ImagePlaceholder from '../../static/images/image-placeholder.png'
import ImagePlaceholder from '../../static/images/puppy-new.jpg'
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown'
import { createContentfulLogV2 } from 'src/api/userActionLogs'
import { Context } from '../../../Store'
import Sidebar from 'src/components/Staff/Sidebar'
import Showdown from 'showdown'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import {
  useGlobalState,
  setCurrentStaffInfo,
  setReRenderStaff,
  setCurrentStaffId,
} from '../../hooks/state/state'
import ReactGA from 'react-ga'
import { staffMessages } from '../../constants/messages/en'
import MultiLocationSelection from 'src/components/MultilocationSelection/MultilocationSelection'
import { parseStaffPagesFromNewStaffData } from './helper'
import urlSlug from 'url-slug';

const WebsitePageStaff = ({ user }) => {
  const [iFrameURL, setIFrameURL] = useState('')
  const [initialDataStaff, setInitialDataStaff] = useState(null)
  const [dataStaff, setDataStaff] = useState('')
  const {setIsAlertActive} = useContext(Context)
  const [step, setStep] = useState('multilocation')
  const [currGroup, setCurrGroup] = useState(null)
  const [currStaff, setCurrStaff] = useState(null)
  const [saveButtonDisabled, setSaveButtonDisable] = useState(true)
  const [staffPageId, setStaffPageId] = useState(null)
  const [isGroupsReordered, setIsGroupsReordered] = useState(false)
  const [reorderedStaffMembers, setReorderedStaffMembers] = useState([])
  const [iframeView, setIframeView] = useState('large')
  const [, setError] = useState(null)
  const [isnewStaffAdded, setIsNewStaffAdded] = useState(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [isUpdateStaffImage, setIsUpdateStaffImage] = useState(false)
  const [currentStaffMember, setCurrentStaffMember] = useState(null)
  const { addMessage } = useFlash()
  let location = useLocation()
  const turndownService = new TurndownService()
  let history = useHistory()
  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [environment, setEnvironment] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isBannerUpdated] = useGlobalState('isBannerUpdated')
  const [GlobalstaffInfo] = useGlobalState('currentStaffInfo')
  const [currentStaffId] = useGlobalState('currentStaffId')
  const [staffPagesList] = useGlobalState('staffPages')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [isOnlyStaff, setIsOnlyStaff] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [saving, setSaving] = useState(false)
  const [staffMemberReordered, setStaffMemberReordered] = useState(false)
  const [isAddingStaff, setIsAddingStaff] = useState(false); // to be able to toggle displayInMobileApp

  let activeEnv = process.env.CONTENTFUL_ENV
  let converter = new Showdown.Converter()
  const masterImages = process.env.MASTER_ENTRIES.split(',')
  const [selectedSite] = useGlobalState('selectedSite')
  const baseSiteURL = selectedSite.siteURL.replace(/#.*$/, '')

  let description = isBannerUpdated
    ? descriptionUpdated
    : descriptionInitial.text

  let bannerObj = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    author: {
      description,
    },
  }
  window.onbeforeunload = function () {
    if (isFormDirty) return ''
    else return undefined
  }

  useEffect(() => {
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'

    cma
      .getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE)
      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENV))
      .then((env) => setEnvironment(env))
      .catch((_)=>{
        alert("Connection with Contentul is not established");
      });
  }, [])

  useEffect(() => {
    if (
      GlobalstaffInfo &&
      Object.keys(GlobalstaffInfo).length === 0 &&
      staffPagesList.length === 0
    ) {
      history.push(
        `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
      )
    }
  }, [GlobalstaffInfo, staffPagesList])

  useEffect(() => {
    if (!location && location.state) {
      setIFrameURL(location.state.siteURL)
    }
  }, [location])

  useEffect(() => {
    if (isUpdateStaffImage) {
      if (dataStaff[currGroup].staffMembers[currStaff].selectedImage) {
        setSaveButtonDisable(false)
        setIsUpdateStaffImage(false)
        setIsFormDirty(true)
      }
    } else if (isGroupsReordered) {
      setIsFormDirty(true)
      setSaveButtonDisable(false)
    } else {
      // if (initialDataStaff && initialDataStaff !== JSON.stringify(dataStaff)) {
      //   setIsFormDirty(true)
      //   setSaveButtonDisable(false)
      //   if (
      //     dataStaff &&
      //     dataStaff[currGroup] &&
      //     dataStaff[currGroup].staffMembers &&
      //     dataStaff[currGroup].staffMembers[currStaff] &&
      //     dataStaff[currGroup].staffMembers[currStaff].name === ''
      //   ) {
      //     setSaveButtonDisable(true)
      //   }
      // }
    }

    // sendMsg(dataStaff)
    // sendMsg({ type: 'StaffChange', content: dataStaff })
      GlobalstaffInfo?.fields?.items &&
      dataStaff &&
      sendMsg({
        type: 'ScreenSizeChanged',
        // content: parseStaffPages(GlobalstaffInfo),
        content: parseStaffPagesFromNewStaffData(
          dataStaff,
          GlobalstaffInfo.fields.items[0],
          GlobalstaffInfo
        ),
      })

    // Check if reorder was done among the staff
    if (reorderedStaffMembers.length > 0) {
      updateReorderStaffMembers()
    }
  }, [dataStaff])

  useEffect(() => {
    getData()
  }, [isnewStaffAdded, currentStaffId])

  async function getData(forceUpdate) {
    try {
      setAddButtonDisabled(true);
      let data
      let content = await client.getEntries({
        content_type: 'templateDynamicLayoutPage',
        'sys.id[in]': currentStaffId,
        include: 5,
      })
      if(content.items.length > 0){
        data = content.items[0]
        setCurrentStaffInfo(content.items[0])
      } else {
        const staffPage = await selectedSiteInfo.sitePages.find(
          (page) => page.fields.pageType === 'Staff'
        )
        data = JSON.parse(JSON.stringify(staffPage))
        setCurrentStaffInfo(staffPage)
      }
      if (data) {
        let staffArray = []
        // setting the page id to link the new staff groups
        if (data.sys && data.sys.id) {
          setStaffPageId(data.sys.id)
        }

          data?.fields?.items.map((entry) => {
            if (entry?.fields == null) {
              return null
            }
            if (entry?.fields?.staffCard) {
              let staffImageUrl, staffImageId, staffVariation

              // adds a Staff Group variation into the Staff Card
              // variation-A = show "Short Bio"
              // variation-B = do not show "Short Bio"
              staffVariation = entry?.fields?.variation

              entry.fields.staffCard.map(async (card) => {
                // if entry is deleted in Contentful but it's still referenced
                if (!card.fields){
                  try {
                    // remove missing entry reference from Contentful
                    const space = await cma.getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE);
                    const env = await space.getEnvironment(process.env.CONTENTFUL_ENV);
                    const staffGroup = await env.getEntry(entry.sys.id);
                    if (staffGroup.fields.staffCard['en-US']) {
                      staffGroup.fields.staffCard['en-US'] = staffGroup.fields.staffCard['en-US']
                        .filter((item) => item.sys.id != card.sys.id)
                      const updatedStaffGroup = await staffGroup.update();
                      await updatedStaffGroup.publish();
                    }
                  } catch (error) {
                    console.error('Error while deleting staff missing entry', error);
                  }
                  return;
                }


                if (card?.fields?.staffImage?.fields?.image?.fields?.file?.url) {
                  staffImageUrl = `https:${card.fields.staffImage.fields.image.fields.file.url}`
                  staffImageId = card.fields.staffImage.fields.image.sys.id
                } else {
                  staffImageUrl = ImagePlaceholder
                }

                // staff image entry id
                let staffImageSectionId
                if (card?.fields?.staffImage?.sys) {
                  staffImageSectionId = card.fields.staffImage.sys.id
                }

                let bladeSection =
                  entry.fields.sectionHeadline && entry.fields.sectionHeadline
                let preventDeletion = entry.fields.preventDeletion
                  ? true
                  : false

                let bladeDescription = entry.fields.bodyText && {
                  json: entry.fields.bodyText,
                }

                let bladeSectionId = entry.sys?.id

                let richTextDocument
                if (card.fields?.longBio) {
                  richTextDocument = {
                    nodeType: 'document',
                    data: {},
                    content: card.fields.longBio.content,
                  }
                }

                let longBio = { json: card.fields.longBio }
                let shortBio = { json: card.fields.shortBio }
                let name = card.fields.staffName && card.fields.staffName
                let title = card.fields.title && card.fields.title
                let staffEntryId = card.sys.id
                const mobileAppDisplay = card.fields.mobileAppDisplay;

                let bExists = false
                for (let i = 0; i < staffArray.length; i++) {
                  if (staffArray[i].key === bladeSectionId) {
                    bExists = true
                    // adding staff object in this group object
                    let staffMember = {
                      staffEntryId: staffEntryId,
                      name: name,
                      title: title,
                      staffImage: staffImageUrl,
                      staffImageId: staffImageId,
                      shortBio: shortBio,
                      longBio: longBio,
                      richTextDocument: richTextDocument,
                      staffImageSectionId: staffImageSectionId,
                      staffVariation: staffVariation,
                      mobileAppDisplay,
                    }
                    staffArray[i].staffMembers.push(staffMember)
                  }
                }
                if (!bExists) {
                  // creating the group object
                  let group = {
                    key: bladeSectionId,
                    groupName: bladeSection,
                    preventDeletion,
                    variation: staffVariation,
                    bodyText: bladeDescription,
                    staffMembers: [
                      {
                        staffEntryId: staffEntryId,
                        name: name,
                        title: title,
                        staffImage: staffImageUrl,
                        staffImageId: staffImageId,
                        // richTextDocument: richTextDocument,
                        shortBio: shortBio,
                        longBio: longBio,
                        staffVariation: staffVariation,
                        staffImageSectionId: staffImageSectionId,
                        mobileAppDisplay,
                      },
                    ],
                  }
                  staffArray.push(group)
                }
              })
            }
          })
        setInitialDataStaff(JSON.stringify(staffArray))
        if (isInitialLoad && staffArray && staffArray.length > 0) {
          setTimeout(() => {
            sendMsg({ scrollToGroupId: staffArray[0].key })
          }, 1000)
        }
        setIsInitialLoad(false)
        setDataStaff(staffArray)

        setAddButtonDisabled(false)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const createContentfulLogRest = async (
    entryID,
    entryTitle,
    verbalDescription,
    updateType = 'Staff',
    path
  ) => {
    const input = {
      contentfulId: entryID,
      contentfulTitle: entryTitle,
      isPublished: 0,
      userId: user.id,
      verbalDescription,
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
      updateType,
      websiteId: selectedSite.siteId,
      path: path,
    }

    ReactGA.event({
      category: 'Content - Staff',
      action: `User ${user.id} Made Changes to Staff`,
      label: `Value: ${verbalDescription}`,
    })

    try {
      const query = JSON.stringify(input).replace(/"([^"]+)":/g, '$1:')
      await createContentfulLogV2(query)
    } catch (e) {
      setError(e)
    }
  }

  const updateStaffImageInfo = (imageStaff) => {
    const newDataStaff = [...dataStaff]
    const staffinfo = {
      ...newDataStaff[currGroup].staffMembers[currStaff],
      ...imageStaff,
    }
    newDataStaff[currGroup].staffMembers[currStaff] = staffinfo
    setDataStaff(newDataStaff)
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      win.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData })

      GlobalstaffInfo?.fields?.items &&
      dataStaff
        ? sendMsg({
            type: 'ScreenSizeChanged',
            // content: parseStaffPages(GlobalstaffInfo),
            content: parseStaffPagesFromNewStaffData(
              dataStaff,
              GlobalstaffInfo.fields.items[0],
              GlobalstaffInfo
            ),
          })
        : sendMsg({
            message: 'no clicks',
          })
    })
  }

  const goBack = (origin) => {
    const newDataStaff = [...dataStaff]

    if (step == 'group_details') {
      //getData()
      // newDataStaff.map((group, index) => {
      //   if (group.key.length < 4) {
      //     newDataStaff.splice(index, 1)
      //   }
      // })

      // setDataStaff(newDataStaff)
      sendMsg({
        type: 'ScreenSizeChanged',
        // content: parseStaffPages(GlobalstaffInfo),
        content: parseStaffPagesFromNewStaffData(
          newDataStaff,
          GlobalstaffInfo.fields.items[0],
          GlobalstaffInfo
        ),
      })
      setStep('groups')
    } else if (step == 'staff_details') {
      setIsAddingStaff(false)
      dataStaff[currGroup].staffMembers.map((member, index) => {
        // if member is current and Back clicked
        // if any fields populated (apart from Name), prompt to save or delete
        if (member === currentStaffMember && member.new && origin != 'save') {
          if (
            member.isFileSelected ||
            member.longBio ||
            member.shortBio ||
            member.title
          ) {
            alert(staffMessages.STAFF_MEMBER_SAVE_OR_DELETE)
            setStep('staff_details')
          }
          // if NO fields populated (apart from name), alert status and exit
          else if (
            !member.isFileSelected &&
            !member.longBio &&
            !member.shortBio &&
            !member.title
          ) {
            newDataStaff[currGroup].staffMembers.splice(index, 1)
            alert(staffMessages.STAFF_MEMBER_EMPTY_NOT_SAVED)
            setDataStaff(newDataStaff)
            //sendMsg(dataStaff)
            setStep('group_details')
          }
        }

        // exit to groups
        else {
          setStep('group_details')
        }
      })
    } else {
      history.push(
        `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
      )
    }
  }

  const addGroup = () => {
    const newKey = dataStaff.length.toString()
    let newGroup = {
      key: newKey,
      groupName: 'New Group',
      staffMembers: [],
      new: true,
      variation: 'Variation-A',
    }

    const newDataStaff = [newGroup, ...dataStaff]
    //newDataStaff.splice(0, 0, newGroup)

    setDataStaff(newDataStaff)
    setSaveButtonDisable(false)
    setIsFormDirty(true)
    //sendMsg(newDataStaff)
    setTimeout(() => {
      sendMsg({ scrollToGroupId: newKey })
    }, 1000)
    setCurrGroup(0)
    setStep('group_details')
  }

  const getStaffGroupUrl = () => {
    return GlobalstaffInfo?.fields?.url;
  }

  const deleteGroup = async (result, groupId, movingStaff) => {
    const grId = groupId ?? currGroup
    if (!result || result == 'undefined')
      result = confirm(staffMessages.CONFIRM_DELETE_GROUP)
    if (result) {
      setAddButtonDisabled(true)
      !movingStaff && setSaveButtonDisable(true)
      if (movingStaff && dataStaff[grId].new) {
        setAddButtonDisabled(false)
        return;
      }
      if (dataStaff[grId].new) {
        const newDataStaff = [...dataStaff]
        newDataStaff.splice(grId, 1)
        setDataStaff(newDataStaff)
      } else {
        environment
          .getEntry(staffPageId)
          .then(async (entry) => {
            const removedGroup = dataStaff[grId]
            entry.fields.items['en-US'] = entry.fields.items['en-US'].filter(
              (item) => item.sys.id != dataStaff[grId].key
            )
            createContentfulLogRest(
              removedGroup.key,
              removedGroup.groupName,
              `Delete Staff Group: ${removedGroup.groupName}`,
              'Staff',
              getStaffGroupUrl()
            )

            return entry.update()
          })
          .then((entry) => entry.publish())
          .then(() => {
            if (!movingStaff) {
              setStep('groups')
              const newDataStaff = [...dataStaff]
              newDataStaff.splice(grId, 1)
              setDataStaff(newDataStaff)
              setCurrGroup(null)
            }
          })
          .catch(console.error)
      }
      setAddButtonDisabled(false)
    }
  }

  const updateGroupName = (data) => {
    const newDataStaff = [...dataStaff]
    newDataStaff[currGroup].groupName = data || ' '
    newDataStaff[currGroup].isRenamed = true
    setDataStaff(newDataStaff)
    setSaveButtonDisable(false)
    setIsFormDirty(true)
  }

  const updateGroupDescription = (data) => {
    const markdown = turndownService.turndown(data)
    let newDataStaff = [...dataStaff]
    richTextFromMarkdown(markdown).then((res) => {
      // set it to blank if no data
      newDataStaff[currGroup].bodyText =
        data.length === 0
          ? (newDataStaff[currGroup].bodyText = {
              json: null,
            })
          : (newDataStaff[currGroup].bodyText = {
              json: res,
            })
      newDataStaff[currGroup].descriptionUpdated = true
      setDataStaff(newDataStaff)
      setSaveButtonDisable(false)
      setIsFormDirty(true)
    })
  }

  const updateVariation = (data) => {
    const newDataStaff = [...dataStaff]
    newDataStaff[currGroup].variation = data
    newDataStaff[currGroup].updatedVariation = true
    setDataStaff(newDataStaff)
    setSaveButtonDisable(false)
    setIsFormDirty(true)
  }

  const goGroup = (groupKey) => {
    setCurrGroup(groupKey)
    setStep('group_details')
  }

  const reorderGroups = (source, dest) => {
    const groupToMove = dataStaff[source]
    const newDataStaff = [...dataStaff]
    newDataStaff.splice(source, 1)
    newDataStaff.splice(dest, 0, groupToMove)
    setDataStaff(newDataStaff)
    setIsGroupsReordered(true)
    setIsFormDirty(true)
    setSaveButtonDisable(false)
  }

  const addStaff = () => {
    setStaffMemberReordered(false) // ignore reorder "flag" if adding new staff, reorder is done right away, this is for "loader/disable" buttons issue
    const newKey = dataStaff[currGroup].staffMembers.length.toString()
    const newDataStaff = [...dataStaff]

    newDataStaff[currGroup].staffMembers.push({
      staffEntryId: newKey,
      name: 'New Staff Member',
      title: null,
      staffImage: ImagePlaceholder,
      shortBio: null,
      longBio: null,
      new: true,
    })

    setDataStaff(newDataStaff)
    setCurrStaff(newKey)

    let [last] = dataStaff[currGroup].staffMembers.slice(-1)

    setCurrentStaffMember(last)
    setIsAddingStaff(true)
    setStep('staff_details')
    setSaveButtonDisable(false)
    setIsFormDirty(true)
  }

  const deleteStaff = async (onlyStaff, staffId) => {
    let result
    if (onlyStaff) {
      result = confirm(staffMessages.NO_EMPTY_GROUPS)

      if (result) {
        deleteGroup(true)
      }
    } else {
      result = confirm(staffMessages.CONFIRM_DELETE_STAFF_MEMBER)
      if (result) deleteStaffWithConfirmation(result, staffId)
    }
  }

  const deleteStaffWithConfirmation = async (result, staffId) => {
    setAddButtonDisabled(true)
    setSaveButtonDisable(true)
    const staffEntryId =
      staffId ?? dataStaff[currGroup].staffMembers[currStaff].staffEntryId

    const staffInfo = dataStaff[currGroup].staffMembers.find(
      (staff) => staff.staffEntryId === staffEntryId
    )


    const url = `/staff/${urlSlug(staffInfo.name)}`;

    await environment
      .getEntry(dataStaff[currGroup].key)
      .then(async (entry) => {
        entry.fields.staffCard['en-US'] = entry.fields.staffCard[
          'en-US'
        ].filter((item) => item.sys.id != staffEntryId)
        return entry.update()
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        setStep('group_details')
        createContentfulLogRest(
          entry.sys.id,
          entry.fields.name['en-US'],
          'Staff Removed',
          'Staff',
          url
        )
        const newDataStaff = [...dataStaff]
        const indexOfRemoved = newDataStaff[currGroup].staffMembers.findIndex(
          (staff) => staff.staffEntryId === staffId
        )
        newDataStaff[currGroup].staffMembers.splice(indexOfRemoved, 1)
        setDataStaff(newDataStaff)
        setCurrStaff(null)
        setAddButtonDisabled(false)
      })
      .catch(console.error)
  }

  const updateStaffImage = (data) => {
    const newDataStaff = [...dataStaff]
    newDataStaff[currGroup].staffMembers[currStaff].edited = true
    newDataStaff[currGroup].staffMembers[currStaff].staffImage = data
    setCurrentStaffMember(dataStaff[currGroup].staffMembers[currStaff])
    setDataStaff(newDataStaff)
  }

  const updateStaffName = (data) => {
    let newDataStaff = [...dataStaff]
    newDataStaff[currGroup].staffMembers[currStaff].edited = true

    // set it to blank if no data
    newDataStaff[currGroup].staffMembers[currStaff].name = data ? data : ''
    setCurrentStaffMember(newDataStaff[currGroup].staffMembers[currStaff])

    setDataStaff(newDataStaff)
    //sendMsg(dataStaff)
    if (data.length > 0) {
      setSaveButtonDisable(false)
    } else {
      setSaveButtonDisable(true)
    }
  }

  const updateStaffGroup = (newGroupIndex) => {
    let newDataStaff = [...dataStaff]
    let result
    const staff = newDataStaff[currGroup].staffMembers[currStaff]
    const staffMembersLength = newDataStaff[currGroup].staffMembers.length

    const moveStaff = (isOnlyStaff) => {
      let newCurrGroup = newGroupIndex
      //  staff.edited = true
      staff.changedGroup = true
      staff.oldGroup = newDataStaff[currGroup].key
      newDataStaff[currGroup].staffMembers.splice(currStaff, 1)
      newDataStaff[newGroupIndex].staffMembers = [
        staff,
        ...newDataStaff[newGroupIndex].staffMembers,
      ]
      if (isOnlyStaff) {
        newDataStaff.splice(currGroup, 1)
        if (currGroup < newCurrGroup) newCurrGroup = newCurrGroup - 1
        deleteGroup(true, currGroup, true)
      }

      // set it to blank if no data
      setCurrStaff(0)
      setCurrGroup(newCurrGroup)

      // setCurrentStaffInfo(newDataStaff)
      setDataStaff(newDataStaff)
      //sendMsg(dataStaff)
      setSaveButtonDisable(false)
      setIsFormDirty(true)
    }

    if (staffMembersLength == 1) {
      result = confirm(staffMessages.NO_EMPTY_GROUPS_MOVING_STAFF)
      if (result) moveStaff(true)
    } else moveStaff(false)
  }

  const updateStaffTitle = (data) => {
    let newDataStaff = [...dataStaff]
    newDataStaff[currGroup].staffMembers[currStaff].edited = true

    // set it to blank if no data
    newDataStaff[currGroup].staffMembers[currStaff].title = data ? data : ''
    setDataStaff(newDataStaff)
    setCurrentStaffMember(newDataStaff[currGroup].staffMembers[currStaff])
    setSaveButtonDisable(false)
    setIsFormDirty(true)
    //sendMsg(dataStaff)
  }

  const updateStaffShortBio = (data) => {
    const markdown = turndownService.turndown(data)
    let newDataStaff = [...dataStaff]
    richTextFromMarkdown(markdown).then((res) => {
      newDataStaff[currGroup].staffMembers[currStaff].edited = true
      // set it to blank if no data
      newDataStaff[currGroup].staffMembers[currStaff].shortBio =
        data.length === 0
          ? (newDataStaff[currGroup].staffMembers[currStaff].shortBio = {
              json: null,
            })
          : (newDataStaff[currGroup].staffMembers[currStaff].shortBio = {
              json: res,
            })
      setDataStaff(newDataStaff)
      setSaveButtonDisable(false)
      setIsFormDirty(true)
    })

    setCurrentStaffMember(newDataStaff[currGroup].staffMembers[currStaff])
  }

  const updateStaffLongBio = (data) => {
    const markdown = turndownService.turndown(data)
    let newDataStaff = [...dataStaff]
    richTextFromMarkdown(markdown).then((res) => {
      newDataStaff[currGroup].staffMembers[currStaff].edited = true

      // set it to blank if no data
      newDataStaff[currGroup].staffMembers[currStaff].longBio =
        data.length === 0
          ? (newDataStaff[currGroup].staffMembers[currStaff].longBio = {
              json: null,
            })
          : (newDataStaff[currGroup].staffMembers[currStaff].longBio = {
              json: res,
            })
      setDataStaff(newDataStaff)
      setSaveButtonDisable(false)
      setIsFormDirty(true)
    })

    setCurrentStaffMember(dataStaff[currGroup].staffMembers[currStaff])
  }

  const updateDisplayInMobileApp = (data) => {
    const newDataStaff = [...dataStaff]
    newDataStaff[currGroup].staffMembers[currStaff].mobileAppDisplay = data
    setDataStaff(newDataStaff)
    setSaveButtonDisable(false)
    setIsFormDirty(true)
  }

  const goStaff = (staffKey, staffLength) => {
    if (staffLength <= 1) {
      setIsOnlyStaff(true)
    } else {
      setIsOnlyStaff(false)
    }
    setCurrStaff(staffKey)
    setIsAddingStaff(false)
    setStep('staff_details')
  }

  const reorderStaff = (source, dest) => {
    const staffToMove = dataStaff[currGroup].staffMembers[source]
    const newDataStaff = [...dataStaff]
    // delete the source element
    newDataStaff[currGroup].staffMembers.splice(source, 1)
    // insert staffToMove into destination index
    newDataStaff[currGroup].staffMembers.splice(dest, 0, staffToMove)
    setDataStaff(newDataStaff)

    setIsFormDirty(true)
    setSaveButtonDisable(false)

    if (!reorderedStaffMembers.includes(dataStaff[currGroup].key)) {
      reorderedStaffMembers.push(dataStaff[currGroup].key)
    }
  }

  const renderStep = () => {
    switch (step) {
      case 'multilocation':
        if (staffPagesList.length == 1) {
          setStep('groups')
          return
        }
        return (
          <MultiLocationSelection
            title={'Staff Information'}
            description={`Please select a location to update staff.`}
            accordionFor="multiLocationStaff"
            onClick={() => setStep('groups')}
          />
        )
      case 'groups':
      default:
        return (
          <Groups
            data={dataStaff}
            addGroup={addGroup}
            goGroup={goGroup}
            reorderGroups={reorderGroups}
            addButtonDisabled={addButtonDisabled}
            deleteGroup={deleteGroup}
          />
        )

      case 'group_details':
        return (
          <GroupDetails
            selectedSite={selectedSite}
            group={currGroup}
            data={dataStaff}
            addStaff={addStaff}
            goStaff={goStaff}
            deleteGroup={deleteGroup}
            updateGroupName={updateGroupName}
            updateGroupDescription={updateGroupDescription}
            reorderStaff={reorderStaff}
            addButtonDisabled={addButtonDisabled}
            setAddButtonDisabled={setAddButtonDisabled}
            updateVariation={updateVariation}
            deleteStaff={deleteStaff}
          />
        )

      case 'staff_details':
        return (
          <StaffDetails
            isAddingStaff={isAddingStaff}
            group={currGroup}
            staff={currStaff}
            data={dataStaff}
            isCropped={false}
            selectedSite={selectedSite}
            isOnlyStaff={isOnlyStaff}
            deleteStaff={deleteStaff}
            updateStaffImage={updateStaffImage}
            updateStaffName={updateStaffName}
            updateStaffTitle={updateStaffTitle}
            updateStaffShortBio={updateStaffShortBio}
            updateStaffLongBio={updateStaffLongBio}
            updateStaffGroup={updateStaffGroup}
            saveButtonDisabled={saveButtonDisabled}
            setSaveButtonDisable={setSaveButtonDisable}
            setIsUpdateStaffImage={setIsUpdateStaffImage}
            updateStaffImageInfo={updateStaffImageInfo}
            updateDisplayInMobileApp={updateDisplayInMobileApp}
          />
        )
    }
  }

  let addNewStaff = async (staffGroup, staffMember) => {
    if (staffMember.new || (staffMember.new && staffMember.edited)) {
      try {
        if (staffMember.added) {
          return null
        }
        let imageContainerId
        if (staffMember.isFileSelected) {
          await createImageAsset(staffMember).then((assetId) =>
            createImageContainer(assetId, staffMember).then((containerId) => {
              imageContainerId = containerId
            })
          )
        }

        const staffEntry = {
          fields: {
            name: {
              'en-US': `STAFF - ${selectedSite.name} ${selectedSite.yextId} - ${
                staffMember.name || 'New Staff Member'
              }`,
            },
            staffName: {
              'en-US': staffMember.name || 'New Staff Member',
            },
            title: {
              'en-US': staffMember.title,
            },
            mobileAppDisplay: {
              'en-US': staffMember.mobileAppDisplay || false,
            },
            group: {
              'en-US': {
                sys: {
                  // This hardcoded ID is needed to create a new staff member,
                  // and it is equivalent a "SITE-0_Group - Doctors" in the "STAFF GROUP" field
                  // requires attention in the future
                  id: '2lI5U2nGeQeoIeKE4yWAka',
                  linkType: 'Entry',
                  type: 'Link',
                },
              },
            },
            shortBio: {
              'en-US': staffMember.shortBio ? staffMember.shortBio.json : null,
            },
            longBio: {
              'en-US': staffMember.longBio ? staffMember.longBio.json : null,
            },
            // WIP - ADD STAFF IMAGE WHEN CREATE NEW STAFF MEMBER
            staffImage: {
              'en-US': {
                sys: {
                  id:
                    imageContainerId ||
                    process.env.CONTENTFUL_IMAGE_STAFF_PLACEHOLDER,
                  linkType: 'Entry',
                  type: 'Link',
                },
              },
            },
            url: {
              'en-US':
                staffPagesList.length > 1
                  ? `${GlobalstaffInfo.fields.url}/${staffMember.name
                      .replace(/\s+/g, '-')
                      .toLowerCase()}`
                  : '',
            },
          },
        }
        if (!staffGroup.new || (staffGroup.new && staffGroup.staffGroupKey)) {
          await environment
            .getEntry(
              staffGroup.new ? staffGroup.staffGroupKey : staffGroup.key
            )
            .then(async (entry) => {
              await environment
                .createEntry('staff', staffEntry)
                .then(async (entryN) => {
                  staffMember.entryId = entryN && entryN.sys && entryN.sys.id
                  entry.fields.staffCard['en-US'].push({
                    sys: {
                      id: entryN && entryN.sys && entryN.sys.id,
                      linkType: 'Entry',
                      type: 'Link',
                    },
                  })
                  staffMember['added'] = true
                  entryN
                    .update()
                    .then((entryN) => entryN.publish())
                    .then(() => {
                      entry
                        .update()
                        .then((entry) => entry.publish())
                        .then(async () => {
                          setIsNewStaffAdded(!isnewStaffAdded)
                          // setAddButtonDisabled(false) //move to useeffect
                          const url = `/staff/${urlSlug(staffMember.name)}`;
                          await createContentfulLogRest(
                            entryN.sys.id,
                            entryN.fields.name['en-US'],
                            staffMember.mobileAppDisplay ? `New Staff - Show this clinician's bio in the mobile app` : 'New Staff',
                            "Staff",
                            url
                          )
                        })
                    })
                })
            })
            .catch((err) =>
              console.error('Err while adding new staff to contentful:', err)
            )
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  let updateExistingGroup = async (staffGroup) => {
    if (staffGroup.key.length > 4) {
      await environment
        .getEntry(staffGroup.key)
        .then((entry) => {
          // renaming the entry name and section headline
          if (entry.fields.sectionHeadline) {
            entry.fields.sectionHeadline['en-US'] = staffGroup.groupName //edit
          } else {
            // add new field
            entry.fields.sectionHeadline = {
              'en-US': staffGroup.groupName,
            }
          }

          entry.fields.name[
            'en-US'
          ] = `STAFF BLADE - ${selectedSite.name} ${selectedSite.yextId} - ${staffGroup.groupName} `

          if (staffGroup.bodyText?.json) {
            entry.fields.bodyText['en-US'] = staffGroup.bodyText.json //edit
          } else {
            // add new field
            entry.fields.bodyText = {
              'en-US': null,
            }
          }

          entry.fields.variation['en-US'] = staffGroup.variation

          return entry.update()
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          staffGroup.isRenamed = false
          createContentfulLogRest(
            entry.sys.id,
            entry.fields.name['en-US'],
            'Staff Blade Renamed',
            'Staff',
            getStaffGroupUrl()
          )
          setAddButtonDisabled(false)
        })
        .catch((err) => {
          setAddButtonDisabled(false)
          console.error('Err caught while updateExistingGroupName:', err)
        })
    }
  }

  let updateExistingGroupName = async (staffGroup) => {
    if (staffGroup.isRenamed && staffGroup.key.length > 4) {
      await environment
        .getEntry(staffGroup.key)
        .then((entry) => {
          // renaming the entry name and section headline
          if (entry.fields.sectionHeadline) {
            entry.fields.sectionHeadline['en-US'] = staffGroup.groupName //edit
          } else {
            // add new field
            entry.fields.sectionHeadline = {
              'en-US': staffGroup.groupName,
            }
          }

          entry.fields.name[
            'en-US'
          ] = `STAFF BLADE - ${selectedSite.name} ${selectedSite.yextId} - ${staffGroup.groupName} `
          return entry.update()
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          staffGroup.isRenamed = false
          createContentfulLogRest(
            entry.sys.id,
            entry.fields.name['en-US'],
            'Staff Blade Renamed',
            'Staff',
            getStaffGroupUrl()
          )
          // setAddButtonDisabled(false)
        })
        .catch((err) => {
          setAddButtonDisabled(false)
          console.error('Err caught while updateExistingGroupName:', err)
        })
    }
  }

  let addNewGroup = async (staffGroup) => {
    //Creating 'staff' first, then crerate 'staffBlade' andd attach 'staff' to 'staffBlade'

    if (staffGroup.added) {
      return
    }

    if (staffGroup.staffMembers && staffGroup.staffMembers.length > 0) {
      let staffMember = staffGroup.staffMembers[0]
      let imageContainerId
      if (staffMember.isFileSelected) {
        await createImageAsset(staffMember).then((assetId) =>
          createImageContainer(assetId, staffMember).then((containerId) => {
            imageContainerId = containerId
          })
        )
      }
      const staffEntry = {
        fields: {
          name: {
            'en-US': `STAFF - ${selectedSite.name} ${selectedSite.yextId} - ${
              staffMember.name || 'New Staff Member'
            } `,
          },
          staffName: {
            'en-US': staffMember.name || 'New Staff Member',
          },
          title: {
            'en-US': staffMember.title,
          },
          mobileAppDisplay: {
            'en-US': staffMember.mobileAppDisplay || false,
          },
          group: {
            'en-US': {
              sys: {
                // This hardcoded ID is needed to create a new staff member,
                // and it is equivalent a "SITE-0_Group - Doctors" in the "STAFF GROUP" field
                // requires attention in the future
                id: '2lI5U2nGeQeoIeKE4yWAka',
                linkType: 'Entry',
                type: 'Link',
              },
            },
          },
          shortBio: {
            'en-US': staffMember.shortBio ? staffMember.shortBio.json : null,
          },
          longBio: {
            'en-US': staffMember.longBio ? staffMember.longBio.json : null,
          },
          // WIP - ADD STAFF IMAGE WHEN CREATE NEW STAFF MEMBER
          staffImage: {
            'en-US': {
              sys: {
                id:
                  imageContainerId ||
                  process.env.CONTENTFUL_IMAGE_STAFF_PLACEHOLDER,
                linkType: 'Entry',
                type: 'Link',
              },
            },
          },
          url: {
            'en-US':
              staffPagesList.length > 1
                ? `${GlobalstaffInfo.fields.url}/${staffMember.name
                    .replace(/\s+/g, '-')
                    .toLowerCase()}`
                : '',
          },
        },
      }
      await environment
        .createEntry('staff', staffEntry)
        .then((entry) => entry.update())
        .then((entry) => entry.publish())
        .then(async (entry) => {
          await environment
            .createEntry('staffBlade', {
              fields: {
                name: {
                  'en-US': `STAFF BLADE - ${selectedSite.name} ${
                    selectedSite.yextId
                  } - ${staffGroup.groupName || 'Unnamed Staff Group'} `,
                },
                bodyText: {
                  'en-US': staffGroup?.bodyText?.json || {
                    nodeType: 'document',
                    data: {},
                    content: [],
                  },
                },
                sectionHeadline: {
                  'en-US': staffGroup.groupName || 'Unnamed Staff Group',
                },
                variation: { 'en-US': staffGroup.variation },
                staffCard: {
                  'en-US': [
                    {
                      sys: {
                        id: entry.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                      },
                    },
                  ],
                },
                // THIS IS NOT BEEN USED IN MASTER / NEW UAT - only in DEVCR
                group: {
                  'en-US': {
                    sys: {
                      // (UPDATE ME AFTER MIGRATION TO MASTER)
                      id: activeEnv === 'devcr' ? '1fUPqkPUo4UUQwsgU4q6k2' : '', // static Group entry (required field in Contentful)
                      linkType: 'Entry',
                      type: 'Link',
                    },
                  },
                },
              },
            })
            .then((entry) => entry.update())
            .then((entry) => entry.publish())
            .then(async (groupEntry) => {
              try {
                await environment
                  .getEntry(staffPageId)
                  .then((entry) => {
                    const nonStaffBladeArray = entry.fields.items[
                      'en-US'
                    ].filter(
                      (item) => !dataStaff.some((e) => e.key === item.sys.id)
                    )

                    const staffBladeArray = entry.fields.items['en-US'].filter(
                      (item) => dataStaff.some((e) => e.key === item.sys.id)
                    )

                    entry.fields.items['en-US'] = [
                      ...nonStaffBladeArray,
                      {
                        sys: {
                          type: 'Link',
                          linkType: 'Entry',
                          id: groupEntry.sys.id,
                        },
                      },
                      ...staffBladeArray,
                    ]

                    return entry.update()
                  })
                  .then((entry) => entry.publish())
                  .then(async () => {
                    await createContentfulLogRest(
                      groupEntry.sys.id,
                      groupEntry.fields.name['en-US']
                        ? groupEntry.fields.name['en-US']
                        : 'No Name',
                      `New Group Created: ${groupEntry.fields.name['en-US']}`,
                      'Staff',
                      getStaffGroupUrl()
                    )
                    const url = `/staff/${urlSlug(staffMember.name)}`;
                    await createContentfulLogRest(
                      entry.sys.id,
                      entry.fields.name['en-US'],
                      staffMember.mobileAppDisplay ? `New Staff - Show this clinician's bio in the mobile app` : 'New Staff',
                      "Staff",
                      url
                    )
                    staffGroup['added'] = true
                    staffGroup.staffGroupKey = groupEntry.sys.id
                    staffGroup.key = groupEntry.sys.id
                    setIsNewStaffAdded(!isnewStaffAdded)
                    // setAddButtonDisabled(false)  // move to useeffect
                    return null
                  })
              } catch (error) {
                console.error(error)
                return null
              }
            })
        })
    }
    return null
    // else {
    //   const confirm = window.confirm(
    //     'Please add at least 1 New Staff Member to this group...'
    //   )

    //   setSaveButtonDisable(true)
    //   setAddButtonDisabled(false)
    //   return null
    // }
  }

  const changeStaffGroup = async (staff, oldGroup, newGroup) => {
    return await environment
      .getEntry(oldGroup)
      .then((entry) => {
        const newStaffArray = entry.fields.staffCard['en-US'].filter(
          (item) => staff.staffEntryId !== item.sys.id
        )
        if (newStaffArray.length === 0) return entry
        entry.fields.staffCard['en-US'] = newStaffArray

        return entry.update()
      })
      .then((entry) => entry.publish())
      .then(async (entry) => {
        await environment
          .getEntry(newGroup)
          .then((newEntry) => {
            const isStaffAlreadyInTheGroup = newEntry.fields.staffCard[
              'en-US'
            ].find((i) => i.sys.id === staff.staffEntryId)

            if (!isStaffAlreadyInTheGroup) {
              newEntry.fields.staffCard['en-US'] = [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Entry',
                    id: staff.staffEntryId,
                  },
                },
                ...newEntry.fields.staffCard['en-US'],
              ]

              createContentfulLogRest(
                staff.staffEntryId,
                staff.name,
                `StaffMember (${staff.name}) changed from ${entry.fields.name['en-US']} to ${newEntry.fields.name['en-US']}`,
                'Staff',
                getStaffGroupUrl()
              )

              return newEntry.update()
            }
          })
          .then((newEntry) => newEntry.publish())
      })
      //.then(setAddButtonDisabled(false))
      .catch(console.error)
  }

  let editExistingStaff = async (staffMember) => {
    return await environment
      .getEntry(staffMember.staffEntryId)
      .then((entry) => {
        entry.fields.title = { 'en-US': staffMember.title || '' }
        entry.fields.staffName = {
          'en-US': staffMember.name || 'Unnamed staff member',
        }
        entry.fields.name = {
          'en-US': `STAFF - ${selectedSite.name} ${selectedSite.yextId} - ${
            staffMember.name || 'Unnamed staff member'
          } `,
        }
        if (staffMember.longBio) {
          // if field is populated
          if (entry.fields.longBio) {
            entry.fields.longBio['en-US'] = staffMember.longBio.json
          }
          // if field is not populated
          else {
            entry.fields.longBio = {
              'en-US': staffMember.longBio.json,
            }
          }
        }
        if (staffMember.shortBio) {
          // if field is populated
          if (entry.fields.shortBio) {
            entry.fields.shortBio['en-US'] = staffMember.shortBio.json
          }
          // if field is not populated
          else {
            entry.fields.shortBio = {
              'en-US': staffMember.shortBio.json,
            }
          }
        }
        if (!staffMember.staffImageSectionId) {
          // if staff has no image, assign placeholder
          environment
            .getEntry(process.env.CONTENTFUL_IMAGE_STAFF_PLACEHOLDER)
            .then((entry) => {
              environment
                .getAsset(entry.fields.image['en-US'].sys.id)
                .then(async (asset) => {
                  staffMember.staffImage = asset.fields.file['en-US'].url
                })
              staffMember.staffImageId = entry.fields.image['en-US'].sys.id
            })
          staffMember.staffImageSectionId =
            process.env.CONTENTFUL_IMAGE_STAFF_PLACEHOLDER

          entry.fields.staffImage = {
            'en-US': {
              sys: {
                id: process.env.CONTENTFUL_IMAGE_STAFF_PLACEHOLDER,
                linkType: 'Entry',
                type: 'Link',
              },
            },
          }
        }
        const url = `/staff/${urlSlug(staffMember.name)}`;
        createContentfulLogRest(
          entry.sys.id,
          entry.fields.name['en-US'],
          'Staff Entry Updated',
          'Staff',
          url
        )
        return entry.update()
        //.then(() => setIsNewStaffAdded(!isnewStaffAdded))
      })
      .then((entry) => entry.publish())
      //.then(setAddButtonDisabled(false))
      .catch(console.error)
  }

  const createImageAsset = async (staffMember) => {
    let imageFileName = staffMember.name
      .toLowerCase()
      // eslint-disable-next-line prettier/prettier
      .replace('/s/g', '-')
      .replace('.', '')

    return await environment
      .createAssetFromFiles({
        fields: {
          title: {
            'en-US': `IMAGE ASSET - ${selectedSite.name} ${selectedSite.yextId} - ${staffMember.name} `,
          },
          description: {
            'en-US': `${selectedSite.name} - ${staffMember.name} `,
          },
          file: {
            'en-US': {
              contentType: 'image/svg+xml',
              fileName: `new- cropped - staff - ${imageFileName}.jpg`,
              file: staffMember.newCroppedImage || staffMember.selectedImage,
            },
          },
        },
      })
      .then((asset) => asset.processForAllLocales())
      .then((asset) => asset.update())
      .then((asset) => asset.publish())
      .then((asset) => {
        return asset.sys.id
      })
  }

  const createImageContainer = async (assetId, staffMember) => {
    return await environment
      .createEntry('image', {
        fields: {
          name: {
            'en-US': `IMAGE CONTAINER - ${selectedSite.name} ${
              selectedSite.yextId
            } - ${staffMember.name || 'Unnamed Placeholder'} `,
          },
          altText: {
            'en-US': `${staffMember.name}`,
          },
          image: {
            'en-US': {
              sys: {
                id: assetId,
                linkType: 'Asset',
                type: 'Link',
              },
            },
          },
        },
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        return entry.sys.id
      })
  }

  const assignImageContainer = async (imageContainerId, staffMember) => {
    await environment
      .getEntry(staffMember.staffEntryId)
      .then((entry) => {
        entry.fields.staffImage = {
          'en-US': {
            sys: {
              id: imageContainerId,
              linkType: 'Entry',
              type: 'Link',
            },
          },
        }
        const url = `/staff/${urlSlug(staffMember.name)}`;
        createContentfulLogRest(
          entry.sys.id,
          entry.fields.name['en-US'],
          'Staff Image Updated',
          'Staff',
          url
        )
        return entry.update()
      })
      .then((entry) => entry.publish())
      .then(() => {
        if (!staffMember.new) {
          // setAddButtonDisabled(false)  // move to useeffect
          setIsNewStaffAdded(!isnewStaffAdded)
        }
      })
      .catch(console.error)
  }

  const updateImageAssetFile = async (staffMember) => {
    let imageFileName =
      staffMember.name.toLowerCase().replace('/s/g', '-').replace('.', '') +
      Math.floor(Math.random() * Math.floor(staffMember.name.length)).toString()

    //get and update asset
    environment
      .getAsset(staffMember.staffImageId)
      .then(async (asset) => {
        const upload = await environment.createUpload({
          file: staffMember.newCroppedImage || staffMember.selectedImage,
        })
        asset.fields.file = {
          'en-US': {
            contentType: 'image/svg+xml',
            fileName: `new- cropped - staff - ${imageFileName}.jpg`,
            uploadFrom: {
              sys: {
                type: 'Link',
                linkType: 'Upload',
                id: upload.sys.id,
              },
            },
          },
        }
        return asset.update()
      })
      .then((asset) => asset.processForAllLocales())
      .then((asset) => asset.publish())
      .then((asset) => {
        const url = `/staff/${urlSlug(staffMember.name)}`;
        createContentfulLogRest(
          asset.sys.id,
          asset.fields.title['en-US'],
          'Image Asset Updated',
          'Staff',
          url
        )
        setIsNewStaffAdded(!isnewStaffAdded)
        // setAddButtonDisabled(false)  // move to useeffect
      })
      .catch(console.error)
  }

  let updateCroppedImage = async (staffMember) => {
    try {
      // Existing staff with placeholder image and uploaded new file
      if (
        masterImages.includes(staffMember.staffImageSectionId) &&
        staffMember.isFileSelected
      ) {
        // create asset, then create container, then assign container to staff member
        createImageAsset(staffMember).then((assetId) => {
          createImageContainer(assetId, staffMember).then(
            (imageContainerId) => {
              assignImageContainer(imageContainerId, staffMember)
            }
          )
        })
      } else {
        // uploaded new image (existing image not placeholder) or cropped the image
        // To Do: check if an edit does not involve cropping the image, then do not updateImageAssetFile
        updateImageAssetFile(staffMember)
      }
    } catch (error) {
      console.error(error)
    }
  }

  let updateReorderGroups = async (isNewGroupAvailable) => {
    try {
      await environment
        .getEntry(staffPageId)
        .then((entry) => {
          const newDataStaff = [...dataStaff]
          newDataStaff.map((group, index) => {
            if (group.key.length < 4) {
              newDataStaff.splice(index, 1)
            }
          })
          const nonStaffBladeArray = entry.fields.items['en-US'].filter(
            (item) => !newDataStaff.some((e) => e.key === item.sys.id)
          )
          const groupArray = entry.fields.items['en-US'].filter((item) =>
            newDataStaff.some((e) => e.key === item.sys.id)
          )
          groupArray.map((group, index) => {
            group.sys.id = newDataStaff[index].key
          })
          entry.fields.items['en-US'] = nonStaffBladeArray.concat(groupArray)
          return entry.update()
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          createContentfulLogRest(entry.sys.id, 'Group Reordered',"","Staff", getStaffGroupUrl())
          if (!isNewGroupAvailable) setStep('groups')
          setIsGroupsReordered(!isGroupsReordered)
        })
    } catch (error) {
      console.error(error)
    }
  }

  let updateReorderStaffMembers = () => {
    reorderedStaffMembers.map(async (key) => {
      try {
        await environment
          .getEntry(key)
          .then((entry) => {
            const staffArray = entry.fields.staffCard['en-US']

            staffArray.map((staff, index) => {
              staff.sys.id = dataStaff.find(
                (item) => item.key === key
              ).staffMembers[index].staffEntryId
            })
            entry.fields.staffCard['en-US'] = staffArray
            return entry.update()
          })
          .then((entry) => entry.publish())
          .then((entry) => {
            createContentfulLogRest(entry.sys.id, 'Staff Members Reordered', "", "Staff", getStaffGroupUrl())
            console.log(
              '🚀 ~ file: WebsitePageStaff.js ~ line 1609 ~ .then ~ Staff Members Reordered'
            )
            setStaffMemberReordered(true)
            setAddButtonDisabled(false)
          })
      } catch (error) {
        console.error(error)
      }
    })
    setReorderedStaffMembers([])
  }

  let conFirmAddGroup = (staffExist, staffGroup) => {
    if (staffExist) {
      addNewGroup(staffGroup)
      return false
    } else {
      let result = confirm(staffMessages.GROUP_NEEDS_STAFF_MEMBER)
      setSaveButtonDisable(true)
      setAddButtonDisabled(false)
      if (result) return true
      else return false
    }
  }

  const saveChanges = async () => {
    if(environment===null){
      alert("Connection with Contentul is not established");
      return;
    }
    console.log(
      '🚀 ~ file: WebsitePageStaff.js ~ line 1808 ~ saveChanges ~ saveChanges'
    )

    setIsAlertActive(true)
    setAddButtonDisabled(true)
    setSaving(true)

    let isEdited = false
    let isNewGroup = false
    let keepInGroupDetails = false
    let isNewStaff = false // this will determine if reorder should proceed at the end
    let ignoreGroupReorder = false

    // creating/updating/editing staff member entries
    dataStaff.forEach(async (staffGroup) => {
      let ignoreAddNewStaff = false
      // Check groups
      if (staffGroup.new) {
        isEdited = true
        isNewGroup = true
        isNewStaff = true
        let staffExist = staffGroup.staffMembers.length > 0 ? true : false

        if (isGroupsReordered) {
          if (staffExist) keepInGroupDetails = false
          else keepInGroupDetails = true

          updateReorderGroups(true).then(() => {
            keepInGroupDetails = conFirmAddGroup(staffExist, staffGroup)
          })
        } else {
          keepInGroupDetails = conFirmAddGroup(staffExist, staffGroup)
        }
      } else if (isGroupsReordered && staffGroup.isRenamed && !isNewGroup) {
        ignoreAddNewStaff = true
        isEdited = true
        ignoreGroupReorder = true
        await updateReorderGroups().then(async () => {
          await updateExistingGroupName(staffGroup).then(() => {
            staffGroup.staffMembers.forEach(async (item) => {
              //Adding new staff member to existing Group
              if (item.new) {
                isEdited = true
                isNewStaff = true
                await addNewStaff(staffGroup, item)
              }
            })
            if (!isNewStaff) setAddButtonDisabled(false)
          })
        })
      } else if (!staffGroup.new && staffGroup.isRenamed) {
        ignoreAddNewStaff = true
        isEdited = true
        await updateExistingGroupName(staffGroup).then(async () => {
          staffGroup.staffMembers.forEach(async (item) => {
            //Adding new staff member to existing Group
            if (item.new) {
              isEdited = true
              isNewStaff = true
              await addNewStaff(staffGroup, item)
            }
          })
          if (staffGroup.descriptionUpdated || staffGroup.updatedVariation) {
            await updateExistingGroup(staffGroup)
          }
          if (!isNewStaff) setAddButtonDisabled(false)
        })
      getData()
      } else if (
        staffGroup.updatedVariation ||
        staffGroup.isRenamed ||
        staffGroup.descriptionUpdated
      ) {
        await updateExistingGroup(staffGroup)
        getData()
          // staffGroup.staffMembers.forEach(async (item) => {
          //   //Adding new staff member to existing Group
          //   if (item.new) {
          //     isEdited = true
          //     isNewStaff = true
          //     await addNewStaff(staffGroup, item)
          //   }
          // })
          // if (!isNewStaff) setAddButtonDisabled(false)
      }
      // else {
      //   console.log(
      //     '🚀 ~ file: WebsitePageStaff.js ~ line 1405 ~ dataStaff.forEach ~ staffGroup other condition'
      //   )
      //   ignoreAddNewStaff = true
      //   isEdited = true
      //   await updateExistingGroup(staffGroup).then(() => {
      //     console.log(
      //       '🚀 ~ file: WebsitePageStaff.js ~ line 1364 ~ awaitupdateExistingGroupName ~ updateExistingGroupName'
      //     )
      //     staffGroup.staffMembers.forEach(async (item) => {
      //       //Adding new staff member to existing Group
      //       if (item.new) {
      //         isEdited = true
      //         isNewStaff = true
      //         await addNewStaff(staffGroup, item)
      //       }
      //     })
      //     if (!isNewStaff) setAddButtonDisabled(false)
      //   })
      // }

      // Check staff
      staffGroup.staffMembers.forEach(async (item) => {
        //Adding new staff member to existing Group
        if (item.new) {
          isEdited = true
          isNewStaff = true
          if (isGroupsReordered) {
            ignoreGroupReorder = true
            updateReorderGroups(true).then(await addNewStaff(staffGroup, item))
          } else if (!ignoreAddNewStaff) await addNewStaff(staffGroup, item)
        }

        // if only image is cropped or new image uploaded for exisitng staff and text content is not changed
        // Note: this scenario never happens because item.edited is set to true even when only image was updated
        if (
          (item.isCropped || item.isFileSelected) &&
          !item.edited &&
          !item.new
        ) {
          isEdited = true
          updateCroppedImage(item)
        }

        //if content Edited in existing staff
        if (item.edited === true && !item.new) {
          isEdited = true
          await editExistingStaff(item)
          if ((item.isCropped || item.isFileSelected) && !item.new) {
            updateCroppedImage(item)
          } else {
            setAddButtonDisabled(false)
          }
        }

        //if staff changed group
        if (item.changedGroup) {
          await changeStaffGroup(item, item.oldGroup, staffGroup.key)
        }
      })
    })

    if (!isEdited && reorderedStaffMembers.length == 0 && !isGroupsReordered) {
      // setAddButtonDisabled(false)
    }
    if (staffMemberReordered) {
      setAddButtonDisabled(false)
      setStaffMemberReordered(false)
    }

    /** If isNewStaff, we let reorder of staff happen after
     * the requery from contentful is done (as a result of updating the state isnewStaffAdded)
     * in the useEffect.  Else, we do the reordering now.
     */
    if (!isNewStaff && reorderedStaffMembers.length > 0) {
      // Check if a reorder was done among the staff members
      updateReorderStaffMembers()
    }

    // Check if reorder was done among the groups
    if (isGroupsReordered && !isNewGroup && !ignoreGroupReorder) {
      await updateReorderGroups()
      setAddButtonDisabled(false)
    }

    // Reset the reorder flags
    setIsGroupsReordered(false)

    if (step != 'groups') {
      if (!(step == 'group_details' && keepInGroupDetails)) goBack('save')
    }
    setSaveButtonDisable(true)
    setIsFormDirty(false)
    setSaving(false)
    // setTimeout(async () => {
    //   await getData(true)
    //   // setAddButtonDisabled(false)
    // }, 4000)
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty}
        message={() => staffMessages.CONFIRM_LOSE_UNSAVED_STAFF_INFO}
      />
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          sendOnLoadMsg={() => {
            sendOnLoadMsg()
            sendMsg({
              type: 'ScreenSizeChanged',
              content: parseStaffPagesFromNewStaffData(
                dataStaff,
                GlobalstaffInfo.fields.items[0],
                GlobalstaffInfo
              ),
            })
          }}
        />

        <PreviewFrame
          siteName={staffPagesList[0]?.fields?.title}
          selectedSite={`${baseSiteURL}${GlobalstaffInfo?.fields?.url ?? ''} `}
          // selectedSite={`http://localhost:8000/${
          //   GlobalstaffInfo?.fields?.url ?? ''
          // }`}
          sendOnLoadMsg={sendOnLoadMsg}
          d
          iframeView={iframeView}
        />
      </div>
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={saving}
        saveButtonDisabled={saveButtonDisabled}
        onBackClick={() => {
          goBack()
          setReRenderStaff(true)
        }}
        userGroups={user.groups}
      >
        {renderStep()}
      </Sidebar>
    </div>
  )
}

export default WebsitePageStaff
