import React, { useState, useEffect } from 'react'
import Showdown from 'showdown'
import { useForm } from 'react-hook-form'
import {
  AdditionalHours,
  Day,
  Input,
  Table,
  TextArea,
  Select,
  SimpleLabel,
  ParagraphSmall,
} from '../../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'

const StandardHours = ({
  hoursData,
  setIsFormDirty,
  name,
  text,
  updatePreview,
  resetForm,
  setResetForm,
}) => {
  const { register, setValue, reset } = useForm()
  const [data, setData] = useState({
    monday: {
      status: hoursData.current['monday'].isClosed
        ? 'closed'
        : hoursData.current['monday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    tuesday: {
      status: hoursData.current['tuesday'].isClosed
        ? 'closed'
        : hoursData.current['tuesday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    wednesday: {
      status: hoursData.current['wednesday'].isClosed
        ? 'closed'
        : hoursData.current['wednesday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    thursday: {
      status: hoursData.current['thursday'].isClosed
        ? 'closed'
        : hoursData.current['thursday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    friday: {
      status: hoursData.current['friday'].isClosed
        ? 'closed'
        : hoursData.current['friday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    saturday: {
      status: hoursData.current['saturday'].isClosed
        ? 'closed'
        : hoursData.current['saturday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
    sunday: {
      status: hoursData.current['sunday'].isClosed
        ? 'closed'
        : hoursData.current['sunday'].openIntervals.length == 2
        ? 'split'
        : 'open',
    },
  })

  useEffect(() => {
    updateEditHoursTab(name.includes('main') ? 'main' : 'extended')
  }, [])

  useEffect(() => {
    if (resetForm) {
      reset()
      setResetForm(false)
      setIsFormDirty(false)
    }
    setData({
      monday: {
        status: hoursData.current['monday'].isClosed
          ? 'closed'
          : hoursData.current['monday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      tuesday: {
        status: hoursData.current['tuesday'].isClosed
          ? 'closed'
          : hoursData.current['tuesday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      wednesday: {
        status: hoursData.current['wednesday'].isClosed
          ? 'closed'
          : hoursData.current['wednesday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      thursday: {
        status: hoursData.current['thursday'].isClosed
          ? 'closed'
          : hoursData.current['thursday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      friday: {
        status: hoursData.current['friday'].isClosed
          ? 'closed'
          : hoursData.current['friday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      saturday: {
        status: hoursData.current['saturday'].isClosed
          ? 'closed'
          : hoursData.current['saturday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
      sunday: {
        status: hoursData.current['sunday'].isClosed
          ? 'closed'
          : hoursData.current['sunday'].openIntervals.length == 2
          ? 'split'
          : 'open',
      },
    })
  }, [resetForm])

  const weekDays = [
    { long: 'monday', short: 'Mon' },
    { long: 'tuesday', short: 'Tue' },
    { long: 'wednesday', short: 'Wed' },
    { long: 'thursday', short: 'Thu' },
    { long: 'friday', short: 'Fri' },
    { long: 'saturday', short: 'Sat' },
    { long: 'sunday', short: 'Sun' },
  ]

  let converter = new Showdown.Converter()

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      win.postMessage(strMsg, '*')
    }
  }

  const InlineTextArea = () => {
    return (
      <Table className="w-full">
        <tbody>
          <tr>
            <td colSpan="3">
              <AdditionalHours>
                {name.includes('mainHours')
                  ? 'Additional Hours Text'
                  : 'Extended Hours Text'}
              </AdditionalHours>
              <br />
              <TextArea
                style={{ padding: '10px' }}
                defaultValue={text && text.current}
                onChange={(event) => {
                  text.current = event.target.value
                  setIsFormDirty(true)
                  updatePreview && updatePreview()
                }}
                maxLength="255"
              />
              <ParagraphSmall
                className="my-0"
                style={{ color: 'unset', textAlign: 'right', padding: 0 }}
              >{`${text.current.length}/255`}</ParagraphSmall>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const InlineSelect = ({ day }) => {
    const options = name.includes('extended')
      ? [
          {
            value: 'closed',
            label: 'Closed',
          },
          {
            value: 'open',
            label: 'Open',
          },
        ]
      : [
          {
            value: 'closed',
            label: 'Closed',
          },
          {
            value: 'open',
            label: 'Open',
          },
          {
            value: 'split',
            label: 'Split',
          },
        ]

    if (
      data[day].status != 'split' &&
      !resetForm &&
      hoursData.current[day].openIntervals.length > 1
    ) {
      hoursData.current[day].openIntervals = [
        hoursData.current[day].openIntervals[0],
      ]
      updatePreview && updatePreview()
    }

    return (
      <div className="inlineSelect hoursTime">
        <Select
          value={data[day].status}
          className="cursor-pointer"
          id={`status_${day}`}
          onChange={(event) => {
            const newData = { ...data }
            if (
              event.target.value == 'split' &&
              hoursData.current[day].openIntervals.length < 2
            ) {
              addSplitHours(day)
            }
            newData[day].status = event.target.value
            hoursData.current[day].isClosed = event.target.value == 'closed'
            setIsFormDirty(true)
            setData(newData)
            updatePreview && updatePreview()
          }}
        >
          {options.map((option) => {
            return (
              <option
                key={Math.random().toString(36).substr(2, 9)}
                value={option.value}
              >
                {option.label}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  const DayFields = ({ day }) => {
    const openState = data[day].status

    return hoursData.current[day].openIntervals.map((interval, key) => {
      return (
        <div
          key={`container-${key}`}
          className="flex items-center justify-between gap-x-2"
        >
          <div
            key={`start-${key}`}
            className={`${
              openState == 'closed' ? 'invisible' : 'visible'
            } inlineField hoursTime ${key > 0 ? 'split-row-start' : ''}`}
            style={{ flexGrow: 1 }}
          >
            <Input
              type="time"
              className="cursor-pointer"
              defaultValue={interval.start}
              {...register(`${day}.openIntervals.${key}.start`)}
              onChange={(event) => {
                hoursData.current[day].openIntervals[key].start =
                  event.currentTarget.value
                updatePreview && updatePreview()
              }}
              onBlur={() => {
                setIsFormDirty(true)
                reset()
              }}
              style={{ fontSize: '0.8rem' }}
            />
          </div>
          <SimpleLabel
            className={` ${openState == 'closed' ? 'invisible' : 'visible'} `}
          >
            to
          </SimpleLabel>
          <div
            key={`end-${key}`}
            className={`${
              openState == 'closed' ? 'invisible' : 'visible'
            } inlineField hoursTime `}
            style={{ flexGrow: 1 }}
          >
            <Input
              type="time"
              className="cursor-pointer"
              defaultValue={interval.end}
              {...register(`${day}.openIntervals.${key}.end`)}
              onChange={(event) => {
                hoursData.current[day].openIntervals[key].end =
                  event.currentTarget.value
                updatePreview && updatePreview()
              }}
              onBlur={() => {
                setIsFormDirty(true)
                reset()
              }}
              style={{ fontSize: '0.8rem' }}
            />
          </div>
        </div>
      )
    })
  }

  const addSplitHours = (day) => {
    let timeIntervalArray = [
      ...hoursData.current[day].openIntervals,
      { start: '00:00', end: '00:00' },
    ]
    hoursData.current[day].openIntervals = timeIntervalArray
    setIsFormDirty(true)
    updatePreview && updatePreview()

    // setUpdatedHours(newData)
  }

  const DayLabel = ({ label }) => {
    return <Day key={label}>{label}</Day>
  }

  const enableAll = () => {
    const dataToCopy = hoursData.current['monday']
    const newHoursData = {}
    const newStatus = dataToCopy.isClosed
      ? 'closed'
      : dataToCopy.openIntervals.length > 1
      ? 'split'
      : 'open'
    const newState = {}
    Object.keys(hoursData.current).forEach((d) => {
      newState[d] = { status: newStatus }
      hoursData.current[d].isClosed = dataToCopy.isClosed

      dataToCopy.openIntervals.map((i, index) => {
        if(dataToCopy.openIntervals.length > 1 && hoursData.current[d].openIntervals.length < 2) addSplitHours(d)
        setValue(`${d}.openIntervals.${index}.start`, i.start)
        hoursData.current[d].openIntervals[index].start = i.start
        setValue(`${d}.openIntervals.${index}.end`, i.end)
        hoursData.current[d].openIntervals[index].end = i.end

      })
    })
    setData(newState)
    setIsFormDirty(true)
    updatePreview && updatePreview()
  }

  const ApplyButton = () => {
    return (
      <div
        className="apply-button-container self-end"
        style={{ color: '#007f85' }}
      >
        <button
          className={`apply-button`}
          onClick={() => enableAll()}
          // disabled={isSplitHrsAvail}
        >
          Apply to all?
        </button>
      </div>
    )
  }

  const updateEditHoursTab = (tab) => {
    sendMsg({ editingHours: tab })
  }

  return (
    <form className="h-full">
      <div className="flex items-center gap-y-4 flex-col p-4 overflow-auto h-full">
        {weekDays.map((day, idx) => (
          <div key={idx} className="flex flex-col gap-y-2">
            <div className="flex justify-between items-center gap-x-3 w-full">
              <DayLabel label={day.short} />
              <InlineSelect label={day.short} day={day.long} />
              <div className="flex gap-y-2 flex-col" style={{ flexGrow: 1 }}>
                <DayFields day={day.long} />
              </div>
            </div>
            {idx === 0 && <ApplyButton />}
          </div>
        ))}
        <InlineTextArea />
      </div>
    </form>
  )
}

export default StandardHours
