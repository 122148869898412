import {
  setInitialHours,
  setCurrentYextId,
  setCurrentStaffId,
  setCurrentStaffInfo,
  setYextResponse,
  setCurrentServiceInfo,
  setCurrentServiceId,
} from 'src/hooks/state/state'

export const getHoursAccordionData = (
  accordionFor,
  data,
  selectedSite,
  onClick
) => {
  let accordionData = {}
  accordionData['title'] = 'Hours of Operation'
  accordionData['description'] =
    'Edit your hours of operation and any additional information about service availability.'
  accordionData['options'] = data.map((each) => {
    let optionData = {}
    // optionData['pathName'] = url
    optionData['name'] = each['name']
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each['url']}`,
      locationName: each['name'],
    }
    optionData['onClick'] = () => {
      setInitialHours(each.hours)
      setCurrentYextId(each.yextId)
      setYextResponse({ ...each.hours, meta: each.meta })
      onClick && onClick()
    }
    return optionData
  })
  return accordionData
}

export const getStaffAccordionData = (
  accordionFor,
  data,
  selectedSite,
  onClick
) => {
  let accordionData = {}
  accordionData['title'] = 'Staff Information'
  accordionData['description'] =
    'Add, edit and delete staff members and organize them into groups.'
  accordionData['options'] = data.map((each) => {
    let optionData = {}
    // optionData['pathName'] = url
    optionData['name'] = each.fields.title
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each.fields.url}`,
    }
    optionData['onClick'] = () => {
      setCurrentStaffId(each.sys.id)
      setCurrentStaffInfo(each)
      onClick && onClick()
    }
    return optionData
  })
  return accordionData
}

export const getServiceAccordionData = (
  accordionFor,
  data,
  selectedSite,
  onClick
) => {
  let accordionData = {}
  accordionData['title'] = 'Services Information'
  accordionData['description'] = 'Add, edit and delete services.'
  accordionData['options'] = data.map((each) => {
    let optionData = {}
    // optionData['pathName'] = url
    optionData['name'] = each.fields.title
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each.fields.url}`,
    }
    optionData['onClick'] = () => {
      setCurrentServiceId(each.sys.id)
      setCurrentServiceInfo(each)
      onClick && onClick()
    }
    return optionData
  })
  return accordionData
}

export const getContactInfoAccordionData = (
  accordionFor,
  data,
  selectedSite,
  onClick
) => {
  let accordionData = {}
  accordionData['title'] = 'Contact Info';
  accordionData['description'] =
    'Edit your hours of operation and any additional information about service availability.';
  accordionData['options'] = data.map((each) => {
    let optionData = {};
    optionData['name'] = each['name'];
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each['url']}`,
      locationName: each['name'],
    };
    optionData['onClick'] = () => {
      setCurrentYextId(each.yextId);
      const contactInfo = {};
      contactInfo['c_textPhone'] = each?.c_textPhone;
      contactInfo['fax'] = each?.fax;
      contactInfo['mainPhone'] = each?.mainPhone;
      contactInfo['meta'] = each?.meta;
      contactInfo['url'] = each?.url;
      setYextResponse(contactInfo);
      onClick && onClick();
    }
    return optionData;
  });
  return accordionData;
}
