import { Link } from 'react-router-dom'
import UserGroupDetails from './UserGroupsDetails'
import { Card, CardWrapper } from '../UserGroupCell.styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useState, useEffect } from 'react'

const UserGroupsCard = ({ group, imgSrc }) => {
  const baseSiteURL = group.siteURL.replace(/#.*$/, '')
  const [logoImg, setLogoImg] = useState()

  // get yext data
  const getYextData = async (yextId) => {
    return await fetch(
      `${process.env.EE_REST_YEXT_ENDPOINT}?yextID=${yextId}`,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
        },
      }
    ).then((response) => {
      if (response.ok) return response.json()
    })
  }

  const makeYextID = async (yextId) => {
    return yextId.toString().length < 4 ? '0' + yextId : yextId
  }

  const getImage = async () => {
    const validYextId = await makeYextID(group.yextId)
    const yextData = await getYextData(validYextId)
    const last = yextData?.response?.logo?.image?.thumbnails?.length - 1
    const logoImg =
      yextData.response?.logo?.image?.url ||
      `https://images.ctfassets.net/rt5zmd3ipxai/1YP5mirfu7EI9R6SUyd39E/4f7cbc7ee8970284810978cd88410e19/Default_User_Image.png?w=196&h=196`
    setLogoImg(logoImg)
  }
  useEffect(()=>{
    getImage();
  }, [])

  return (
    <CardWrapper>
      <Link
        to={{
          pathname: `/site/${group.siteId}/website-page/main/${group.name}`,
          state: {
            siteId: group.siteId,
            siteURL: group.siteURL,
            yextId: group.yextId,
            name: group.name,
          },
        }}
      >
        <Card>
          <div className="max-w-sm text-center rounded overflow-hidden shadow-lg">
            <LazyLoadImage
              alt={'Site Screenshot'}
              height="196"
              width="196"
              effect="blur"
              src={logoImg} // use normal <img> attributes as props
            />
            <div className="px-6 py-4">
              <UserGroupDetails name={group.name} yextId={group.yextId} />
            </div>
          </div>

          {/* <div className="object-cover w-full shadow-inner h-11">
            <LazyLoadImage
              alt={'Site Screenshot'}
              effect="blur"
              src={logoImg} // use normal <img> attributes as props
            />
          </div> */}
        </Card>
      </Link>
    </CardWrapper>
  )
}

export default UserGroupsCard
