import React, { useEffect, useState } from 'react'
import ImageCropper from '../../Blocks/ImageCropper'
import UploadIcon from '../../../static/images/upload-icon.svg'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Showdown from 'showdown'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  TextField,
} from '@material-ui/core'

import { useGlobalState } from '../../../hooks/state';
import CustomSwitch from 'src/components/CustomSwitch/CustomSwitch'
import { Paragraph } from '../../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'

import { fileMessages } from '../../../constants/messages/en'
import ChevronDown from '-!svg-react-loader?name=Icon!../../../static/images/dropdown_icon.svg'

const StaffDetails = (props) => {
  let converter = new Showdown.Converter()

  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo');
  const [imageToCrop, setCropImage] = useState(
    props.data[props.group].staffMembers[props.staff]?.staffImage
  )
  const [croppedImage, setCroppedImage] = useState(null)
  const [nameLengthError, setNameLengthError] = useState('')
  const [shortBioData, setShortBioData] = useState(
    documentToHtmlString(
      props.data[props.group].staffMembers[props.staff]?.shortBio?.json
    )
  )
  const [shortBioDataHTML, setShortBioDataHTML] = useState('')
  const [shortBioDataLength, setShortBioLength] = useState(0)
  const [longBioData, setLongBioData] = useState(
    documentToHtmlString(
      props.data[props.group].staffMembers[props.staff]?.longBio?.json
    )
  )
  const [displayInMobileApp, setDisplayInMobileApp] = useState(props.data[props.group].staffMembers[props.staff]?.mobileAppDisplay);

  const masterImages = process.env.MASTER_ENTRIES.split(',')
  const isNewStaff = props.data[props.group].staffMembers[props.staff].new

  useEffect(() => {
    let shortBioJson =
      props.data[props.group].staffMembers[props.staff].shortBio?.json || ''
    let htmlString = documentToHtmlString(shortBioJson)
    let htmlDom = new DOMParser().parseFromString(htmlString, 'text/html')
    truncateNode(htmlDom, 150)

    setShortBioLength(
      getTextFromHTML(documentToHtmlString(shortBioJson))?.length
    )
  }, [])

  const truncateNode = (htmlDom, limit) => {
    if (htmlDom.nodeType === Node.TEXT_NODE) {
      htmlDom.textContent = htmlDom.textContent.substring(0, limit)
      return limit - htmlDom.textContent.length
    }
    htmlDom.childNodes.forEach((child) => {
      limit = truncateNode(child, limit)
    })
    return limit
  }

  const getTextFromHTML = (text) => {
    if (text && text.length > 0) {
      let formattedText = converter.makeHtml(text)
      var para = new DOMParser().parseFromString(formattedText, 'text/html')
      return para.body.innerText
    }
    return ''
  }

  const onSelectFile = async (e) => {
    let imageStaff = {
      isFileSelected: false,
      selectedImage: undefined,
    }

    imageStaff.isFileSelected = true

    if ((e.target.files && e.target.files.length > 0) || croppedImage) {
      if (e.target.files[0].size / 1024 / 1024 >= 5) {
        // size in MB
        alert('This file exceeds our maximum size of 5MB')
        return
      } else {
        props.setSaveButtonDisable(true)
        props.setIsUpdateStaffImage(true)
        var image64
        let reader = new FileReader()
        reader.addEventListener('load', () => {
          setCropImage(reader.result)
        })
        reader.readAsDataURL(e.target.files[0])
        reader.onload = function () {
          var binary = atob(reader.result.split(',')[1])
          var array = []
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }

          image64 = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
          if (image64) {
            imageStaff.selectedImage = image64
            props.updateStaffImageInfo(imageStaff)
          }
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      }
    }
  }

  async function get64image(blobURL) {
    const response = await fetch(blobURL)
    const blob = await response.blob()

    return blob
  }
  var image64Cropped
  const onCrop = async (cropped) => {
    get64image(cropped).then((data) => {
      const reader = new FileReader()
      reader.readAsDataURL(data)
      reader.onloadend = function () {
        const oldCroppedValue = croppedImage
        setCroppedImage(data)
        if (oldCroppedValue) {
          props.data[props.group].staffMembers[props.staff].isCropped = true
          props.updateStaffImage(reader.result)
        }
      }

      reader.onload = function () {
        var binary = atob(reader.result.split(',')[1])
        var array = []
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i))
        }

        image64Cropped = new Blob([new Uint8Array(array)], {
          type: 'image/jpeg',
        })

        props.data[props.group].staffMembers[props.staff].newCroppedImage =
          image64Cropped
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    })
  }

  let staffGroupShortBio = props.data[props.group].variation === 'Variation-A'

  const handleNameChange = (data) => {
    props.setSaveButtonDisable(false)
    setNameLengthError('')
    if (data.length == 0) {
      setNameLengthError(fileMessages.NAME_LENGTH_ERROR)
      props.setSaveButtonDisable(true)
    }
  }

  // delay the "sendMsg" function by the amount specified (500), instead of send it on each keystroke
  // this is due to high CPU usage
  // this could be smarter and made a hook

  const [staffName, setStaffName] = useState(
    props.data[props.group].staffMembers[props.staff].name
  )
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.updateStaffName(staffName)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [staffName])

  const [staffTitle, setStaffTitle] = useState(
    props.data[props.group].staffMembers[props.staff].title
  )
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.updateStaffTitle(staffTitle)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [staffTitle])

  const [shortBio, setShortBio] = useState(shortBioData)
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.updateStaffShortBio(shortBio)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [shortBio])

  const [fullBio, setFullBio] = useState(longBioData)
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.updateStaffLongBio(fullBio)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [fullBio])
  return (
    <div className="staff-form">
      <div>
        <div id="staff-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Edit Staff Member</h2>
          <Paragraph>
            Update a staff member’s information here. Rectangular profiles will
            not display a short biography. In addition, it is not possible to
            preview the Full Biography in Easy Editor, but when published can be
            viewed by clicking “View Profile” on the live site.
          </Paragraph>
        </div>
        <div
          id="staff-staffImage"
          style={{ marginBottom: '20px' }}
          className="flex gap-4"
        >
          <ImageCropper
            image={imageToCrop}
            updateImage={onCrop}
            disabled={
              !props.data[props.group].staffMembers[props.staff]
                .isFileSelected &&
              masterImages.includes(
                props.data[props.group].staffMembers[props.staff]
                  .staffImageSectionId
              )
            }
          />

          <label
            style={{
              color: '#ffffff',
              display: 'flex',
              cursor: 'pointer',
              background: '#255660',
              height: 'fit-content',
              padding: '8px 16px',
              borderRadius: 40,
              alignSelf: 'center',
            }}
          >
            <UploadIcon />
            <span className="pl-2">Upload photo</span>
            <input
              className="pb-8 hidden"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </label>
        </div>
        <div id="staff-name" className="h-70  active:text-teal-500">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Name *</span>
          </div>
          <input
            type="text"
            maxLength="100"
            defaultValue={
              props.data[props.group].staffMembers[props.staff].name
            }
            onChange={(event) => {
              // props.updateStaffName(event.target.value)
              setStaffName(event.target.value)
              handleNameChange(event.target.value)
            }}
            className="focus:text-black focus:outline-none"
          />
          <p
            style={{
              color: 'unset',
              fontSize: 12,
              marginTop: 0,
              marginLeft: 10,
            }}
          >{`${
            props.data[props.group].staffMembers[props.staff].name?.length
          }/100 Characters`}</p>
        </div>
        {nameLengthError && (
          <p className="text-orange-700 mb-20 -mt-1">{nameLengthError}</p>
        )}

        <div
          id="staff-title"
          className="h-70 active:text-teal-500"
          style={{ marginBottom: '20px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Title</span>
          </div>
          <input
            type="text"
            defaultValue={
              props.data[props.group].staffMembers[props.staff].title
            }
            // onChange={() => props.updateStaffTitle(event.target.value)}
            onChange={() => setStaffTitle(event.target.value)}
            className="focus:outline-none"
            maxLength="50"
          />
          <p
            style={{
              color: 'unset',
              fontSize: 12,
              marginTop: 0,
              marginLeft: 10,
            }}
          >{`${
            props.data[props.group].staffMembers[props.staff].title
              ? props.data[props.group].staffMembers[props.staff].title?.length
              : 0
          }/50 Characters`}</p>
        </div>

        {staffGroupShortBio ? (
          <div id="staff-shortBio" style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="mb-2">Short Bio</span>
            </div>
            <div>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bold', 'italic'],
                }}
                data={shortBioData}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  let currText = getTextFromHTML(data)
                  if (currText.length >= 151) {
                    editor.setData(shortBioDataHTML)
                  } else {
                    // props.updateStaffShortBio(data)
                    setShortBio(data)
                    setShortBioDataHTML(data)
                    setShortBioLength(getTextFromHTML(data)?.length)
                  }
                }}
                className="border-0 hello"
              />
              <p
                style={{
                  color: 'unset',
                  fontSize: 12,
                  marginTop: 4,
                  marginLeft: 10,
                }}
              >{`${shortBioDataLength}/150 Characters`}</p>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <span className="mb-2">Short Bio</span>

            <p style={{ color: '#cecece', paddingTop: '10px' }}>
              The current Staff Group variation does not support{' '}
              <strong>Short Biography</strong>. Please, change it (if needed).
            </p>
          </div>
        )}
      </div>
      <div id="staff-longBio" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="mb-2">Full Bio</span>
        </div>
        <div>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                // 'heading',
                // '|',
                'bold',
                'italic',
                // 'link',
                // 'bulletedList',
                // 'numberedList',
                // 'blockQuote',
              ],
            }}
            data={longBioData}
            onChange={(event, editor) => {
              const data = editor.getData()
              setFullBio(data)
            }}
          />
        </div>
      </div>

      {selectedSiteInfo.websiteType === 'General Practice' && (
        <div className="mb-3">
          <div className="flex gap-1">
            <CustomSwitch
              checked={displayInMobileApp}
              onClick={() => {
                props.updateDisplayInMobileApp(!displayInMobileApp)
                setDisplayInMobileApp(!displayInMobileApp)
              }}
              disabled={!props.isAddingStaff}
            />
            <p className="text-md font-semibold m-0">Display in the mobile app</p>
          </div>
          <p className="text-xs">This should only be turned on for DVMs that accept appointments in the GP mobile app.</p>
        </div>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ChevronDown />}>
          Move to another staff group
        </AccordionSummary>
        <AccordionDetails>
          <div id="staff-group-dropdown" className="mb-6">
            <div className="mb-2">
              Select another staff group to move this staff member to that group
              after save.
            </div>

            <TextField
              select
              fullWidth
              id="staff-grp-dropdown"
              value={props.group}
              onChange={(e) => props.updateStaffGroup(e.target.value)}
              disableUnderline
              variant="outlined"
              className="pt-2 pb-2 leading-6"
            >
              {props.data.map((grp, idx) => (
                <MenuItem value={idx} key={idx}>
                  {grp.groupName ?? 'No Name'}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </AccordionDetails>
      </Accordion>

      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'flex-end',
        }}
      >
        <button
          onClick={() => props.deleteStaff(props.data[props.group].staffMembers?.length == 1, props.data[props.group].staffMembers[props.staff]?.staffEntryId)}
          disabled={isNewStaff}
        >
          {/* <FontAwesomeIcon
            icon={faTrash}
            color={isNewStaff ? '#e2e2e2' : '#cb3a3c'}
          /> */}
          <span
            style={{
              display: 'inline-block',
              fontSize: '14px',
              // textDecoration: 'underline',
              // color: isNewStaff ? '#e2e2e2' : '#cb3a3c',
              color: '#006F85',
            }}
          >
            Delete staff member
          </span>
        </button>
      </div>
    </div>
  )
}

export default StaffDetails
