import React, { useEffect, useState, useContext } from 'react'

import { Context } from '../../../Store'
const UnpublishedAlert = () => {
  const {isAlertActive} = useContext(Context)

  return (
    <>
      {isAlertActive ? (
        <div
          className="fixed bottom-5 unpublishedAlert"
          style={{
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '999',
          }}
        >
          <div
            className="p-2 bg-red-800 items-center text-indigo-100 leading-none  flex lg:inline-flex"
            role="alert"
          >
            <span className="flex  bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
              Notice
            </span>
            <span className="font-semibold mr-2 text-left flex-auto">
              You have unpublished changes
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default UnpublishedAlert
