import React, { useState, useEffect, useCallback } from 'react'
import WebsiteForm from 'src/components/WebsiteForm'
import { createGroup } from '../../api/groups'
import * as _ from 'lodash'
import { getAllGroups } from '../../api/groups'
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom'

const NewWebsiteRest = () => {
  const cms = useCMS()
  const history = useHistory()

  const [error, setError] = useState(null)

  const createNewWebsite = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      let createErr = await createGroup(query)
      if (createErr.errors && createErr.errors.length > 0) {
        createErr = { ...createErr, graphQLErrors: createErr.errors }
        setError(createErr)
      } else {
        cms.alerts.info('Website Created')
        history.push('/websites')
      }
    } catch (e) {
      setError(e)
    }
  }

  const onSave = (data) => {
    createNewWebsite(data)
  }

  return (
    <div className="container mx-auto grid rw-segment mt-6">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">New Website</h2>
      </header>
      <div className="rw-segment-main">
        <WebsiteForm onSave={onSave} error={error} />
      </div>
    </div>
  )
}

export default NewWebsiteRest
