// @flow
/**
 * @file Manages the messages
 */

const bannerMessages = {
  CONFIRM_LOSE_UNSAVED_BANNER:
    'You are about to lose all your unsaved changes in Emergency Banner.  Are you sure you want to proceed?',
}

const homeCarouselMessages = {
  CONFIRM_LOSE_UNSAVED_CHANGES:
    'You are about to lose all your unsaved changes.  Are you sure you want to proceed?',
}

const fileMessages = {
  FILE_TOO_BIG: 'This file exceeds our maximum size of 8MB',
  NAME_LENGTH_ERROR: 'Name is a required field and cannot be empty',
}

const staffMessages = {
  CONFIRM_DELETE_GROUP: 'Are you sure you want to delete this group?',
  CONFIRM_DELETE_STAFF_MEMBER:
    'Are you sure you want to delete this staff member?',
  CONFIRM_LOSE_UNSAVED_STAFF_INFO:
    'You are about to lose all your unsaved changes in Staff Information.  Are you sure you want to proceed?',
  GROUP_NEEDS_STAFF_MEMBER:
    'Group should have at least one staff member. Do you want to add staff?',
  NO_EMPTY_GROUPS:
    'Staff groups cannot be empty. Deleting the only staff member of this group will also delete the group.',
  STAFF_MEMBER_SAVE_OR_DELETE: 'Staff Member not saved, please save or delete',
  STAFF_MEMBER_EMPTY_NOT_SAVED: 'Staff member empty, and was not saved',
  NO_EMPTY_GROUPS_MOVING_STAFF:
    'Staff groups cannot be empty. Moving the only staff member of this group will delete this the group.',
}

const servicesMessages = {
  CONFIRM_DELETE_GROUP: 'Are you sure you want to delete this group?',
  CONFIRM_DELETE_SERVICE: 'Are you sure you want to delete this service?',
  CONFIRM_LOSE_UNSAVED_SERVICE_INFO:
    'You are about to lose all your unsaved changes in Service Information.  Are you sure you want to proceed?',
  GROUP_NEEDS_SERVICE:
    'Group should have at least one service. Do you want to add service?',
  NO_EMPTY_GROUPS: 'Service groups cannot be empty. Please add another service before hiding this one.',
  NO_EMPTY_SERVICE_PAGE: 'Service page cannot be empty. Please add another service group before hiding this one.',
  SERVICE_SAVE_OR_DELETE: 'Service not saved, please save or delete',
}

const hoursMessages = {
  CONFIRM_LOSE_UNSAVED_HOURS:
    'You are about to lose all your unsaved changes in Hours of Operation.  Are you sure you want to proceed?',
}

const loginMessages = {
  USE_CHROME_BROWSER:
    'Easy Editor works best in Google Chrome, at full screen, and not in incognito mode.',
}

const userMessages = {
  CONFIRM_DELETE_USER: 'Are you sure you want to delete user ',
  CONFIRM_DELETE_ENTRY: 'Are you sure you want to delete this entry?',
}
const siteMessages = {
  SITE_HAS_USER: 'The user has already been added to this site.',
}

const userFormMessages = {
  EMAIL: 'Email',
  HELPER_EMAIL: 'Enter a valid NVA registered email',
  NAME: 'Name',
  UPDATED_AT: 'Updated At',
  ROLE: 'Role',
  USER: 'User',
  ADMIN: 'Admin',
  MANDATORY: 'Fields are mandatory',
}

const websiteFormMessages = {
  NAME: 'Name',
  HELPER_NAME: 'Enter Website Name',
  SITE_URL: 'Site URL',
  HELPER_SITE_URL1: 'Enter the full URL example: https://www.site.com',
  WEBSITE_ID: 'Website Id',
  YEXT_ID: 'Yext Id',
  MANDATORY: 'Fields are mandatory',
}

export {
  bannerMessages,
  homeCarouselMessages,
  fileMessages,
  hoursMessages,
  servicesMessages,
  loginMessages,
  siteMessages,
  staffMessages,
  userMessages,
  userFormMessages,
  websiteFormMessages,
}
