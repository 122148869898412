import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import NewWebsiteRest from 'src/components/NewWebsiteRest'

import { Link } from 'react-router-dom'

const NewWebsitePage = ({ role, userid, user }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      <Link
        to={'/websites'}
        className="bg-teal-500 text-white p-2 cursor-pointer px-4 py-1 ml-3"
      >
        Back
      </Link>
      <NewWebsiteRest id={userid} />
    </UpdatedMainLayout>
  )
}

export default NewWebsitePage
