import React from 'react'
import { useHistory } from 'react-router-dom'

function UserCard({ user, handleEdit }) {
  const history = useHistory()
  return (
    <div className="overflow-hidden shadow-outer w-full flex flex-row my-1 p-6">
      <div className="w-2/6 flex flex-col m-auto">
        <span className="font-medium text-base font-sans">{user.nickname}</span>
        <span className="text-sm">{user.email}</span>
      </div>
      <div className="w-2/6 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">{user.email}</span>
        <span className="text-sm">Email</span>
      </div>
      <div className="w-1/6 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">
          {user.groups.length}
        </span>
        <span className="text-sm">Number of sites</span>
      </div>
      <div className="w-1/6 flex flex-col m-auto">
        <button
          className="text-teal-500 focus:outline-none"
          onClick={() => handleEdit(user)}
        >
          Edit User websites
        </button>
        <button
          className="text-teal-500 focus:outline-none"
          onClick={() => {
            history.push(`/users/${user.id || 1}`)
          }}
        >
          Edit User details
        </button>
      </div>
    </div>
  )
}

export default UserCard