import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Msal from 'msal'
import { msalConfig } from '../../../auth/authConfig'
import { useLocation, useParams } from 'react-router-dom'
import ReactGA from 'react-ga'
import { fileMessages } from '../../constants/messages/en'
import { useGlobalState } from '../../hooks/state/state'

//import CaptureIcon from '-!svg-react-loader?name=Icon!../../../static/images/camera-solid.svg'

const ContactForm = ({ setEmailSuccess, close }) => {
  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()
  const [loading, setLoading] = useState(false)
  //const [emailSuccess, setEmailSuccess] = useState(false)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [fileName, setFileName] = useState()
  const [image, setImage] = useState()
  const [filesToSend, setFilesToSend] = useState([])
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')

  let location = useLocation()
  let { siteId, pageName } = useParams()

  const { register, handleSubmit, errors, formState } = useForm()

  const {
    ShareServiceClient,
    StorageSharedKeyCredential,
  } = require('@azure/storage-file-share')
  const containerAccount = 'easyeditorfileupload'
  const sas =
    '?sv=2021-06-08&ss=bf&srt=sco&sp=rwdlaciytf&se=2024-10-01T23:12:32Z&st=2022-10-05T15:12:32Z&spr=https,http&sig=jGSnlDTuZnu0i877iOHdaxD3XqIz%2FY8mejOSqdte558%3D'
  const serviceClientWithSAS = new ShareServiceClient(
    `https://${containerAccount}.file.core.windows.net${sas}`
  )
  const shareName = 'myupload'
  const directoryName = 'easyeditoruploads'
  const { isSubmitted } = formState

  const onSubmit = (data) => {
    if (!data.siteId) data.siteId = 'no site ID associated'
    if (!data.siteUrl) data.siteUrl = 'no site URL associated'

    // image ? (data.image = image) : ''
    if (image) data.heroImage = filesToSend.join(',')
    setLoading(true)
    const url = process.env.SERVICE_EMAIL_ENDPOINT
    // 'https://prod-26.westus.logic.azure.com:443/workflows/28250da2685a4961ba3917cb13a16c65/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=7iZC2jGSVJoefa-L4P-Y_kCS_Ndc5sTuduQqOWXDpmA'
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(function (response) {
      setLoading(false)
      setEmailSuccess(true)
    })
    setEmailSuccess(true)
    ReactGA.event({
      category: 'Support Email',
      action: `${account?.name} Submitted a support ticket`,
      label: `Value: ${data.message}`,
    })
  }
  async function main(file) {
    if (Math.round(file.size / 1024) >= 8500) {
      alert(fileMessages.FILE_TOO_BIG)
      return
    }
    const fileName = file.name.replace(' ', '')

    setFilesToSend((oldArray) => [
      ...oldArray,
      `<a href="https://easyeditorfileupload.file.core.windows.net/myupload/easyeditoruploads/${fileName}${sas}" download> ${fileName} </a>`,
    ])
    const directoryClient = serviceClientWithSAS
      .getShareClient(shareName)
      .getDirectoryClient(directoryName)

    const content = file
    const fileClient = directoryClient.getFileClient(fileName)
    await fileClient.create(file.size)

    // Upload file range
    await fileClient.uploadRange(content, 0, file.size)
  }

  let handleChange = (e) => {
    if (e.target.name == 'subject') {
      setSubject(e.target.value)
    } else if (e.target.name == 'message') {
      setMessage(e.target.value)
    }
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files

      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        setImage(files[i])
        main(file)
      }
      e.target.value = '' // default chrome behaviour is disabling uploading the same file after it was removed
    }
  }
  let removeImage = (index) => {
    var array = [...filesToSend]
    if (index !== -1) {
      array.splice(index, 1)
      setFilesToSend(array)
    }
    setImage()
    setFileName()
  }

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <input
        type="hidden"
        value={account?.name}
        name="name"
        {...register('name')}
      />

      <input
        type="hidden"
        value={selectedSiteInfo.url}
        name="siteUrl"
        {...register('siteUrl')}
      />
      <input
        type="hidden"
        value={siteId}
        name="siteId"
        {...register('siteId')}
      />
      <input
        type="hidden"
        value={filesToSend}
        name="heroImage"
        {...register('heroImage')}
      />
      <input
        {...register('email')}
        className="pointer-events-none appearance-none block w-full bg-gray-200 text-gray-500 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        name="email"
        type="hidden"
        value={account?.userName}
      />
      <input
        {...register('support')}
        name="support"
        type="hidden"
        value={'support'}
      />
      <div className="bg-seaweed pt-4 px-5 pb-6">
        <div className="">
          <div className="flex justify-between">
            <h3 className="text-white text-xl">Contact Marketing Support</h3>
            <button
              type="button"
              className="contact-modal-close-button rw-modal-close-button focus:outline-none"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close ? close : () => {}}
              style={{ transform: 'scale(1.5)' }}
            >
              <span className="text-white" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <span className="text-white">
            Need other changes to your site? Questions or suggestions about Easy
            Editor? Send us a message here or email us at{' '}
            <a href="mailto:marketing@nva.com" className="underline">
              marketing@nva.com
            </a>
            .
          </span>
        </div>
      </div>
      <div className="px-5 py-4">
        <div className="flex flex-wrap">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide font-bold"
              htmlFor="grid-password"
            >
              Subject
            </label>
            <input
              {...register('subject', { required: true })}
              className="simple-input appearance-none block w-full bg-transparent text-sm font-hairline border-white border-b p-2 mb-3 leading-tight focus:outline-none "
              name="subject"
              type="text"
              placeholder="How can we help you?"
              value={subject}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            ></label>
            <textarea
              {...register('message', { required: true })}
              className="simple-input no-resize appearance-none block w-full bg-transparent text-gray-700 border border-black p-2 mb-3 leading-tight focus:outline-none h-48 resize-none"
              name="message"
              placeholder="Add your message here..."
              value={message}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label
            style={{
              textDecoration: 'underline',
              color: '#0a8287',
              display: 'flex',
              cursor: 'pointer',
              width: 'fit-content'
            }}
          >
            {/* <CaptureIcon className="w-4 fill-current text-teal-500 mr-2" />{' '} */}
            Attach an image (up to 8MB)
            <input
              className="pb-8 hidden"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </label>
          {filesToSend
            ? filesToSend.map((file, index) => {
                return (
                  <div
                    key={file}
                    onClick={() => removeImage(index)}
                    className="mt-3 bg-white text-gray-800 font-bold rounded border-b-2 border-teal-500 hover:border-teal-500 hover:bg-teal-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
                  >
                    <div dangerouslySetInnerHTML={{ __html: file }} />
                    <svg
                      className="ml-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentcolor"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                      />
                    </svg>
                    {/* <button className="mt-3 bg-white text-gray-800 font-bold rounded border-b-2 border-red-500 hover:border-red-600 hover:bg-red-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                        <span className="mr-2">{file.replace('"', '')}</span>

                      </button> */}
                  </div>
                )
              })
            : ''}
        </div>
        <div className="flex justify-end pt-4">
          {subject != '' && message != '' ? (
            <div>
              {loading ? (
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              ) : (
                <input
                  type="submit"
                  className="cursor-pointer shadow bg-teal-600 focus:outline-none text-white font-hairline py-2 px-4 "
                  value="Send"
                />
              )}
            </div>
          ) : (
            <div>
              <input
                type="button"
                className="bg-gray-200 focus:outline-none text-gray-600 font-hairline py-2 px-4"
                value="Send"
              />
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default ContactForm
