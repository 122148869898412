import { camelCase } from 'lodash'
import { useEffect, useRef, useState } from 'react'

const PreviewFrame = ({ ...props }) => {
  const iframeWrapper = useRef(null)
  const [scale, setScale] = useState(1)
  const [iframeWidth, setIframeWidth] = useState()
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    if (props.cms) {
      props.cms.events.subscribe('sidebar:opened', (event) => {
        handleResize('open')
      })
      props.cms.events.subscribe('sidebar:closed', (event) => {
        handleResize('closed')
      })
    } else {
      document
        .querySelector('[aria-label="toggles cms sidebar"]')
        .addEventListener('click', handleToggleClass)
    }

    const sidebar = document.querySelector('.sidebar')

    function handleToggleClass() {
      sidebar.classList.contains('sidebar-closed')
        ? handleResize('open')
        : handleResize('closed')
    }

    function handleResize(sidebar) {
      if (iframeWrapper !== null && iframeWrapper.current !== null) {
        const iframeScale =
          sidebar && sidebar === 'open'
            ? iframeWrapper.current.offsetWidth > 768
              ? iframeWrapper.current.offsetWidth - 425
              : iframeWrapper.current.offsetWidth
            : sidebar &&
              sidebar === 'closed' &&
              iframeWrapper.current.offsetWidth < 1045
            ? iframeWrapper.current.offsetWidth + 425
            : iframeWrapper.current.offsetWidth
        const iframeWidth = iframeScale < 1045 ? '1027px' : '100%'
        setScale(Math.min(iframeScale) / 1065)
        setIframeWidth(iframeWidth)
      }
    }
    handleResize()
  }, [])

  useEffect(() => {
    setIframeLoading(true);
  }, [props.selectedSite]);

  const loadingScreen = () => {
    if (!iframeLoading) return;

    return (
      <div
        className="absolute top-0 left-0 w-full h-full bg-white z-10 flex items-center justify-center"
        style={{
          opacity: '0.9',
        }}
      >
        <div className="text-center">
          <div style={{
              backgroundImage: 'url(/loading.gif)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: '100% 100%',
              height: '25px',
              width: '25px',
              margin: 'auto'
            }}
          ></div>
          <div className="text-3xl font-bold">Loading...</div>
          {props.loadingMessage && <div className="text-sm">{ props.loadingMessage }</div>}
        </div>
      </div>
    )
  }

  return (
    <div
      ref={iframeWrapper}
      className="h-full m-auto relative flex flex-col px-5"
      style={{
        width: `${props.iframeView == 'mobile' ? '400px' : '100%'}`
      }}
    >
      {loadingScreen()}
      <div
        className="flex flex-wrap dummy-browser shadow-outer w-full"
        style={{
          width: `${props.iframeView === 'mobile' ? '100%' : iframeWidth} `,
          transform: `${
            props.iframeView === 'mobile'
              ? 'none'
              : `scale(${scale < 1 ? scale : 1})`
          } `,
          transformOrigin: 'bottom left',
          border: '1.5px solid black',
          borderBottom: '0',
          // // marginTop: `${props.iframeView === 'mobile' ? '2.5rem' : '1.5rem'} `,
        }}
      >
        <div className="left"></div>
        <div className="middle">
          <input
            className="fakeBrowserURL"
            type="text"
            value={props.siteName}
          />
        </div>
        <div className="right flex items-center justify-end">
          <span className="icon-square"></span>
          <span className="icon-square"></span>
          <span className="icon-square"></span>
        </div>
      </div>
      <iframe
        onLoad={() => {
          props.sendOnLoadMsg();
          setIframeLoading(false);
        }}
        name="websitePreviewFrame"
        src={props.selectedSite}
        style={{
          width: `${props.iframeView === 'mobile' ? '100%' : iframeWidth} `,
          // height: `${props.iframeView === 'mobile' ? '58vh' : '60vh'}`,
          transform: `${
            props.iframeView != 'mobile'
              ? `scale(${scale < 1 ? scale : 1})`
              : 'none'
          } `,
          transformOrigin: 'top left',
          border: '1.5px solid black',
          borderTop: '0',
        }}
        title="Iframe Example"
        id="websitePreviewFrame"
        className="shadow-outer w-full h-full"
        width="1130"
      ></iframe>
    </div>
  )
}

export default PreviewFrame
