import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/dropdown_icon.svg'
import { useGlobalState } from '../../hooks/state/state'
import './LocationDropdown.css'

const LocationDropdown = ({ groups }) => {
  let history = useHistory()
  const [selectedSite] = useGlobalState('selectedSite')
  const [showDropdown, setShowDropdown] = useState(false)

  let handleSiteSelection = (group) => {
    let siteData = {
      siteId: group.siteId,
      siteURL: group.siteURL,
      yextId: group.yextId,
      name: group.name,
    }

    history.push(
      `/site/${group.siteId}/website-page/main/${group.name}`,
      siteData
    )
    setShowDropdown(false)
    window.location.reload()
  }

  if (!groups) return <></>

  return (
    <div className="flex flex-col location-container pt-2 pb-2 pl-4 ">
      <div>
        <label htmlFor="site-dropdown" className="text-sm text-teal-900">
          Website
        </label>
        <button
          id="site-dropdown"
          className="flex flex-row justify-between pr-4 selected-site-text focus:outline-none"
          onClick={() => setShowDropdown(!showDropdown)}
          style={{ width: '100%' }}
        >
          {selectedSite.name}{' '}
          <ChevronDown className="w-6 pl-4 mt-2 text-black" />
        </button>
      </div>
      {showDropdown ? (
        <div className="dropdown dropdown-contents absolute flex flex-col pb-6 bg-white shadow-2xl z-10 overflow-y-auto">
          {groups.map((group, index) => (
            <div
              className="flex flex-col py-2 cursor-pointer dropdown-item"
              key={index}
              onClick={() => handleSiteSelection(group)}
            >
              <span className="mx-6"> {group.name} </span>
              <span className="mx-6 font-light text-gray-400">
                Location #{group.yextId}
              </span>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default LocationDropdown
