import { request } from '../utils/request';

/**
 * Publish Log REST queries
 */
const queries = {
  allLogs:
    '{idpublish_logs,userEmail,userId,websiteId,yextId,websiteName,verbalDescription,isEntryPublished,isSendInEmail,updateType,contentfulEntryId,createdAt,publishedAt,savedUpdates {idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}}',
  createPublishLog: '{idpublish_logs}',
  getLatestEBUpdate: '{newValue}',
  getLatestContactInfoUpdate: '{newValue}',
  getlatestEBUpdatesByPublishId: '{newValue}',
  getlatestContactInfoUpdatesByPublishId: '{newValue}',
  getLatestHOOUpdate:
    '{newValue, openIntervals {idopen_intervals, savedUpdateId, oldStart, oldEnd, newStart, newEnd, createdAt}}',
  getLatestHoursUpdates:
    '{idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt ,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}',
  getlatestHoursUpdatesByPublishId:
    '{idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}',
  getUnpublishedLogsByUserId:
    '{idpublish_logs,userEmail,userId,websiteId,yextId}',
  updatePublished: '{idpublish_logs}',
  updateEmailSent: '{idpublish_logs}',
  deletePublishLogById:
    '{idpublish_logs,userEmail,userId,websiteId,websiteName,verbalDescription,isEntryPublished,isSendInEmail,updateType,contentfulEntryId,createdAt,publishedAt,savedUpdates {idsaved_updates,publishLogId,type,hoo_fieldName,hoo_day,oldValue,newValue,createdAt,openIntervals {idopen_intervals,savedUpdateId,oldStart,oldEnd,newStart,newEnd,createdAt}}}',
}

/**
 * Get all unpublished logs.
 */
export const getUnpublishedLogs = async () => {
  return await request('get', `{unpublishedLogs ${queries.allLogs}}`)
}

/**
 * Get all publish logs.
 */
export const getAllpublishLogs = async () => {
  return await request('get', `{allpublishLogs ${queries.allLogs}}`)
}

/**
 * Get all publish logs by user.
 */
export const getUnpublishedLogsByUser = async ({ email }) => {
  const response = await request(
    'get',
    `{unpublishedLogsByUser (userEmail: "${email}")${queries.allLogs}}`
  )
  const data = response?.data?.unpublishedLogsByUser;
  if (data){
    return data;
  }
  console.log("Error trying to get all publish logs by user", "getUnpublishedLogsByUser");
  return [];
}

/**
 * Get all publish logs by user and siteId.
 */
export const getUnpublishedLogsByUserAndSiteId = async ({ email, siteId }) => {
  const response = await request(
    'get',
    `{unpublishedLogsByUserAndSiteId (userEmail: "${email}", websiteId: ${siteId})${queries.allLogs}}`
  );
  const data = response?.data?.unpublishedLogsByUserAndSiteId;
  if (data){
    return data;
  }
  console.log("Error trying to get all publish logs by user and siteId", "getUnpublishedLogsByUserAndSiteId");
  return [];
}

/**
 * Create new publish log.
 */
export const createPublishLog = async (query) => {
  return await request(
    'post',
    `mutation{createPublishLog (input: ${query}) ${queries.createPublishLog}}`,
    true
  )
}

/**
 * add emergency banner log
 */
export const addOrUpdateEmergBannerNewValue = async (input) => {
  return await request(
    'post',
    `mutation{addOrUpdateEmergBannerNewValue (input: ${input}) ${queries.createPublishLog}}`,
    true
  );
}

/**
 * add contact info log
 */
export const addOrUpdateContactInfoNewValue = async (input) => {
  return await request(
    'post',
    `mutation{addOrUpdateContactInfoNewValue (input: ${input}) ${queries.createPublishLog}}`,
    true
  );
}

/**
 * add hours of operation log
 */
export const addOrUpdateHOONewValue = async (input) => {
  return await request(
    'post',
    `mutation{addOrUpdateHOONewValue (input: ${input}) ${queries.createPublishLog}}`,
    true
  );
}

/**
 * Get latest EB update by userid and siteId.
 */
export const getLatestEBUpdate = async (userId, siteId) =>
  await request(
    'get',
    `{latestEBUpdate (userId: ${userId}, websiteId: ${siteId}) ${queries.getLatestEBUpdate}}`
  )

/**
 * Get latest EB update by userid and siteId.
 */
export const getLatestContactInfoUpdate = async (userId, siteId, yextId) =>
  await request(
    'get',
    `{latestContactInfoUpdate (userId: ${userId}, websiteId: ${siteId}, yextId: "${yextId}") ${queries.getLatestContactInfoUpdate}}`
  )

/**
 * Get latest EB update by publishlog Id.
 */
export const getlatestEBUpdatesByPublishId = async (publishLogId) =>
  await request(
    'get',
    `{latestEBUpdatesByPublishId (publishLogId: "${publishLogId}") ${queries.getlatestEBUpdatesByPublishId}}`
  )

/**
 * Get latest EB update by publishlog Id.
 */
export const getlatestContactInfoUpdatesByPublishId = async (publishLogId) =>
  await request(
    'get',
    `{latestContactInfoUpdatesByPublishId (publishLogId: "${publishLogId}") ${queries.getlatestContactInfoUpdatesByPublishId}}`
  )

/**
 * Get latest HOO update.
 */
export const getLatestHOOUpdate = async (
  type,
  fieldName,
  day,
  userId,
  websiteId,
  yextId
) =>
  await request(
    'get',
    `{latestHOOUpdate (type: "${type}", fieldName: "${fieldName}", day: "${day}", userId: ${userId}, yextId: "${yextId}") ${queries.getLatestHOOUpdate}}`
  )

/**
 * Get latest HOO updates by userId, siteId and yextId.
 */
export const getLatestHoursUpdates = async (userId, siteId, yextId) =>
  await request(
    'get',
    `{latestHoursUpdates (userId: ${userId}, websiteId: ${siteId}, yextId: "${yextId}") ${queries.getLatestHoursUpdates}}`
  )

//Get unpublished logs by userId

export const getUnpublishedLogsByUserId = async (userId) =>
  await request(
    'get',
    `{unpublishedLogsByUserId (userId: ${userId}) ${queries.getUnpublishedLogsByUserId}}`
  )

//Get unpublished logs by userId and siteId
export const getUnpublishedLogsByUserIdAndSiteId = async ({userId, siteId}) => {
  const response = await request(
    'get',
    `{unpublishedLogsByUserIdAndSiteId (userId: ${userId}, websiteId: ${siteId}) ${queries.getUnpublishedLogsByUserId}}`
  );
  return response?.data?.unpublishedLogsByUserIdAndSiteId;
}
/**
 * Get all latest HOO updates.
 */
export const getlatestHoursUpdatesByPublishId = async (publishLogId) =>
  await request(
    'get',
    `{latestHoursUpdatesByPublishId (publishLogId: "${publishLogId}") ${queries.getlatestHoursUpdatesByPublishId}}`
  )

/**
 * Update publish log as published.
 */
export const updatePublished = async (userId) => {
  return await request(
    'post',
    `mutation{updatePublishLogPublishedByUserId (id: ${userId}) ${queries.updatePublished}}`,
    true
  )
}

/**
 * Update publish log as published.
 */
export const updatePublishedByUserIdAndSiteId = async ({userId, siteId}) => {
  return await request(
    'post',
    `mutation{updatePublishLogPublishedByUserIdAndSiteId (id: ${userId}, siteId: ${siteId}) ${queries.updatePublished}}`,
    true
  )
}

/**
 * Update to send email to false once email is sent.
 */
export const updateEmailSent = async (userId) => {
  return await request(
    'post',
    `mutation{updatePublishLogEmailSentByUserId (id: ${userId}) ${queries.updatePublished}}`,
    true
  )
}

/**
 * Delete publish log.
 * @param {string} id publish log id
 */
export const deletePublishLogById = async (id) => {
  return await request(
    'post',
    `mutation{deletePublishLogById (id: ${id}) {numRows}}`,
    true
  )
}

/**
 * Get EE Emergency Banner.
 */
export const getConstantByKey = async (key) => {
  return await request(
    'get',
    `{getConstantByKey (key: "${key}") {value}}`,
    false
  )
}

/**
 * Set EE Emergency Banner.
 */
export const updateConstant = async (input) => {
  return await request(
    'post',
    `mutation{updateConstant (input: {key: "${input.key}", value: "${input.value}"}){value}}`,
    true
  )
}
