import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import { useGlobalState } from '../../hooks/state/state'
import LaunchIcon from '@material-ui/icons/Launch'

const DesktopMobileToggle = ({ ...props }) => {
  const [selectedSite] = useGlobalState('selectedSite')
  const baseSiteURL = selectedSite.siteURL.replace(/#.*$/, '')

  return (
    <div className="flex justify-between content-center view-buttons pb-3 px-5">
      <div className="flex-1" />
      <div className="flex ">
        <LaunchIcon />
        <div className="text-sm ml-2">
          <div>Open Original Site in Browser</div>
          <label>
            <a
              className="text-sm underline"
              href={baseSiteURL}
              target="_blank"
              rel="noreferrer"
            >
              {selectedSite.name}
            </a>
          </label>
        </div>
      </div>
      <div className="flex-1" />
      <div
        className="right-5 flex items-center justify-end"
        style={{ minWidth: '33vw' }}
      >
        <div className="mr-2">View as:</div>
        <button
          onClick={() => {
            props.setIframeView('large')
            props.sendOnLoadMsg()
          }}
          className={`${
            props.iframeView == 'large'
              ? 'active-view-button text-white rounded-full'
              : ''
          } py-2 px-3 focus:outline-none`}
        >
          <DesktopMacIcon
            style={{
              color: `${props.iframeView == 'large' ? 'white' : ''}`,
            }}
          />
          <span
            className="ml-2"
            style={{
              color: `${props.iframeView == 'large' ? 'white' : '#255660'}`,
            }}
          >
            Desktop
          </span>
        </button>
        <button
          onClick={() => {
            props.setIframeView('mobile')
            props.sendOnLoadMsg()
          }}
          className={`${
            props.iframeView == 'mobile'
              ? 'active-view-button text-white rounded-full'
              : ''
          } py-2 px-3 focus:outline-none`}
        >
          <PhoneIphoneIcon
            style={{
              color: `${props.iframeView == 'mobile' ? 'white' : ''}`,
            }}
          />
          <span
            className="ml-2"
            style={{
              color: `${props.iframeView == 'mobile' ? 'white' : '#255660'}`,
            }}
          >
            Mobile
          </span>
        </button>
      </div>
    </div>
  )
}

export default DesktopMobileToggle
