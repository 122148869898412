import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
// import EditUserCell from 'src/components/EditUserCell'
import EditUserCellRest from 'src/components/EditUserCellRest'
import { Link } from 'react-router-dom'

const EditUserPage = ({ role, editId, user }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      {/* <EditUserCell id={userId} /> */}
      <Link
        to={'/users'}
        className="bg-teal-500 text-white p-2 cursor-pointer px-4 py-1 ml-3"
      >
        Back
      </Link>
      <EditUserCellRest id={editId} />
    </UpdatedMainLayout>
  )
}

export default EditUserPage
