import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
// import UserActionLogsByUserCell from 'src/components/UserActionLogsByUserCell'
import { msalConfig } from '../../../auth/authConfig'
import * as Msal from 'msal'
import UserActionLogsByUserCellRest from 'src/components/UserActionLogsByUserCellRest'
import CatImg from '../../static/images/cute-cat.png'

import './PublishModal.css'

const PublishModal = ({
  savingPublishedChanges,
  isSaving,
  isShowing,
  hide,
  user,
}) => {
  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()

  const resetPublishButton = () => {
    savingPublishedChanges(false)
    hide()
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div
                className="modal-header"
                style={{
                  alignSelf: 'flex-start',
                  paddingTop: '40px',
                  color: '#fff',
                  fontSize: '30px',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {!isSaving && <h1>Publish your website</h1>}
                {isSaving && <h1>Your website is being published</h1>}
                <button
                  type="button"
                  className="modal-close-button rw-modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={resetPublishButton}
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: '700',
                    lineHeight: '1',
                    cursor: 'pointer',
                    border: 'none',
                    transform: 'scale(1.5)'
                  }}
                >
                  <span className="text-white" aria-hidden="true">&times;</span>
                </button>
              </div>
              {!isSaving && (
                <div className="rw-modal-body">
                  {account && account.userName && (
                    <UserActionLogsByUserCellRest
                      email={account.userName}
                      user={user}
                    />
                  )}
                </div>
              )}
              {(isSaving === 'publishing') && (
                <div className="rw-modal-body">
                  <div style={{
                    backgroundImage: 'url(/loading.gif)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '100% 100%',
                    height: '50px',
                    width: '50px',
                    margin: 'auto'
                  }}
                  ></div>
                <div className="text-3xl font-bold">Publishing. Please, don't refresh</div>
                </div>
              )}
              {(isSaving === true || isSaving === 'loading' ) && (
                <div className="rw-modal-body py-8 pr-6 pl-4 flex-row font-light text-lg">
                  <div className="w-3/12 mr-10">
                    <img
                      src={CatImg}
                      alt="cute cartoon cat thumbs up"
                      width="240"
                    />
                  </div>
                  <div className="w-2/3">
                    <p className="leading-relaxed pt-3 font-black subheading-text">
                      It may take up to 15 minutes for your changes to appear on
                      your website.
                    </p>
                    <p className="leading-relaxed pb-3">
                      You will receive an email when the publish is complete. If
                      you do not see your changes, please follow the
                      instructions in the email.
                    </p>
                    <p className="leading-relaxed py-3">
                    You will not be able to make additional changes to your
                    website until the previous publish is complete. If you
                    have any questions, please use the Support options on
                    the main navigation above. You can find answers to FAQs
                    or use the link to Contact Support on Retriever.
                    </p>
                    <p className="leading-relaxed py-3">
                      Thank you! <br />
                      NVA Marketing Knowledge and Success Team
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fragment>,
        document.body
      )
    : null
}

export default PublishModal
