/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react'
import { getUserById } from '../../api/users'
import MainLayout from 'src/layouts/MainLayout'
import { useParams, useHistory, Link } from 'react-router-dom'
import WebsitePageMain from '../../pages/WebsitePagePage/WebsitePageMain'
import WebsitePageHomeCarousel from '../../pages/WebsitePagePage/WebsitePageHomeCarousel'
import WebsitePageHoursEditForm from '../../pages/WebsitePagePage/WebsitePageHoursEditForm'
import WebsiteEmergencyBannerEditForm from '../../pages/WebsitePagePage/WebsiteEmergencyBannerEditForm'
import WebsiteStaffEditForm from '../../pages/WebsitePagePage/WebsitePageStaff'
import WebsiteServicesEditForm from '../../pages/WebsitePagePage/WebsitePageServices'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { client, cma } from '../../../../api/src/lib/contentful'
import * as _ from 'lodash'

import {
  setInitialBannerMsg,
  setInitialHours,
  setUpdatedHours,
  setIsHoursUpdated,
  setIsBannerUpdated,
  setUpdateBannerMsg,
  setSelectedSite,
  setYextId,
  setYextResponse,
  setMultiLocations,
  setCurrentYextId,
  setStaffPages,
  useGlobalState,
  setShowBannerState,
  setBannerBackgroundColor,
  setWebsiteTheme,
  setReRenderStaff,
  setServicesPages,
  setServicesMaster,
  setSelectedSiteInfo,
  setEmergencyBannerMessages,
  setFailedYextData,
  setOrchestratorSiteInfo,
} from 'src/hooks/state/state'
import PawLoader from '../PawLoader'
import {
  getLatestEBUpdate,
  getLatestHOOUpdate,
  getLatestHoursUpdates,
  getLatestContactInfoUpdate
} from 'src/api/publishLogs'
import { ParseServiceGroup } from 'src/utils/parser'
import WebsitePageEmergencyBanner from 'src/pages/WebsitePagePage/WebsitePageEmergencyBanner'
import WebsitePageHours from 'src/pages/WebsitePagePage/WebsitePageHours'
import WebsitePageContactInfo from 'src/pages/WebsitePagePage/WebsitePageContactInfo';
import { EmergencyBanner } from 'src/components/EmergencyBanner/EmergencyBanner';

const ValidateUserGroupCellRest = ({ routingInfo, user }) => {
  let { siteId, pageName } = useParams()
  let history = useHistory()
  const [isLoading, setLoadingStatus] = useState(true)
  const [wasStaffAndEBFetched, setWasStaffAndEBFetched] = useState(false)
  const [isLocked, setIsLocked] = useState(false);
  const [initialHours] = useGlobalState('initialHours')
  const [initialBannerMsg] = useGlobalState('initialBanner')
  const [yextId] = useGlobalState('yextIdObj')
  const [reRenderStaff] = useGlobalState('reRenderStaff')
  const [failedYextData] = useGlobalState('failedYextData') //NVAMSP-120: EE: Sites without a hero carousel/hours fail to load in EE

  let selectedSite =
    routingInfo && routingInfo.location && routingInfo.location.state

  let view = 'large'

  if (selectedSite) {
    view = selectedSite.view ? selectedSite.view : 'large'
  }

  const getSiteInfo = async (siteId) => {
    const url = `${process.env.ORCHESTRATOR_SITE_INFO}?siteId=${siteId}&includeScheduledProcessesPending=true`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
      },
    });
    const res = await response.json();
    setOrchestratorSiteInfo(res);
    return res;
  }

  // get yext data
  const getYextData = async (yextId) => {
    return await fetch(
      `${process.env.EE_REST_YEXT_ENDPOINT}?yextID=${yextId}`,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
        },
      }
    ).then(async(response) => {
      if((response.ok)) {
        const res = await response.json()
        if(res.name === 'Error'){
          throw new Error(res.message)
        }
        return res;
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  const removeHolidayReopenHours = (hoursObj) => {
    let cleanObj = {}
    let days = [
      'friday',
      'monday',
      'saturday',
      'sunday',
      'thursday',
      'tuesday',
      'wednesday',
    ]
    if (hoursObj && Object.keys(hoursObj).length > 0) {
      days.forEach((eachDay) => {
        if (hoursObj[eachDay]) {
          cleanObj[eachDay] = hoursObj[eachDay]
        }
      })
    }

    return { ...cleanObj }
  }

  const getHolidayHours = (hours) => {
    return hours?.map((h) =>
      h.openIntervals
        ? h
        : { ...h, openIntervals: [{ start: '00:00', end: '23:59' }] }
    )
  }

  //building hours object
  let buildHours = async (yextData, userObj, yextId) => {
    const weekdayHours = { ...yextData.response.hours }
    delete weekdayHours.holidayHours
    const initialHoursContent = {
      hours: { ...removeHolidayReopenHours(weekdayHours) },
      additionalHoursText: yextData.response.additionalHoursText,
      c_extendedHours: yextData.response.c_extendedHours
        ? removeHolidayReopenHours(yextData.response.c_extendedHours)
        : {},
      c_extendedAdditionalHoursText:
        yextData.response.c_extendedAdditionalHoursText,
      holidayHours: getHolidayHours(yextData.response.hours.holidayHours),
    }

    const hooResponse = await getLatestHoursUpdates(userObj.id, siteId, yextId)
    const toggleResponse = await getLatestHOOUpdate(
      'Ext Hrs',
      'toggle',
      null,
      userObj.id,
      siteId,
      yextId.toString()
    )
    //console.log('buildHours -> toggleResponse', toggleResponse)
    const haveExtendedHours =
      toggleResponse.data.latestHOOUpdate &&
      toggleResponse.data.latestHOOUpdate.length > 0
        ? toggleResponse.data.latestHOOUpdate[0].newValue == 'false'
          ? false
          : true
        : yextData.response.c_extendedHours
        ? true
        : false

    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ]

    if (
      hooResponse.data.latestHoursUpdates != null &&
      hooResponse.data.latestHoursUpdates.length > 0
    ) {
      hooResponse.data.latestHoursUpdates.forEach((item) => {
        if (item.type == 'Hours') {
          switch (item.hoo_fieldName) {
            case 'interval': {
              let intervals = []

              item.openIntervals.map((interval) =>
                intervals.push({
                  start: interval.newStart,
                  end: interval.newEnd,
                })
              )
              if (weekDays.includes(item.hoo_day)) {
                initialHoursContent.hours[`${item.hoo_day}`]
                  ? !initialHoursContent.hours[`${item.hoo_day}`].isClosed
                    ? (initialHoursContent.hours[
                        `${item.hoo_day}`
                      ].openIntervals = intervals)
                    : ''
                  : (initialHoursContent.hours[`${item.hoo_day}`] = {
                      openIntervals: intervals,
                    })
              } else {
                const index = initialHoursContent.holidayHours?.findIndex(
                  (i) => i.date == item.hoo_day
                )
                if (index >= 0) {
                  initialHoursContent.holidayHours[
                    initialHoursContent.holidayHours.findIndex(
                      (i) => i.date == item.hoo_day
                    )
                  ]['openIntervals'] = intervals
                } else {
                  if(initialHoursContent.holidayHours && Array.isArray(initialHoursContent.holidayHours)){
                    initialHoursContent.holidayHours?.push({
                      date: item.hoo_day,
                      openIntervals: intervals,
                    })
                  }
                  else {
                    initialHoursContent.holidayHours = []
                    initialHoursContent.holidayHours.push({
                      date: item.hoo_day,
                      openIntervals: intervals,
                    })
                  }
                }
              }

              // initialHoursContent.hours[`${item.hoo_day}`] = {
              //   openIntervals: intervals,
              // }
              break
            }
            case 'status':
              if (weekDays.includes(item.hoo_day)) {
                initialHoursContent.hours[`${item.hoo_day}`]
                  ? item.newValue == 'Closed'
                    ? (initialHoursContent.hours[`${item.hoo_day}`] = {
                        isClosed: item.newValue == 'Open' ? false : true,
                      })
                    : (initialHoursContent.hours[`${item.hoo_day}`].isClosed =
                        item.newValue == 'Open' ? false : true)
                  : (initialHoursContent.hours[`${item.hoo_day}`] = {
                      isClosed: item.newValue == 'Open' ? false : true,
                    })
              } else {
                const index = initialHoursContent.holidayHours?.findIndex(
                  (i) => i.date == item.hoo_day
                )
                if (index >= 0) {
                  initialHoursContent.holidayHours[
                    initialHoursContent.holidayHours.findIndex(
                      (i) => i.date == item.hoo_day
                    )
                  ]['isClosed'] = item.newValue == 'Closed'
                } else {
                  initialHoursContent.holidayHours?.push({
                    date: item.hoo_day,
                    isClosed: item.newValue == 'Closed',
                    openIntervals: [{ start: '00:00', end: '23:59' }],
                  })
                }
              }

              // initialHoursContent.hours[`${item.hoo_day}`].isClosed =
              //   item.newValue == 'Closed' ? true : false
              //delete initialHoursContent.hours[`${item.hoo_day}`].openIntervals
              break
            case 'text':
              initialHoursContent.additionalHoursText = item.newValue
              break
            case 'holiday':
              initialHoursContent.holidayHours?.splice(
                initialHoursContent.holidayHours?.findIndex(
                  (i) => i.date == item.hoo_day
                ),
                1
              )
              break
            default:
              break
          }
        } else if (item.type == 'Ext Hrs' && haveExtendedHours == true) {
          switch (item.hoo_fieldName) {
            case 'interval': {
              let intervals = []

              item.openIntervals.map((interval) =>
                intervals.push({
                  start: interval.newStart,
                  end: interval.newEnd,
                })
              )

              initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                ? !initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                    .isClosed
                  ? (initialHoursContent.c_extendedHours[
                      `${item.hoo_day}`
                    ].openIntervals = intervals)
                  : ''
                : (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                    openIntervals: intervals,
                  })

              // initialHoursContent.c_extendedHours[
              //   `${item.hoo_day}`
              // ].openIntervals = intervals

              break
            }
            case 'status':
              initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                ? item.newValue == 'Closed'
                  ? (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                      isClosed: item.newValue == 'Open' ? false : true,
                    })
                  : (initialHoursContent.c_extendedHours[
                      `${item.hoo_day}`
                    ].isClosed = item.newValue == 'Open' ? false : true)
                : (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                    isClosed: item.newValue == 'Open' ? false : true,
                  })
              // initialHoursContent.c_extendedHours[`${item.hoo_day}`].isClosed =
              //   item.newValue == 'Closed' ? true : false

              // delete initialHoursContent.c_extendedHours[`${item.hoo_day}`]
              //   .openIntervals
              break
            case 'text':
              initialHoursContent.c_extendedAdditionalHoursText = item.newValue
              break
            default:
              break
          }
        }
      })

      if (!haveExtendedHours) {
        initialHoursContent.c_extendedHours = {}
      }
    }

    initialHoursContent?.holidayHours?.sort((a, b) =>
      a.date.localeCompare(b.date)
    )

    return initialHoursContent
  }

  let buildEbObj = async (yextData, userObj) => {
    let bannerText = formatBannerText(
      yextData.response.c_websiteEmergencyBanner
    )
    const initialBannerContent = {
      banner: bannerText,
    }

    const ebResponse = await getLatestEBUpdate(userObj.id, siteId)
    if (
      ebResponse.data.latestEBUpdate != null &&
      ebResponse.data.latestEBUpdate.length > 0
    ) {
      bannerText = formatBannerText(ebResponse.data.latestEBUpdate[0].newValue)
      initialBannerContent.banner = bannerText
    }

    return initialBannerContent
  }

  /**
   * Get a user and yext data.
   */
  const getUserRestAndYextData = async (userObj) => {
      try {
        // get yextId from user object based on passed siteId
        const foundYextId = userObj.groups.filter(
          (group) => siteId == group.siteId
        );

        let multilocationObj = foundYextId[0].multiLocationObject
          ? foundYextId[0].multiLocationObject.split('&')
          : null;

        if (foundYextId.length > 0) {
          setYextId(foundYextId)
          setCurrentYextId(foundYextId[0].yextId.toString())
          let hackyYextId = null
          // ! - this is a TEMPORARY HACK - if an id is less than 1000, concat an extra 0 in front
          // ! because mysql doesnt allow to attach an extra 0 in front of ints
          if (foundYextId[0].yextId.toString().length < 4) {
            hackyYextId = `0${foundYextId[0].yextId}`
          }

          const yextData = await getYextData(
            hackyYextId != null ? hackyYextId : foundYextId[0].yextId
          )
          //Validation added for NVAMSP-120: EE: Sites without a hero carousel/hours fail to load in EE
          if (!yextData|| !yextData.response.hours){
            setFailedYextData(true)
            setLoadingStatus(false)
            return;
          }

          setYextResponse(JSON.parse(JSON.stringify(yextData.response)))

          //building mainhours Object
          let initialHoursContent = await buildHours(
            yextData,
            userObj,
            foundYextId[0].yextId
          )
          // console.log(
          //   'getUserRestAndYextData -> initialHoursContent',
          //   initialHoursContent
          // )

          //building EB object
          let initialBannerContent = await buildEbObj(yextData, userObj)

          //building multi location object

          if (multilocationObj && multilocationObj.length > 0) {
            let locationsObj = await Promise.all(
              multilocationObj?.map(async (location) => {
                location = JSON.parse(location)

                const yextData = await getYextData(
                  location.yextId.length < 4
                    ? `0${location.yextId}`
                    : location.yextId
                )
                let hoursObj = await buildHours(
                  yextData,
                  userObj,
                  location.yextId
                )

                const yextInfo = {
                  url: location.url,
                  name: location.name,
                  yextId: location.yextId,
                  hours: hoursObj,
                  meta: yextData.response.meta,
                };

                if(yextData.response.c_textPhone){
                  yextInfo.c_textPhone = yextData.response.c_textPhone;
                }
                if(yextData.response.mainPhone){
                  yextInfo.mainPhone = yextData.response.mainPhone;
                }
                if(yextData.response.fax){
                  yextInfo.fax = yextData.response.fax;
                }

                return yextInfo;
              })
            )
            setMultiLocations(locationsObj)
          } else {
            setMultiLocations([])
          }

          setInitialHours(initialHoursContent)

          setUpdatedHours({})
          setIsHoursUpdated(false)
          setIsBannerUpdated(false)
          setUpdateBannerMsg('')
          setInitialBannerMsg('text', initialBannerContent.banner)
        }

        setLoadingStatus(false)
      } catch (error) {
        throw new Error(error)
      }
    }

  const formatBannerText = (text) => {
    return typeof text !== 'undefined' ? text.replace(/\\/g, '') : ''
  }

  const getStaffDataAndEB = async () => {
    try {
      const data = await client.getEntries({
        content_type: 'site',
        'fields.siteId': siteId,
        include: 5,
      })

      const masterData = await client.getEntries({
        content_type: 'servicesBladeBlockListing',
        'fields.name[match]': 'MASTER-EE',
        include: 5,
      })

      if (masterData.items) {
        const services = ParseServiceGroup(masterData.items)
        setServicesMaster(services)
      }

      if (data.includes.Entry) {
        let staffPages = []
        let servicesPages = []
        data.includes.Entry.map((item) => {
          if (item.fields.pageType && item.fields.pageType === 'Staff') {
            staffPages.push(item)
          } else if (
            item.fields.pageType &&
            item.fields.pageType === 'Services'
          ) {
            servicesPages.push(item)
          }
        })
        setStaffPages(staffPages)
        setServicesPages(servicesPages)
      }
      setReRenderStaff(false)

      if (data?.items?.[0]?.fields && data.items[0].sys) {
        setSelectedSiteInfo(data.items[0].fields)

        const emergencyBannerMasterMessages = await client.getEntries({
          content_type: 'emergencyBannerMessage',
          'fields.businessLine[in]': data.items[0].fields.websiteType,
          include: 1,
        })

        emergencyBannerMasterMessages?.items &&
          setEmergencyBannerMessages(
            emergencyBannerMasterMessages?.items.sort((a, b) =>
              a.fields.name.localeCompare(b.fields.name)
            )
          )

        setShowBannerState(
          data.items[0].fields.showEmergencyBanner == false || data.items[0].fields.showEmergencyBanner == undefined ? false : true
        )
        setBannerBackgroundColor(
          data.items[0].fields.emergencyBannerBackgroundColour || 'Red'
        )
        setWebsiteTheme(data.items[0].fields.theme || undefined)
        setInitialBannerMsg('contentfulEntry', data.items[0].sys.id)
      }
      setWasStaffAndEBFetched(true)
    } catch (err) {
      // TODO: handle error (or loader will display forever)
      console.log(err)
    }
  }

  const getLockStatus = () => {
    fetch(`${process.env.SITE_PUBLISHER_URL}/status/${siteId}`, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.lock) {
          setIsLocked(true);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }

  useEffect(() => {
    user && getUserRestAndYextData(user);
  }, [user])

  useEffect(() => {
    if (reRenderStaff) getStaffDataAndEB()
    getLockStatus();
    getSiteInfo(siteId);
  },[])

  setSelectedSite(selectedSite ? selectedSite : yextId[0])

  return (!isLoading && wasStaffAndEBFetched) ? (
    failedYextData || yextId && yextId.length > 0 && !_.isEmpty(initialHours) ? (
      <>
        {isLocked && <EmergencyBanner
          text={<p>This website is currently locked from publishing because of pending changes. Please contact us
          through <a
                onClick={()=>window.open(process.env.RETRIEVER_SUPPORT_LINK, '_blank').focus()}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >Retriever support</a> with any questions.
          </p>}
        />}
        <MainLayout user={user}>
          {pageName &&
          (pageName == 'hours' || pageName == 'multi-location-hours') ? (
            <WebsitePageHours routingInfo={routingInfo} view={view} user={user} />
          ) : pageName && pageName == 'about' ? (
            <WebsitePageEmergencyBanner
              routingInfo={routingInfo}
              user={user}
              view={view}
              key={initialBannerMsg.key}
            />
          ) : pageName &&
            (pageName == 'staff' || pageName == 'multi-location-staff') ? (
            <WebsiteStaffEditForm routingInfo={routingInfo} user={user} />
          ) : pageName && pageName == 'services' ? (
            <WebsiteServicesEditForm routingInfo={routingInfo} user={user} />
          ) : pageName && pageName == 'main' ? (
            <WebsitePageMain routingInfo={routingInfo} view={view} user={user} />
          ) : pageName && pageName == 'home' ? (
            <WebsitePageHomeCarousel
              routingInfo={routingInfo}
              view={view}
              user={user}
            />
          ) : pageName &&
          (pageName == 'contactinfo' || pageName == 'multi-location-contactinfo') ? (
            <WebsitePageContactInfo routingInfo={routingInfo} user={user} />
          ) : (
            history.goBack()
          )}
        </MainLayout>
      </>
    ) : (
      <UpdatedMainLayout>
        <span className="pl-6">
          Sorry you do not have access to this site. Please go back to{' '}
          <Link to="/dashboard" className="underline text-teal-600">
            Dashboard
          </Link>
        </span>
      </UpdatedMainLayout>
    )
  ) : (
    <UpdatedMainLayout>
      <PawLoader isBigVersion />
    </UpdatedMainLayout>
  )
}

export default ValidateUserGroupCellRest
