import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getAllGroups } from '../../api/groups'
import Websites from 'src/components/Websites'
import PawLoader from '../PawLoader'

const WebsitesRest = () => {
  const [websites, setWebsites] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)
  const [isInfoChanged, setIsInfoChanged] = useState(false)

  /**
   * Get a websites from REST API.
   */
  const getWebsitesRest = useCallback(async () => {
    // get all websites
    const response = await getAllGroups()
    let restWebsites = response.data.groups

    setWebsites(restWebsites)
    setLoadingStatus(false)
    setIsInfoChanged(false)
  }, [])

  useEffect(() => {
    getWebsitesRest()
  }, [getWebsitesRest, isInfoChanged])

  return !isLoading ? (
    <Websites websites={websites} setIsInfoChanged={setIsInfoChanged} />
  ) : (
    <Fragment>
      <PawLoader isBigVersion />
    </Fragment>
  )
}

export default WebsitesRest
