import React from 'react'
import { useHistory } from 'react-router-dom'

function WebsiteCard({ website }) {
  const history = useHistory()
  return (
    <div className="overflow-hidden shadow-outer w-full flex flex-row my-1 p-6">
      <div className="w-4/12 flex flex-col m-auto">
        <span className="font-medium text-base font-sans">{website.name}</span>
      </div>
      <div className="w-4/12 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">{website.siteURL}</span>
        <span className="text-sm">Site URL</span>
      </div>
      <div className="w-1/12 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">{website.siteId}</span>
        <span className="text-sm">Website ID</span>
      </div>
      <div className="w-1/12 flex flex-col m-auto">
        <span className="font-medium text-sm font-sans">{website.yextId}</span>
        <span className="text-sm">Yext ID</span>
      </div>
      <div className="w-2/12 flex flex-col m-auto">
        <button
          className="text-teal-500 focus:outline-none"
          onClick={() => {
            history.push(`/websites/${website.id || 1}`)
          }}
        >
          Edit
        </button>
      </div>
    </div>
  )
}

export default WebsiteCard
