import React, { useEffect, useState } from 'react'
// import { useFlash } from '@redwoodjs/web'
import { Prompt, useHistory } from 'react-router-dom'
import TurndownService from 'turndown'
import { client, cma } from '../../../../api/src/lib/contentful'
import Groups from '../../components/Services/Groups'
import GroupDetails from '../../components/Services/GroupDetails'
import ServiceDetails from '../../components/Services/ServicesDetails'
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown'
import { createContentfulLogV2 } from 'src/api/userActionLogs'
// import { Context } from '../../../Store'
import Sidebar from 'src/components/Staff/Sidebar'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import {
  useGlobalState,
  setReRenderStaff,
  setCurrentServiceInfo,
} from '../../hooks/state/state'
import ReactGA from 'react-ga'
import { servicesMessages } from '../../constants/messages/en'
import { ParseServiceGroup } from 'src/utils/parser'
import MultiLocationSelection from 'src/components/MultilocationSelection/MultilocationSelection'
import { sendMsg } from 'src/utils'
import PawLoader from 'src/components/PawLoader';
import {
  SERVICE_DETAIL,
  SERVICE_GROUP,
  SERVICE_DETAIL_LINK
} from 'src/constants/previewHtmlTemplates';

const ACTIONS = {
  ADD_GROUP: 'Group service added',
  DELETE_GROUP: 'Group service deleted',
  REORDER_GROUP: 'Group service reordered',
  UPDATE_GROUP_DESCRIPTION: 'Group service description updated',

  ADD_SERVICE: 'Service detail added',
  DELETE_SERVICE: 'Service detail deleted',
  REORDER_SERVICE: 'Service detail reordered',
  UPDATE_SERVICE_LOCAL_COPY: 'Service detail local copy updated'
};

const EMPTY_LOCAL_COPY = {
  "en-US": {
    "data": {},
    "content": [
      {
        "data": {},
        "content": [
            {
              "data": {},
              "marks": [],
              "value": " ",
              "nodeType": "text"
            }
        ],
        "nodeType": "paragraph"
      }
    ],
    "nodeType": "document"
  }
};

/**
 * Creates an object to be used in the user action log
 * @param {string} entryID - The ID of the entry
 * @param {string} entryTitle - The title of the entry
 * @param {string} verbalDescription - The description of the action
 * @param {boolean} isCloned - Whether the entry was cloned or not
 * @returns {object} - The object to be used in the user action log
 */
const createActionObject = (entryID, entryTitle, verbalDescription, isCloned, path) => ({
    entryID: isCloned ? `clonedFrom:${entryID}` : entryID,
    entryTitle,
    verbalDescription,
    path
});

const WebsitePageServices = ({ user }) => {
  const [initialdataServices, setInitialdataServices] = useState(null)
  const [dataServices, setDataServices] = useState([]);
  const [actionsPerformed, setActionsPerformed] = useState([]);
  const [step, setStep] = useState('groups')
  const [currGroup, setCurrGroup] = useState(null)
  const [currService, setCurrService] = useState(null)
  const [saveButtonDisabled, setSaveButtonDisable] = useState(true)
  const [servicePageId, setServicePageId] = useState(null)
  const [isGroupsReordered, setIsGroupsReordered] = useState(false)
  const [reorderedServices, setReorderedServices] = useState([])
  const [updatedHideStatus, setUpdatedHideStatus] = useState([]) // used to update hideBlade field in Contentful
  const [iframeView, setIframeView] = useState('large')
  const [, setError] = useState(null)
  const [isNewStaffAdded, setIsNewStaffAdded] = useState(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [currentService, setCurrentService] = useState(null)
  const turndownService = new TurndownService()
  let history = useHistory()
  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [environment, setEnvironment] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [GlobalServiceInfo] = useGlobalState('currentServiceInfo')
  const [currentServiceId] = useGlobalState('currentServiceId')
  const [servicesPagesList] = useGlobalState('servicesPages')
  const [servicesMasterData] = useGlobalState('servicesMaster')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isBannerUpdated] = useGlobalState('isBannerUpdated')
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [isOnlyService, setIsOnlyService] = useState(false)
  const [saving, setSaving] = useState(false)

  const [selectedSite] = useGlobalState('selectedSite')
  const baseSiteURL = selectedSite.siteURL.replace(/#.*$/, '')
  let description = isBannerUpdated
    ? descriptionUpdated
    : descriptionInitial.text;

  window.onbeforeunload = function () {
    if (isFormDirty) return ''
    else return undefined
  }

  const addNewActionPerformed = (action) => {
    if(actionsPerformed.find(a => a.verbalDescription === action.verbalDescription)) return;
    setActionsPerformed([...actionsPerformed, action]);
    const mySet = new Set();
    mySet.add(action);
  }

  useEffect(() => {
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'

    cma
      .getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE)
      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENV))
      .then((env) => setEnvironment(env))
      .catch((error)=>{
        alert("Connection with Contentul is not established");
      });
  }, [])

  useEffect(() => {
    if (
      GlobalServiceInfo &&
      Object.keys(GlobalServiceInfo).length === 0 &&
      servicesPagesList.length === 0
    ) {
      history.push(
        `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
      )
    }
  }, [GlobalServiceInfo])

  useEffect(() => {
    if (
      initialdataServices &&
      initialdataServices !== JSON.stringify(dataServices)
    ) {
      setIsFormDirty(true)
      setSaveButtonDisable(false)
      if (
        dataServices &&
        dataServices[currGroup] &&
        dataServices[currGroup].services &&
        dataServices[currGroup].services[currService] &&
        dataServices[currGroup].services[currService].name === ''
      ) {
        setSaveButtonDisable(true)
      }
    }

    sendMsg({ type: 'Services', isGroupsReordered, content: dataServices?.filter((g) => g?.isService) });
  }, [dataServices])

  async function getData() {
    try {
      let data
      const servicesPage = selectedSiteInfo.sitePages.find(
      (page) => page.fields.pageType === 'Services'
      );
      let content = await client.getEntries({
        content_type: 'templateDynamicLayoutPage',
        'sys.id[in]': servicesPage?.sys?.id,
        include: 5,
      });
      if(servicePageId == null) setServicePageId(servicesPage?.sys?.id);
      if(content.items.length > 0){
        data = content.items[0]
        setCurrentServiceInfo(content.items[0])
      } else {
        data = servicesPage
        setCurrentServiceInfo(servicesPage)
      }

      if (!data) {
        return;
      }
      // setting the page id to link the new service groups
      if (data.sys && data.sys.id) {
        setServicePageId(data.sys.id);
      }

      let serviceArray = ParseServiceGroup(data.fields.items);

      setInitialdataServices(JSON.stringify(serviceArray));
      setDataServices(serviceArray);

      if (addButtonDisabled) {
        setAddButtonDisabled(false);
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getData();
  }, [currentServiceId, isNewStaffAdded])

  const createContentfulLogRest = async (
    entryID,
    entryTitle,
    verbalDescription,
    path
  ) => {
    const input = {
      contentfulId: entryID,
      contentfulTitle: entryTitle,
      isPublished: 0,
      userId: user.id,
      verbalDescription,
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
      updateType: 'Services',
      websiteId: selectedSite.siteId,
      path: `/${path}`
    }

    ReactGA.event({
      category: 'Content - Services',
      action: `User ${user.id} Made Changes to Services`,
      label: `Value: ${verbalDescription}`,
    })

    try {
      const query = JSON.stringify(input).replace(/"([^"]+)":/g, '$1:')
      await createContentfulLogV2(query)
    } catch (e) {
      setError(e)
    }
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData });
      if(step === 'staff_details') {
        sendMsg({
          type: 'ServicesDetailsUpdateLocalCopy',
          isGroupsReordered,
          content: dataServices[currGroup].services[currService],
          templateHtml: SERVICE_DETAIL
        });
        sendMsg({ goToLocalCopy: true });
      }
      if(step === 'group_details') {
        sendMsg({ type: 'Services', isGroupsReordered, content: dataServices?.filter((g) => g?.isService) });
        sendMsg({ scrollToGroupId: dataServices?.[currGroup]?.key });
      }
      if(step === 'groups'){
        sendMsg({ type: 'Services', isGroupsReordered, content: dataServices?.filter((g) => g?.isService) });
      }
    })
  }

  const goBack = () => {
    if (step == 'group_details') {
      setStep('groups');
    } else if (step == 'staff_details') {
      setCurrService(null);
      setTimeout(() => sendMsg({ scrollToGroupId: dataServices[currGroup].key }), 2000);
      setStep('group_details');
    } else {
      history.push(
        `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
      );
    }
  }

  // Prepares the array to update the Contentful hideBlade value of a service group
  const updateGroupHideStatus = (groupId, hideBlade) => {
    // Find if the item already exists in the array
    const groupIndex = updatedHideStatus.findIndex(group => group.groupId === groupId);

    // If the group ID exists in the array it means it will go back to the original state (either true or false)
    // Thus it's not necessary to update it in Contenful
    if (groupIndex !== -1) {
      const updatedGroups = [...updatedHideStatus];

      // Remove the item to not update unecessarily in Contentful
      updatedGroups.splice(groupIndex, 1);
      setUpdatedHideStatus(updatedGroups);
    } else {
      // Add the item to update
      setUpdatedHideStatus([...updatedHideStatus, { groupId, hideBlade }]);
    }
  }

  /**
   * If the entry added is from servicesMaster, it checks if it's already included in dataServices.
   * The service could come directly from dataServices or from serviceMaster. This is set in Groups.js in:
   * setAvailableGroups([...hiddenGroups, ...masterGroups])
   * Similarly in GroupDetails.js in: setAvailableServices(masterLibrary)
   * @param {array} services - The array of services
   * @param {string} entryId - The ID of the entry
   * @returns {array} - A boolean indicating if it's already included in dataServices and the index of the entry
   */
  const isEntryAlreadyIncluded = ({ services, entryId }) => {
    const index = services.findIndex((s) => s.key === entryId || s.serviceEntryId === entryId)

    return [index >= 0, index];
  }

  const addGroup = (item) => {
    const [isEntryIncluded, index] = isEntryAlreadyIncluded({ services: dataServices, entryId: item.key });
    let newDataServices = [...dataServices];

    if (isEntryIncluded) {
      newDataServices[index].hideBlade = false;
      newDataServices[index].new = true;
      newDataServices[index].isModified = true;
      item.services = item.services.map((service, i) => ({
        ...service,
        hideService: i === 0 ? false : true,
        isModified: true
      }));
      // Move the current service group to the end of the array
      const groupToMove = newDataServices.splice(index, 1)[0];
      newDataServices.push(groupToMove);

      addNewActionPerformed(createActionObject(item.key, item.groupName, ACTIONS.ADD_GROUP, false, getPath()));
    } else {
      const modifiedServices = item.services.map((service, i) => ({
        ...service,
        hideService: i === 0 ? false : true,
        isModified: true
      }));
      let newGroup = {
        ...item,
        services: [...modifiedServices],
        hideBlade: false,
        new: true,
        isModified: true,
      };
      addNewActionPerformed(createActionObject(item.key, item.groupName, ACTIONS.ADD_GROUP, true, getPath()));
      newDataServices = [...newDataServices, newGroup];
    }

    setIsGroupsReordered(true);

    setDataServices(newDataServices);
    sendMsg({
      type: item.isMaster && !isEntryIncluded ? 'ServicesAddGroup' : 'ServicesAddExistingGroup',
      isGroupsReordered,
      content: newDataServices.filter((g) => g?.isService),
      goToItem: item.key
    });
    updateGroupHideStatus(item.key, false);
  }

  const deleteGroup = async (groupId) => {
    const currentGroupIndex = dataServices.findIndex((g) => g.key === groupId);

    const visibleGroups = dataServices.filter(
      (group) => !group.hideBlade && group.isService
    );
    if (visibleGroups.length === 1) {
      alert(servicesMessages.NO_EMPTY_SERVICE_PAGE);
      return;
    }

    const newdataServices = [...dataServices];

    newdataServices[currentGroupIndex].hideBlade = true;
    newdataServices[currentGroupIndex].isModified = true;
    setDataServices(newdataServices);

    updateGroupHideStatus(groupId, true);
    sendMsg({
      type: 'ServicesDeleteGroup',
      isGroupsReordered,
      content: { groups: newdataServices.filter((g) => g?.isService), deleted: groupId },
    });
    addNewActionPerformed(
        createActionObject(
          newdataServices[currentGroupIndex].key,
          newdataServices[currentGroupIndex].groupName,
          ACTIONS.DELETE_GROUP,
          false,
          getPath()
      ));
  }

  const updateGroupName = (data) => {
    const newdataServices = [...dataServices]
    newdataServices[currGroup].groupName = data
    newdataServices[currGroup].isRenamed = true
    setDataServices(newdataServices)
    sendMsg({
      type: 'ServicesUpdateGroupName',
      isGroupsReordered,
      content: newdataServices.filter((g) => g?.isService),
    })
  }

  const updateGroupDescription = async (data) => {
    const markdown = turndownService.turndown(data);
    let newdataServices = [...dataServices];
    const markdownData = await richTextFromMarkdown(markdown);
    if (!_.isEqual(newdataServices[currGroup].bodyText, { json: markdownData })) {
      newdataServices[currGroup].bodyText =
        data.length === 0
          ? (newdataServices[currGroup].bodyText = {
              json: null,
            })
          : (newdataServices[currGroup].bodyText = {
              json: markdownData,
            });
      newdataServices[currGroup].isModified = true;
      addNewActionPerformed(
        createActionObject(
          newdataServices[currGroup].key,
          newdataServices[currGroup].groupName,
          ACTIONS.UPDATE_GROUP_DESCRIPTION,
          false,
          getPath()
      ));
      setDataServices(newdataServices);
    }
    // set it to blank if no data
    sendMsg({
      type: 'ServicesUpdateGroupDescription',
      isGroupsReordered,
      content: newdataServices[currGroup],
    });
    sendMsg({
      type: 'ServicesAddService',
      isGroupsReordered,
      content: newdataServices[currGroup],
    });
  }

  const goGroup = (groupKey) => {
    setCurrGroup(groupKey)
    setCurrService(null)
    setStep('group_details')
  }

  const getRealDestGroups = (dest) => {
    let realDest = 0;
    for (let i = 0; i < dataServices.length; i++) {
      if (!dataServices[i]?.isService) {
        continue;
      }
      if(dataServices[i]?.hideBlade){
        continue;
      }
      if(realDest === dest){
        realDest = i;
        break;
      }
      realDest++;
    }
    return realDest;
  }

  const reorderGroups = (source, dest) => {
    const groupToMove = dataServices[source];
    const newdataServices = [...dataServices];

    const REAL_DEST = getRealDestGroups(dest);

    newdataServices.splice(source, 1);
    newdataServices.splice(REAL_DEST, 0, groupToMove);
    setDataServices(newdataServices);
    setIsGroupsReordered(true);
    addNewActionPerformed(
      createActionObject(
        servicePageId,
        "",
        ACTIONS.REORDER_GROUP,
        false,
        getPath()
    ));
  }

  const deleteService = async (serviceId) => {
    const newDataServices = [...dataServices];

    const currentServiceIndex = newDataServices[currGroup].services.findIndex(
      (s) => s.serviceEntryId === serviceId
    );

    const visibleServices = newDataServices[currGroup].services.filter(
      (s) => !s.hideService
    );
    if (visibleServices.length === 1) {
      alert(servicesMessages.NO_EMPTY_GROUPS);
      return;
    }

    newDataServices[currGroup].services[currentServiceIndex].hideService = true;
    newDataServices[currGroup].services[currentServiceIndex].wasHidden = true;
    newDataServices[currGroup].services[currentServiceIndex].isModified = true;
    setDataServices(newDataServices);

    addNewActionPerformed(
      createActionObject(
        newDataServices[currGroup].services[currentServiceIndex].serviceEntryId,
        newDataServices[currGroup].services[currentServiceIndex].name,
        ACTIONS.DELETE_SERVICE,
        false,
        getPath()
    ));
    sendMsg({
      type: 'ServicesDeleteService',
      isGroupsReordered,
      content: newDataServices.filter((g) => g?.isService),
    });
  }

  const addServiceFromList = (serviceId, item) => {
    const newDataServices = [...dataServices];

    const service = {
      ...item,
      hideService: false,
      new: true,
      isModified: true,
    };

    const [isEntryIncluded, index] = isEntryAlreadyIncluded({
      services: newDataServices[currGroup].services,
      entryId: serviceId
    });

    if (isEntryIncluded) {
      newDataServices[currGroup].services[index].hideService = false;
      newDataServices[currGroup].services[index].new = true;
      newDataServices[currGroup].services[index].isModified = true;

      // Move the current service to the end of the array
      const serviceToMove = newDataServices[currGroup].services.splice(index, 1)[0];
      newDataServices[currGroup].services.push(serviceToMove);

      addNewActionPerformed(
        createActionObject(
          newDataServices[currGroup].services[index].serviceEntryId,
          newDataServices[currGroup].services[index].name,
          ACTIONS.ADD_SERVICE,
          false,
          getPath()
      ));
    } else {
      newDataServices[currGroup].services.push(service);
      addNewActionPerformed(
        createActionObject(
          service.serviceEntryId,
          service.name,
          ACTIONS.ADD_SERVICE,
          true,
          getPath()
      ));
    }
    newDataServices[currGroup].isModified = true;
    setDataServices(newDataServices);
    sendMsg({
      type: 'ServicesAddService',
      isGroupsReordered,
      content: newDataServices[currGroup],
    });
  }

  const updateServiceName = (data) => {
    let newdataServices = [...dataServices]
    newdataServices[currGroup].services[currService].edited = true

    // set it to blank if no data
    newdataServices[currGroup].services[currService].name = data ? data : ''
    setCurrentService(newdataServices[currGroup].services[currService])

    setDataServices(newdataServices)
    sendMsg({
      type: 'ServicesDetailsUpdateName',
      isGroupsReordered,
      content: newdataServices.filter((g) => g?.isService),
    })
    //sendMsg(dataServices)
    if (data.length > 0) {
      setSaveButtonDisable(false)
    } else {
      setSaveButtonDisable(true)
    }
  }

  const updateLocalCopy = async (data) => {
    const markdown = turndownService.turndown(data);
    let newdataServices = [...dataServices];
    const markdownData = await richTextFromMarkdown(markdown);
    newdataServices[currGroup].services[currService].isModified = true;
    // set it to blank if no data
    if(data.length === 0){
      newdataServices[currGroup].services[currService].localCopy = null;
    }else{
      newdataServices[currGroup].services[currService].localCopy = {
        json: markdownData,
      };
    }
    setDataServices(newdataServices);
    addNewActionPerformed(
      createActionObject(
        newdataServices[currGroup].services[currService].serviceEntryId,
        newdataServices[currGroup].services[currService].name,
        ACTIONS.UPDATE_SERVICE_LOCAL_COPY,
        false,
        getPath()
    ));
    sendMsg({
      type: 'ServicesDetailsUpdateLocalCopy',
      isGroupsReordered,
      content: newdataServices[currGroup].services[currService],
      templateHtml: SERVICE_DETAIL
    });

    setCurrentService(newdataServices[currGroup].services[currService]);
  }

  const goStaff = (serviceKey, serviceLength) => {
    if (serviceLength <= 1) {
      setIsOnlyService(true)
    } else {
      setIsOnlyService(false)
    }
    setCurrService(serviceKey)
    setStep('staff_details')
  }

  const getRealDestServiceDetail = (dest) => {
    let realDest = 0;
    for (let i = 0; i < dataServices[currGroup].services.length; i++) {
      if(dataServices[currGroup].services[i]?.hideService){
        continue;
      }
      if(realDest === dest){
        realDest = i;
        break;
      }
      realDest++;
    }
    return realDest;
  }

  const reorderService = (source, dest) => {
    const serviceToMove = dataServices[currGroup].services[source];
    const newdataServices = [...dataServices];

    const REAL_DEST = getRealDestServiceDetail(dest);

    // delete the source element
    newdataServices[currGroup].services.splice(source, 1);
    // insert staffToMove into destination index
    newdataServices[currGroup].services.splice(REAL_DEST, 0, serviceToMove);
    newdataServices[currGroup].isModified = true;
    addNewActionPerformed(
      createActionObject(
        newdataServices[currGroup].key,
        newdataServices[currGroup].groupName,
        ACTIONS.REORDER_SERVICE,
        false,
        getPath()
    ));
    setDataServices(newdataServices);
    sendMsg({
      type: 'Services',
      isServicesReordered: true,
      content: newdataServices.filter((g) => g?.isService),
    });

    if (!reorderedServices.includes(dataServices[currGroup].key)) {
      reorderedServices.push(dataServices[currGroup].key);
    }
  }

  const renderStep = () => {
    switch (step) {
      case 'groups':
      default:
        return (
          <Groups
            data={dataServices}
            masterData={servicesMasterData}
            addGroup={addGroup}
            goGroup={goGroup}
            reorderGroups={reorderGroups}
            addButtonDisabled={addButtonDisabled}
            deleteGroup={deleteGroup}
          />
        )

      case 'group_details':
        return (
          <GroupDetails
            selectedSite={selectedSite}
            group={currGroup}
            data={dataServices}
            masterData={
              servicesMasterData?.filter(
                (sg) => sg.groupName === dataServices[currGroup]?.groupName
              )[0]
            }
            addServiceFromList={addServiceFromList}
            goStaff={goStaff}
            // goStaff={() => {}}
            deleteGroup={deleteGroup}
            updateGroupName={updateGroupName}
            updateGroupDescription={updateGroupDescription}
            reorderService={reorderService}
            addButtonDisabled={addButtonDisabled}
            setAddButtonDisabled={setAddButtonDisabled}
            onServiceDelete={deleteService}
          />
        )

      case 'staff_details':
        return (
          <ServiceDetails
            group={currGroup}
            service={currService}
            data={dataServices}
            isCropped={false}
            selectedSite={selectedSite}
            isOnlyService={isOnlyService}
            // deleteStaff={deleteStaff}
            updateServiceName={updateServiceName}
            updateLocalCopy={updateLocalCopy}
            // updateServiceOverview={updateServiceOverview}
            // updateServiceDetails={updateServiceDetails}
            saveButtonDisabled={saveButtonDisabled}
            setSaveButtonDisable={setSaveButtonDisable}
          />
        )
    }
  }

  const getEntry = async (entryId) => {
    return await environment.getEntry(entryId);
  }

  const createServiceDetailsCard = async (serviceDetailCard) => {
    return await environment
      .createEntry('serviceDetailsCard', serviceDetailCard);
  }

  const createServiceDetailsCardFromMaster = async (masterEntry, service) => {
    const cloneEntry = { ...masterEntry };
    cloneEntry.fields.name[
      'en-US'
    ] = `SERVICE DETAILS CARD - ${selectedSite.name} ${selectedSite.yextId} - ${service.name}`;
    cloneEntry.fields.localCopy = { 'en-US': service.localCopy?.json };
    cloneEntry.fields.hideService = { 'en-US': service.hideService };
    delete cloneEntry.sys;
    const newClonedEntry = await createServiceDetailsCard(cloneEntry);
    return await newClonedEntry.publish();
  }

  const isMaster = (entry) => entry.fields.name['en-US'].includes('MASTER');

  const updateServiceDetailsCard = async ({serviceDetailCard, localCopy, hideService}) => {
    if(!serviceDetailCard) return;
    if(localCopy){
      serviceDetailCard.fields.localCopy = { 'en-US': localCopy };
    }else{
      serviceDetailCard.fields.localCopy = EMPTY_LOCAL_COPY;
    }
    serviceDetailCard.fields.hideService = { 'en-US': hideService };
    const serviceDetailCardUpdated = await serviceDetailCard.update();
    await serviceDetailCardUpdated.publish();
  }

  const updateServiceGroup = async ({serviceGroup, bodyText, hideBlade, services}) => {
    if(!serviceGroup) return;
    if(bodyText){
      serviceGroup.fields.bodyText = { 'en-US': bodyText };
    }else{
      serviceGroup.fields.bodyText = EMPTY_LOCAL_COPY;
    }
    serviceGroup.fields.hideBlade = { 'en-US': hideBlade };
    serviceGroup.fields.serviceTile = { 'en-US': [...services] };
    const serviceGroupUpdated = await serviceGroup.update();
    await serviceGroupUpdated.publish();
  }

  const createServiceGroup = async (serviceGroup) => {
    return await environment
      .createEntry('servicesBladeBlockListing', serviceGroup);
  }

  const createServiceGroupFromMaster = async ({masterEntry, serviceGroup, serviceTitle}) => {
    if(!masterEntry) return;
    const cloneEntry = { ...masterEntry };
    cloneEntry.fields.name[
      'en-US'
    ] = `SERVICES BLADE - ${selectedSite.name} ${selectedSite.yextId} - ${serviceGroup.groupName}`;
    cloneEntry.fields.serviceTile = { 'en-US': [...serviceTitle] };
    cloneEntry.fields.bodyText = { 'en-US': serviceGroup.bodyText?.json ?? null };
    cloneEntry.fields.hideBlade = { 'en-US': serviceGroup.hideBlade };
    delete cloneEntry.sys;
    const newServiceGroupCloned = await createServiceGroup(cloneEntry);
    return await newServiceGroupCloned.publish();
  }

  const replaceMasterReferenceByUniqueReference = (entry, newId) => {
    entry.key = newId;
  }

  const replaceMasterServiceDetailsReferenceByUniqueReference = (entry, newId) => {
    entry.serviceEntryId = newId;
  }

  const sendLogs = async () => {
    await Promise.all(
      actionsPerformed.map(async (action) => {
        await createContentfulLogRest(
          action.entryID,
          action.entryTitle,
          action.verbalDescription,
          action.path
        );
      })
    );
    setActionsPerformed([]);
  }


  const saveChanges = async () => {
    if(environment===null){
      alert("Connection with Contentul is not established");
      return;
    }
    setAddButtonDisabled(true);
    setSaving(true);

    let allDataServices = [...dataServices];


    // handling Service Details cards
    for (let i = 0; i < allDataServices?.length; i++) {
      for (let j = 0; j < allDataServices?.[i]?.services?.length; j++){
        if(!allDataServices?.[i]?.services[j]?.isModified){
          continue;
        }
        const serviceDetailSaved = await getEntry(allDataServices?.[i]?.services?.[j]?.serviceEntryId);
        if (!isMaster(serviceDetailSaved)){
          await updateServiceDetailsCard({
            serviceDetailCard : serviceDetailSaved,
            localCopy: allDataServices?.[i]?.services?.[j]?.localCopy?.json,
            hideService: allDataServices?.[i]?.services?.[j]?.hideService
          });
          continue;
        }
        const serviceCreated = await createServiceDetailsCardFromMaster(serviceDetailSaved, allDataServices?.[i]?.services?.[j]);
        replaceMasterServiceDetailsReferenceByUniqueReference(allDataServices[i].services[j], serviceCreated.sys.id);
        allDataServices[i].isModified = true;
      };
    };

    let isNewGroupAdded = false;
    // handling Service Groups (Service blade - block listing)
    for (let i = 0; i < allDataServices.length; i++) {
      if(!allDataServices?.[i]?.isModified){
        continue;
      }
      const serviceGroupOriginal = await getEntry(allDataServices[i].key);
      const servicesDetail = allDataServices[i].services.map(item => ({
        sys: {
          id: item.serviceEntryId,
          linkType: 'Entry',
          type: 'Link',
        }
      }));
      if(!isMaster(serviceGroupOriginal)){
        await updateServiceGroup({
          serviceGroup: serviceGroupOriginal,
          bodyText: allDataServices[i].bodyText?.json,
          hideBlade: allDataServices[i].hideBlade,
          services: servicesDetail
        });
        continue;
      }
      const serviceGroupCreated = await createServiceGroupFromMaster({
        masterEntry: serviceGroupOriginal,
        serviceGroup: allDataServices[i],
        serviceTitle: servicesDetail
      });

      replaceMasterReferenceByUniqueReference(allDataServices[i], serviceGroupCreated?.sys?.id);
      isNewGroupAdded = true;
    };

    if(isNewGroupAdded || isGroupsReordered){
      const groupsReordered = allDataServices.map(item => ({
        sys: {
          id: item.key,
          linkType: 'Entry',
          type: 'Link',
        }
      }));
      const entryService = await getEntry(servicePageId);
      entryService.fields.items['en-US'] = [...groupsReordered];
      const entryServiceUpdated = await entryService.update();
      await entryServiceUpdated.publish();
      setIsGroupsReordered(false);
    }

    await sendLogs();
    setSaveButtonDisable(true);
    setAddButtonDisabled(false);
    setIsFormDirty(false);
    setSaving(false);
    setReRenderStaff(true);
    await getData();
  }

  const getPath = () => {
    let serviceUrl = GlobalServiceInfo?.fields?.url;
    if(!serviceUrl){
      return "";
    }
    if(serviceUrl?.startsWith('/')){
      serviceUrl = serviceUrl.slice(1);
    }
    if(currService != null){
      serviceUrl += `/${dataServices[currGroup]?.services[currService]?.serviceUrl}`;
    }
    if(serviceUrl.endsWith('/')){
      serviceUrl = serviceUrl.slice(0, -1);
    }
    return serviceUrl;
  }

  const getPreviewURL = () => {
    let url = process.env.INSTANT_PREVIEW_URL;
    // let url = "http://localhost:8000";
    if(url.endsWith('/')){
      url = url.slice(0, -1);
    }
    let serviceUrl = getPath();
    if(serviceUrl){
      url += `/${serviceUrl}`;
    }
    url += `:${selectedSite.siteId}?showIPcontrols=false`;
    return url;
  }

  const previewServices = () => {
    const URL = getPreviewURL();
    return (
      <PreviewFrame
        siteName={servicesPagesList[0]?.fields?.title}
        selectedSite={URL}
        sendOnLoadMsg={sendOnLoadMsg}
        iframeView={iframeView}
        loadingMessage="Loading Services Preview - this can take up to 30 seconds"
      />
    );
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && !saveButtonDisabled}
        message={() => servicesMessages.CONFIRM_LOSE_UNSAVED_SERVICE_INFO}
      />
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={saving}
        saveButtonDisabled={saveButtonDisabled}
        onBackClick={() => {
          goBack()
          setReRenderStaff(true)
        }}
        userGroups={user.groups}
      >
        {addButtonDisabled && (<PawLoader/>)}
        {!addButtonDisabled && renderStep()}
      </Sidebar>
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          // sendOnLoadMsg={sendOnLoadMsg}
        />
        {previewServices()}
      </div>
    </div>
  )
}

export default WebsitePageServices
