import React, { useState, useRef, useEffect } from 'react'
import './SimpleDragItem.css'
import DragHandle from '-!svg-react-loader?name=Icon!../../static/images/drag_handle.svg'
import ArrowUp from '-!svg-react-loader?name=Icon!../../static/images/move_up_icon.svg'
import ArrowDown from '-!svg-react-loader?name=Icon!../../static/images/move_down_icon.svg'
import ArrowTop from '-!svg-react-loader?name=Icon!../../static/images/move_top_icon.svg'
import ArrowBottom from '-!svg-react-loader?name=Icon!../../static/images/move_bottom_icon.svg'
import RemoveIcon from '-!svg-react-loader?name=Icon!../../static/images/delete_icon.svg'
import EditIcon from '-!svg-react-loader?name=Icon!../../static/images/edit_icon.svg'
import HideIcon from '-!svg-react-loader?name=Icon!../../static/images/hide_icon.svg'
import MenuIcon from '-!svg-react-loader?name=Icon!../../static/images/menu_icon.svg'

import { ParagraphSmall } from '../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'
import { now } from 'moment'

const SimpleDragItem = ({
  keyIndex,
  itemKey,
  item,
  title,
  description,
  img,
  imgAltText,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  onDragStart,
  sendMsg,
  onEditClick,
  moveOnClick,
  lastItemKey,
  firstVisibleItemKey = 0,
  hideEdit,
  hideRemove,
  hideHide,
  onHideClick,
  onRemoveClick,
  isSelected,
  preventItemClick,
}) => {
  const editClick = (event) => {
    if (sendMsg) {
      sendMsg({
        scrollToGroupId: item.key,
      })
    }
    setShowPopup(false)
    onEditClick && onEditClick(itemKey)
    event.stopPropagation()
  }
  const numberOfItems =
    7 - (hideRemove ? 1 : 0) - (hideEdit ? 1 : 0) - (hideHide ? 1 : 0)
  const popupHeight = numberOfItems * 42 // 42 is the height of each element in the popup menu

  const [showPopup, setShowPopup] = useState(false)
  const containerRef = useRef(null)
  const popupRef = useRef()
  const [topPosition, setTopPosition] = useState(0)
  const [index, setIndex] = useState(keyIndex);
  const timeClicked = useRef(null)
  const [menuPopUpSize, setMenuPopUpSize] = useState({
    top: 0,
    bottom: 0,
  })

  const handleMouseMove = (event) => {
    if (now() - timeClicked.current > 4000) {
      const ref = popupRef.current
      let elm
      for (elm = event.target; elm && elm !== ref; elm = elm.parentElement);

      if (elm !== ref) {
        handlePopupMouseLeave()
      }
    }
  }

  const handleMouseClick = () => {
    setShowPopup(false)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('click', handleMouseClick)
  }

  const handlePopupMouseLeave = () => {
    setShowPopup(false)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('click', handleMouseClick)
  }

  useEffect(() => {
    const topContainer =
    containerRef?.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getBoundingClientRect()
        ?.y

    setTopPosition(topContainer)
  }, [containerRef.current])

  const configIndex = () => {
    if (Number.isInteger(keyIndex)) {
      setIndex(keyIndex);
      return;
    }
    setIndex(itemKey);
  }

  useEffect(() => {
    configIndex();
  }, [keyIndex, itemKey]);

  const renderMenuItem = (
    child,
    label,
    onClick,
    extraTextStyle,
    isDisabled
  ) => {
    return (
      <div className="menu-item-container" onClick={onClick}>
        <div className="menu-item">
          {child}
          <span
            className={`menu-item-text ${
              isDisabled ? 'menu-item-disabled' : ''
            }`}
            style={extraTextStyle}
          >
            {label}
          </span>
        </div>
      </div>
    )
  }

  const RenderDetails = () => (
    <div>
      <div className={img ? 'show-ellipsis' : ''}>
        {title || item.groupName || 'No Name'}
      </div>
      {description && (
        <ParagraphSmall className="services-list my-0">
          {description}
        </ParagraphSmall>
      )}
    </div>
  )

  const getMenuPopUpButtom = () => {
    if (containerRef?.current?.getBoundingClientRect().y - topPosition - (popupHeight/2) + (containerRef?.current?.getBoundingClientRect().height/2) + popupHeight >
            window.innerHeight){
      return 0;
    }
    return null;
  }

  const getCurrentTopPosition = () => {
    return containerRef?.current?.getBoundingClientRect().y - (popupHeight/2) + (containerRef?.current?.getBoundingClientRect().height/2);
  }

  const getMenuPopUpTop = () => {
    if (isNaN(containerRef?.current?.getBoundingClientRect().y)){
      return 0;
    }
    const TOP_CURRENT_POSITION = getCurrentTopPosition();
    if ((TOP_CURRENT_POSITION + popupHeight) > window.innerHeight){
      return window.innerHeight - popupHeight - topPosition - 10;
    }
    return TOP_CURRENT_POSITION - topPosition;
  }

  const menuPosition = () => {
    const menuPopUpButtom = getMenuPopUpButtom();
    const menuPopUpTop = getMenuPopUpTop();

    setMenuPopUpSize({
      top: menuPopUpTop,
      bottom: menuPopUpButtom,
    });
  }

  useEffect(() => {
    if(!showPopup){
      return;
    }
    menuPosition();
  }, [showPopup]);


  return (
    <div
      ref={containerRef}
      key={itemKey}
      id={item.key}
      className={`drag-container${isSelected ? ' selected-card' : ''}`}
      style={{
        display:
          item.hideBlade || item.hideService || item.hideCard ? 'none' : null,
      }}
      draggable
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={(e) => onDrop(e, keyIndex != null ? keyIndex : itemKey)}
      onDragStart={(e) => onDragStart(e, itemKey)}
      onClick={preventItemClick ? () => {} : editClick}
    >
      <DragHandle style={{ minWidth: '24px', cursor: 'grabbing' }} />

      <button
        style={{ textAlign: 'left', flexGrow: 1 }}
        className={`hide-outline `}
      >
        {img ? (
          <div style={{ display: 'flex' }}>
            <img
              src={img}
              style={{ paddingRight: 15, width: '25%', objectFit: 'cover' }}
              alt={imgAltText}
            />
            <RenderDetails />
          </div>
        ) : (
          <RenderDetails />
        )}
      </button>
      <div className="icons-list ">
        <MenuIcon
          onClick={(event) => {
            if (!showPopup) {
              timeClicked.current = now()
              setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                document.addEventListener('mousemove', handleMouseMove)
              }, 100)

              setTimeout(() => {
                document.addEventListener('click', handleMouseClick)
              }, 100)
            }

            setShowPopup(!showPopup)
            event.stopPropagation()
          }}
        />
      </div>
      <div
        ref={popupRef}
        className={`menu-popup ${showPopup ? 'show' : 'hide'}`}
        style={{
          left: isNaN(containerRef?.current?.getBoundingClientRect().right)
            ? 16
            : containerRef?.current?.getBoundingClientRect().right + 16,
          bottom: menuPopUpSize?.bottom,
          top: menuPopUpSize?.top,
        }}
      >
        {!hideEdit &&
          renderMenuItem(
            <EditIcon />,
            'View/Edit',
            onEditClick ? editClick : () => {},
            null,
            onEditClick == null
          )}
        {!hideHide &&
          renderMenuItem(
            <HideIcon />,
            'Hide',
            onHideClick
              ? (event) => {
                  setShowPopup(false)
                  onHideClick()
                  event.stopPropagation()
                }
              : () => {},
            null,
            onHideClick == null
          )}
        {renderMenuItem(
          <ArrowTop />,
          'Move To Top',
          (event) => {
            if (itemKey === 0) return
            setShowPopup(false)
            moveOnClick(itemKey, 0)
            event.stopPropagation()
          },
          null,
          index === firstVisibleItemKey
        )}
        {renderMenuItem(
          <ArrowBottom />,
          'Move To Bottom',
          (event) => {
            if (lastItemKey === itemKey) return
            setShowPopup(false)
            moveOnClick(itemKey, lastItemKey)
            event.stopPropagation()
          },
          null,
          lastItemKey === index
        )}
        {renderMenuItem(
          <ArrowUp />,
          'Move Up One',
          (event) => {
            if (itemKey === 0) return
            setShowPopup(false)
            moveOnClick(itemKey, keyIndex ? keyIndex - 1 : itemKey - 1) //staff and carousel have different logic TODO: make them the same
            event.stopPropagation()
          },
          null,
          index === firstVisibleItemKey
        )}
        {renderMenuItem(
          <ArrowDown />,
          'Move Down One',
          (event) => {
            if (lastItemKey === itemKey) return
            setShowPopup(false)
            moveOnClick(itemKey, keyIndex != null ? keyIndex + 1 : itemKey + 1)
            event.stopPropagation()
          },
          null,
          lastItemKey === index
        )}
        {!hideRemove &&
          renderMenuItem(
            <RemoveIcon />,
            'Delete',
            (event) => {
              onRemoveClick && onRemoveClick()
              event.stopPropagation()
            },
            {
              color: 'red',
            },
            onRemoveClick == null
          )}
      </div>
    </div>
  )
}

export default SimpleDragItem
