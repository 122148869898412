import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import WebsiteCard from '../WebsiteCard/WebsiteCard'
import SortGrid from '../SortGrid/SortGrid'
import Pagination from '../Pagination/Pagination'

const WebsitesList = ({ websites }) => {
  const [name, setName] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  let websiteList = useMemo(() => websites.sort(dynamicSort('name')), [])

  useEffect(() => {
    const pageParam = searchParams.get('page')
    const parsedPageParam = parseInt(pageParam)
    if (parsedPageParam) {
      parsedPageParam > totalPages
        ? setCurrentPage(totalPages)
        : setCurrentPage(parsedPageParam)
    }
  }, [])

  if (name != '') {
    websiteList = websiteList.filter((website) => {
      if (website.name.toLowerCase().includes(name.toLowerCase())) {
        return website
      }
    })
  }

  function dynamicSort(property) {
    return function (a, b) {
      return a[property].localeCompare(b[property])
    }
  }

  function splitPerPages() {
    let pages = []
    let page = []
    for (let i = 0; i < websiteList.length; i++) {
      if (i % 10 == 0 && i != 0) {
        pages.push(page)
        page = []
      }
      page.push(websiteList[i])
    }
    pages.push(page)
    return pages
  }

  const totalPages = Math.ceil(websiteList.length / 10)
  const pages = splitPerPages()

  return (
    <div className="py-5">
      <SortGrid
        searchName={setName}
        setCurrentPage={setCurrentPage}
        gridType="websites"
      />
      <div className="flex flex-col w-11/12 m-auto">
        {pages[currentPage - 1]?.map((website, index) => {
          return <WebsiteCard website={website} key={index} />
        })}

        <Pagination
          totalPages={totalPages}
          totalResults={websiteList.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  )
}

export default WebsitesList
