import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Showdown from 'showdown'
import DeleteIcon from '-!svg-react-loader?name=Icon!../../../static/images/delete_icon.svg'
import { useForm } from 'react-hook-form'
import {
  Input,
  Select,
  SimpleLabel,
} from '../../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'

const SpecialHours = ({
  hoursData,
  setIsFormDirty,
  name,
  deleteHolidayHours,
  resetForm,
  setResetForm,
  errorRef,
  showErrorMessage,
  setShowErrorMessage,
}) => {
  const { register, setValue, unregister, reset } = useForm()
  const [data, setData] = useState([])

  useEffect(() => {
    if (data.length != hoursData.current.length) {
      updateEditHoursTab('main')
      setData(
        hoursData.current.map((i) => ({
          status: i.isClosed
            ? 'closed'
            : i.openIntervals.length > 1
            ? 'split'
            : 'open',
        }))
      )
    }
  }, [hoursData?.current?.length])

  useEffect(() => {
    if (resetForm) {
      reset()
      setData(
        hoursData.current.map((i) => ({
          status: i.isClosed
            ? 'closed'
            : i.openIntervals.length > 1
            ? 'split'
            : 'open',
        }))
      )
      setResetForm(false)
      setIsFormDirty(false)
    }
  }, [resetForm])

  let converter = new Showdown.Converter()

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      win.postMessage(strMsg, '*')
    }
  }

  const InlineSelect = ({ day }) => {
    const options = name.includes('extended')
      ? [
          {
            value: 'closed',
            label: 'Closed',
          },
          {
            value: 'open',
            label: 'Open',
          },
        ]
      : [
          {
            value: 'closed',
            label: 'Closed',
          },
          {
            value: 'open',
            label: 'Open',
          },
          {
            value: 'split',
            label: 'Split',
          },
        ]

    if (
      data[day].status != 'split' &&
      !resetForm &&
      hoursData.current[day]?.openIntervals?.length > 1
    ) {
      hoursData.current[day].openIntervals = [
        hoursData.current[day].openIntervals[0],
      ]
    }

    return (
      <div className="inlineSelect ">
        <Select
          value={data[day].status}
          className="cursor-pointer"
          style={{ fontSize: '12px', width: 'unset' }}
          id={`status_${day}`}
          onChange={(event) => {
            const newData = [...data]
            newData[day].status = event.target.value
            hoursData.current[day].isClosed = event.target.value == 'closed'
            setIsFormDirty(true)
            setData(newData)
          }}
        >
          {options.map((option) => {
            return (
              <option
                key={Math.random().toString(36).substr(2, 9)}
                value={option.value}
              >
                {option.label}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  const DayFields = ({ day }) => {
    const openState = data[day].status

    if (
      openState == 'split' &&
      hoursData.current[day].openIntervals.length < 2
    ) {
      addSplitHours(day)
    }
    return hoursData.current[day]?.openIntervals?.map((interval, key) => {
      return (
        <div
          key={`container-${key}`}
          className="flex items-center justify-between gap-x-0.5"
        >
          <div
            key={`start-${key}`}
            className={`${
              openState == 'closed' ? 'invisible' : 'visible'
            } inlineField hoursTime ${key > 0 ? 'split-row-start' : ''}`}
            // style={{ flexGrow: 1 }}
          >
            <Input
              type="time"
              className="cursor-pointer"
              defaultValue={interval.start}
              {...register(`${day}.openIntervals.${key}.start`)}
              onChange={(event) => {
                hoursData.current[day].openIntervals[key].start =
                  event.currentTarget.value
              }}
              onBlur={() => {
                setIsFormDirty(true)
                reset()
              }}
              style={{ fontSize: '0.7rem' }}
            />
          </div>
          <SimpleLabel
            className={` ${openState == 'closed' ? 'invisible' : 'visible'} `}
          >
            to
          </SimpleLabel>
          <div
            key={`end-${key}`}
            className={`${
              openState == 'closed' ? 'invisible' : 'visible'
            } inlineField hoursTime `}
            // style={{ flexGrow: 1 }}
          >
            <Input
              type="time"
              className="cursor-pointer"
              defaultValue={interval.end}
              {...register(`${day}.openIntervals.${key}.end`)}
              onChange={(event) => {
                hoursData.current[day].openIntervals[key].end =
                  event.currentTarget.value
              }}
              onBlur={() => {
                setIsFormDirty(true)
                reset()
              }}
              style={{ fontSize: '0.7rem' }}
            />
          </div>
        </div>
      )
    })
  }

  const addSplitHours = (day) => {
    let timeIntervalArray = [
      ...hoursData.current[day].openIntervals,
      { start: '00:00', end: '00:00' },
    ]
    hoursData.current[day].openIntervals = timeIntervalArray
    setIsFormDirty(true)

    // setUpdatedHours(newData)
  }

  const ApplyButton = () => {
    return (
      <div
        className="apply-button-container self-end"
        style={{ color: '#007f85' }}
      >
        <button
          className={`apply-button`}
          onClick={() => {
            hoursData.current = [
              ...hoursData.current,
              {
                isClosed: false,
                date: '', //moment().format('YYYY-MM-DD'),
                openIntervals: [
                  {
                    start: '00:00',
                    end: '23:59',
                  },
                ],
              },
            ]
            setData([...data, { status: 'open' }])
            setIsFormDirty(true)
          }}
          // disabled={isSplitHrsAvail}
        >
          Add New
        </button>
      </div>
    )
  }

  const updateEditHoursTab = (tab) => {
    sendMsg({ editingHours: tab })
  }

  const moveFormData = () => {
    hoursData.current.forEach((d, idx) => {
      d.openIntervals.forEach((i, index) => {
        setValue(`${idx}.openIntervals.${index}.start`, i.start)
        setValue(`${idx}.openIntervals.${index}.end`, i.end)
      })
    })
  }

  const removeItem = (index) => {
    const deletedItem = hoursData.current[index]
    deleteHolidayHours(deletedItem, true) // true is for update hours after deleting the item
    const lastIndex = data.length - 1
    const newData = [...data]
    newData.splice(index, 1)

    const newHours = [...hoursData.current]
    newHours.splice(index, 1)

    hoursData.current = newHours
    setData(newData)

    moveFormData()
    unregister(lastIndex.toString())
  }

  return (
    <form className="h-full">
      <div className="flex items-center gap-y-4 flex-col p-4 overflow-auto h-full">
        <div>
          <h2 className="sidebar-header">Special/Holiday Hours</h2>
          <p className="sidebar-para mt-0">
            {`Add special hours for specific dates, one day at a time.  Only future dates can be set. These changes cannot be previewed in Easy Editor, but will override the Main Hours on the specified date as well as appearing on Google My Business, Facebook and all other platforms.`}
          </p>
        </div>
        {data.map((_, idx) => {
          if (hoursData.current[idx]) {
            return (
              <div
                className="flex justify-between items-center gap-x-1 w-full"
                key={idx}
              >
                <Input
                  className="cursor-pointer"
                  type="date"
                  min={moment().format('YYYY-MM-DD')}
                  value={hoursData.current[idx].date}
                  onChange={(event) => {
                    hoursData.current[idx].date == '' &&
                      showErrorMessage &&
                      setShowErrorMessage(false)
                    hoursData.current[idx].date = event.currentTarget.value
                    const newData = [...data]
                    newData[idx].date = event.currentTarget.value
                    setData(newData)
                    setIsFormDirty(true)
                  }}
                  style={{ fontSize: '0.7rem', flexGrow: 1 }}
                />
                <InlineSelect label={idx} day={idx} />
                <div className="flex gap-y-2 flex-col">
                  <DayFields day={idx} />
                </div>
                <div style={{ minWidth: '18px' }} className="cursor-pointer">
                  <DeleteIcon
                    onClick={() => {
                      removeItem(idx)
                      setIsFormDirty(true)
                    }}
                  />
                </div>
              </div>
            )
          }
        })}
        <ApplyButton />
        <div style={{ flexGrow: 1, flexShrink: 1 }} />
        {showErrorMessage && (
          <div
            className="text-center font-semibold mt-2"
            style={{ color: '#DC2626', fontSize: '17px' }}
            ref={errorRef}
          >
            Your changes were not saved. You can not set an empty date.
          </div>
        )}
      </div>
    </form>
  )
}

export default SpecialHours
