import React, { useState, useEffect, useContext } from 'react'
import { useForm, usePlugin, useCMS, FieldsBuilder, withTina } from 'tinacms'
import {
  Link,
  useLocation,
  useHistory,
  Prompt,
  useParams,
} from 'react-router-dom'
import './WebsitePageMain.css'
import './WebsitePageHoursEditForm.css'
import './WebsiteEmergencyBannerEditForm.css'
import Modal from '../../components/ContactModal/ContactModal'
import {
  hideFooterButtonStyles,
  showFooterButtonStyles,
  updateHours,
} from './helper'
import {
  AdditionalHours,
  Day,
  Input,
  Label,
  Paragraph,
  Table,
  TextArea,
  Select,
} from './WebsitePageHoursEditForm.styles.js'
import './WebsitePageHoursEditForm.css'
import * as _ from 'lodash'
import ArrowLeft from '-!svg-react-loader?name=Icon!../../static/images/arrow.svg'
import { Context } from '../../../Store'
import { hoursMessages } from '../../constants/messages/en'

import {
  setIsHoursUpdated,
  setUpdatedHours,
  setSelectedSite,
  useGlobalState,
  setIsHoursDeleted,
  setReRenderStaff,
} from '../../hooks/state/state'
import Showdown from 'showdown'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'
import { createPublishLog, getLatestHoursUpdates } from 'src/api/publishLogs'

const WebsitePageHoursEditForm = ({ routingInfo, user }) => {
  const cms = useCMS()
  const [, setIFrameURL] = useState('')
  let location = useLocation()
  let history = useHistory()
  let { pageName } = useParams()
  const [, setError] = useState(null)
  const [firstLoadDone, setFirstLoadDone] = useState(false)
  const [sidebarToggleBtn, setSidebarToggleBtn] = useState(false)
  const [iframeView, setIframeView] = useState('large')
  const [isFormDirty, setIsFormDirty] = useState(false)
  const {setIsAlertActive} = useContext(Context)
  const [isShowing, setIsShowingModal] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)

  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isBannerUpdated] = useGlobalState('isBannerUpdated')
  const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [isHoursDeleted] = useGlobalState('isHoursDeleted')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [iframeLoading, setIframeLoading] = useState(true)
  const [selectedSite] = useGlobalState('selectedSite')
  const [currentYextId] = useGlobalState('currentYextId')
  const [yextResponse] = useGlobalState('yextResponse')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [splitStatus, setSplitStatus] = useState({})

  let converter = new Showdown.Converter()
  let description = isBannerUpdated
    ? descriptionUpdated
    : descriptionInitial.text

  let bannerObj = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    author: {
      description,
    },
  }
  if (!selectedSite) {
    setSelectedSite(yextId[0])
  }

  window.onbeforeunload = function () {
    if (isFormDirty && !isHoursDeleted) return ''
    else return undefined
  }

  useEffect(() => {
    if (location && location.state) {
      setIFrameURL(location.state.siteURL)
    }
  }, [location])

  useEffect(() => {
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'

    setSidebarToggleBtn(
      document.querySelector('[aria-label="toggles cms sidebar"]')
    )
  }, [])

  useEffect(() => {
    if (pageName == 'multi-location-hours') {
      if (
        location &&
        location.multiLocationHours &&
        location.multiLocationHours.siteURL &&
        location.multiLocationHours.locationName
      ) {
        setSelectedSite({
          ...selectedSite,
          siteURL: location.multiLocationHours.siteURL,
          locationName: location.multiLocationHours.locationName,
        })
      } else {
        history.push(
          `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
        )
      }
    }
  }, [])

  let showSupportModal = () => {
    setIsShowingModal(true)
    setEmailSuccess(false)
  }

  const initDayValue = (closed) => {
    return {
      isClosed: closed,
      openIntervals: [
        {
          start: '00:00',
          end: '23:59',
        },
      ],
    }
  }

  const initHours = (closed) => {
    return {
      monday: initDayValue(closed),
      tuesday: initDayValue(closed),
      wednesday: initDayValue(closed),
      thursday: initDayValue(closed),
      friday: initDayValue(closed),
      saturday: initDayValue(closed),
      sunday: initDayValue(closed),
    }
  }

  //DELETE THIS

  // const createUserActionLogsRest = async (data) => {
  //   try {
  //     const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
  //     await createUserActionLogs(query)
  //   } catch (e) {
  //     setError(e)
  //   }
  // }

  const createPublishLogRest = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      await createPublishLog(query)
    } catch (e) {
      setError(e)
    }
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      win.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData })

      sendMsg(initialValues)
      setIframeLoading(false)
    })
  }

  const initial = updatedHours[currentYextId]
    ? updatedHours[currentYextId]
    : initialHours

  let mainHours = {}
  if (typeof initial.hours !== 'undefined') {
    mainHours = updateHours(initial.hours)
  } else {
    mainHours = initHours(false)
  }

  let extHours = {}
  if (
    typeof initial.c_extendedHours !== 'undefined' &&
    Object.keys(initial.c_extendedHours).length > 0
  ) {
    extHours = updateHours(initial.c_extendedHours)
  } else {
    // extHours = initHours
    extHours = null
  }

  let additionalHoursText = ''

  if (typeof initial.additionalHoursText !== 'undefined') {
    additionalHoursText = initial.additionalHoursText
  }

  let c_extendedAdditionalHoursText = ''
  if (typeof initial.c_extendedAdditionalHoursText !== 'undefined')
    c_extendedAdditionalHoursText = initial.c_extendedAdditionalHoursText

  const processHoursToSend = (hrs, currenttab) => {
    const hours =
      hrs && Object.keys(hrs).length > 0
        ? hrs
        : JSON.parse(JSON.stringify(initHours(false)))

    if (typeof hours.monday.isClosed === 'string') {
      if (hours.monday.isClosed == 'true') {
        hours.monday.isClosed = true
      } else if (hours.monday.isClosed == 'split') {
        hours.monday.isClosed = 'split'
      } else {
        hours.monday.isClosed = false
      }
    }

    if (typeof hours.tuesday.isClosed === 'string') {
      if (hours.tuesday.isClosed == 'true') {
        hours.tuesday.isClosed = true
      } else if (hours.tuesday.isClosed == 'split') {
        hours.tuesday.isClosed = 'split'
      } else {
        hours.tuesday.isClosed = false
      }
    }

    if (typeof hours.wednesday.isClosed === 'string') {
      if (hours.wednesday.isClosed == 'true') {
        hours.wednesday.isClosed = true
      } else if (hours.wednesday.isClosed == 'split') {
        hours.wednesday.isClosed = 'split'
      } else {
        hours.wednesday.isClosed = false
      }
    }

    if (typeof hours.thursday.isClosed === 'string') {
      if (hours.thursday.isClosed == 'true') {
        hours.thursday.isClosed = true
      } else if (hours.thursday.isClosed == 'split') {
        hours.thursday.isClosed = 'split'
      } else {
        hours.thursday.isClosed = false
      }
    }

    if (typeof hours.friday.isClosed === 'string') {
      if (hours.friday.isClosed == 'true') {
        hours.friday.isClosed = true
      } else if (hours.friday.isClosed == 'split') {
        hours.friday.isClosed = 'split'
      } else {
        hours.friday.isClosed = false
      }
    }

    if (typeof hours.saturday.isClosed === 'string') {
      if (hours.saturday.isClosed == 'true') {
        hours.saturday.isClosed = true
      } else if (hours.saturday.isClosed == 'split') {
        hours.saturday.isClosed = 'split'
      } else {
        hours.saturday.isClosed = false
      }
    }

    if (typeof hours.sunday.isClosed === 'string') {
      if (hours.sunday.isClosed == 'true') {
        hours.sunday.isClosed = true
      } else if (hours.sunday.isClosed == 'split') {
        hours.sunday.isClosed = 'split'
      } else {
        hours.sunday.isClosed = false
      }
    }
    // Fix for undefined hours in iframe
    if (hours.monday.isClosed && hours.monday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.monday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.monday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.monday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.monday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.tuesday.isClosed && hours.tuesday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.tuesday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.tuesday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.tuesday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.tuesday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.wednesday.isClosed && hours.wednesday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.wednesday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.wednesday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.wednesday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.wednesday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.thursday.isClosed && hours.thursday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.thursday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.thursday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.thursday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.thursday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.friday.isClosed && hours.friday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.friday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.friday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.friday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.friday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.saturday.isClosed && hours.saturday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.saturday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.saturday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.saturday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.saturday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    if (hours.sunday.isClosed && hours.sunday.isClosed != 'split') {
      if (currenttab === 'main') {
        form.finalForm.change(
          'mainHours.hours.sunday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'mainHours.hours.sunday.openIntervals[0].end',
          '23:59'
        )
      } else if (currenttab === 'extended') {
        form.finalForm.change(
          'extendedHours.extendedHours.sunday.openIntervals[0].start',
          '00:00'
        )
        form.finalForm.change(
          'extendedHours.extendedHours.sunday.openIntervals[0].end',
          '23:59'
        )
      }
    }

    return hours
  }

  function LabelTo(props) {
    let isClosed = false
    if (typeof props.input.value === 'string') {
      if (props.input.value === 'true') isClosed = true
    } else {
      isClosed = props.input.value
    }
    return (
      <Label className={` ${isClosed ? 'invisible' : 'visible'} `}>to</Label>
    )
  }

  function DayFields(props) {
    const isClosedField = props?.tinaForm?.getFieldState(props.field.isClosed)
    const isClosedState = isClosedField?.value
    const day = props.field.day

    if (splitStatus[day] && props.input.value.length < 2) {
      addSplitHours(props)
    }
    return props.input.value.map((interval, key) => {
      return (
        <>
          <div
            key={`start-${key}`}
            className={`${
              isClosedState == true || isClosedState == 'true'
                ? 'invisible'
                : 'visible'
            } inlineField hoursTime ${key > 0 ? 'split-row-start' : ''}`}
          >
            <Input
              data-closed={isClosedState}
              type="time"
              value={interval.start}
              onChange={(event) => {
                form.finalForm.change(
                  `mainHours.hours.${day}.openIntervals[${key}].start`,
                  event.target.value
                )
              }}
              style={{ fontSize: '0.8rem' }}
            />
          </div>
          <Label
            className={` ${
              isClosedState == true || isClosedState == 'true'
                ? 'invisible'
                : 'visible'
            } `}
          >
            to
          </Label>
          <div
            key={`end-${key}`}
            className={`${
              isClosedState == true || isClosedState == 'true'
                ? 'invisible'
                : 'visible'
            } inlineField hoursTime `}
          >
            <Input
              data-closed={isClosedState}
              type="time"
              value={interval.end}
              onChange={(event) => {
                form.finalForm.change(
                  `mainHours.hours.${day}.openIntervals[${key}].end`,
                  event.target.value
                )
              }}
              style={{ fontSize: '0.8rem' }}
            />
          </div>
        </>
      )
    })
  }

  function addSplitHours(props) {
    const day = props.field.day
    let timeIntervalArray = [...props.input.value]

    form.finalForm.change(`mainHours.hours.${day}.openIntervals`, [
      ...timeIntervalArray,
      { start: '00:00', end: '23:59' },
    ])
  }

  function InlineField(props) {
    const isClosedField = props?.tinaForm?.getFieldState(props.field.isClosed)
    const isClosedState = isClosedField?.value
    return (
      <div
        className={`${
          isClosedState == true || isClosedState == 'true'
            ? 'invisible'
            : 'visible'
        } inlineField hoursTime`}
      >
        <Input
          data-closed={isClosedState}
          type="time"
          {...props.input}
          style={{ fontSize: '0.8rem' }}
        />
      </div>
    )
  }

  let enableAll = (start, end, isClosed, currentTab) => {
    const mondayisClosed = isClosed

    if (currentTab === 'extended') {
      //Tuesday
      form.finalForm.change(
        'extendedHours.extendedHours.tuesday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.tuesday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.tuesday.openIntervals[0].end',
        end
      )

      //Wednesday
      form.finalForm.change(
        'extendedHours.extendedHours.wednesday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.wednesday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.wednesday.openIntervals[0].end',
        end
      )

      //Thursday
      form.finalForm.change(
        'extendedHours.extendedHours.thursday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.thursday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.thursday.openIntervals[0].end',
        end
      )

      //Friday
      form.finalForm.change(
        'extendedHours.extendedHours.friday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.friday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.friday.openIntervals[0].end',
        end
      )

      //Saturday
      form.finalForm.change(
        'extendedHours.extendedHours.saturday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.saturday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.saturday.openIntervals[0].end',
        end
      )

      //Sunday
      form.finalForm.change(
        'extendedHours.extendedHours.sunday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'extendedHours.extendedHours.sunday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'extendedHours.extendedHours.sunday.openIntervals[0].end',
        end
      )
    }

    //apply all to main hours
    if (currentTab === 'main') {
      //Tuesday
      form.finalForm.change('mainHours.hours.tuesday.isClosed', mondayisClosed)
      form.finalForm.change(
        'mainHours.hours.tuesday.openIntervals[0].start',
        start
      )
      form.finalForm.change('mainHours.hours.tuesday.openIntervals[0].end', end)

      //Wednesday
      form.finalForm.change(
        'mainHours.hours.wednesday.isClosed',
        mondayisClosed
      )
      form.finalForm.change(
        'mainHours.hours.wednesday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'mainHours.hours.wednesday.openIntervals[0].end',
        end
      )

      //Thursday
      form.finalForm.change('mainHours.hours.thursday.isClosed', mondayisClosed)
      form.finalForm.change(
        'mainHours.hours.thursday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'mainHours.hours.thursday.openIntervals[0].end',
        end
      )

      //Friday
      form.finalForm.change('mainHours.hours.friday.isClosed', mondayisClosed)
      form.finalForm.change(
        'mainHours.hours.friday.openIntervals[0].start',
        start
      )
      form.finalForm.change('mainHours.hours.friday.openIntervals[0].end', end)

      //Saturday
      form.finalForm.change('mainHours.hours.saturday.isClosed', mondayisClosed)
      form.finalForm.change(
        'mainHours.hours.saturday.openIntervals[0].start',
        start
      )
      form.finalForm.change(
        'mainHours.hours.saturday.openIntervals[0].end',
        end
      )

      //Sunday
      form.finalForm.change('mainHours.hours.sunday.isClosed', mondayisClosed)
      form.finalForm.change(
        'mainHours.hours.sunday.openIntervals[0].start',
        start
      )
      form.finalForm.change('mainHours.hours.sunday.openIntervals[0].end', end)
    }
  }

  function ApplyButton(props) {
    let openIntervals = props?.tinaForm?.getFieldState(props.field.hours)
    let currentTab = props.field.tab

    openIntervals = openIntervals.value.hours
      ? openIntervals.value.hours
      : openIntervals.value.extendedHours
    let isSplitHrsAvail = false

    Object.entries(openIntervals).forEach((intervals) => {
      if (intervals[1].openIntervals.length > 1) isSplitHrsAvail = true
    })

    let start = openIntervals.monday.openIntervals[0].start
    let end = openIntervals.monday.openIntervals[0].end
    let isClosed = openIntervals.monday.isClosed

    return (
      <div className="apply-button-container">
        <button
          className={`${isSplitHrsAvail ? 'apply-disabled' : 'apply-button'}`}
          onClick={() => enableAll(start, end, isClosed, currentTab)}
          disabled={isSplitHrsAvail}
        >
          {props.field.label}
        </button>
      </div>
    )
  }
  function InlineTextArea(props) {
    return (
      <Table>
        <tbody>
          <tr>
            <td colSpan="3">
              <AdditionalHours>
                {props.input.name.includes('mainHours')
                  ? 'Additional Hours Text'
                  : 'Extended Hours Text'}
              </AdditionalHours>
              <br />
              <TextArea
                style={{ padding: '10px' }}
                {...props.input}
                maxLength="255"
              />
              <p
                style={{ color: 'unset', textAlign: 'right' }}
              >{`${props.input.value.length}/255`}</p>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  function InlineSelect(props) {
    let openIntervals = props?.tinaForm?.getFieldState(
      props.field.openIntervals
    )
    const day = props.field.day

    if (
      openIntervals &&
      openIntervals.value.length > 1 &&
      props.input.value != 'false' &&
      props.input.value != true &&
      props.input.value != 'true' &&
      props.input.value != 'split'
    ) {
      form.finalForm.change(`mainHours.hours.${day}.isClosed`, 'split')
      setIsFormDirty(false)
    }

    if (
      openIntervals &&
      openIntervals.value.length > 1 &&
      (props.input.value == 'false' || props.input.value == true)
    ) {
      let obj = splitStatus
      obj[day] = false
      setSplitStatus(obj)
      form.finalForm.change(`mainHours.hours.${day}.openIntervals`, [
        openIntervals.value[0],
      ])
    }

    if (
      props.input.value == 'split' &&
      openIntervals &&
      openIntervals.value.length < 2
    ) {
      let obj = splitStatus
      obj[day] = true
      setSplitStatus(obj)
    }

    return (
      <div className="inlineSelect hoursTime">
        <Select {...props.input}>
          {props.field.options.map((option) => {
            return (
              <option
                key={Math.random().toString(36).substr(2, 9)}
                value={option.value}
              >
                {option.label}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  function hoursStyles() {
    return (
      <style jsx>{`
        div[class^='FieldsGroup'] {
          display: grid;
          grid-template-columns: 13% 25% 27.5% 7% 27.5%;
          grid-template-rows: 50px 30px 30px auto auto auto auto auto auto auto;
          align-items: center;
          row-gap: 0.75rem;
        }
      `}</style>
    )
  }

  function DayLabel(props) {
    return <Day key={props.field.label}>{props.field.label}</Day>
  }

  function HeadingText(props) {
    return (
      <div className="fp-2 mt-5 mb-5 section-header">
        <h2 className="text-teal-600 text-3xl">{props.field.label}</h2>
        {props.field.text ? <Paragraph>{props.field.text}</Paragraph> : null}
      </div>
    )
  }

  function IntroText(props) {
    return (
      <div className="fp-2 mt-1 mb-4">
        <h3 className="text-dark-600 font-semibold">{props.field.label}</h3>
        <Paragraph>{props.field.text}</Paragraph>
      </div>
    )
  }

  function ExtendedHoursText(props) {
    return (
      <div className="fp-2 mt-1 mb-4">
        <h3 className="text-dark-600 font-semibold">{props.field.label}</h3>
        <Paragraph>
          If your location has special hours or other alternatives, you can add
          that information here. To customize the name of these hours, please
          submit a
          <Link
            className="text-teal-600 font-medium"
            onClick={showSupportModal}
          >
            &nbsp; support request
          </Link>
          .
        </Paragraph>
      </div>
    )
  }

  const AddRemoveExtended = (props) => {
    const { input, field, form } = props

    const ConditionalField = (
      <style>{`
        div[class^='FieldsGroup'] {
          height: auto;
        }
      `}</style>
    )
    const nestedFields = field.fields(input.value)

    // field.trigger =
    //   nestedFields.length != 0
    //     ? { component: 'toggle' }
    //     : {
    //         text:
    //           "You don't have extended hours. Please contact customer support if you wish to add extended hours.",
    //         component: IntroText,
    //       }

    const conditionalFields = nestedFields.map((f) => {
      const fieldPath = field.name.split('.').slice(0, -1)
      const name = fieldPath.concat(f.name).join('.')
      return { ...f, name }
    })

    const fields = [
      { name: 'extendedStyle', component: () => ConditionalField },
      { ...field.trigger, name: field.name, label: field.label },
      ...conditionalFields,
    ]

    return (
      <div
        onClick={() => updateEditHoursTab('extended')}
        className="edit-button"
      >
        <FieldsBuilder fields={fields} form={form} />
      </div>
    )
  }

  const MainHours = (props) => {
    const { field, form } = props
    return (
      <div onClick={() => updateEditHoursTab('main')} className="edit-button">
        <FieldsBuilder fields={field.fields()} form={form} />
      </div>
    )
  }

  const updateEditHoursTab = (tab) => {
    sendMsg({ editingHours: tab })
  }

  const initialValues = {
    mainHours: {
      hours: mainHours,
      additionalHoursText,
    },
    extendedHours: {
      extendedHours: extHours,
      c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      typeof initial.c_extendedHours !== 'undefined' &&
      Object.keys(initial.c_extendedHours).length > 0,
    isEdited: false,
  }

  const formConfig = {
    id: 'hours',
    initialValues: initialValues,
    // loadInitialValues: async () => {
    //   return { ...initialValues }
    // },
    fields: [
      {
        name: 'linkToBack',
        linkText: 'Back',
        component: () => (
          <button
            onClick={() => {
              setReRenderStaff(true)
              history.goBack()
            }}
            className="focus:outline-none flex flex-row py-2 items-center"
            style={{
              borderBottom: '1px solid var(--tina-color-grey-2)',
              width: '100%',
            }}
          >
            <ArrowLeft className="w-4 mr-2 transform rotate-180 text-gray-500" />{' '}
            Back
          </button>
        ),
      },
      {
        name: 'hidebuttonstyles',
        component: hideFooterButtonStyles,
      },
      {
        name: 'hoursOperationText',
        label: 'Hours of Operation',
        text:
          "Change your location's hours here. These changes appear on your website and are also published across the web as well.",
        component: HeadingText,
      },
      {
        name: 'mainHoursText',
        label: 'Main Hours',
        text: 'These are your primary hours of operation.',
        component: IntroText,
      },
      {
        name: 'clickableMainHours',
        label: ' ',
        component: MainHours,
        fields: () => {
          return [
            {
              name: 'mainHours',
              label: 'Edit Main Hours',
              component: 'group',
              fields: [
                {
                  name: 'styles',
                  component: hoursStyles,
                },
                {
                  name: 'showbuttonstyles',
                  component: showFooterButtonStyles,
                },
                // START DAY GROUP
                {
                  name: 'sectionHeaderEditMainHours',
                  label: 'Edit Main Hours',
                  component: HeadingText,
                },
                {
                  name: 'mondayLabel',
                  label: 'Mon',
                  component: DayLabel,
                },
                {
                  name: 'hours.monday.isClosed',
                  label: 'Mon',
                  day: 'monday',
                  openIntervals: 'mainHours.hours.monday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },
                // {
                //   name: 'hours.monday.openIntervals[0].start',
                //   type: 'start',
                //   isClosed: 'mainHours.hours.monday.isClosed',
                //   component: InlineField,
                // },
                // {
                //   name: 'hours.monday.isClosed',
                //   component: LabelTo,
                // },
                // {
                //   name: 'hours.monday.openIntervals[0].end',
                //   type: 'end',
                //   isClosed: 'mainHours.hours.monday.isClosed',
                //   component: InlineField,
                // },
                {
                  name: 'hours.monday.openIntervals',
                  day: 'monday',
                  isClosed: 'mainHours.hours.monday.isClosed',
                  component: DayFields,
                },
                // END DAY GROUP
                {
                  name: 'toggleStyle',
                  component: () => (
                    <style jsx>{`
                      div[class^='apply-button-container'] {
                        grid-column-start: 1;
                        grid-column-end: 6;
                        display: flex;
                        justify-content: flex-end;
                      }

                      div[class^='apply-button-container']
                        > button[class^='apply-button'] {
                        text-align: right;
                        margin-bottom: 0;
                        margin-right: 5px;
                        padding: 5px;
                        color: #007f85;
                        background-color: rgb(0, 127, 133, 0.1);
                      }
                      div[class^='apply-button-container']
                        > button[class^='apply-disabled'] {
                        text-align: right;
                        margin-bottom: 0;
                        margin-right: 5px;
                        padding: 5px;
                        cursor: auto;
                        color: #bac1c1;
                        background-color: rgb(181, 191, 191, 0.1);
                      }
                    `}</style>
                  ),
                },
                {
                  name: 'applyToAll',
                  label: 'Apply to all?',
                  tab: 'main',
                  isClosed: 'mainHours.hours.monday.isClosed',
                  hours: 'mainHours',
                  start: 'mainHours.hours.monday.openIntervals[0].start',
                  end: 'mainHours.hours.monday.openIntervals[0].end',
                  component: ApplyButton,
                },
                {
                  name: 'tuesdayLabel',
                  label: 'Tue',
                  component: DayLabel,
                },
                {
                  name: 'hours.tuesday.isClosed',
                  label: 'Tue',
                  day: 'tuesday',
                  openIntervals: 'mainHours.hours.tuesday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },
                {
                  name: 'hours.tuesday.openIntervals',
                  day: 'tuesday',
                  isClosed: 'mainHours.hours.tuesday.isClosed',
                  component: DayFields,
                },
                //END DAY GROUP
                // START DAY GROUP
                {
                  name: 'wednesdayLabel',
                  label: 'Wed',
                  component: DayLabel,
                },
                {
                  name: 'hours.wednesday.isClosed',
                  label: 'Wed',
                  day: 'wednesday',
                  openIntervals: 'mainHours.hours.wednesday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },
                {
                  name: 'hours.wednesday.openIntervals',
                  day: 'wednesday',
                  isClosed: 'mainHours.hours.wednesday.isClosed',
                  component: DayFields,
                },
                // END DAY GROUP
                // START DAY GROUP
                {
                  name: 'thursdayLabel',
                  label: 'Thu',
                  component: DayLabel,
                },
                {
                  name: 'hours.thursday.isClosed',
                  label: 'Thu',
                  day: 'thursday',
                  openIntervals: 'mainHours.hours.thursday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },

                {
                  name: 'hours.thursday.openIntervals',
                  day: 'thursday',
                  isClosed: 'mainHours.hours.thursday.isClosed',
                  component: DayFields,
                },
                // END DAY GROUP
                // START DAY GROUP
                {
                  name: 'fridayLabel',
                  label: 'Fri',
                  component: DayLabel,
                },
                {
                  name: 'hours.friday.isClosed',
                  label: 'Fri',
                  day: 'friday',
                  openIntervals: 'mainHours.hours.friday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },

                {
                  name: 'hours.friday.openIntervals',
                  day: 'friday',
                  isClosed: 'mainHours.hours.friday.isClosed',
                  component: DayFields,
                },
                // END DAY GROUP
                // START DAY GROUP
                {
                  name: 'saturdayLabel',
                  label: 'Sat',
                  component: DayLabel,
                },
                {
                  name: 'hours.saturday.isClosed',
                  label: 'Sat',
                  day: 'saturday',
                  openIntervals: 'mainHours.hours.saturday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },
                {
                  name: 'hours.saturday.openIntervals',
                  day: 'saturday',
                  isClosed: 'mainHours.hours.saturday.isClosed',
                  component: DayFields,
                },
                // {
                //   name: 'addSplitHours',
                //   label: '+ Add Split Hours',
                //   day : 'sat',
                //   component: () => (
                //     <button className="add-split-hours" onClick={() => addSplitHours('sat')}>
                //       + Add split hours
                //     </button>
                //   )
                // },
                // {
                //   name: 'hours.saturday.openIntervals',
                //   type: 'start',
                //   isClosed: 'mainHours.hours.saturday.isClosed',
                //   component: InlineField,
                // },
                // {
                //   name: 'hours.saturday.isClosed',
                //   component: LabelTo,
                // },
                // {
                //   name: 'hours.saturday.openIntervals',
                //   type: 'end',
                //   isClosed: 'mainHours.hours.saturday.isClosed',
                //   component: InlineField,
                // },
                // END DAY GROUP
                // START DAY GROUP
                {
                  name: 'sundayLabel',
                  label: 'Sun',
                  component: DayLabel,
                },
                {
                  name: 'hours.sunday.isClosed',
                  label: 'Sun',
                  day: 'sunday',
                  openIntervals: 'mainHours.hours.sunday.openIntervals',
                  component: InlineSelect,
                  options: [
                    {
                      value: true,
                      label: 'Closed',
                    },
                    {
                      value: false,
                      label: 'Open',
                    },
                    {
                      value: 'split',
                      label: 'Split',
                    },
                  ],
                },
                {
                  name: 'hours.sunday.openIntervals',
                  day: 'sunday',
                  isClosed: 'mainHours.hours.sunday.isClosed',
                  component: DayFields,
                },
                // END DAY GROUP
                {
                  name: 'additionalHoursText',
                  component: InlineTextArea,
                },
              ],
            },
          ]
        },
      },
      {
        name: 'Extended Hours Text',
        label: 'Extended Hours',
        component: ExtendedHoursText,
      },
      {
        name: 'haveExtendedHours',
        label: 'Have Extended Hours?',
        component: AddRemoveExtended,
        trigger: { component: 'toggle' },
        fields: (value) => {
          return value
            ? [
                {
                  name: 'extendedHours',
                  label: 'Edit Extended Hours',
                  component: 'group',
                  fields: [
                    {
                      name: 'styles',
                      component: hoursStyles,
                    },
                    {
                      name: 'showbuttonstyles',
                      component: showFooterButtonStyles,
                    },
                    // START DAY GROUP
                    {
                      name: 'sectionHeaderEditExtendedHours',
                      label: 'Edit Extended Hours',
                      component: HeadingText,
                    },
                    {
                      name: 'mondayLabel',
                      label: 'Mon',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.monday.isClosed',
                      label: 'Mon',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },
                    {
                      name: 'extendedHours.monday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.monday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.monday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.monday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.monday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP
                    {
                      name: 'buttonStyleExtended',
                      component: () => (
                        <style jsx>{`
                          div[class^='apply-button-container'] {
                            grid-column-start: 1;
                            grid-column-end: 6;
                            display: flex;
                            justify-content: flex-end;
                          }

                          div[class^='apply-button-container']
                            > button[class^='apply-button'] {
                            text-align: right;
                            margin-bottom: 0;
                            margin-right: 5px;
                            padding: 5px;
                            color: #007f85;
                            background-color: rgb(0, 127, 133, 0.1);
                          }
                        `}</style>
                      ),
                    },
                    {
                      name: 'applyToAllExtended',
                      label: 'Apply to all?',
                      tab: 'extended',
                      isClosed: 'extendedHours.extendedHours.monday.isClosed',
                      hours: 'extendedHours',
                      start:
                        'extendedHours.extendedHours.monday.openIntervals[0].start',
                      end:
                        'extendedHours.extendedHours.monday.openIntervals[0].end',
                      component: ApplyButton,
                    },
                    {
                      name: 'tuesdayLabel',
                      label: 'Tue',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.tuesday.isClosed',
                      label: 'Tue',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },
                    {
                      name: 'extendedHours.tuesday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.tuesday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.tuesday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.tuesday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.tuesday.isClosed',
                      component: InlineField,
                    },
                    // START DAY GROUP
                    {
                      name: 'wednesdayLabel',
                      label: 'Wed',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.wednesday.isClosed',
                      label: 'Wed',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },
                    {
                      name: 'extendedHours.wednesday.openIntervals[0].start',
                      isClosed:
                        'extendedHours.extendedHours.wednesday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.wednesday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.wednesday.openIntervals[0].end',
                      isClosed:
                        'extendedHours.extendedHours.wednesday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP
                    // START DAY GROUP
                    {
                      name: 'thursdayLabel',
                      label: 'Thu',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.thursday.isClosed',
                      label: 'Thu',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },
                    {
                      name: 'extendedHours.thursday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.thursday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.thursday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.thursday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.thursday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP
                    // START DAY GROUP
                    {
                      name: 'fridayLabel',
                      label: 'Fri',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.friday.isClosed',
                      label: 'Fri',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },
                    {
                      name: 'extendedHours.friday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.friday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.friday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.friday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.friday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP
                    // START DAY GROUP
                    {
                      name: 'saturdayLabel',
                      label: 'Sat',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.saturday.isClosed',
                      label: 'Sat',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },

                    {
                      name: 'extendedHours.saturday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.saturday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.saturday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.saturday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.saturday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP
                    // START DAY GROUP
                    {
                      name: 'sundayLabel',
                      label: 'Sun',
                      component: DayLabel,
                    },
                    {
                      name: 'extendedHours.sunday.isClosed',
                      label: 'Sun',
                      component: InlineSelect,
                      options: [
                        {
                          value: true,
                          label: 'Closed',
                        },
                        {
                          value: false,
                          label: 'Open',
                        },
                      ],
                    },

                    {
                      name: 'extendedHours.sunday.openIntervals[0].start',
                      isClosed: 'extendedHours.extendedHours.sunday.isClosed',
                      component: InlineField,
                    },
                    {
                      name: 'extendedHours.sunday.isClosed',
                      component: LabelTo,
                    },
                    {
                      name: 'extendedHours.sunday.openIntervals[0].end',
                      isClosed: 'extendedHours.extendedHours.sunday.isClosed',
                      component: InlineField,
                    },
                    // END DAY GROUP

                    {
                      name: 'c_extendedAdditionalHoursText',
                      component: InlineTextArea,
                    },
                  ],
                },
              ]
            : []
        },
      },
    ],
    onSubmit: async (formData) => {
      //return if there are any invalid time intervals
      const totalHours = formData.haveExtendedHours
        ? [
            ...Object.entries(formData.mainHours.hours),
            ...Object.entries(formData.extendedHours.extendedHours),
          ]
        : [...Object.entries(formData.mainHours.hours)]

      let invalidSplitHours = false
      Object.entries(totalHours).forEach((day) => {
        let intervals = day[1]
        let startTime = new Date(`
        '01/01/2020' ${intervals[1].openIntervals[0].start}
      `)
        let endTime = new Date(
          `01/01/2020 ${intervals[1].openIntervals[0].end}`
        )
        startTime = startTime.getTime()
        endTime = endTime.getTime()

        if (intervals[1].openIntervals.length > 1) {
          let firstStartIntrvl = startTime
          let firstEndIntrvl = endTime

          let secondStartIntrvl = new Date(`
        '01/01/2020' ${intervals[1].openIntervals[1].start}`)
          let secondEndIntrvl = new Date(`
          '01/01/2020' ${intervals[1].openIntervals[1].end}`)
          secondStartIntrvl = secondStartIntrvl.getTime()
          secondEndIntrvl = secondEndIntrvl.getTime()

          if (
            firstStartIntrvl >= firstEndIntrvl ||
            firstEndIntrvl > secondStartIntrvl ||
            secondStartIntrvl >= secondEndIntrvl
          ) {
            invalidSplitHours = true
          }
        }
      })

      if (invalidSplitHours) {
        alert(
          'Invalid hours have been entered. Please verify your hours or contact Marketing Support to make changes.'
        )
        return
      }
      let latestSave = null
      const result = await getLatestHoursUpdates(
        user.id,
        selectedSite.siteId,
        currentYextId
      )
      if (
        result &&
        result.data &&
        result.data.latestHoursUpdates &&
        result.data.latestHoursUpdates.length > 0
      ) {
        latestSave = result.data.latestHoursUpdates
      }
      const prepHours = (hours, extended = false) => {
        Object.entries(hours).forEach(async (day) => {
          const getOldIsClosed = () => {
            if (latestSave) {
              let savedIsClosed = latestSave.filter(
                (item) =>
                  item.type == (extended ? 'Ext Hrs' : 'Hours') &&
                  item.hoo_fieldName == 'status' &&
                  item.hoo_day == day[0]
              )

              if (savedIsClosed.length > 0) {
                return savedIsClosed[0].newValue == 'Closed' ? true : false
              }
            }

            return extended
              ? yextResponse.c_extendedHours
                ? yextResponse.c_extendedHours[`${day[0]}`].isClosed
                  ? true
                  : false
                : false
              : yextResponse.hours[`${day[0]}`].isClosed
              ? true
              : false
          }

          const oldIsClosed = getOldIsClosed()

          let newIsClosed = day[1].isClosed
          if (day[1].isClosed == 'split') {
            newIsClosed = false
          }

          if (oldIsClosed !== newIsClosed) {
            if (!updateType.includes(extended ? 'Ext Hours' : 'Hours')) {
              updateType += extended ? 'Ext Hours, ' : 'Hours, '
            }

            savedUpdates.push({
              type: extended ? 'Ext Hrs' : 'Hours',
              hoo_fieldName: 'status',
              hoo_day: day[0],
              oldValue: oldIsClosed ? 'Closed' : 'Open',
              newValue: newIsClosed ? 'Closed' : 'Open',
            })
          }

          const getOldIntervals = () => {
            if (latestSave) {
              let savedIntervals = latestSave.filter(
                (item) =>
                  item.type == (extended ? 'Ext Hrs' : 'Hours') &&
                  item.hoo_fieldName == 'interval' &&
                  item.hoo_day == day[0]
              )

              if (
                savedIntervals.length > 0 &&
                savedIntervals[0].openIntervals
              ) {
                return savedIntervals[0].openIntervals.map((interval) => {
                  return {
                    start: interval.newStart,
                    end: interval.newEnd,
                  }
                })
              }
            }
            return extended
              ? yextResponse.c_extendedHours
                ? yextResponse.c_extendedHours[`${day[0]}`].openIntervals
                : undefined
              : yextResponse.hours[`${day[0]}`].openIntervals
          }

          const oldIntervals = getOldIntervals()

          // if oldintervals is undefined, it means old isClosed is true and most likely coming from yext
          // and if new isClosed is also true, it means we should not save changes for its intervals
          // so in order to save changes, if oldintervals is undefined, new isClosed should be false
          // OR oldintervals has value

          if (
            ((!oldIntervals && !newIsClosed) || oldIntervals) &&
            JSON.stringify(oldIntervals) !==
              JSON.stringify(day[1].openIntervals)
          ) {
            if (!updateType.includes(extended ? 'Ext Hours' : 'Hours')) {
              updateType += extended ? 'Ext Hours, ' : 'Hours, '
            }

            const openIntervals = []
            day[1].openIntervals.forEach((interval, index) => {
              openIntervals.push({
                oldStart:
                  oldIntervals && oldIntervals[index]
                    ? oldIntervals[index].start
                    : null,
                oldEnd:
                  oldIntervals && oldIntervals[index]
                    ? oldIntervals[index].end
                    : null,
                newStart: interval.start,
                newEnd: interval.end,
              })
            })

            savedUpdates.push({
              type: extended ? 'Ext Hrs' : 'Hours',
              hoo_fieldName: 'interval',
              hoo_day: day[0],
              openIntervals: openIntervals,
            })
          }
        })
      }

      const prepAddlText = async (text, extended = false) => {
        const getOldText = () => {
          if (latestSave) {
            const savedText = latestSave.filter(
              (item) =>
                item.type == (extended ? 'Ext Hrs' : 'Hours') &&
                item.hoo_fieldName == 'text'
            )

            if (savedText.length > 0) {
              return savedText[0].newValue
            }
          }
          return extended
            ? yextResponse.c_extendedAdditionalHoursText
            : yextResponse.additionalHoursText
        }

        const oldText = getOldText()

        if (oldText !== text) {
          if (!updateType.includes(extended ? 'Ext Text' : 'Hours Text')) {
            updateType += extended ? 'Ext Text, ' : 'Hours Text, '
          }

          savedUpdates.push({
            type: extended ? 'Ext Hrs' : 'Hours',
            hoo_fieldName: 'text',
            oldValue: oldText,
            newValue: text,
          })
        }
      }

      const savedUpdates = []
      let updateType = ''

      // MAIN HOURS
      prepHours(formData.mainHours.hours)

      // MAIN HOURS TEXT
      prepAddlText(formData.mainHours.additionalHoursText)

      // TOGGLE EXTENDED HOURS
      const getOldToggleExtHours = () => {
        if (latestSave) {
          const savedToggle = latestSave.filter(
            (item) => item.type == 'Ext Hrs' && item.hoo_fieldName == 'toggle'
          )

          if (savedToggle.length > 0) {
            return savedToggle[0].newValue == 'true' ? true : false
          }
        }
        return yextResponse.c_extendedHours ? true : false
      }

      const oldToggleExtHours = getOldToggleExtHours()

      if (oldToggleExtHours !== formData.haveExtendedHours) {
        if (!updateType.includes('Toggle Ext Hrs')) {
          updateType += 'Toggle Ext Hrs, '
        }

        savedUpdates.push({
          type: 'Ext Hrs',
          hoo_fieldName: 'toggle',
          oldValue: oldToggleExtHours.toString(),
          newValue: formData.haveExtendedHours.toString(),
        })
      }

      if (formData.haveExtendedHours) {
        // EXTENDED HOURS
        prepHours(formData.extendedHours.extendedHours, true)

        // EXTENDED HOURS ADDTL TEXT
        prepAddlText(formData.extendedHours.c_extendedAdditionalHoursText, true)
      }

      try {
        if (updateType !== '') {
          const input = {
            userId: routingInfo.user?.id,
            userEmail: routingInfo.account.userName,
            websiteId: selectedSite.siteId.toString(),
            yextId: currentYextId.toString(),
            websiteName: selectedSite.name,
            updateType: updateType.substring(0, updateType.length - 2),
            savedUpdates: savedUpdates,
            businessLine: selectedSiteInfo.websiteType,
            divisionId: yextResponse.meta?.folderId,
          }

          await createPublishLogRest(input)
          //return

          ReactGA.event({
            category: 'Content - Hours',
            action: `User ${routingInfo.user?.id} saved change to Hours`,
            // label: `Value: ${modifiedHoursAndAdditionalText.trim()}`,
          })

          //setIsFormDirty(false)
          setIsHoursUpdated(true)

          const newHours = {
            hours: formData.hours,
            additionalHoursText: formData.mainHours.additionalHoursText,
            c_extendedHours: formData.haveExtendedHours
              ? formData.extendedHours.extendedHours
              : {},
            c_extendedAdditionalHoursText:
              formData.extendedHours.c_extendedAdditionalHoursText,
          }

          let currentUpdatedhours = JSON.parse(JSON.stringify(updatedHours))

          currentUpdatedhours[currentYextId]
            ? (currentUpdatedhours[currentYextId] = newHours)
            : (currentUpdatedhours = Object.assign(updatedHours, {
                [currentYextId]: newHours,
              }))

          setUpdatedHours(currentUpdatedhours)
        }
        // TO DO - have one for each type of update
        // ReactGA.event({
        //   category: 'Content',
        //   action: `User ${routingInfo.userid} saved change to Hours`,
        //   label: `Value: ${modifiedHoursAndAdditionalText.trim()}`,
        // })

        // const newHours = {
        //   hours: formData.hours,
        //   additionalHoursText: formData.mainHours.additionalHoursText,
        //   c_extendedHours: formData.haveExtendedHours
        //     ? formData.extendedHours.extendedHours
        //     : {},
        //   c_extendedAdditionalHoursText:
        //     formData.extendedHours.c_extendedAdditionalHoursText,
        // }

        setIsHoursDeleted(false)
        setIsAlertActive(true)
        setIsFormDirty(false)
        //setIsHoursUpdated(true)
      } catch (error) {
        console.error(error)
      }
    },
    onChange: (formData) => {
      setIsFormDirty(true)
      formData.values.hours = processHoursToSend(
        formData.values.mainHours.hours,
        'main'
      )

      if (
        formData.values.extendedHours.extendedHours ||
        formData.values.haveExtendedHours
      ) {
        formData.values.extendedHours.extendedHours = processHoursToSend(
          formData.values.extendedHours?.extendedHours,
          'extended'
        )
      }
      sendMsg(formData.values)
    },
  }

  const [, form] = useForm(formConfig)

  usePlugin(form)

  if (!cms.sidebar.isOpen && !firstLoadDone && sidebarToggleBtn) {
    sidebarToggleBtn.click()
    setFirstLoadDone(true)
  }

  const getPreviewURL = (url) => {
    if (!url.includes('#')) {
      return `${url}#hours-panel-map`
    }
    return url
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && !isHoursDeleted}
        message={() => hoursMessages.CONFIRM_LOSE_UNSAVED_HOURS}
      />
      <DesktopMobileToggle
        setIframeView={setIframeView}
        iframeView={iframeView}
        sendOnLoadMsg={sendOnLoadMsg}
      />
      <PreviewFrame
        siteName={`${selectedSite.locationName || selectedSite.name}`}
        selectedSite={getPreviewURL(
          location?.multiLocationHours?.siteURL || selectedSite.siteURL
        )}
        // selectedSite="http://localhost:9000"
        sendOnLoadMsg={sendOnLoadMsg}
        iframeView={iframeView}
        cms={cms}
      />
      {isShowing ? (
        <Modal
          setIsShowingModal={setIsShowingModal}
          emailSuccess={emailSuccess}
          setEmailSuccess={setEmailSuccess}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default withTina(WebsitePageHoursEditForm, {
  enabled: true,
  sidebar: true,
})
