import React, { useState, useEffect, Fragment, forwardRef } from 'react'
import './UserActionLogsCellRest.css'
import {
  getAllContentfulLogsByDate,
  getUserActionLogsByDate,
} from 'src/api/userActionLogs'
import UserActionLogs from 'src/components/UserActionLogs'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import {
  prepareContentfulLogHelper,
  prepareLogHelper,
} from '../UserActionLog/helper'
import PawLoader from '../PawLoader'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import * as _ from 'lodash'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import moment from 'moment'
import { CsvBuilder } from 'filefy'

const UserActionLogsCellRest = () => {
  const [logs, setLogs] = useState([])
  const [contentfulLogs, setContentfulLogs] = useState()
  const [filterReordered, setFilterReordered] = useState(true)

  const [isLoading, setLoadingStatus] = useState(true)
  const [source, setSource] = useState('yext')
  const [startDate, setStartDate] = useState(
    moment().add(-30, 'days').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [fetchedDateRange, setFetchedDateRange] = useState({
    start: startDate,
    end: endDate,
  })

  const today = new Date().toDateString().replace(/ /g, '_')

  /**
   * Get a logs from REST API.
   */
  const getActionLogsRest = async () => {
    setContentfulLogs('')
    setLoadingStatus(true)
    const response = await getUserActionLogsByDate({
      start: moment(startDate).utc().format(),
      end: moment(endDate).endOf('day').utc().format(),
    })

    const data = response.data.userActionLogsByDate
    if (data && data.length > 0) {
      const logsForTableWithDataTemp = []

      // loop through all logs
      data.forEach((log) => {
        // push each log into a temp array
        let parsedLog = prepareLogHelper(log)

        // add website name to log object
        parsedLog = {
          ...parsedLog,
          ...{
            websiteName: log.websiteName,
            websiteId: log.websiteId,
            userId: log.userId,
            divisionId: log.divisionId,
            businessLine: log.businessLine,
            yextId: log.yextId,
            savedUpdates: log.savedUpdates,
          },
        }

        let complexLog = {}

        // merge parsed log data and yextid into common object
        complexLog = {
          ...complexLog,
          ...parsedLog,
        }

        logsForTableWithDataTemp.unshift(complexLog)
        // }
      })
      setLogs(logsForTableWithDataTemp)
    }

    setLoadingStatus(false)
  }

  useEffect(() => {
    fetchData()
  }, [filterReordered])

  const fetchData = () => {
    switch (source) {
      case 'Contentful':
        getContentfulLogsRest()
        break
      case 'yext':
        getActionLogsRest()
        break
      default:
        break
    }
  }
  const handleFilterReordered = () => {
    setFilterReordered(!filterReordered)
  }
  const getContentfulLogsRest = async () => {
    setLoadingStatus(true)
    setLogs('')
    const response = await getAllContentfulLogsByDate({
      start: moment(startDate).utc().format(),
      end: moment(endDate).endOf('day').utc().format(),
    })
    const data = response.data.getAllContentfulLogsByDate
      .filter((log) => {
        return filterReordered
          ? !log.contentfulTitle?.includes('Reordered')
          : true
      })
      .map((log) => {
        return { ...log, ...prepareContentfulLogHelper(log) }
      })
    setContentfulLogs(data)
    setLoadingStatus(false)
  }

  const handleChangeSource = () => {
    switch (source) {
      case 'Contentful':
        setSource('yext')
        getActionLogsRest()
        break
      case 'yext':
        setSource('Contentful')
        getContentfulLogsRest()
        break
      default:
        break
    }
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }
  return !isLoading ? (
    <UpdatedMainLayout>
      <div className="rw-user-action-logs__container py-2 pt-5">
        <div className="flex justify-between items-center">
          <button
            className="w-auto h-40 px-16 text-white bg-teal-600"
            onClick={() => handleChangeSource()}
          >
            Switch Source
          </button>
          <label>
            <input
              type="checkbox"
              checked={filterReordered}
              onChange={handleFilterReordered}
              name="filterReordered"
              className="mr-1"
            />
            Exclude Reordered
          </label>

          {/* <div className="rw-user-action-logs__csv-container">
            <GetAppIcon style={{ color: '#007F85' }} />
            <Button className="rw-user-action-logs__csv-container-button">
              <CSVLink
                asyncOnClick={true}
                filename={`Easy_Editor_${
                  source == 'yext' ? 'YEXT' : 'CONTENTFUL'
                }_Log_${today}.csv`}
                data={getCSVData()}
                // data="TEST"
              >
                Download CSV
              </CSVLink>
            </Button>
          </div> */}
          <div className="flex gap-4 items-center">
            <span>Selected Date Range:</span>
            <input
              className="cursor-pointer h-full text-center border-2 border-teal-600"
              type="date"
              // min={moment().format('YYYY-MM-DD')}
              value={startDate}
              onChange={(event) => {
                setStartDate(event.currentTarget.value)
              }}
            />
            <input
              className="cursor-pointer h-full text-center border-2 border-teal-600"
              type="date"
              // min={moment().format('YYYY-MM-DD')}
              value={endDate}
              onChange={(event) => {
                setEndDate(event.currentTarget.value)
              }}
            />
            <button
              className="w-auto h-40 px-16 text-white bg-teal-600"
              onClick={() => {
                setFetchedDateRange({ start: startDate, end: endDate })
                fetchData()
              }}
              disabled={
                startDate == fetchedDateRange.start &&
                endDate == fetchedDateRange.end
              }
            >
              Get Data
            </button>
          </div>
        </div>
      </div>

      {contentfulLogs && (
        <div className="rw-user-action-logs__container">
          <MaterialTable
            icons={tableIcons}
            style={{ zIndex: 1 }}
            options={{
              search: true,
              filtering: true,
              toolbar: true,
              headerStyle: { backgroundColor: '#c9cbd1' },
              pageSize: 10,
              sorting: true,
              pageSizeOptions: [10, 20, 100, 200],
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportCsv: (columns, data) => {
                let formattedData = []
                // creates the new source data to CSV download
                // let newData =
                data &&
                  data.map((item) => {
                    let newResults = {
                      Date:
                        item.date ??
                        moment
                          .utc(item.createdAt)
                          .local()
                          .format('MM/DD/YYYY h:mm a'),
                      'Item Update': item.update ?? item.updateType,
                      Email: item.email,
                      'User ID': item.userId ?? item.id,
                      Website: item.websiteName,
                      'Site ID': item.websiteId,
                      // 'Log ID': item.logId ?? item.id,
                      'Yext Id': item.yextId,
                      // 'Table Data': item.tableData && item.tableData.id,
                      'Business Line': item.businessLine,
                      'Division Id': item.divisionId,
                      'Old Hours': item.oldHours,
                      'Modified Hours': item.modifiedHours,
                      'Old Extended Additional Text':
                        item.oldExtendedAdditionalText,
                      'Modified Extended Additional Text':
                        item.modifiedExtendedAdditionalText,
                      'Additional Text': item.additionalText,
                      'Modified Additional Text': item.modifiedAdditionalText,
                      'Old Emergency Banner Message': item.oldEmergencyBanner,
                      'Modified Emergency Banner Message':
                        item.modifiedEmergencyBanner,
                      Changes: item.verbalDescription,
                      'Contentful Entity Id': item.contentfulId,
                      'Contentful Entity Title': item.contentfulTitle,
                      isEntryPublished: item.isEntryPublished,
                    }

                    formattedData.push(newResults)

                    return item
                  })

                const builder = new CsvBuilder(
                  `Easy_Editor_CONTENTFUL_Log_${today}.csv`
                )
                  .setColumns(Object.keys(formattedData[0]))
                  .addRows(formattedData.map((item) => Object.values(item)))
                  .exportFile()

                return builder
              },
            }}
            columns={[
              {
                title: 'DATE & TIME',
                field: 'date',
              },
              {
                title: 'UPDATE',
                field: 'updateType',
              },
              {
                title: 'UPDATED BY',
                field: 'email',
              },
              // {
              //   title: 'User ID',
              //   field: 'id',
              // },
              {
                title: 'WEBSITE NAME',
                field: 'websiteName',
              },
              // {
              //   title: 'WEBSITE ID',
              //   field: 'websiteId',
              // },
              {
                title: 'Location ID',
                field: 'yextId',
              },
              {
                title: 'Business Line',
                field: 'businessLine',
              },
              {
                title: 'Division ID',
                field: 'divisionId',
              },
              {
                title: 'Is Published',
                field: 'isEntryPublished',
              },
            ]}
            data={contentfulLogs}
            detailPanel={[
              {
                icon: () => <ArrowForwardIosIcon />,
                render: (rowData) => {
                  return (
                    <div className="rw-additional-text rw-segment">
                      <div className="rw-additional-text__container">
                        <div className="rw-additional-text__title">
                          Verbose Description
                        </div>
                        <div className="rw-additional-text__content flex">
                          <p className="pr-2">{rowData.verbalDescription} </p>
                          <p className="pr-2">{rowData.contentfulTitle} </p>
                        </div>
                      </div>
                    </div>
                  )
                },
              },
            ]}
            title="Contentful Changes Logs"
          />
        </div>
      )}

      {logs && <UserActionLogs logs={logs} />}
    </UpdatedMainLayout>
  ) : (
    <Fragment>
      <UpdatedMainLayout>
        <PawLoader isBigVersion />
      </UpdatedMainLayout>
    </Fragment>
  )
}

export default UserActionLogsCellRest
