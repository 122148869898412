import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import SearchIcon from '-!svg-react-loader?name=Icon!../../static/images/search-solid.svg'

function SortGrid({
  searchName,
  searchUserType,
  getSortType,
  gridType = 'users',
  setCurrentPage,
}) {
  const history = useHistory()
  const [name, setName] = useState('')
  const [userType, setUserType] = useState('')
  const [sortType, setSortType] = useState('')

  let handleChange = (e) => {
    searchName(e.target.value)
    setName(e.target.value)
    if (gridType == 'users') {
      searchUserType('')
      getSortType('')
    }
    if (setCurrentPage) {
      setCurrentPage(1)
    }
  }

  let handleSiteSelection = (e) => {
    searchUserType(e.target.value)
    searchName('')
    getSortType('')
    setName('')
  }

  let handleSortSelection = (e) => {
    getSortType(e.target.value)
    searchName('')
    searchUserType('')
    setName('')
  }

  return (
    <div className="w-11/12 flex flex-row justify-between pb-8 m-auto">
      <div className="flex flex-row">
        <label className="flex flex-row border-b border-gray-700 mr-6">
          <SearchIcon className="w-4 text-teal-500 mr-4" />
          <input
            type="text"
            className="font-light text-gray-400 font-light bg-transparent focus:outline-none "
            value={name}
            placeholder={`Search for ${
              gridType === 'users' ? 'Users' : 'Websites'
            }...`}
            onChange={handleChange}
          />
        </label>
        {gridType == 'websites' ? (
          ''
        ) : (
          <select
            onChange={handleSiteSelection}
            className="bg-transparent font-light border-b border-gray-700 rounded-none mr-6"
          >
            <option disabled selected>
              All User Types
            </option>
            <option>Admin</option>
            <option>Editor</option>
          </select>
        )}
      </div>
      {gridType == 'websites' && (
        <button
          className="text-teal-500 focus:outline-none"
          onClick={() => {
            history.push(`/websites/new`)
          }}
        >
          Add Website
        </button>
      )}
      {gridType == 'users' && (
        <button
          className="text-teal-500 focus:outline-none"
          onClick={() => {
            history.push(`/users/new`)
          }}
        >
          Add user
        </button>
      )}
      {gridType == 'users' && (
        <div>
          <select
            onChange={handleSortSelection}
            className="bg-transparent font-light border-b border-gray-700 rounded-none"
          >
            <option disabled selected>
              Sort
            </option>
            <option>Name</option>
            <option>Sites</option>
          </select>
        </div>
      )}
    </div>
  )
}

export default SortGrid
