// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

// import { Router, Route, Private } from '@redwoodjs/router'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import * as Msal from 'msal'
import { useState, useEffect, useCallback } from 'react'

import { useAuthDataContext } from '../auth/authProvider'
import { msalConfig, loginRequest } from '../auth/authConfig'
import PrivateRoute from '../auth/privateRoute'

import UserActionLogsPage from './pages/UserActionLogsPage/UserActionLogsPage'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import NewUserPage from './pages/NewUserPage'
import EditUserPage from './pages/EditUserPage'
import UserPage from './pages/UserPage'
import NewWebsitePage from './pages/NewWebsitePage'
import EditWebsitePage from './pages/EditWebsitePage'
import WebsitePage from './pages/WebsitePage'
import UsersPage from './pages/UsersPage'
import WebsitesPage from './pages/WebsitesPage'
import AdminPage from './pages/AdminPage'
import DashboardPage from './pages/DashboardPage'
import HoursOfOperationPage from './pages/HoursOfOperationPage'
import SettingsPage from './pages/SettingsPage'
import MaintenancePage from './pages/MaintenancePage'

const authClient = new Msal.UserAgentApplication(msalConfig)
const account = authClient.getAccount()

import AboutPageService from './services/about-page.service'
import WebsitePagePage from './pages/WebsitePagePage/WebsitePagePage'
import { EmergencyBanner } from 'src/components/EmergencyBanner/EmergencyBanner'
import { setIsEmerBannerPresent, useGlobalState } from 'src/hooks/state/state'
import { getConstants } from './api/userActionLogs'
const Routes = () => {
  const [emBannerValue, setEmBannerValue] = useState()
  const [maintenanceMode, setMaintenanceMode] = useGlobalState('maintenanceMode')
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')

  const getMaintenanceMode = (data) => {
    const value = data.find(c => c?.key === "maintenance")?.value;
    if(value) {
      return value;
    }
    return "";
  }

  const getEmBannerValue = (data) => {
    const value = data.find(c => c?.key === "ee_emergency_banner")?.value;
    if(value) {
      return value;
    }
    return "";
  }

  const getEmBannerValueAndMaintanceMode = async () => {
    // get action logs from state
    const response = await getConstants();
    const data = response?.data?.getConstants;
    if(data === null || data === undefined || data.length === 0) {
      return;
    }
    const banner = getEmBannerValue(data);
    banner && setEmBannerValue(banner);

    const maintenanceMode = getMaintenanceMode(data);
    maintenanceMode && setMaintenanceMode(maintenanceMode);
  }

  useEffect(() => {
    getEmBannerValueAndMaintanceMode();
  }, [])

  emBannerValue && emBannerValue != '' ? setIsEmerBannerPresent(true) : null
  if (maintenanceMode && isCurrentUserAdmin != null && !isCurrentUserAdmin) {
    return (
      <div className="App h-full flex flex-col">
        {emBannerValue && emBannerValue != '' ? (
          <EmergencyBanner text={emBannerValue} />
        ) : null}
        <Switch>
          <Route path="/" component={MaintenancePage} />
        </Switch>
      </div>
    )
  } else {
    return (
      <div className="App h-full flex flex-col">
        {emBannerValue && emBannerValue != '' ? (
          <EmergencyBanner text={emBannerValue} />
        ) : null}
        <Switch>
          <Route path="/about">
            <AboutPage />
          </Route>

          <Route path="/hoursOfOperation" component={HoursOfOperationPage} />
          <PrivateRoute
            path="/contact"
            account={account}
            component={ContactPage}
          />
          <PrivateRoute
            account={account}
            path="/dashboard"
            component={DashboardPage}
          />
          <PrivateRoute
            account={account}
            path="/settings"
            component={SettingsPage}
          />

          <PrivateRoute
            account={account}
            path="/log"
            component={UserActionLogsPage}
          />

          <PrivateRoute
            account={account}
            path="/users/new"
            component={NewUserPage}
          />

          <PrivateRoute
            account={account}
            path="/users/:id/edit"
            component={EditUserPage}
          />

          <PrivateRoute
            account={account}
            path="/users/:id"
            component={UserPage}
          />

          <PrivateRoute
            account={account}
            path="/websites/new"
            component={NewWebsitePage}
          />

          <PrivateRoute
            account={account}
            path="/websites/:id/edit"
            component={EditWebsitePage}
          />

          <PrivateRoute
            account={account}
            path="/websites/:id"
            component={WebsitePage}
          />

          <PrivateRoute account={account} path="/users" component={UsersPage} />
          <PrivateRoute
            account={account}
            path="/websites"
            component={WebsitesPage}
          />
          <PrivateRoute account={account} path="/admin" component={AdminPage} />

          <PrivateRoute
            account={account}
            path="/site/:siteId/website-page/:pageName/:websiteName"
            component={WebsitePagePage}
          />

          <Route path="/" component={HomePage} />
        </Switch>
      </div>
    )
  }
}

export default Routes
