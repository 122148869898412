import React, {useState, useEffect} from 'react';
import MaterialTable from 'material-table';



const ContactInfoTable = ({ newValue, oldValue }) => {

  const [state, setState] = useState({
    oldValue: {},
    newValue: {},
  });

  const formatedData = (value) => {
    if (!value) {
      return {};
    }
    return JSON.parse(value.replaceAll("'", '"'));
  }

  useEffect(() => {
    const oldValueFormated = formatedData(oldValue);
    const newValueFormated = formatedData(newValue);
    setState({
      oldValue: oldValueFormated,
      newValue: newValueFormated,
    });



  }, [oldValue, newValue]);

  return (
    <div className="rw-additional-text rw-segment">
      <MaterialTable
        options={{
          search: false,
          filtering: false,
          paging: false,
          showTitle: false,
          toolbar: false,
        }}
        columns={[
          { title: 'Type',
            field: 'type',
          },
          { title: 'Old value',
            field: 'oldValue',
            render: (rowData) => {
              if(!rowData.oldValue && !rowData.newValue){
                return (<span>-</span>);
              }
              if(!rowData.oldValue){
                return (<span style={{textDecoration: "line-through", color: "red"}}>-</span>);
              }
              if(rowData.oldValue !== rowData.newValue){
                return (<span style={{textDecoration: "line-through", color: "red"}}>{rowData.oldValue}</span>);
              }
              return(<span>{rowData.oldValue}</span>);
            },
          },
          { title: 'New value',
            field: 'newValue',
            render: (rowData) => {
              if(!rowData.newValue){
                return (<span>-</span>);
              }
              if(rowData.oldValue !== rowData.newValue){
                return (<span style={{color: "green"}}>{rowData.newValue}</span>);
              }
              return(<span>{rowData.newValue}</span>);
            },
          },
        ]}
        data={[
          { type: 'Phone', oldValue: state?.oldValue?.phone || '', newValue: state?.newValue?.phone },
          { type: 'Text phone', oldValue: state?.oldValue?.text || '', newValue: state?.newValue?.text },
          { type: 'Fax', oldValue: state?.oldValue?.fax || '', newValue: state?.newValue?.fax },
        ]}
      />
    </div>
  )
}

export default ContactInfoTable;
