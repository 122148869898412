import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import MainLayout from '../../layouts/MainLayout'

import FilterForm from '../UserGroupsCell/FilterForm/FilterForm'
import Grid from '../UserGroupsCell/Grid/Grid'
import SearchBox from '../UserGroupsCell/SearchBox/SearchBox'
import {
  ActionsBar,
  GridRow,
  UserGroupsCell,
} from '../UserGroupsCell/UserGroupCell.styles'
import { getUserById } from '../../api/users'
import PawLoader from '../PawLoader'
import ReactGA from 'react-ga'
import { setReRenderStaff } from 'src/hooks/state/state'

const UserGroupsCellRest = ({ imgSrc, user }) => {
  const [searchString, setSearchString] = useState('')
  const [sortSelection, setSortSelection] = useState('')
  const history = useHistory()
  setReRenderStaff(true)

  const handleSelection = (e) => {
    setSortSelection(e.target.value)
  }

  if (user?.groups?.length == 1) {
    //single site, redirect to main
    ReactGA.event({
      category: 'Navigation',
      action: `${user.name} redirected to a single website`,
      label: `siteID: ${user.groups[0].siteId}`,
    })
    history.push(
      `/site/${user.groups[0].siteId}/website-page/main/${user.groups[0].name}`
    )
  } else {
    user &&
      user.groups &&
      ReactGA.event({
        category: 'Navigation',
        action: `${user.name} displayed with a dashboard contained ${user.groups.length} sites`,
        label: `siteIDs: ${user?.groups?.map((site) => site.siteId)}`,
      })
  }

  return user !== null ? (
    user.groups.length > 0 ? (
      <UserGroupsCell>
        <ActionsBar>
          <SearchBox setSearchString={setSearchString} />
          <div className="col-6 text-right">
            <select
              onChange={handleSelection}
              className="bg-transparent font-light border-b border-gray-700 rounded-none focus:outline-none"
            >
              <option disabled selected>
                Sort
              </option>
              <option>Name</option>
              <option>Location</option>
            </select>
          </div>
        </ActionsBar>
        <GridRow>
          <Grid
            groups={user.groups}
            searchString={searchString}
            sortSelection={sortSelection}
            imgSrc={imgSrc}
          />
        </GridRow>
      </UserGroupsCell>
    ) : (
      <UserGroupsCell>
        You currently have no sites assigned to your account. Please{' '}
        <a className="text-teal-500" href="https://nva.service-now.com/retriever?id=sc_cat_item&sys_id=fcf45a20873ce1106d54ca2e0ebb3519" target="_blank" rel="noopener noreferrer">
          contact us through Retriever</a>{' '}
        to request access to edit a website.
      </UserGroupsCell>
    )
  ) : (
    <PawLoader isBigVersion />
  )
}

export default UserGroupsCellRest
