import React, { useState } from 'react'
import PawLoader from '../../PawLoader'
import SimpleDragItem from 'src/components/SimpleDragItem/SimpleDragItem'
import SimpleListItem from 'src/components/SimpleListtem/SimpleListItem'

const Groups = (props) => {
  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    ev.dataTransfer.setData('key', key)
  }

  var enterTarget = null

  const onDragEnter = (ev) => {
    if (ev.target.closest('.drag-container')) {
      enterTarget = ev.target
      ev.target.closest('.drag-container').classList.add('showDropAreas-green')
    }
  }

  const onDragLeave = (ev) => {
    if (
      ev.target.closest('.drag-container') &&
      enterTarget == ev.target.closest('.drag-container')
    ) {
      ev.target
        .closest('.drag-container')
        .classList.remove('showDropAreas-green')
    }
  }

  const onDrop = (ev, destKey) => {
    ev.target.closest('.drag-container').classList.remove('showDropAreas-green')
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderGroups(sourceKey, destKey)
  }

  const moveOnClick = (sourceKey, destKey) => {
    if (destKey >= 0 && destKey < props?.data?.length) {
      props.reorderGroups(sourceKey, destKey)
    }
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  return (
    <div className="staff-form">
      <div>
        <div id="group-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Staff Groups</h2>
          <p className="sidebar-para mt-0">
            {`Select a group to edit the staff members. Drag and drop the groups to rearrange them on the page. When you have completed all edits to groups and staff, make sure to save before publishing.`}
          </p>
        </div>

        <div id="group-add" style={{ marginBottom: '20px' }}>
          <SimpleListItem
            key={0}
            itemKey={0}
            item={null}
            title={'Create a New Group'}
            isDisabled={props.addButtonDisabled}
            onClick={props.addButtonDisabled ? null : () => props.addGroup()}
          />
        </div>

        <div id="group-groups" style={{ marginBottom: '40px' }}>
          {props.addButtonDisabled ? (
            <PawLoader />
          ) : props.data ? (
            props.data.map((item, key) => {
              if (!item.deleted) {
                return (
                  <SimpleDragItem
                    itemKey={key}
                    item={item}
                    title={item.groupName}
                    description={item.staffMembers
                      ?.map((i) => i.name)
                      .join(', ')}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    sendMsg={sendMsg}
                    onEditClick={props.goGroup}
                    moveOnClick={moveOnClick}
                    lastItemKey={props.data.length - 1}
                    firstVisibleItemKey={0}
                    onRemoveClick={() => props.deleteGroup(null, key)}
                    hideHide
                  />
                )
              }
            })
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Groups
