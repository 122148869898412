import { Form, FormError, FieldError, Label, Submit } from '@redwoodjs/forms'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import * as _ from 'lodash'
import { userFormMessages } from '../../constants/messages/en'

const UserForm = ({ onSave, error, groups, userToEdit = {} }) => {
  const [inlineFormError, setInlineFormError] = useState('')

  const { register, handleSubmit } = useForm({})

  const onSubmit = (data) => {
    setInlineFormError('')
    if (data && data.email && data.name && data.roleId && data.updatedAt) {
      //data.groups = parseInt(data.groups.id)
      // data.groups.yextId = parseInt(data.groups.yextId)
      // data.groups =
      //   (data.groups && data.groups.map((id) => parseInt(id.id))) || 1
      // data.roleId = parseInt(data.roleId)
      // data.loginsCount = parseInt(data.loginsCount)
      data = {
        ...data,
        picture: '',
        userId: data.email,
        nickname: data.name,
        roleId: parseInt(data.roleId),
      }
      onSave(data)
    } else {
      setInlineFormError(userFormMessages.MANDATORY)
    }
  }

  const CustomFormError = () => {
    return (
      <div className="rw-form-error-wrapper">
        <p className="rw-form-error-title">{inlineFormError}</p>
      </div>
    )
  }

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={handleSubmit(onSubmit)} error={error}>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        {inlineFormError ? <CustomFormError /> : ''}

        <Label
          name="email"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {userFormMessages.EMAIL}
        </Label>
        <input
          {...register('email', { required: true })}
          name="email"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.email : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />
        <span>{userFormMessages.HELPER_EMAIL}</span>
        <FieldError name="email" className="rw-field-error" />

        <Label
          name="name"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {userFormMessages.NAME}
        </Label>
        <input
          {...register('name', { required: true })}
          name="name"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.name : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />
        <FieldError name="name" className="rw-field-error" />

        <Label
          htmlFor="updatedAt"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {userFormMessages.UPDATED_AT}
        </Label>
        <input
          style={{
            pointerEvents: 'none',
            background: 'lightgrey',
          }}
          {...register('updatedAt', { required: true })}
          name="updatedAt"
          defaultValue={new Date().toISOString()}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
        />

        <Label
          name="roleId"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          {userFormMessages.ROLE}
        </Label>
        <select
          {...register('roleId')}
          name="roleId"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.roleId : 2}
          className="rw-input w-auto"
          // errorClassName="rw-input rw-input-error"
        >
          <option value="2">{userFormMessages.USER}</option>
          <option value="1">{userFormMessages.ADMIN}</option>
        </select>
        <FieldError name="role" className="rw-field-error" />

        <div className="rw-button-group">
          <Submit className="rw-button rw-button-blue">
            {!_.isEmpty(userToEdit) ? 'Update' : 'Save'}
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default UserForm
