export const SERVICE_DETAIL = `
  <div class="service-detail">
    <section class="sc-iwjyx1-0 dUmLwH serviceDetail">
      <div class="container">
        <div class="serviceName">
          <h1 class="sc-1lhs05s-0 PNWdN no-bar" role="heading" aria-level="1" id="title">{{EE_TITLE}}</h1>
        </div>
        <div class="sc-wturor-0 eQA-DwQ serviceOverview">{{EE_SERVICE_OVERVIEW}}</div>
        <div class="serviceGallery">
          <div class="sc-10lpcsk-0 ifwbzF showAllTilesContainer">
            <div class="sc-1mj8z02-0 huHlDn image-container" fill="true">
              <picture>
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=250&amp;w=375&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=500&amp;w=750&amp;q=72 2x "
                  type="image/webp" media="(max-width: 375px)" />
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=564&amp;w=1252&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=1128&amp;w=2504&amp;q=72 2x "
                  type="image/webp" media="(min-width: 1600px)" />
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=482&amp;w=1070&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=964&amp;w=2140&amp;q=72 2x "
                  type="image/webp" media="(min-width: 1366px)" />
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=482&amp;w=964&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=964&amp;w=1928&amp;q=72 2x "
                  type="image/webp" media="(min-width: 1024px)" />
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=370&amp;w=540&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=740&amp;w=1080&amp;q=72 2x "
                  type="image/webp" media="(min-width: 768px)" />
                <source
                  srcSet="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=250&amp;w=375&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=500&amp;w=750&amp;q=72 2x "
                  type="image/webp" media="(min-width: 375px)" /><img
                  alt="Cat with cone around its neck sitting down inside"
                  title="Cat with cone around its neck sitting down inside"
                  src="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=482&amp;w=1070&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=964&amp;w=2140&amp;q=72"
                  decoding="async" data-nimg="future-fill" class="sc-eu5zgc-0 jwuGFj" loading="lazy"
                  style="position:absolute;height:100%;width:100%;left:0;top:0;right:0;bottom:0;color:transparent;object-fit:cover;object-position:center center" />
              </picture>
            </div>
          </div>
        </div>
        <div class="serviceDetails">
          <div class="serviceDetailsClass">
            <div class="sc-wturor-0 eQA-DwQ">{{EE_SERVICE_DETAILS}}</div>
          </div>
          <div id="LocalCopyDiv">{{EE_LOCAL_COPY}}</div>
        </div>
      </div>
    </section>
  </div>
`;

export const SERVICE_GROUP = `
  <div class="sc-5trg3i-0 jKizrS" id="{{EE_SERVICE_GROUP_ID}}">
    <div class="services-blade container">
      <div class="service-container">
        <h2 class="sc-1lhs05s-0 eSDLys blade-heading" role="heading" aria-level="2" id="online-services">{{EE_SERVICE_GROUP_TITLE}}</h2>
        <div class="sc-wturor-0 gNWsGk blade-description">{{EE_SERVICE_GROUP_DESCRIPTION}}</div>
        <div class="service-tile-container">{{EE_SERVICE_DETAIL_LINKS}}</div>
      </div>
    </div>
  </div>
`;

export const SERVICE_DETAIL_LINK = `
  <a title="Click Here to Learn More about {{EE_SERVICE_DETAIL_TITLE}}" role="link"
    class=" sc-1ux5mjm-1 cPsVEO service-tile" target="_self" id="{{EE_SERVICE_DETAIL_ID}}"
    aria-label="{{EE_SERVICE_DETAIL_TITLE}}" data-analytics-type="cta-services-tile"
    data-analytics-value="{{EE_SERVICE_DETAIL_TITLE}}" data-analytics-variation="">
    <div class="image-wrapper">
      <div class="sc-1mj8z02-0 hoESnz image-container" fill="true">
        <picture>
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=334&amp;w=375&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=668&amp;w=750&amp;q=72 2x "
            type="image/webp" media="(max-width: 375px)">
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=246&amp;w=276&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=492&amp;w=552&amp;q=72 2x "
            type="image/webp" media="(min-width: 1600px)">
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=210&amp;w=236&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=420&amp;w=472&amp;q=72 2x "
            type="image/webp" media="(min-width: 1366px)">
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=200&amp;w=226&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=400&amp;w=452&amp;q=72 2x "
            type="image/webp" media="(min-width: 1024px)">
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=384&amp;w=320&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=768&amp;w=640&amp;q=72 2x "
            type="image/webp" media="(min-width: 768px)">
          <source
            srcset="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=334&amp;w=375&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=668&amp;w=750&amp;q=72 2x "
            type="image/webp" media="(min-width: 375px)"><img alt="Cat on couch looking at laptop"
            title="Cat on couch looking at laptop"
            src="https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=210&amp;w=236&amp;q=72, https:{{EE_IMAGE}}?fit=fill&amp;fm=webp&amp;h=420&amp;w=472&amp;q=72"
            decoding="async" data-nimg="future-fill" class="sc-eu5zgc-0 cLCpsP" loading="lazy"
            style="position:absolute;height:100%;width:100%;left:0;top:0;right:0;bottom:0;color:transparent;object-fit:cover;object-position:center center">
        </picture>
      </div>
    </div>
    <div class="sc-5mwt0n-0 kTscFm service-text">
      <h3 class="sc-1lhs05s-0 eSDLys card-header" role="heading" aria-level="3">{{EE_SERVICE_DETAIL_TITLE}}</h3>
      <div class="sc-wturor-0 gNWsGk card-rich-text">{{EE_SERVICE_DETAIL_DESCRIPTION}}</div>
      <p class="sc-ovnq1p-0 jbfgxq para-cta-wrapper"><span
          class="fakeAnchor sc-1ux5mjm-0 kZcSkj arrow">Learn More</span></p>
    </div>
  </a>
`;
