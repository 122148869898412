import React from 'react'
import './SimpleListItem.css'
import PlusIcon from '-!svg-react-loader?name=Icon!../../static/images/plus_icon.svg'

import { ParagraphSmall } from '../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'

const SimpleListItem = ({
  itemKey,
  item,
  title,
  description,
  img,
  imgAltText,
  sendMsg,
  onClick,
  isSelected,
  isDisabled,
  ...rest
}) => {
  const onEditClick = () => {
    if (sendMsg) {
      sendMsg({
        scrollToGroupId: item.key,
      })
    }
    onClick(itemKey)
  }

  const onPlusClick = () => {
    if (rest.onPlusClick) {
      rest.onPlusClick(itemKey)
    } else {
      onEditClick()
    }
  }

  const RenderDetails = () => (
    <div>
      <div className={img ? 'show-ellipsis' : ''}>
        {title || item?.groupName || 'No Name'}
      </div>
      {description && (
        <ParagraphSmall className="services-list my-0">
          {description}
        </ParagraphSmall>
      )}
    </div>
  )

  return (
    <div
      key={itemKey}
      id={item?.key ?? itemKey}
      className={`simpleitem-container ${isSelected ? 'selected-card' : ''}${
        isDisabled ? 'isDisabled' : ''
      }`}
      onClick={onEditClick}
    >
      <button
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          flexGrow: 1,
          gap: '16px',
        }}
        className={'hide-outline'}
      >
        <div onClick={onPlusClick} className="pl-4">
          <PlusIcon />
        </div>
        <div className="simpleitem-text" role="button">
          {img ? (
            <div style={{ display: 'flex' }}>
              <img
                src={img}
                style={{ paddingRight: 15, width: '25%', objectFit: 'cover' }}
                alt={imgAltText}
              />
              <RenderDetails />
            </div>
          ) : (
            <RenderDetails />
          )}
        </div>
      </button>
    </div>
  )
}

export default SimpleListItem
