import { Fragment, useState, useEffect, useContext } from 'react'
import * as _ from 'lodash'
import PublishLogTable from './PublishLogTable'
import { useParams } from 'react-router-dom'
import { ModalContext } from '../../context/ModalContext'
import { useGlobalState } from '../../hooks/state/state'

const FIFTEEN_MINUTES = 900000;

const UserActionLog = ({
  publishLogs,
  undoPublishLog,
  contentfulLogs,
  onPublishClickCallback,
  siteProcess,
}) => {
  const [timerRunning, setTimerRunning] = useState();
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')
  let { siteId } = useParams()

  const combinedUnpublishedEntries =
    (publishLogs?.length || 0) + (contentfulLogs?.length || 0)

  const { isSaving } = useContext(ModalContext)

  const getPublishTime = () => {
    const dataFromLocalStorage = localStorage.getItem(`publishTimer-${siteId}`);
    if(!dataFromLocalStorage) {
      return null;
    }
    return parseInt(dataFromLocalStorage);
  }

  const isAllowToPublish = () => {
    if (isCurrentUserAdmin) return true;
    const lastPublishTime = getPublishTime();
    if(!lastPublishTime) return true;
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastPublishTime;
    if(timeDiff > FIFTEEN_MINUTES) {
      return true;
    }
    return false;
  }

  const makePublishButtonAvailableAfterFifteenMinutes = () => {
    const currentTime = new Date().getTime();
    const publishButtonAlowedTime = getPublishTime() + FIFTEEN_MINUTES;
    const timeLeft = publishButtonAlowedTime - currentTime;
    if(timeLeft < 0) {
      return;
    }
    setTimeout(() => {
      setPublishButtonAvailableTime();
    }, timeLeft);
  }

  const setPublishButtonAvailableTime = () => {
    setTimerRunning(false);
  }

  const statusOfPublishButton = async () => {
    const allowToLoad = isAllowToPublish();
    if(allowToLoad) {
      setTimerRunning(false);
      return;
    }
    setTimerRunning(true);
    makePublishButtonAvailableAfterFifteenMinutes();
  }

  const initial = async () => {
    statusOfPublishButton();
  }

  const checkPublishButtonDisabled = () => {
    if(isCurrentUserAdmin) return false;
    if (siteProcess?.status == 'queued' || isSaving == 'loading' || siteProcess?.lock || isSaving == 'publishing') {
      return true;
    }
    return false || timerRunning
  }

  // disable the oublish button for 5 minutes on click
  useEffect(() => {
    initial();
  }, []);

  return (
    <div className="rw-modal__wrapper">
      {(publishLogs && publishLogs.length > 0) ||
      (contentfulLogs && contentfulLogs.length > 0) ? (
        <Fragment>
          <div className="rw-modal-title">
            <p className="rw-modal__unpublished-updates">
              You have{' '}
              {combinedUnpublishedEntries > 0
                ? combinedUnpublishedEntries
                : 'no'}{' '}
              unpublished update(s). <br />
              {siteProcess?.lock ?
                <p style={{color: 'darkred'}}>This website is currently locked from publishing because of pending changes. Please contact us
                through <a
                      onClick={()=>window.open(process.env.RETRIEVER_SUPPORT_LINK, '_blank').focus()}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >Retriever support</a> with any questions.
                </p>
                  :
                <>Make sure you make all desired changes before pressing Publish.
                Publishing may take up to 15 minutes. You will receive an email
                when your changes are live. This CANNOT be undone</>
              }
            </p>

            <button
              type="button"
              className="rw-modal-publish-button"
              onClick={onPublishClickCallback}
              disabled={checkPublishButtonDisabled()}
            >
              Publish
            </button>
            {/* <button
              type="button"
              className="rw-modal-discard-button"
              onClick={undoAllActioLogsCallback}
            >
              discard all updates
            </button> */}
          </div>
          {/* <LogTable
            contentfulLogs={contentfulLogs}
            logs={logs}
            undoActioLogCallback={undoActioLogCallback}
          /> */}
          <PublishLogTable
            logs={publishLogs}
            contentfulLogs={contentfulLogs}
            undoPublishLog={undoPublishLog}
          />
        </Fragment>
      ) : (
        <div
          className="rw-modal-publish-button__no-changes-msg"
          style={{ alignItems: 'center', textAlign: 'center' }}
        >
          <h2>There are no currently saved changes!</h2>
        </div>
      )}
    </div>
  )
}

export default UserActionLog
