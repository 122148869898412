import React, { useState, useEffect, useRef } from 'react'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/new_chevron_icon.svg'
import AdminIcon from '-!svg-react-loader?name=Icon!../../static/images/admin_icon.svg'
import { useGlobalState } from 'src/hooks/state/state'
import { getConstants } from '../../api/userActionLogs'

const AdminDropDown = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownContainer = useRef(null)
  const buttonContainerRef = useRef(null)
  const chevronIconRef = useRef(null)
  const [maintenanceMode, setMaintenanceMode] = useGlobalState('maintenanceMode');

  useEffect(() => {
    getValues();
    window.addEventListener('click', handleOnClick)

    return () => {
      window.removeEventListener('click', handleOnClick)
    }
  }, [])

  const handleOnClick = (event) => {
    const chevronClicked = !!getParentByRef(event.target, chevronIconRef)
    let width = dropdownContainer.current
      ? dropdownContainer.current.offsetWidth
      : 0
    dropdownContainer.current &&
      dropdownContainer.current.setAttribute(
        'style',
        `left:${
          (chevronClicked
            ? buttonContainerRef.current.offsetWidth
            : event.offsetX) -
          width / 2
        }px`
      )
    var el = event.target
    var div = getParentByClass(el, 'admin-dropdown-button')
    !div && setShowDropdown(false)
  }

  const getParentByClass = (el, className) => {
    do {
      if (el.classList.contains(className)) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  const getParentByRef = (el, ref) => {
    do {
      if (el == ref.current) {
        return el
      } else {
        el = el.parentNode
      }
    } while (el && el.parentNode)
  }

  const getMaintenanceMode = (data) => {
    const value = data.find(c => c?.key === "maintenance")?.value;
    if(value) {
      return value;
    }
    return "";
  }

  const getValues = async () => {
    const response = await getConstants();
    const data = response?.data?.getConstants;
    if(!data) {
      return;
    }
    const maintenanceMode = getMaintenanceMode(data);
    maintenanceMode && setMaintenanceMode(maintenanceMode);
  }

  return (
    <div className="relative my-auto" ref={buttonContainerRef}>
      <button
        className="flex flex-row text-white focus:outline-none gap-1 items-center admin-dropdown-button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {maintenanceMode === 'on' ? (<label className="font-bold text-white" style={{background: 'red'}}>&nbsp;Maintenance ON&nbsp;</label>) : ('')}
        <AdminIcon className="" />
        Administration
        <div className="pl-2" ref={chevronIconRef}>
          <ChevronDown />
        </div>
      </button>
      {showDropdown ? (
        <div
          className="absolute flex flex-col bg-white shadow-2xl support-dropdown-contents z-10"
          ref={dropdownContainer}
        >
          <a className=" font-light" rel="noreferrer" href="/users">
            <div className="py-2 px-6 cursor-pointer dropdown-item">Users</div>
          </a>
          <a className="font-light" rel="noreferrer" href="/websites">
            <div className="py-2 px-6 cursor-pointer dropdown-item">
              Websites
            </div>
          </a>
          <a className=" font-light" rel="noreferrer" href="/log">
            <div className="py-2 px-6 cursor-pointer dropdown-item">Logs</div>
          </a>
          <a className="font-light" rel="noreferrer" href="/settings">
            <div className="py-2 px-6 cursor-pointer dropdown-item">
              Settings
            </div>
          </a>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default AdminDropDown
