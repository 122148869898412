import { Link, routes } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'
import { useState } from 'react'
import { useCMS } from 'tinacms'

import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'

import { useGlobalState } from '../../hooks/state/state'

const MainLayout = ({ user, children }) => {
  const { isAuthenticated, logIn, logOut } = useAuth()
  const [sidebarActive, setSidebarState] = useState(true)
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')
  const [headerBottomCoordinate] = useGlobalState('headerBottomCoordinate')

  const cms = useCMS()

  cms.events.subscribe('sidebar:opened', (event) => {
    setSidebarState(!sidebarActive)
  })

  cms.events.subscribe('sidebar:closed', (event) => {
    setSidebarState(!sidebarActive)
  })

  return (
    <>
      <Header user={user} />
      <main
        className="main-content flex-1 bg-gray-100 h-full"
        // style={{ paddingTop: !isCurrentUserAdmin ? (headerBottomCoordinate) || '150px' : (headerBottomCoordinate) || '71px' }}
      >
        {children}
      </main>
      <Footer />
    </>
  )
}

export default MainLayout
