import UpdatedMainLayout from '../../layouts/UpdatedMainLayout/UpdatedMainLayout'
import WebsitesRest from 'src/components/WebsitesRest'

const WebsitesPage = ({ role, user}) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      <WebsitesRest />
    </UpdatedMainLayout>
  )
}

export default WebsitesPage
