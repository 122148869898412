import React, { useEffect, useRef, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useGlobalState } from '../../hooks/state/state'
import './PreviewCard.css'

const theme = {
  Corporate: {
    heroCTA: '#007f85',
    text: 'GoudyOldStyleBold',
    textCTA: 'GoudyOldStyleMT',
    desktop: {
      textSize: '62px',
      subHeadingSize: '28px',
      textLineHeight: '64px',
      textLetterSpacing: '0.62px',
      subHeadingLineHeight: '32px',
      subHeadingLetterSpacing: '0.28px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '50px',
      subHeadingSize: '15px',
      textLineHeight: '64px',
      textLetterSpacing: '0.5px',
      subHeadingLineHeight: '25px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: 'normal',
      ctaLetterSpacing: '1.9px',
    },
  },
  EarthyV1: {
    heroCTA: '#6a7a62',
    text: 'LoraRegular',
    textCTA: 'RalewayBold',
    desktop: {
      textSize: '50px',
      subHeadingSize: '28px',
      textLineHeight: '60px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '32px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '15px',
      textLineHeight: '40px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  EarthyV2: {
    heroCTA: '#063b5d',
    text: 'CourgetteRegular',
    textCTA: 'OpenSansSemiBold',
    desktop: {
      textSize: '54px',
      subHeadingSize: '28px',
      textLineHeight: '64px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '38px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '14px',
      textLineHeight: '40px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '25px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  EarthyV3: {
    heroCTA: '#f59174',
    text: 'MerriweatherBold',
    textCTA: 'CabinBold',
    desktop: {
      textSize: '70px',
      subHeadingSize: '28px',
      textLineHeight: '80px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '35px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '42px',
      subHeadingSize: '16px',
      textLineHeight: '52px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  Equine: {
    heroCTA: '#423F40',
    text: 'MontserratRegular',
    textCTA: 'MontserratMedium',
    desktop: {
      textSize: '50px',
      subHeadingSize: '32px',
      textLineHeight: '60px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '38px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '15px',
      textLineHeight: '40px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '24px',
      ctaLetterSpacing: 'normal',
    },
  },
  ModernV1: {
    heroCTA: '#527c8e',
    text: 'UbuntuBold',
    textCTA: 'UbuntuMedium',
    borderRadius: '100px',
    desktop: {
      textSize: '54px',
      subHeadingSize: '26px',
      textLineHeight: '64px',
      textLetterSpacing: '5.4px',
      subHeadingLineHeight: '38px',
      subHeadingLetterSpacing: '1.6px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '4px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.3px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  ModernV2: {
    heroCTA: '#ff5338',
    text: 'CormorantMedium',
    textCTA: 'MontserratSemiBold',
    desktop: {
      textSize: '64px',
      subHeadingSize: '30px',
      textLineHeight: '70px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '36px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '16px',
      textLineHeight: '40px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '24px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  ModernV3: {
    heroCTA: '#5069e7',
    text: 'AbrilFatfaceRegular',
    textCTA: 'LatoRegular',
    borderRadius: '100px',
    desktop: {
      textSize: '56px',
      subHeadingSize: '30px',
      textLineHeight: '64px',
      textLetterSpacing: '5.6px',
      subHeadingLineHeight: '38px',
      subHeadingLetterSpacing: '1.8px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '4px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  OutdoorV1: {
    heroCTA: '#f66e00',
    text: 'LatoBlack',
    textCTA: 'LatoBlack',
    backgroundImage: 'linear-gradient(to right,#f66e00,#db3700)',
    desktop: {
      textSize: '70px',
      subHeadingSize: '24px',
      textLineHeight: '85px',
      textLetterSpacing: '4.9px',
      subHeadingLineHeight: '26px',
      subHeadingLetterSpacing: '1.68px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '14px',
      textLineHeight: '44px',
      textLetterSpacing: '1.8px',
      subHeadingLineHeight: '24px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  OutdoorV2: {
    heroCTA: '#4b99b1',
    text: 'FjallaOneRegular',
    textCTA: 'FjallaOneRegular',
    backgroundImage: 'linear-gradient(to right,#4b99b1,#337d94)',
    desktop: {
      textSize: '80px',
      subHeadingSize: '30px',
      textLineHeight: '90px',
      textLetterSpacing: '5px',
      subHeadingLineHeight: '40px',
      subHeadingLetterSpacing: '1.8px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '44px',
      subHeadingSize: '14px',
      textLineHeight: '54px',
      textLetterSpacing: '2.2px',
      subHeadingLineHeight: '24px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  OutdoorV3: {
    heroCTA: '#fe3725',
    text: 'ArvoBold',
    textCTA: 'ArvoBold',
    desktop: {
      textSize: '70px',
      subHeadingSize: '20px',
      textLineHeight: '80px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '16px',
      subHeadingLetterSpacing: '1.8px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PetSuites: {
    heroCTA: '#4a0d66',
    text: 'CopseRegular',
    textCTA: 'NunitoBold',
    desktop: {
      textSize: '58px',
      subHeadingSize: '28px',
      textLineHeight: '70px',
      textLetterSpacing: '0.58px',
      subHeadingLineHeight: '40px',
      subHeadingLetterSpacing: '0.56px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '0.4px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PlayfulV1: {
    heroCTA: '#745af6',
    text: 'SourceSansProSemiBold',
    textCTA: 'SourceSansProBold',
    desktop: {
      textSize: '58px',
      subHeadingSize: '28px',
      textLineHeight: '70px',
      textLetterSpacing: '0.58px',
      subHeadingLineHeight: '40px',
      subHeadingLetterSpacing: '0.56px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '0.4px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PlayfulV2: {
    heroCTA: '#65b766',
    text: 'AlegreyaSansBold',
    textCTA: 'SourceSansProBold',
    desktop: {
      textSize: '70px',
      subHeadingSize: '28px',
      textLineHeight: '76px',
      textLetterSpacing: '3.5px',
      subHeadingLineHeight: '40px',
      subHeadingLetterSpacing: '0.56px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '1.6px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.16px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PlayfulV3: {
    heroCTA: '#ff8141',
    text: 'QuicksandBold',
    textCTA: 'QuicksandBold',
    desktop: {
      textSize: '58px',
      subHeadingSize: '28px',
      textLineHeight: '68px',
      textLetterSpacing: '0.58px',
      subHeadingLineHeight: '40px',
      subHeadingLetterSpacing: '0.28px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '46px',
      textLetterSpacing: '0.4px',
      subHeadingLineHeight: '28px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PreciseV1: {
    heroCTA: '#00a7ff',
    text: 'WorkSansLight',
    textCTA: 'WorkSansRegular',
    desktop: {
      textSize: '50px',
      subHeadingSize: '26px',
      textLineHeight: '60px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '30px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '36px',
      subHeadingSize: '14px',
      textLineHeight: '40px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '22px',
      subHeadingLetterSpacing: '0.3px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  PreciseV2: {
    heroCTA: '#296293',
    text: 'RubikRegular',
    textCTA: 'KarlaRegular',
    desktop: {
      textSize: '50px',
      subHeadingSize: '26px',
      textLineHeight: '60px',
      textLetterSpacing: '0.5px',
      subHeadingLineHeight: '34px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '40px',
      subHeadingSize: '16px',
      textLineHeight: '50px',
      textLetterSpacing: 'normal',
      subHeadingLineHeight: '20px',
      subHeadingLetterSpacing: '0.32px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
  },
  undefined: {
    heroCTA: '#007f85',
    text: 'GoudyOldStyleBold',
    textCTA: 'GoudyOldStyleMT',
    desktop: {
      textSize: '62px',
      subHeadingSize: '28px',
      textLineHeight: '64px',
      textLetterSpacing: '0.62px',
      subHeadingLineHeight: '32px',
      subHeadingLetterSpacing: '0.28px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '50px',
      subHeadingSize: '15px',
      textLineHeight: '64px',
      textLetterSpacing: '0.5px',
      subHeadingLineHeight: '25px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: 'normal',
      ctaLetterSpacing: '1.9px',
    },
  },
  null: {
    heroCTA: '#007f85',
    text: 'GoudyOldStyleBold',
    textCTA: 'GoudyOldStyleMT',
    desktop: {
      textSize: '62px',
      subHeadingSize: '28px',
      textLineHeight: '64px',
      textLetterSpacing: '0.62px',
      subHeadingLineHeight: '32px',
      subHeadingLetterSpacing: '0.28px',
      ctaLineHeight: '30px',
      ctaLetterSpacing: '1.4px',
    },
    mobile: {
      textSize: '50px',
      subHeadingSize: '15px',
      textLineHeight: '64px',
      textLetterSpacing: '0.5px',
      subHeadingLineHeight: '25px',
      subHeadingLetterSpacing: 'normal',
      ctaLineHeight: 'normal',
      ctaLetterSpacing: '1.9px',
    },
  },
}

const PreviewCard = ({
  card: {
    img,
    imgAltText,
    headline,
    subHeadline,
    textBlock,
    fontColor,
    ctaLabel,
    mobileImage,
  },
  iframeView,
  cms,
}) => {
  const iframeWrapper = useRef(null)
  const [scale, setScale] = useState(1)
  const [iframeWidth, setIframeWidth] = useState()
  const [websiteTheme] = useGlobalState('websiteTheme')
  const heroCTAColor = theme[websiteTheme]?.heroCTA
  const [frameHeight, setFrameHeight] = useState('567px')

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    if (cms) {
      cms.events.subscribe('sidebar:opened', () => {
        handleResize('open')
      })
      cms.events.subscribe('sidebar:closed', () => {
        handleResize('closed')
      })
    } else {
      document
        .querySelector('[aria-label="toggles cms sidebar"]')
        .addEventListener('click', handleToggleClass)
    }

    const sidebar = document.querySelector('.sidebar')

    function handleToggleClass() {
      sidebar.classList.contains('sidebar-closed')
        ? handleResize('open')
        : handleResize('closed')
    }

    function handleResize(sidebar) {
      if (iframeWrapper !== null && iframeWrapper.current !== null) {
        const iframeScale =
          sidebar && sidebar === 'open'
            ? iframeWrapper.current.offsetWidth > 768
              ? iframeWrapper.current.offsetWidth - 425
              : iframeWrapper.current.offsetWidth
            : sidebar &&
              sidebar === 'closed' &&
              iframeWrapper.current.offsetWidth < 1045
            ? iframeWrapper.current.offsetWidth + 425
            : iframeWrapper.current.offsetWidth
        const iframeWidth = iframeScale < 1045 ? '1024px' : '100%'

        setScale(Math.min(iframeScale) / 1095)
        setIframeWidth(iframeWidth)
      }
    }
    setTimeout(() => {
      handleResize()
    }, 10)
  }, [])

  let iframeViewType = iframeView == 'mobile' ? 'mobile' : 'desktop'

  return (
    <div
      ref={iframeWrapper}
      style={
        iframeView != 'mobile'
          ? {
              width: `${iframeView == 'mobile' ? '400px' : '100%'}`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }
          : {
              width: `${iframeView == 'mobile' ? '400px' : '100%'}`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }
      }
      className={`preview-card__iframe-wrapper w-full relative mx-auto`}
    >
      <img
        src={iframeView == 'mobile' ? mobileImage : img}
        alt={imgAltText}
        className={`object-cover w-full`}
        style={{ height: `${iframeView == 'mobile' ? '250px' : '100%'}` }}
      />
      <section
        style={{
          transform: `${
            iframeView != 'mobile' ? `scale(${scale < 1 ? scale : 1})` : 'none'
          } `,
          transformOrigin: 'left',
        }}
        className={`card-text-container ${
          iframeView === 'mobile' ? 'card-text-container-mobile' : ''
        }`}
      >
        <div
          className={`card-text ${
            fontColor === 'Dark' ? 'is-dark' : ''
          } ${
            iframeView === 'mobile' ? 'card-text-mobile' : ''
          }`}
        >
          <h1
            className={`card-headline ${
              iframeView === 'mobile' ? 'card-headline-mobile' : ''
            }`}
            style={{
              fontFamily: theme[websiteTheme]?.text,
              fontSize: theme[websiteTheme][iframeViewType]?.textSize,
              letterSpacing:
                theme[websiteTheme][iframeViewType]?.textLetterSpacing,
              lineHeight: theme[websiteTheme][iframeViewType]?.textLineHeight,
            }}
          >
            {headline}
          </h1>
          <div
            className={`card-rich-text mb-2 ${
              iframeView === 'mobile'
                ? 'card-rich-text-mobile'
                : 'card-rich-text-desktop'
            }`}
          >
            {subHeadline && (
              <h3
                style={{
                  fontFamily: theme[websiteTheme]?.text,
                  fontSize: theme[websiteTheme][iframeViewType]?.subHeadingSize,
                  lineHeight:
                    theme[websiteTheme][iframeViewType]?.subHeadingLineHeight,
                  letterSpacing:
                    theme[websiteTheme][iframeViewType]
                      ?.subHeadingLetterSpacing,
                }}
              >
                {subHeadline}
              </h3>
            )}
            {textBlock && documentToReactComponents(textBlock)}
          </div>
          {ctaLabel && (
            <div
              style={{
                background: theme[websiteTheme]?.backgroundImage
                  ? theme[websiteTheme]?.backgroundImage
                  : heroCTAColor,
                fontFamily: theme[websiteTheme]?.textCTA,
                borderRadius: theme[websiteTheme]?.borderRadius ?? '0px',
                letterSpacing:
                  theme[websiteTheme][iframeViewType]?.ctaLetterSpacing,
                lineHeight: theme[websiteTheme][iframeViewType]?.ctaLineHeight,
              }}
              className={`cta-label ${
                iframeView === 'mobile' ? 'cta-label-mobile' : ''
              }`}
            >
              {ctaLabel}
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default PreviewCard
