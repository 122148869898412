import React, { Fragment, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link, useParams } from 'react-router-dom'
import ContactForm from '../../components/ContactForm/ContactForm'
import SuccessModal from '../ContactForm/SuccessModal'
import * as Msal from 'msal'
import { msalConfig } from '../../../auth/authConfig'

const ContactModal = ({ setIsShowingModal, emailSuccess, setEmailSuccess }) => {
  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()

  return (
    <Fragment>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div
          className={
            emailSuccess ? `success-modal rw-modal` : `contact-modal rw-modal`
          }
        >
          {!emailSuccess ? (
            <div
              className="w-full overflow-y-auto"
              // style={{ maxHeight: '670px' }}
            >
              <ContactForm
                setEmailSuccess={setEmailSuccess}
                close={() => setIsShowingModal(false)}
              />
            </div>
          ) : (
            <SuccessModal
              senderEmail={account.userName}
              setIsShowingModal={setIsShowingModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ContactModal
