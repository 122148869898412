import React, { useState, useEffect, useCallback } from 'react'
import UserForm from 'src/components/UserForm'
import { createUser } from '../../api/users'
import * as _ from 'lodash'
import { getAllGroups } from '../../api/groups'
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom'

const NewUserRest = () => {
  const cms = useCMS()
  const history = useHistory()

  const [groups, setGroups] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)
  const [error, setError] = useState(null)

  /**
   * Create new user using REST query.
   * @param {Object} data User form data
   */
  const createNewUser = async (data) => {
    try {
      setLoadingStatus(true)
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      let createErr = await createUser(query)
      if (createErr.errors && createErr.errors.length > 0) {
        createErr = { ...createErr, graphQLErrors: createErr.errors }
        setError(createErr)
      } else {
        setLoadingStatus(false)
        cms.alerts.info('User Created')
        history.push('/users')
      }
    } catch (e) {
      setError(e)
    }
  }

  const onSave = (data) => {
    createNewUser(data)
  }

  /**
   * Get all available groups
   */
  const getAllGroupsRest = useCallback(async () => {
    const groupsRest = await getAllGroups()
    const { data } = groupsRest
    setGroups(data.groups)
    setLoadingStatus(false)
  }, [])

  useEffect(() => {
    getAllGroupsRest()
  }, [getAllGroupsRest])

  return (
    <div className="container mx-auto grid rw-segment mt-6">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">New User</h2>
      </header>
      <div className="rw-segment-main">
        {groups.length > 0 && (
          <UserForm onSave={onSave} error={error} groups={groups} />
        )}
      </div>
    </div>
  )
}

export default NewUserRest
