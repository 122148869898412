import * as Msal from 'msal'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'
import { msalConfig } from '../../../auth/authConfig'
import { getUserByEmail } from '../../api/users'
import SupportDropDown from '../SupportDropDown/SupportDropDown'
import { ModalContext } from '../../context/ModalContext'
import { Context } from '../../../Store'
// import NVALogo from '../../static/images/logo-colour.png'
// import NVALogo from '../../static/images/EE-logo-2022-white.png'
import NVALogo from '-!svg-react-loader?name=Icon!../../static/images/EE-logo-2022-white.svg'
import DashboardIcon from '-!svg-react-loader?name=Icon!../../static/images/dashboard_icon.svg'
import Modal from '../PublishModal'
import './Header.css'
import UserDropDown from '../../components/UpdatedHeader/Navbar/UserDropDown'
import { NavList, NavMenu } from '../UpdatedHeader/UpdatedHeader.styles'
import NavItem from '../UpdatedHeader/NavItem/NavItem'

import {
  useGlobalState,
  setisCurrentUserAdmin,
  setHeaderBottomCoordinate,
} from '../../hooks/state/state'
import AdminDropDown from '../AdminDropDown/AdminDropDown'
import { getConstants } from 'src/api/userActionLogs'

const Header = ({ user }, props) => {
  //const { isAuthenticated, logIn, logOut, userMetadata, loading } = useAuth()

  const { isSaving, isShowing, savingPublishedChanges, toggle } = useContext(
    ModalContext
  )

  const [
    emergencyBannerBottomCoordinate,
    setEmergencyBannerBottomCoordinate,
  ] = useState(null)
  const [headerBottomCoordinate] = useGlobalState('headerBottomCoordinate')

  const [selectedSite] = useGlobalState('selectedSite')
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')
  const [isEmerBannerPresent] = useGlobalState('isEmerBannerPresent')

  //setting to to sidebar

  useEffect(() => {
    let sideBar = document.querySelector('div[class^="SidebarContainer"]')
    let sideBarWrapper = document.querySelector('div[class^="SidebarWrapper"]')
    let header = document.querySelector('.header-top')

    header && setHeaderBottomCoordinate(header.getBoundingClientRect().bottom)

    let emergencyBanner = document.getElementById('emergency-banner-easyeditor')
    emergencyBanner &&
      setEmergencyBannerBottomCoordinate(
        emergencyBanner.getBoundingClientRect().bottom
      )
    // reset header bottom coordinate upon resize
    window.addEventListener('resize', () => {
      header && setHeaderBottomCoordinate(header.getBoundingClientRect().bottom)
      emergencyBanner &&
        setEmergencyBannerBottomCoordinate(
          emergencyBanner.getBoundingClientRect().bottom
        )
    })

    if (isEmerBannerPresent) {
      // TODO: refactor this to use declarative logic and className, not vanilla js and inline style
      let emerElem = document.querySelector('#emergency-banner-easyeditor')
      if (emerElem) {
        emerElem.style.cssText += 'position: sticky'
      }
    } else {
      header.style.cssText = 'top: unset'
    }
    setisCurrentUserAdmin(user && user.roleId && user.roleId == 1)
    if (sideBar) {
      let sidebarHeight = `calc(100vh - ${headerBottomCoordinate}px)`
      let sideBarWrapperHeight = `calc(100vh - ${headerBottomCoordinate}px)`

      sideBar.style.cssText += `top: ${headerBottomCoordinate}px !important; height: ${sidebarHeight} !important;`
      sideBarWrapper.style.cssText += `height: ${sideBarWrapperHeight};`
    }
    // isCurrentUserAdmin
    //   ? sideBar
    //     ? ((sideBar.style.cssText += `top: ${
    //         136 + (isEmerBannerPresent ? 50 : 0)
    //       }px !important`),
    //       (sideBarWrapper.style.cssText +=
    //         'height: 92%; padding-bottom: 80px;'))
    //     : ''
    //   : sideBar
    //   ? ((sideBar.style.cssText += `top: ${
    //       71 + (isEmerBannerPresent ? 50 : 0)
    //     }px !important`),
    //     (sideBarWrapper.style.cssText +=
    //       'height : 100%; padding-bottom: 80px; '))
    //   : ''

    // removes the eventlistener on equivalent of 'ComponenetWillUnmount'
    return function cleanup() {
      window.removeEventListener('resize', () => {
        setHeaderBottomCoordinate(header?.getBoundingClientRect().bottom)
      })
    }
  })

  // setting sidebar to close when Publish Modal opens
  useEffect(() => {
    const publishButton = document.getElementById('publish-button')

    publishButton && publishButton.addEventListener('click', publishModalToggle)

    async function publishModalToggle() {
      //Check if maintenance mode is on and prevent publishing
      const response = await getConstants();
      const data = response?.data?.getConstants;
      const value = data?.find(c => c?.key === "maintenance")?.value;
      if(value && user.roleId != 1) {
        window.location.reload();
      }

      const sidebarButtonToggle = document.querySelector(
        '[aria-label="toggles cms sidebar"]'
      )
      // get url path
      let sidebar
      let pathArray = window.location.pathname.split('/')
      let pagePath = pathArray[4]

      if (pagePath === 'staff' ||
          pagePath === 'multi-location-staff' ||
          pagePath === "services" ||
          pagePath === "hours" ||
          pagePath === "about" ||
          pagePath === "home") {
        sidebar = document.querySelector('.sidebar')
        sidebar = sidebar && !sidebar.classList.contains('closed')
      } else {
        sidebar = document.querySelector(`div[class*='SidebarContainer']`)
        sidebar = sidebar?.hasAttribute('open')
      }
      sidebar && sidebarButtonToggle.click()
      toggle()
    }
  }, [])

  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState)
  const homeURL = selectedSite.siteId
    ? `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
    : '/dashboard'
  return (
    <div
      className="bg-mainNVAcolor w-full z-10 header-top p-6"
      // style={{ top: `${emergencyBannerBottomCoordinate}px` }}
    >
      <div className="flex w-full justify-between">
        <div className="flex">
          <a href={homeURL}>
            <NVALogo />
          </a>
          {isCurrentUserAdmin && (
            <div className="pl-10 flex items-center">
              <a
                className="flex flex-row text-white focus:outline-none gap-1 items-center admin-dropdown-button"
                href="/dashboard"
              >
                <DashboardIcon />
                Dashboard
              </a>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-center">
          <div>
            <button
              id="publish-button"
              className="hover:bg-white publish-button"
              // disabled={!isAlertActive ? 'disabled' : ''}
            >
              Publish
            </button>
            <Modal
              isSaving={isSaving}
              isShowing={isShowing}
              hide={toggle}
              savingPublishedChanges={savingPublishedChanges}
              user={user}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <div className="flex flex-row gap-6">
            {isCurrentUserAdmin && <AdminDropDown userId={user?.id} />}
            <SupportDropDown userId={user?.id} />
            <UserDropDown />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
