import * as Msal from 'msal'
import { Redirect } from 'react-router-dom'
import { msalConfig } from '../../../auth/authConfig'
import ReactGA from 'react-ga'
import { loginMessages } from '../../constants/messages/en'

const HomePage = () => {
  // Create the main myMSALObj instance
  // configuration parameters are located at authConfig.js
  const myMSALObj = new Msal.UserAgentApplication(msalConfig)

  const signIn = async () => {
    try {
      await myMSALObj.loginRedirect({})
      await myMSALObj.getAccount()
    } catch (err) {
      throw new Error(err)
    }
  }

  if (myMSALObj.getAccount()) {
    const userAccount = myMSALObj.getAccount()
    ReactGA.event({
      category: 'Login',
      action: `${userAccount.name} Logged in to EE`,
      label: `User Name: ${userAccount.name}`,
    })
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: { loginAccount: userAccount },
        }}
      />
    )
  }

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full md:w-1/2 flex flex-col">
        <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
          <img
            className="mx-auto w-1/2 mb-16"
            src="https://images.ctfassets.net/rt5zmd3ipxai/1jCWD6GV5tVV9kOQku8kwg/ed0b5c0b47838068eba6c101e233355e/NVA-HeaderLogo.png"
          />
          <p className="text-center text-3xl">Welcome.</p>

          <a
            href="#"
            className="bg-black text-white font-bold text-lg hover:bg-gray-700 p-2 mt-8 w-1/2 mx-auto text-center"
            onClick={async () => {
              signIn()
            }}
          >
            Log in
          </a>
          <p
            className="text-base py-4 text-center"
            style={{ color: '#a0a0a0' }}
          >
            {loginMessages.USE_CHROME_BROWSER}
          </p>
        </div>
      </div>

      <div className="w-1/2 shadow-2xl">
        <img
          className="object-cover w-full h-screen hidden md:block"
          src="https://source.unsplash.com/SNf-hZz6zOY"
        />
      </div>
    </div>
  )
}

export default HomePage
