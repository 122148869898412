// styles
import styled from 'styled-components'
import tw from 'twin.macro'

export const ApplyToAll = styled.span`
  font-family: 'open-sans-regular';
  ${tw`float-right py-2 underline text-seaweed`};
`
export const Day = styled.label`
  font-family: 'open-sans-regular';
  min-width: 32px;
  ${tw`text-sm font-normal`};
`
export const Tuesday = styled(Day)`
  padding-right: 2px;
`
export const Thursday = styled(Day)`
  padding-right: 2px;
`
export const Saturday = styled(Day)`
  padding-right: 1px;
`

export const Input = styled.input`
  font-family: 'open-sans-regular';
  ${tw`text-sm font-normal text-center placeholder-black border border-black w-80 h-30`};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: grey;
  }
  :-ms-input-placeholder {
    color: grey;
  }
`
export const Label = styled.label`
  font-family: 'open-sans-regular';
  ${tw`px-2 text-xs font-normal`};
`
export const SimpleLabel = styled.label`
  font-family: 'open-sans-regular';
  ${tw`px-0 text-xs font-normal`};
`

export const Paragraph = styled.p`
  font-family: 'open-sans-regular';
  white-space: normal;
  ${tw`py-1 text-sm font-normal`};
`

export const ParagraphSmall = styled.p`
  font-family: 'open-sans-regular';
  white-space: normal;
  ${tw`py-1 text-xs font-normal`};
`
export const HeadingText = styled.h2`
  font-family: 'GoudyOldStyleBold';
  white-space: normal;
  ${tw`text-teal-600 text-3xl`};
`

export const SectionLink = styled.div`
  width: 100%;
  /* Teal/800 */
  background: ${(props) => props.color ?? '#006F85'};
  /* Elevation/Dark Fill/3 */
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 1.5em;
  ${tw`text-white my-2`};

  :hover {
    background-color: #17353b;
  }
`
export const Select = styled.select`
  font-family: 'open-sans-regular';
  ${tw`text-sm border border-black w-80 h-30`};
`
export const MondaySelect = styled(Select)`
  margin-left: 9px;
`
export const TuesdaySelect = styled(Select)`
  margin-left: 7px;
`
export const WednesdaySelect = styled(Select)`
  margin-left: 10px;
`
export const ThursdaySelect = styled(Select)`
  margin-left: 3px;
`
export const FridaySelect = styled(Select)`
  margin-left: 25px;
`
export const SaturdaySelect = styled(Select)`
  margin-left: 19px;
`
export const SundaySelect = styled(Select)`
  margin-left: 15px;
`
export const Table = styled.table`
  ${tw`pb-3 border-collapse border-separate`}
`
export const AdditionalHours = styled(Day)`
  ${tw`pb-4`}
`
export const TextArea = styled.textarea`
  height: 150px;
  width: 100%;
  ${tw`mt-2 text-sm border border-black`};
`
