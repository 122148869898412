import { css } from 'styled-components'

const styles = css`
  .banner {
    background-color: rgb(235, 0, 0);
    padding: 10px;
    text-align: left;
    color: rgb(255, 255, 255);
    min-height: 50px;
    z-index: 10;
    width: 100%;
  }
  a {
    text-decoration: none;
    color: rgb(126, 217, 0);
    &:hover {
      color: rgb(185, 23, 218);
      text-decoration: underline;
    }
  }
  @supports (-webkit-line-clamp: 2) {
    p {
      width: fit-content;
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      /* stylelint-disable value-no-vendor-prefix */
      display: -webkit-box;
      /* stylelint-enable value-no-vendor-prefix */
      -webkit-line-clamp: 2;
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-box-orient: vertical;
      /* stylelint-enable property-no-vendor-prefix */
    }
  @media (min-width: 1024px) {
    p {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.18px;
    }
  }
`

export default styles
