import UpdatedMainLayout from '../../layouts/UpdatedMainLayout/UpdatedMainLayout'
// import UsersCell from 'src/components/UsersCell'
import UsersCellRest from 'src/components/UsersCellRest'

const UsersPage = ({ role, user }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      {/* <UsersCell /> */}
      <UsersCellRest />
    </UpdatedMainLayout>
  )
}

export default UsersPage
