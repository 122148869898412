import React, { useState, useCallback, useEffect } from 'react';
import {
  getUserActionLogsByUserUnpublished
} from './src/api/userActionLogs';
import { getUserAndGroupsByEmail, createUser } from './src/api/users';
import * as Msal from 'msal';
import { msalConfig, loginRequest } from './auth/authConfig';

export const Context = React.createContext();

const Store = ({ children }) => {
  const [isAlertActive, setIsAlertActive] = useState();
  const [user, setUser] = useState(null);

  const authClient = new Msal.UserAgentApplication(msalConfig);
  const account = authClient.getAccount();

  const isLogged = () =>{
    if (user === null){
      return false;
    }
    return true;
  };
  const createNewUser = async ({email, name, role, userId}) => {
    let date = new Date().toISOString()
    const newUser = {
      email: email,
      updatedAt: date,
      emailVerified: null,
      name: name,
      nickname: name,
      picture: "",
      userId: userId,
      roleId: role
    };
    const query = JSON.stringify(newUser).replace(/"([^"]+)":/g, '$1:');
    await createUser(query);
  };

  const getLogsRest = async () => {
    // get action logs from state

    const email = account ? account.userName : '';
    const response = await getUserActionLogsByUserUnpublished({
      email,
    });
    const data = response.data.userActionLogsByUserUnpublished;

    if (Object.keys(data).length > 0 || data.length > 0) {
      // localStorage.setItem('isUnpublished', true)
      setIsAlertActive(true);
    } else {
      setIsAlertActive(false);
    }
  };

  useEffect(() => {
    if(!account?.userName){
      return;
    }
    getLogsRest();

    async function getUserRole() {
      const foundUser = await getUserAndGroupsByEmail({
        email: account.userName,
      });
      let { data } = foundUser;
      if (data.userAndGroupsByEmail !== null) {
        setUser(data.userAndGroupsByEmail);
        return;
      }
      await createNewUser({
        email: account.userName,
        name: account.name,
        role: 2,
        userId: account.accountIdentifier
      });

      const foundRecentlyCreatedUser = await getUserAndGroupsByEmail({
        email: account.userName,
      });
      data = foundRecentlyCreatedUser?.data;
      setUser(data.userAndGroupsByEmail);
    }

    getUserRole();
  }, [])

  return (
    <Context.Provider value={{
      isAlertActive,
      setIsAlertActive,
      authClient,
      account,
      user,
      isLogged
    }}>
      {children}
    </Context.Provider>
  )
}

export default Store
