import { Form, FormError, FieldError, Label, Submit } from '@redwoodjs/forms'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import * as _ from 'lodash'
import { websiteFormMessages } from '../../constants/messages/en'

const WebsiteForm = ({ onSave, error, websiteToEdit = {} }) => {
  const [inlineFormError, setInlineFormError] = useState('')

  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    setInlineFormError('')
    if (data && data.yextId && data.name && data.siteId && data.siteURL) {
      data = {
        ...data,
        siteId: parseInt(data.siteId),
      }
      onSave(data)
    } else {
      setInlineFormError(websiteFormMessages.MANDATORY)
    }
  }

  const CustomFormError = () => {
    return (
      <div className="rw-form-error-wrapper">
        <p className="rw-form-error-title">{inlineFormError}</p>
      </div>
    )
  }

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={handleSubmit(onSubmit)} error={error}>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        {inlineFormError ? <CustomFormError /> : ''}

        <Label
          name="name"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {websiteFormMessages.NAME}
        </Label>
        <input
          name="name"
          defaultValue={!_.isEmpty(websiteToEdit) ? websiteToEdit.name : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          {...register('name')}
        />
        <span>{websiteFormMessages.HELPER_NAME}</span>
        <FieldError name="name" className="rw-field-error" />

        <Label
          name="siteURL"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {websiteFormMessages.SITE_URL}
        </Label>
        <input
          name="siteURL"
          defaultValue={!_.isEmpty(websiteToEdit) ? websiteToEdit.siteURL : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          {...register('siteURL')}
        />
        <span>{websiteFormMessages.HELPER_SITE_URL1}</span>
        <FieldError name="siteURL" className="rw-field-error" />

        <Label
          name="siteId"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {websiteFormMessages.WEBSITE_ID}
        </Label>
        <input
          name="siteId"
          defaultValue={!_.isEmpty(websiteToEdit) ? websiteToEdit.siteId : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          {...register('siteId')}
        />
        <FieldError name="siteId" className="rw-field-error" />

        <Label
          name="yextId"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          {websiteFormMessages.YEXT_ID}
        </Label>
        <input
          name="yextId"
          defaultValue={!_.isEmpty(websiteToEdit) ? websiteToEdit.yextId : ''}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          {...register('yextId')}
        />
        <FieldError name="yextId" className="rw-field-error" />

        <div className="rw-button-group">
          <Submit className="rw-button rw-button-blue">
            {!_.isEmpty(websiteToEdit) ? 'Update' : 'Save'}
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default WebsiteForm
