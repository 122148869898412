import React, { useState, useEffect } from 'react'
import { withTina, useCMS, useForm, usePlugin } from 'tinacms'
import { useLocation, Link } from 'react-router-dom'
import ArrowLeft from '-!svg-react-loader?name=Icon!../../static/images/arrow.svg'
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg'
import HoursIcon from '-!svg-react-loader?name=Icon!../../static/images/hours_icon.svg'
import AlertIcon from '-!svg-react-loader?name=Icon!../../static/images/alert_icon.svg'
import ToolsIcon from '-!svg-react-loader?name=Icon!../../static/images/tools_icon.svg'
import CarouselIcon from '-!svg-react-loader?name=Icon!../../static/images/carousel_icon.svg'
import PeopleIcon from '-!svg-react-loader?name=Icon!../../static/images/people_icon.svg'

import { hideFooterButtonStyles, updateHours } from './helper'
import './WebsitePagePage.css'
import VideoModal from '../../components/VideoModal'

import LocationDropdown from '../../components/LocationDropdown'
import Showdown from 'showdown'
import Sidebar from './Sidebar';

import {
  // HeadingText,
  SectionLink,
  Paragraph,
  ParagraphSmall,
} from './WebsitePageHoursEditForm.styles.js'

import {
  useGlobalState,
  setCurrentStaffId,
  setCurrentStaffInfo,
  setCurrentServiceId,
  setCurrentServiceInfo,
  setHomePageHeroCards,
} from '../../hooks/state/state'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'

const WebsitePageMain = ({ user }) => {
  const cms = useCMS()
  const [firstLoadDone, setFirstLoadDone] = useState(false)

  const [sidebarToggleBtn, setSidebarToggleBtn] = useState(false)
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);
  const [iframeView, setIframeView] = useState('large')
  const [showVideo, setShowVideo] = useState(false)
  let location = useLocation()
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isBannerUpdated] = useGlobalState('isBannerUpdated')
  // const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [isHoursDeleted] = useGlobalState('isHoursDeleted')
  const [currentYextId] = useGlobalState('currentYextId')
  const [isEBDeleted] = useGlobalState('isEBDeleted')
  // const [multiLocations] = useGlobalState('multiLocations')
  const [globalStaffPages] = useGlobalState('staffPages')
  const [globalServicesPages] = useGlobalState('servicesPages')
  // const [iframeLoading, setIframeLoading] = useState(true)

  const [selectedSite] = useGlobalState('selectedSite')
  const [updatedSelectedSite] = useGlobalState('updatedSelectedSite')
  const [isSelectedSiteUpdated] = useGlobalState('isSelectedSiteUpdated')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [heroCarouselCards] = useGlobalState('homePageHeroCards')

  const [staffInfo, setStaffInfo] = useState([...globalStaffPages])
  const [servicesInfo, setServicesInfo] = useState([...globalServicesPages])

  let converter = new Showdown.Converter()
  let description = isBannerUpdated
    ? descriptionUpdated
    : converter.makeHtml(descriptionInitial.text)

  let bannerObj = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    author: {
      description,
    },
  }

  let siteId = isSelectedSiteUpdated
    ? updatedSelectedSite.siteId
    : selectedSite.siteId

  let siteName = isSelectedSiteUpdated
    ? updatedSelectedSite.name
    : selectedSite.name

  //setInitialHours(initialHours)

  const initial = updatedHours[currentYextId]
    ? { ...updatedHours[currentYextId] }
    : { ...initialHours }

  const initialValues = {
    mainHours: {
      hours: updateHours(initial.hours),
      additionalHoursText: initial.additionalHoursText,
    },
    extendedHours: {
      extendedHours: updateHours(initial.c_extendedHours),
      c_extendedAdditionalHoursText: initial.c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      initial.c_extendedHours &&
      typeof initial.c_extendedHours !== 'undefined' &&
      Object.keys(initial.c_extendedHours).length > 0,
  }

  useEffect(() => {
    if (isHoursDeleted) {
      setTimeout(() => {
        sendMsg(initialValues)
      }, 100)
    }

    if (isEBDeleted) {
      setTimeout(() => {
        sendMsg({ type: 'EmergencyBanner', content: bannerObj })
      }, 100)
    }
  }, [isHoursDeleted, descriptionUpdated])

  useEffect(() => {
    setSidebarToggleBtn(
      document.querySelector('[aria-label="toggles cms sidebar"]')
    )
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'

    // only set original hero carousal cards on initial load
    if (!heroCarouselCards) {
      const homePage = selectedSiteInfo?.sitePages?.find(
        (page) => page.fields.pageType === 'Home Page'
      )

      setHomePageHeroCards(homePage?.fields?.hero?.fields?.cards || [])
    }
  }, [])

  useEffect(() => {
    const nvaDontShow = localStorage.getItem('nva-dont-show-v2')
    const isVideoViewed = sessionStorage.getItem('isVideoViewed')
    if (nvaDontShow != null) {
      const users = JSON.parse(nvaDontShow)

      if (!users.includes(user.id) && user.id) {
        setShowVideo(false)
      }
    } else if (isVideoViewed) {
      setShowVideo(false)
    } else {
      setShowVideo(true)
    }
  }, [user.id])

  useEffect(() => {
    setStaffInfo([...globalStaffPages])
    if (globalStaffPages && globalStaffPages.length == 1) {
      globalStaffPages.forEach((staff) => {
        setCurrentStaffId(staff.sys.id)
        setCurrentStaffInfo(staff)
      })
    }
  }, [globalStaffPages])

  useEffect(() => {
    setServicesInfo([...globalServicesPages])
    if (globalServicesPages && globalServicesPages.length == 1) {
      globalServicesPages.forEach((service) => {
        setCurrentServiceId(service.sys.id)
        setCurrentServiceInfo(service)
      })
    }
  }, [globalServicesPages])

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      // eslint-disable-next-line no-unused-expressions
      win?.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      if (document.getElementById('websitePreviewFrame')) {
        document.getElementById('websitePreviewFrame').style['pointer-events'] =
          'auto'
      }
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        description,
      }
      sendMsg({ type: 'EmergencyBanner', content: bannerData })
      // sendMsg(bannerObj)
      sendMsg(initialValues)
      // setIframeLoading(false)
      sendMsg({
        type: 'HOME_CAROUSEL_CARDS_UPDATED',
        content: heroCarouselCards,
      })
    })
  }

  if (!cms?.sidebar?.isOpen && !firstLoadDone && sidebarToggleBtn) {
    sidebarToggleBtn.click()
    setFirstLoadDone(true)
  }

  return (
    <div
      className="previewWrapper py-3 h-full flex flex-col"
      style={{ position: 'relative' }}
    >
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          sendOnLoadMsg={sendOnLoadMsg}
        />
        <PreviewFrame
          siteName={selectedSite.name}
          selectedSite={selectedSite.siteURL.split('#')[0]}
          // selectedSite="http://localhost:8000"
          sendOnLoadMsg={sendOnLoadMsg}
          iframeView={iframeView}
          cms={cms}
        />
      </div>

      {showVideo ? (
        <VideoModal setShowVideo={setShowVideo} userid={user.id} />
      ) : (
        ''
      )}
      <Sidebar
        setIsSidebarClosed={(val) => setIsSidebarClosed(val)}
        userGroups={user.groups}
        user={user}
        servicesInfo={servicesInfo}
        staffInfo={staffInfo}
      >
      </Sidebar>
    </div>
  )
}

export default WebsitePageMain;
