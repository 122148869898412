import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getGroupByGroupId } from '../../api/groups'
import { Link, useHistory } from 'react-router-dom'
import { deleteUserByEmail } from 'src/api/users'
import * as _ from 'lodash'
import PawLoader from '../PawLoader'
import { userMessages, websiteFormMessages } from '../../constants/messages/en'

const WebsiteRest = ({ id }) => {
  const history = useHistory()
  const onDeleteClick = async (email) => {
    if (confirm(userMessages.CONFIRM_DELETE_USER + email + '?')) {
      const deletedUser = await deleteUserByEmail(email)

      if (deletedUser.data.deleteUserByEmail.email == null)
        history.push('/dashboard')
    }
  }
  const [website, setWebsite] = useState([])

  /**
   * Get a website by email from REST API.
   */
  const getWebsiteRest = useCallback(async () => {
    let websiteObj = {}
    // get website
    const websiteRest = await getGroupByGroupId({ id })
    const { data } = websiteRest
    websiteObj = data.groupByID
    setWebsite(websiteObj)
  }, [id])

  useEffect(() => {
    getWebsiteRest()
  }, [getWebsiteRest])

  return (
    <Fragment>
      {!_.isEmpty(website) ? (
        <Fragment>
          <div className="container mx-auto grid rw-segment">
            <header className="rw-segment-header">
              <h1 className="rw-heading rw-heading-secondary text-center">
                Website {website.id} Details
              </h1>
            </header>
            <table className="rw-table">
              <tbody>
                <tr>
                  <th>Id:</th>
                  <td>{website.id}</td>
                </tr>
                <tr>
                  <th>{websiteFormMessages.NAME}</th>
                  <td>{website.name}</td>
                </tr>
                <tr>
                  <th>{websiteFormMessages.SITE_URL}</th>
                  <td>{website.siteURL}</td>
                </tr>
                <tr>
                  <th>{websiteFormMessages.WEBSITE_ID}</th>
                  <td>{website.siteId}</td>
                </tr>
                <tr>
                  <th>{websiteFormMessages.YEXT_ID}</th>
                  <td>{website.yextId}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav className="rw-button-group">
            <Link
              to={`/websites/${website.id}/edit`}
              className="rw-button rw-button-blue"
            >
              Edit
            </Link>
          </nav>
        </Fragment>
      ) : (
        <PawLoader isBigVersion />
      )}
    </Fragment>
  )
}

export default WebsiteRest
