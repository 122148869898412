import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useRef, useEffect } from 'react'

function ImageCropper({ image, updateImage, disabled }) {
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    aspect: 1 / 1,
    maxHeight: 208,
  })

  useEffect(() => {
    setIsNewImage(true)
  }, [image])

  const [isInitialLoad, setIntialLoad] = useState(true)
  const [isCropped, setIsCropped] = useState(false)
  const [isNewImage, setIsNewImage] = useState(false)

  const [imageRef, setImageRef] = useState()
  const ref = useRef()

  if (crop.width === 0) {
    setCrop({
      unit: '%',
      width: 100,
      height: 100,
      x: 0,
      y: 0,
      aspect: 1 / 1,
      maxHeight: 208,
    })
  }

  let onImageLoaded = (image) => {
    image.crossOrigin = 'Anonymous'
    setImageRef(image)
    setCrop({
      unit: '%',
      width: 100,
      height: 100,
      x: 0,
      y: 0,
      aspect: 1 / 1,
      maxHeight: 208,
    })
  }

  let onCropComplete = (crop) => {
    makeClientCrop(crop)
  }

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef, crop, 'newFile.jpeg')
      if (isCropped) {
        updateImage(croppedImage)
      }
    }
  }

  function getCroppedImg(image, crop, fileName) {
    image.crossOrigin = 'Anonymous'
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // canvas.width = crop.width
    // canvas.height = crop.height
    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
      // crop.width,
      // crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error("Canvas is empty");
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(fileUrl)

        let fileUrl = window.URL.createObjectURL(blob)
        resolve(fileUrl)
      }, 'image/jpeg')
    })
  }

  let onCropChange = (changedCrop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });

    if (!isInitialLoad) {
      const size = Math.min(
        ref.current.componentRef.clientHeight,
        ref.current.componentRef.clientWidth,
        208
      )
      if (ref.current.componentRef.clientHeight < changedCrop.height)
        changedCrop.height = ref.current.componentRef.clientHeight
      if (ref.current.componentRef.clientWidth < changedCrop.width)
        changedCrop.width = ref.current.componentRef.clientWidth

      if (
        isNewImage &&
        ref.current.componentRef.clientHeight != changedCrop.height
      ) {
        changedCrop.height = size
        setIsNewImage(false)
      }
      if (
        isNewImage &&
        ref.current.componentRef.clientWidth != changedCrop.width
      ) {
        changedCrop.width = size
        setIsNewImage(false)
      }
      setIsCropped(true)
    }

    if (isNewImage) {
      setIsCropped(true)
    }

    setCrop({ ...changedCrop })
    setIntialLoad(false)
  }

  return (
    <div className="flex flex-row flex-1">
      <ReactCrop
        ref={ref}
        src={image}
        //style={{ height: '100%' }}
        crop={crop}
        // circularCrop={false}
        // className="w-3/4"
        ruleOfThirds
        onImageLoaded={onImageLoaded}
        onComplete={onCropComplete}
        onChange={onCropChange}
        crossOrigin="Anonymous"
        disabled={disabled}
      />
      {/* {croppedImageUrl && (
      <img alt="Crop" className='max-w-sm pl-2' style={{ maxWidth: "100%"}} src={croppedImageUrl} crossOrigin="Anonymous"/>
    )} */}
    </div>
  )
}

export default ImageCropper
