import React from 'react'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import screenshot from './scrnsht.png'
import UserGroupsCellRest from 'src/components/UserGroupsCellRest'


const DashBoardPage = ({ user }) => {
  return (
    <UpdatedMainLayout user={user}>
      <UserGroupsCellRest imgSrc={screenshot} id={user?.id} user={user} />
    </UpdatedMainLayout>
  )
}

export default DashBoardPage
