import { useEffect, useState, useCallback } from 'react'
import { useMutation, useFlash } from '@redwoodjs/web'
import { Link } from 'react-router-dom'
import UserCard from '../UserCard/UserCard'
import EditUSerModal from '../EditUserModal/EditUserModal'
import SortGrid from '../SortGrid/SortGrid'
import { data } from 'autoprefixer'

const UsersList = ({ users, setIsInfoChanged }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [selecteduser, setSelectedUSer] = useState(null)
  const [name, setName] = useState('')
  const [userType, setUserType] = useState('')
  const [sortType, setSortType] = useState('')

  let handleEdit = (user) => {
    setShowEdit(true)
    setSelectedUSer(user)
  }

  let userList = users
  if (name != '' || userType != '') {
    userList = users.filter((user) => {
      if (
        name != '' &&
        user.nickname.toLowerCase().includes(name.toLowerCase())
      ) {
        return user
      } else if (userType != '') {
        if (userType == 'Admin' && user.roleId == 1) {
          return user
        } else if (userType == 'Editor' && user.roleId == 2) {
          return user
        }
      }
    })
  } else if (sortType != '') {
    if (sortType == 'Name') {
      userList = users.sort(dynamicSort('nickname'))
    } else if (sortType == 'Sites') {
      userList = users.sort(compare)
    }
  }

  function dynamicSort(property) {
    return function (a, b) {
      return a[property].localeCompare(b[property])
    }
  }
  function compare(a, b) {
    let groupA = a.groups.length
    let groupB = b.groups.length
    if (groupA > groupB) return 1
    if (groupB > groupA) return -1

    return 0
  }

  return (
    <div className="py-5">
      <SortGrid
        searchName={setName}
        searchUserType={setUserType}
        getSortType={setSortType}
      />
      <div className="flex flex-col w-11/12 m-auto">
        {userList.map((user, index) => {
          return <UserCard user={user} handleEdit={handleEdit} key={index} />
        })}
      </div>
      {showEdit ? (
        <EditUSerModal
          user={selecteduser}
          showEdit={setShowEdit}
          setIsInfoChanged={setIsInfoChanged}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default UsersList
