import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { addOrUpdateContactInfoNewValue, getLatestContactInfoUpdate } from '../../api/publishLogs'
import './WebsitePageMain.css'
import './WebsitePageContactInfoEditForm.css'
import { sendMsg } from 'src/utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

import {
  useGlobalState,
} from '../../hooks/state/state'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import Sidebar from 'src/components/Staff/Sidebar'
import MultiLocationSelection from 'src/components/MultilocationSelection/MultilocationSelection'
import PawLoader from 'src/components/PawLoader';

const HTML_TEMPLATE = `<p class="nva-para sc-ovnq1p-0 ifWPDH nva-contact-us-card__item-para left" style="margin-right: 15px; width: 68px; white-space: nowrap; line-height: 28px;">{EE_LABEL}:</p>&nbsp;<a title="{EE_LABEL} {EE_NUMBER}" role="link" href="tel:{EE_NUMBER}" class=" sc-1ux5mjm-1 vBHef phone-link" target="_self" aria-label="phone {EE_NUMBER}"><p class="sc-ovnq1p-0 ifWPDH" style="line-height: 28px;">{EE_NUMBER}</p></a>`;
const INITIAL_STATE = {
  phone: '',
  fax: '',
  text: '',
};
const RESTRICTIONS_FORM = {
  REQUIRED: 'required',
  LENGTH: 'length',
  VALID_NUMBER: 'valid_number',
};
const INITIAL_RESTRINCTIONS = {
  phone: [],
  fax: [],
  text: [],
};

const phoneUtil = PhoneNumberUtil.getInstance();
const FOURTY_SECONDS = 40000;
const delay = (delayInMilliseconds) => {
  return new Promise(resolve => setTimeout(resolve, delayInMilliseconds));
};

const WebsitePageContactInfo = ({ routingInfo, user }) => {
  const history = useHistory()
  const [iframeView, setIframeView] = useState('large')
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [selectedSite] = useGlobalState('selectedSite')
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [currentYextId] = useGlobalState('currentYextId')
  const [multiLocations] = useGlobalState('multiLocations')

  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [step, setStep] = useState('multilocation')
  const [isLoading, setIsLoading] = useState(false)
  const errorRef = useRef()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [contactInfoData, setContactInfoData] = useState({...INITIAL_STATE});
  const [restrictions, setRestrictions] = useState({...INITIAL_RESTRINCTIONS});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [initialValue, setInitialValue] = useState({...INITIAL_STATE});
  const [yextValue, setYextValue] = useState({...INITIAL_STATE});

  useEffect(() => {
    if (showErrorMessage) {
      // eslint-disable-next-line no-unused-expressions
      errorRef.current?.scrollIntoView()
    }
  }, [showErrorMessage])


  const setSaveButtonStatus = () => {
    if (restrictions?.phone?.length){
      setSaveButtonDisabled(true);
      return;
    }
    if (restrictions?.fax?.length){
      setSaveButtonDisabled(true);
      return;
    }
    if (restrictions?.text?.length){
      setSaveButtonDisabled(true);
      return;
    }
    setSaveButtonDisabled(false);
  }

  useEffect(() => {
    setSaveButtonStatus();
  }, [restrictions]);

  const resetForm = () => {
    setDataInForm({...INITIAL_STATE});
    setRestrictions({...INITIAL_RESTRINCTIONS});
    setIsFormDirty(false);
    setSaveButtonDisabled(true);
  }


  const goBack = () => {
    resetForm();
    history.push(
      `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
    );
  }

  const goToContactInfoPreview = () => {
    sendMsg({
      goToContactInfo: true,
    });
  }

  const goToContactInfoPreviewWithDelay = () => {
    setTimeout(() => {
      goToContactInfoPreview();
    }, 2000);
  }

  const sendContantInfoToPreview = () => {
    sendMsg({
      type: 'contactInfo',
      content: contactInfoData,
      template: HTML_TEMPLATE
    });
  }

  const sendOnLoadMsgOnToggle = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto';
        goToContactInfoPreview();
        if(step === 'multilocation')return;
        sendContantInfoToPreview();
    }, 2000);
  }

  const sendOnLoadMsg = () => {
    let frame = document.getElementById('websitePreviewFrame')
    if (!frame) {
      return;
    }
    frame.style['pointer-events'] = 'auto';
    setTimeout(() => {
      goToContactInfoPreview();
      if(step === 'multilocation')return;
      sendContantInfoToPreview();
    }, 2000);
  }

  const setPhoneNumber = (value) => {
    setContactInfoData({
      ...contactInfoData,
      phone: value,
    });
  }

  const setFaxNumber = (value) => {
    setContactInfoData({
      ...contactInfoData,
      fax: value,
    });
  }

  const setTextNumber = (value) => {
    setContactInfoData({
      ...contactInfoData,
      text: value,
    });
  }

  const saveChanges = async () => {

    setIsLoading(true);

    const NEW_VALUE = JSON.stringify(contactInfoData).replaceAll('"',"'");

    const OLD_VALUE = JSON.stringify(yextValue).replaceAll('"',"'");

    const input = {
      userId: routingInfo.user?.id,
      userEmail: routingInfo.account.userName,
      websiteId: selectedSite.siteId.toString(),
      yextId: currentYextId.toString(),
      websiteName: selectedSite.name,
      updateType: 'Contact Info',
      savedUpdates: [
        {
          type: 'Contact Info',
          oldValue: OLD_VALUE,
          newValue: NEW_VALUE,
        },
      ],
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
    };
    await addOrUpdateContactInfoNewValueLog(input);
    setIsLoading(false);
    setIsFormDirty(false);
    setInitialValue(contactInfoData);
    setSaveButtonDisabled(true);
    await getData();


  }

  const addOrUpdateContactInfoNewValueLog = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:');
      await addOrUpdateContactInfoNewValue(query);
    } catch (e) {
      console.error('Exc caught while addOrUpdateContactInfoNewValueLog():', e);
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    if(!phoneNumberString) {
      return '';
    }
    // revove all non-digit characters
    let phoneNumber = phoneNumberString.replace(/\D/g, '');

    if(phoneNumber.length > 10) {
      return phoneNumber.slice(0, 10);
    }

    return phoneNumber;
  }

  const setPhoneRestrictions = (phone_restrictions) => {
    setRestrictions({
      ...restrictions,
      phone: phone_restrictions,
    });
  }

  const applyPhoneRestrictions = (phoneNumber) => {
    let phone_restrictions = [];
    if(phoneNumber.length === 0) {
      phone_restrictions.push(RESTRICTIONS_FORM.REQUIRED);
    }
    if(phoneNumber.length >= 0 && phoneNumber.length < 10) {
      phone_restrictions.push(RESTRICTIONS_FORM.LENGTH);
    }
    if(phoneNumber.length >= 0 && !isAVailablePhoneNumber(phoneNumber)) {
      phone_restrictions.push(RESTRICTIONS_FORM.VALID_NUMBER);
    }
    setPhoneRestrictions(phone_restrictions);
  }

  const isAVailablePhoneNumber = (number) => {
    if (number.length < 3) {
      return false;
    }
    const numberToValid = phoneUtil.parse(number, 'US');
    if(!phoneUtil.isPossibleNumber(numberToValid)) {
      return false;
    }
    if (!phoneUtil.isValidNumber(numberToValid)) {
      return false;
    }
    return true;
  }

  const applyFaxRestrictions = (faxNumber) => {
    let fax_restrictions = [];
    if(faxNumber.length > 0 && faxNumber.length < 10) {
      fax_restrictions.push(RESTRICTIONS_FORM.LENGTH);
    }
    if(faxNumber.length > 0 && !isAVailablePhoneNumber(faxNumber)) {
      fax_restrictions.push(RESTRICTIONS_FORM.VALID_NUMBER);
    }
    setFaxRestrictions(fax_restrictions);
  }

  const setFaxRestrictions = (fax_restrictions) => {
    setRestrictions({
      ...restrictions,
      fax: fax_restrictions,
    });
  }

  const applyTextRestrictions = (textNumber) => {
    let text_restrictions = [];
    if(textNumber.length > 0 && textNumber.length < 10) {
      text_restrictions.push(RESTRICTIONS_FORM.LENGTH);
    }
    if(textNumber.length > 0 && !isAVailablePhoneNumber(textNumber)) {
      text_restrictions.push(RESTRICTIONS_FORM.VALID_NUMBER);
    }
    setTextRestrictions(text_restrictions);
  }

  const setTextRestrictions = (text_restrictions) => {
    setRestrictions({
      ...restrictions,
      text: text_restrictions,
    });
  }

  const setPhoneToForm = (phone) => {
    const number = formatPhoneNumber(phone);
    applyPhoneRestrictions(number);
    setPhoneNumber(number);
  };

  const setFaxToForm = (fax) => {
    const number = formatPhoneNumber(fax);
    applyFaxRestrictions(number);
    setFaxNumber(number);
  };

  const setTextToForm = (text) => {
    const number = formatPhoneNumber(text);
    applyTextRestrictions(number);
    setTextNumber(number);
  };

  const getLatestContactInfoUpdateRest = async () => {
    try {
      const response = await getLatestContactInfoUpdate(routingInfo.user?.id, selectedSite.siteId, currentYextId);
      return response;
    } catch (e) {
      console.error('Exc caught while getLatestContactInfoUpdateRest():', e)
    }
  }

  const formatContactInfo = (contactInfo) => {
    const contactInfoObj = JSON.parse(contactInfo.replaceAll("'", '"'));
    return {
      c_textPhone: contactInfoObj?.text,
      mainPhone: contactInfoObj?.phone,
      fax: contactInfoObj?.fax,
    };
  }

  const getContactInfoFromDB = async () => {
    const response = await getLatestContactInfoUpdateRest();

    if (!response?.data?.latestContactInfoUpdate?.length) {
      return null;
    }
    const latestContactInfoUpdate = formatContactInfo(response.data.latestContactInfoUpdate[0].newValue);
    return latestContactInfoUpdate;
  }


  const validateFormDirtyStatus = () => {
    if(contactInfoData.phone !== initialValue.phone) {
      setIsFormDirty(true);
      return;
    }
    if(contactInfoData.fax !== initialValue.fax) {
      setIsFormDirty(true);
      return;
    }
    if(contactInfoData.text !== initialValue.text) {
      setIsFormDirty(true);
      return;
    }
    setIsFormDirty(false);
  }

  useEffect(() => {
    sendContantInfoToPreview();
    validateFormDirtyStatus();
  }, [contactInfoData, initialValue]);

  const getPhoneFromYext = (phone) => {
    let number = phone.slice(-10);
    number = formatPhoneNumber(number);
    applyPhoneRestrictions(number);
    return number;
  };

  const getFaxFromYext = (fax) => {
    let number = fax.slice(-10);
    number = formatPhoneNumber(number);
    applyFaxRestrictions(number);
    return number;
  };

  const getTextFromYext = (text) => {
    let number = text.slice(-10);
    number = formatPhoneNumber(number);
    applyTextRestrictions(number);
    return number;
  };

  const setYextData = (data) => {
    const dataFromYext = {...INITIAL_STATE};
    if(data?.mainPhone) {
      dataFromYext.phone = getPhoneFromYext(data?.mainPhone);
    }
    if(data?.fax) {
      dataFromYext.fax = getFaxFromYext(data?.fax);
    }
    if(data?.c_textPhone) {
      dataFromYext.text = getTextFromYext(data?.c_textPhone);
    }
    setYextValue(dataFromYext);
  }

  const setDataInForm = (data) => {
    const dataFromYext = {...INITIAL_STATE};
    if(data?.mainPhone) {
      dataFromYext.phone = getPhoneFromYext(data?.mainPhone);
    }
    if(data?.fax) {
      dataFromYext.fax = getFaxFromYext(data?.fax);
    }
    if(data?.c_textPhone) {
      dataFromYext.text = getTextFromYext(data?.c_textPhone);
    }
    setContactInfoData(dataFromYext);
    setInitialValue(dataFromYext);
  }

  const getData = async() => {
    setIsLoading(true);
    const contactInfoFromDB = await getContactInfoFromDB();
    if(contactInfoFromDB) {
      setDataInForm(contactInfoFromDB);
    }else{
      setDataInForm(yextResponse);
    }
    setYextData(yextResponse);
    setIsLoading(false);
  }

  const getLastPublishContactInfoTime = () => {
    const dataFromLocalStorage = localStorage.getItem(`lastPublishContactInfoTime-${selectedSite.siteId}`);
    if(!dataFromLocalStorage) {
      return null;
    }
    return parseInt(dataFromLocalStorage);
  }

  const isAllowToLoad = () => {
    const lastPublishContactInfoTime = getLastPublishContactInfoTime();
    if(!lastPublishContactInfoTime) {
      return true;
    }
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastPublishContactInfoTime;
    if(timeDiff > FOURTY_SECONDS) {
      return true;
    }

    return false;
  }

  const waitFourtySeconds = async () => {
    const ContactInfoAllowedTime = getLastPublishContactInfoTime() + FOURTY_SECONDS;
    const currentTime = new Date().getTime();
    const timeDiff = ContactInfoAllowedTime - currentTime;
    if(timeDiff < 0) {
      return;
    }
    await delay(timeDiff);
  }

  const reload = () => {
    window.location.reload();
  }

  const statusToLoad = async () => {
    const allowToLoad = isAllowToLoad();
    if(allowToLoad) return;
    setIsLoading(true);
    await waitFourtySeconds();
    reload();
  }

  const init = async () => {
    await statusToLoad();
    getData();
    goToContactInfoPreviewWithDelay();
  }

  useEffect(() => {
    init();
  }, [yextResponse, currentYextId]);

  const isMultiLocation = () => {
    return multiLocations.length > 1;
  }

  const renderStep = () => {
    switch (step) {
      case 'multilocation':
        if (isMultiLocation()) {
          return (
            <MultiLocationSelection
              title={'Contact Info'}
              description={`Choose a location to update the contact information.`}
              accordionFor="multiLocationContactInfo"
              onClick={() => {
                setStep('main');
              }}
            />
          )
        } else {
          setStep('main')
          return
        }
      case 'main':
      default:
        return (
          <div className="staff-form">
            <div>
              <div id="group-title" style={{ marginBottom: '20px' }}>
                <h2 className="sidebar-header">Contact Info</h2>
                <p className="sidebar-para">
                  {`Change your location’s contact info here. These changes appear on your website and are also published across the web. Phone is a required field.`}
                </p>
                <p className="sidebar-para">
                  {`Enter only numbers, no special characters.`}
                </p>
              </div>

              <div>
                <div id="phone-number" className="h-70 mb-4">
                  <div className={`flex justify-between ${restrictions?.phone?.length ? 'text-red-500' : 'text-inherit'}`}>
                    <span>Phone *</span>
                  </div>
                  <input
                    type="text"
                    maxLength="50"
                    value={contactInfoData.phone}
                    onChange={(event) => {
                      setPhoneToForm(event.target.value);
                    }}
                    className="focus:text-black focus:outline-none"
                  />
                  <p style={{
                        color: restrictions?.phone?.find(p => RESTRICTIONS_FORM.LENGTH === p) ? 'red' : 'green',
                        fontSize: 12,
                        marginTop: 0,
                        marginLeft: 10,
                        marginBottom: 0,
                      }}
                  >{contactInfoData?.phone?.length}/10 Characters</p>
                  <p style={{
                        color: restrictions?.phone?.find(p => RESTRICTIONS_FORM.VALID_NUMBER === p) ? 'red' : 'green',
                        fontSize: 12,
                        marginTop: 0,
                        marginLeft: 10,
                        marginBottom: 0,
                      }}
                  >Insert a valid phone number</p>
                </div>
                <div id="text-number" className="h-70 mb-4">
                  <div className={`flex justify-between ${restrictions?.text?.length ? 'text-red-500' : 'text-inherit'}`}>
                    <span>Text</span>
                  </div>
                  <input
                    type="text"
                    maxLength="50"
                    value={contactInfoData.text}
                    onChange={(event) => {
                      setTextToForm(event.target.value);
                    }}
                    className="focus:text-black focus:outline-none"
                  />
                  <p
                    style={{
                      color: restrictions?.text?.find(p => RESTRICTIONS_FORM.LENGTH === p) ? 'red' : 'green',
                      fontSize: 12,
                      marginTop: 0,
                      marginLeft: 10,
                      marginBottom: 0,
                    }}
                  >{contactInfoData?.text?.length ? `${contactInfoData?.text?.length}/10 Characters` : " "}</p>
                  <p
                    style={{
                      color: restrictions?.text?.find(p => RESTRICTIONS_FORM.VALID_NUMBER === p) ? 'red' : 'green',
                      fontSize: 12,
                      marginTop: 0,
                      marginLeft: 10,
                      marginBottom: 0,
                    }}
                  >{contactInfoData?.text?.length ? `Insert a valid text number` : " "}</p>
                </div>
                <div id="fax-number" className="h-70 mb-4">
                  <div className={`flex justify-between ${restrictions?.fax?.length ? 'text-red-500' : 'text-inherit'}`}>
                    <span>Fax</span>
                  </div>
                  <input
                    type="text"
                    maxLength="50"
                    value={contactInfoData.fax}
                    onChange={(event) => {
                      setFaxToForm(event.target.value);
                    }}
                    className="focus:text-black focus:outline-none"
                  />
                  <p
                    style={{
                      color: restrictions?.fax?.find(p => RESTRICTIONS_FORM.LENGTH === p) ? 'red' : 'green',
                      fontSize: 12,
                      marginTop: 0,
                      marginLeft: 10,
                      marginBottom: 0,
                    }}
                  >{contactInfoData?.fax?.length? `${contactInfoData?.fax?.length}/10 Characters` : " "}</p>
                  <p
                    style={{
                      color: restrictions?.fax?.find(p => RESTRICTIONS_FORM.VALID_NUMBER === p) ? 'red' : 'green',
                      fontSize: 12,
                      marginTop: 0,
                      marginLeft: 10,
                      marginBottom: 0,
                    }}
                  >{contactInfoData?.fax?.length? `Insert a valid fax number` : ' '}</p>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  const getPreviewURL = (url) => {
    if (!url.includes('#')) {
      return `${url}#hours-panel-map`
    }
    return url
  }

  const getPreviewURLMultilocationHandler = () => {
    let url = selectedSite.siteURL;
    // let url = 'http://localhost:8000';
    if(!isMultiLocation()){
      return getPreviewURL(url);
    }
    if(!yextResponse?.url){
      return getPreviewURL(url);
    }

    let yextUrl = yextResponse?.url;
    if(url.endsWith('/')){
      url = url.slice(0, -1);
    }
    if(yextUrl.startsWith('/')){
      yextUrl = yextUrl.slice(1);
    }

    url = `${url}/${yextUrl}`;
    return getPreviewURL(url);
  }

  const previewSiteHandle = () => {
    const URL = getPreviewURLMultilocationHandler();
    return(
      <PreviewFrame
        siteName={selectedSite.name}
        selectedSite={URL}
        sendOnLoadMsg={sendOnLoadMsg}
        iframeView={iframeView}
      />
    );
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <div
        className="h-full py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
        }}
      >
        <DesktopMobileToggle
          setIframeView={setIframeView}
          iframeView={iframeView}
          sendOnLoadMsg={sendOnLoadMsgOnToggle}
        />{' '}
        {previewSiteHandle()}
      </div>
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={isLoading}
        saveButtonDisabled={!(isFormDirty && !saveButtonDisabled)}
        onBackClick={() => {
          goBack()
        }}
        userGroups={user.groups}
        showErrorOutline={showErrorMessage}
      >
      {isLoading && (<PawLoader/>)}
      {!isLoading && renderStep()}
      </Sidebar>
    </div>
  )
}

export default WebsitePageContactInfo
