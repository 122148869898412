import React from 'react'
import NVALogo from '../../static/images/EE-logo-2022.png'

function SuccessModal({ senderEmail, setIsShowingModal }) {
  return (
    <div className="text-center">
      <button
        type="button"
        className="hero-modal-close-button rw-modal-close-button focus:outline-none absolute top-3 right-3"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => setIsShowingModal(false)}
        style={{ transform: 'scale(1.5)' }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="flex justify-center mb-8">
        <img src={NVALogo} className="w-64" />
      </div>

      <div className="mb-12">
        <p className="font-semibold mb-4">Thank you for your request!</p>
        <p className="">
          A support associate will contact you via email with status updates.
        </p>
        <p className="">
          As always, please feel free to email{' '}
          <a
            className="text-teal-600 underline"
            href="mailto:marketing@nva.com"
          >
            marketing@nva.com
          </a>{' '}
          with any additional questions.
        </p>
      </div>
      <div>
        <button
          className="bg-teal-500 text-white p-2 w-32"
          onClick={() => setIsShowingModal(false)}
        >
          Close Window
        </button>
      </div>
    </div>
  )
}

export default SuccessModal
