import React, { useState, useEffect } from 'react'
import { msalConfig } from '../../../auth/authConfig'
import * as Msal from 'msal'
import Modal from '@material-ui/core/Modal'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'

let VideoModal = ({ setShowVideo, userid }) => {
  const [dontShow, setDontShow] = useState(false)
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (userid) {
      const nvaDontShow = localStorage.getItem('nva-dont-show-v2')
      if (nvaDontShow != null) {
        const users = JSON.parse(nvaDontShow)
        if (users.includes(userid)) {
          setDontShow(true)
        }
      }
    }
  }, [userid])

  function closeModal() {
    if (dontShow) {
      const nvaDontShow = localStorage.getItem('nva-dont-show-v2')
      if (nvaDontShow != null) {
        const users = JSON.parse(nvaDontShow)
        if (!users.includes(userid)) {
          users.push(userid)
          localStorage.setItem('nva-dont-show-v2', JSON.stringify(users))
        }
      } else {
        const users = [userid]
        localStorage.setItem('nva-dont-show-v2', JSON.stringify(users))
      }
    } else {
      sessionStorage.setItem('isVideoViewed', true)
    }
    setOpen(false)
    setShowVideo(false)
  }

  const WhiteCheckbox = withStyles({
    root: {
      color: '#e2e2e2',
      '&$checked': {
        color: '#fff',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />)

  return (
    <Modal
      open={open}
      disableBackdropClick
      onClose={() => closeModal()}
      aria-labelledby="modal-training-video"
      aria-describedby="training-video-for-new-users-in-modal"
    >
      <div className="bg-white absolute inset-1/4 w-6/12 h-7/12 flex flex-col">
        <div className="bg-nvabanner flex flex-row justify-end w-full">
          <FormGroup row>
            <FormControlLabel
              control={
                <WhiteCheckbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={dontShow}
                  onChange={(e) => setDontShow(e.target.checked)}
                  name="checkNotShow"
                />
              }
              className="text-white"
              label="Don't show this again"
            />
          </FormGroup>
          <IconButton aria-label="close modal" onClick={() => closeModal()}>
            <CloseIcon className="text-white" />
          </IconButton>
        </div>
        <div className="w-full h-full self-center">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url="https://www.youtube.com/watch?v=Ks_C5ix5jvE"
          />
        </div>
      </div>
    </Modal>
  )
}

export default VideoModal
