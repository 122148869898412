import React, { useEffect, useState, useRef } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { client, cma } from '../../../../api/src/lib/contentful'
import CarouselCards from '../../components/CarouselCards'
import NewHeroCardModal from '../../components/NewHeroCardModal'
import { createContentfulLogV2 } from 'src/api/userActionLogs'
import Sidebar from 'src/components/Staff/Sidebar'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewCard from '../../components/PreviewCard'
import { useGlobalState, setHomePageHeroCards } from '../../hooks/state/state'
import ReactGA from 'react-ga'
import { homeCarouselMessages } from '../../constants/messages/en'
import { isMasterCard } from '../../utils/heroCard'

const WebsitePageHomeCarousel = ({ user }) => {
  const errorRef = useRef()
  const [saveButtonDisabled, setSaveButtonDisable] = useState(true)
  const [isCardsReordered, setIsCardsReordered] = useState(false)
  const [isCardAdded, setIsCardAdded] = useState(false)
  const [iframeView, setIframeView] = useState('large')
  const [, setError] = useState(null)
  let history = useHistory()
  const [isSidebarClosed, setIsSidebarClosed] = useState(false)
  const [environment, setEnvironment] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [yextResponse] = useGlobalState('yextResponse')
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [heroCarouselEntryId, setHeroCarouselEntryId] = useState('')
  const [masterCards, setMasterCards] = useState([])
  const [cards, setCards] = useState([])
  const [selectedSite] = useGlobalState('selectedSite')
  const [originalCards] = useGlobalState('homePageHeroCards')
  const [isShowingRequestForm, setIsShowingRequestForm] = useState(false)
  const [previewCard, setPreviewCard] = useState()
  const [heroTags, setHeroTags] = useState([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [saving, setSaving] = useState(false)

  window.onbeforeunload = function () {
    if (isFormDirty) return ''
    else return undefined
  }

  useEffect(() => {
    async function getData() {
      try {
        let masterCardsEntry
          const homePage = selectedSiteInfo.sitePages.find(
            (page) => page.fields.pageType === 'Home Page'
          )

          await cma
            .getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE)
            .then(async (space) => {
              await space
                .getEnvironment(process.env.CONTENTFUL_ENV)
                .then(async (env) => {
                  setEnvironment(env)
                  await env
                    .getTags()
                    .then(async ({ items: masterTags }) => {
                      masterCardsEntry = await client.getEntries({
                        content_type: 'heroCard',
                        'fields.name[match]': 'MASTER-EE',
                        'fields.businessLine[in]': selectedSiteInfo.websiteType,
                        include: 5,
                      })

                      // get tags for hero cards
                      const tagNamesWithId = []
                      const currentHeroTags = (masterCardsEntry?.items || [])
                        .concat(homePage.fields?.hero?.fields?.cards)
                        .map((item) => item?.metadata?.tags)
                      currentHeroTags.forEach((tagsArr) => {
                        tagsArr.forEach((tag) => {
                          if (
                            !tagNamesWithId.find((t) => t.id === tag.sys.id)
                          ) {
                            tagNamesWithId.push({
                              id: tag.sys.id,
                              name: masterTags.find(
                                (masterTag) => masterTag.sys.id == tag.sys.id
                              )?.name,
                              selected: false,
                            })
                          }
                        })
                      })

                      setHeroTags(tagNamesWithId)
                      setHeroCarouselEntryId(homePage?.fields?.hero?.sys.id)
                      //setIsInitialLoad(false)
                    })
                    .catch((err) => {
                      console.error('Err caught while fetching tags:', err)
                    })
                })
            })
            .catch((error) => {
              alert("Connection with Contentul is not established");
            })

        const heroCards = []
        let data
        if (originalCards) {
          data = originalCards
        } else {
          const homePage = selectedSiteInfo.sitePages.find(
            (page) => page.fields.pageType === 'Home Page'
          )
          data = homePage.fields?.hero?.fields?.cards
          setHeroCarouselEntryId(homePage?.fields?.hero?.sys.id)
          setHomePageHeroCards(homePage.fields?.hero?.fields?.cards)
        }
            data.forEach((entry) => {
            if (entry && entry.fields == null) {
              return null
            }
            heroCards.push({
              id: entry.sys.id,
              name: entry.fields.name,
              headline: entry.fields.headline,
              subHeadline: entry.fields.subHeadline,
              textBlock: entry.fields.textBlock,
              fontColor: entry.fields.fontColor,
              ctaLabel: entry.fields.cta?.fields?.label,
              img: `https:${entry.fields.backgroundImage.fields.image.fields.file.url}`,
              mobileImage: entry.fields.backgroundImage.fields.mobileImage
                ? `https:${entry.fields.backgroundImage.fields.mobileImage.fields.file.url}`
                : `https:${entry.fields.backgroundImage.fields.image.fields.file.url}`,
              imgAltText: entry.fields.backgroundImage.fields.altText,
              hideCard: entry.fields.hideCard,
              tags: entry.metadata.tags.map((tag) => tag.sys.id),
            })
          })
          setCards(heroCards)
          setPreviewCard(heroCards.find((c) => !c.hideCard))

        if (masterCardsEntry?.items) {
          let carouselMasterCards = []

          // filter master cards by location
          masterCardsEntry.items
            .filter(
              (item) =>
                (!item.fields.location ||
                  item.fields.location
                    .split(',')
                    .map((s) => s.trim())
                    .includes(selectedSite.siteId.toString())) &&
                (!item.fields.cta?.fields?.url ||
                  selectedSiteInfo?.sitePages?.find(
                    (page) => page.fields.url === item.fields.cta?.fields?.url
                  ) ||
                  item.fields.name.toLowerCase().includes('pharmacy'))
            )
            .forEach((entry) => {
              if (!entry || entry.fields == null) {
                return null
              }
              carouselMasterCards.push({
                id: entry.sys.id,
                name: entry.fields.name,
                headline: entry.fields.headline,
                subHeadline: entry.fields.subHeadline,
                textBlock: entry.fields.textBlock,
                ctaLabel: entry.fields.cta?.fields?.label,
                img: `https:${entry.fields.backgroundImage?.fields?.image?.fields?.file?.url}`,
                imgAltText: entry.fields.backgroundImage?.fields?.altText,
                tags: entry.metadata.tags.map((tag) => tag.sys.id),
              })
            })
          carouselMasterCards = carouselMasterCards.filter(
            (card) =>
              !heroCards.find((existingCard) => existingCard.id === card.id)
          )
          const hiddenCards = heroCards?.filter((card) => card.hideCard)

          setMasterCards([...hiddenCards, ...carouselMasterCards])
        }
      } catch (err) {
        throw new Error(err);
      }
    }

    getData()
  }, [])

  useEffect(() => {
    if (showErrorMessage) {
      // eslint-disable-next-line no-unused-expressions
      errorRef.current?.scrollIntoView()
    }
  }, [showErrorMessage])

  const createContentfulLogRest = async (
    entryID,
    entryTitle,
    verbalDescription
  ) => {
    const input = {
      contentfulId: entryID,
      contentfulTitle: entryTitle,
      isPublished: 0,
      userId: user.id,
      verbalDescription,
      businessLine: selectedSiteInfo.websiteType,
      divisionId: yextResponse.meta?.folderId,
      updateType: 'Hero Carousel',
      websiteId: selectedSite.siteId,
      path: "/"
    }

    ReactGA.event({
      category: 'Content - Home carousel cards',
      action: `User ${user.id} Made Changes to Home carousel cards`,
      label: `Value: ${verbalDescription}`,
    })

    try {
      const query = JSON.stringify(input).replace(/"([^"]+)":/g, '$1:')
      await createContentfulLogV2(query)
    } catch (e) {
      setError(e)
    }
  }

  const sendOnLoadMsg = () => {}

  const goBack = () => {
    history.push(
      `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
    )
  }

  const reorderCards = (sourceId, destId) => {
    const sourceIndex = cards.findIndex((c) => c.id === sourceId) // find index by ID/key

    const newCards = [...cards]

    var element = newCards[sourceIndex]
    newCards.splice(sourceIndex, 1)
    newCards.splice(destId, 0, element)

    const cardToMove = element

    setCards(newCards)
    setIsFormDirty(true)
    setIsCardsReordered(true)
    setSaveButtonDisable(false)
    setPreviewCard(cardToMove)
  }

  const addCard = (item) => {
    const index = cards.findIndex((c) => c.id === item.id)
    let newCards = [...cards]

    if (index >= 0) {
      newCards[index].hideCard = false
    } else {
      let newCard = {
        ...item,
        hideCard: false,
      }
      newCards = [...newCards, newCard]
    }

    setCards(newCards)
    setPreviewCard(item)

    // remove card from masterCards
    const masterCardsCopy = [...masterCards]
    const newCardIndex = masterCardsCopy.findIndex(
      (card) => card.id === item.id
    )
    masterCardsCopy.splice(newCardIndex, 1)
    setMasterCards(masterCardsCopy)

    setIsFormDirty(true)
    setIsCardAdded(true)
    setSaveButtonDisable(false)
  }

  const hideCard = (removedCard) => {
    const newCards = [...cards]
    const currentCardIndex = newCards.findIndex(
      (card) => card.id === removedCard.id
    )
    if (isMasterCard(removedCard)) {
      newCards.splice(currentCardIndex, 1)
    } else {
      newCards[currentCardIndex].hideCard = true
    }
    setCards(newCards)
    if (newCards.length) {
      setPreviewCard(newCards.find((c) => !c.hideCard))
    } else {
      setPreviewCard(undefined)
    }

    const masterCardsCopy = [...masterCards]
    masterCardsCopy.unshift({ ...removedCard })
    setMasterCards(masterCardsCopy)

    setIsFormDirty(true)
    setSaveButtonDisable(false)
    setShowErrorMessage(false)
  }

  const updateExistingCard = async (card) => {
    await environment
      .getEntry(card.id)
      .then((entry) => {
        entry.fields.hideCard = { 'en-US': card.hideCard }

        return entry.update()
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        createContentfulLogRest(
          entry.sys.id,
          entry.fields.name['en-US'],
          `Update hideCard=${card.hideCard}`
        )
      })
      .catch((err) => {
        console.error('Err caught while updateExistingCard:', err)
      })
  }

  const saveChanges = async () => {
    setSaving(true)
    if(environment===null){
      alert("Connection with Contentul is not established");
      return;
    }
    const activeCards = cards.filter((c) => isMasterCard(c) || !c.hideCard)

    if (activeCards.length > 5) {
      setShowErrorMessage(true)
      // eslint-disable-next-line no-unused-expressions
      errorRef.current?.scrollIntoView()
      setSaving(false)
      return
    }
    // Check if reorder was done among the cards

    environment
      .getEntry(heroCarouselEntryId)
      .then(async (entry) => {
        const removedCards = entry.fields.cards['en-US'].filter(
          (originalCard) =>
            !cards.find((card) => card.id === originalCard.sys.id)
        )

        // eslint-disable-next-line no-unused-expressions
        removedCards?.forEach((card) => {
          const index = entry.fields.cards['en-US'].findIndex(
            (originalCard) => originalCard.sys.id === card.sys.id
          )
          entry.fields.cards['en-US'].splice(index, 1)
        })

        cards.forEach((card) => {
          // filter non-master card entry in original cards and update hideCard for these cards
          if (!isMasterCard(card)) {
            const originalCard = originalCards?.find(
              (c) => c.sys.id === card.id
            )

            if (originalCard && originalCard.fields.hideCard != card.hideCard) {
              updateExistingCard(card)
            }
          } else {
            // add the newly added master card to the carousel entry
            if (
              !originalCards?.find(
                (originalCard) => originalCard.sys.id === card.id
              )
            ) {
              entry.fields.cards['en-US'].push({
                sys: {
                  id: card.id,
                  linkType: 'Entry',
                  type: 'Link',
                },
              })
            }
          }
        })

        if (isCardsReordered) {
          entry.fields.cards['en-US'].sort(
            (a, b) =>
              cards.findIndex((v) => v.id === a.sys.id) -
              cards.findIndex((v) => v.id === b.sys.id)
          )
          await createContentfulLogRest(entry.sys.id, 'Carousel slides reordered')
        }

        return entry.update()
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        if (isCardAdded) {
          createContentfulLogRest(
            entry.sys.id,
            entry.fields.name['en-US'],
            'Home carousel card added'
          )
        }

        // Reset the reorder flags
        setIsCardsReordered(false)
        setIsCardAdded(false)
        setSaveButtonDisable(true)
        setIsFormDirty(false)
        setSaving(false)

        // get updated entry with linked entries
        client
          .getEntries({
            content_type: 'heroCarousel',
            include: 3,
            'sys.id': heroCarouselEntryId,
          })
          .then((updatedEntry) => {
            setHomePageHeroCards(updatedEntry.items[0].fields.cards)
          })
      })
      .catch((error) => {
        console.error(error)
        setSaving(false)
      })
  }

  const onCardClick = (id) => {
    setPreviewCard([...cards, ...masterCards].find((c) => c.id === id))
  }

  const openRequestForm = () => {
    window.open(process.env.CONTACT_CARRUSEL_SLIDE_REQUEST, '_blank').focus();
    // LINES COMENTED TO DEPRECATE THE REQUEST FORM: NVAS-1885
    // setIsShowingRequestForm(true)
  }

  const addTag = (tag) => {
    // const newTags = [...heroTags]
    // newTags.find((t) => t.id === tag.id).selected = true

    setHeroTags(
      heroTags.map((item) => ({ ...item, selected: item.id === tag.id }))
    )
  }

  const removeTag = (tag) => {
    const newTags = [...heroTags]
    newTags.find((t) => t.id === tag.id).selected = false
    setHeroTags(newTags)
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && (isCardsReordered || isCardAdded)}
        message={() => homeCarouselMessages.CONFIRM_LOSE_UNSAVED_CHANGES}
      />
      <div
        className="py-3 flex flex-col"
        style={{
          paddingLeft: `${isSidebarClosed ? 0 : '425px'} `,
          WebkitTransition: 'padding-left 150ms ease-out',
          transition: 'padding-left 150ms ease-out',
          // height: 'calc(100% - 92px)',
        }}
      >
        <div className="flex flex-col h-full">
          <DesktopMobileToggle
            setIframeView={setIframeView}
            iframeView={iframeView}
            sendOnLoadMsg={sendOnLoadMsg}
          />
          <div>
            <div
              className="mx-5 shadow-outer bg-white flex-1 overflow-y-auto"
              style={{
                alignSelf: 'center',
                maxWidth: '1600px',
              }}
            >
              <div
                className="pt-5 px-4 pb-3"
                style={{ fontWeight: 600, fontSize: '24px' }}
              >
                Current Slide Preview:
              </div>
              {previewCard && (
                <PreviewCard card={previewCard} iframeView={iframeView} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        width={425}
        height={'100vh'}
        isClosed={(val) => setIsSidebarClosed(val)}
        saveChanges={saveChanges}
        saving={saving}
        saveButtonDisabled={saveButtonDisabled}
        onBackClick={() => {
          goBack()
        }}
        userGroups={user.groups}
        showErrorOutline={showErrorMessage}
      >
        <CarouselCards
          data={cards}
          masterCards={masterCards}
          reorderCards={reorderCards}
          addCard={addCard}
          hideCard={hideCard}
          onCardClick={onCardClick}
          openRequestForm={openRequestForm}
          previewCard={previewCard}
          heroTags={heroTags}
          addTag={addTag}
          removeTag={removeTag}
          showErrorMessage={showErrorMessage}
          errorRef={errorRef}
        />
      </Sidebar>
      {isShowingRequestForm && (
        <NewHeroCardModal setIsShowingModal={setIsShowingRequestForm} />
      )}
    </div>
  )
}

export default WebsitePageHomeCarousel
