import React, { useState } from 'react'
import SimpleDragItem from 'src/components/SimpleDragItem/SimpleDragItem'
import SimpleListItem from 'src/components/SimpleListtem/SimpleListItem'
import CustomSwitch from 'src/components/CustomSwitch/CustomSwitch'
import { isMasterCard } from '../../utils/heroCard'

const CarouselCards = (props) => {
  const [showMyCardsToggle, setShowMyCardsToggle] = useState(false)

  var enterTarget = null

  const onDragEnter = (ev) => {
    if (ev.target.closest('.drag-container')) {
      enterTarget = ev.target
      ev.target.closest('.drag-container').classList.add('showDropAreas-green')
    }
  }

  const onDragLeave = (ev) => {
    if (
      ev.target.closest('.drag-container') &&
      enterTarget == ev.target.closest('.drag-container')
    ) {
      ev.target
        .closest('.drag-container')
        .classList.remove('showDropAreas-green')
    }
  }

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    ev.target.closest('.drag-container').classList.remove('showDropAreas-green')
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderCards(sourceKey, destKey)
  }

  const moveOnClick = (sourceKey, destKey) => {
    if (destKey >= 0 && destKey < props?.data.length) {
      props.reorderCards(sourceKey, destKey)
    }
  }

  const CloseIcon = () => (
    <svg
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      style={{ display: 'inline' }}
    >
      <path
        d="m9.72504.282539c-.2925-.29249976-.765-.29249976-1.0575 0l-3.6675 3.660001-3.6675-3.667501c-.2925-.2924999-.765001-.2924999-1.057501 0-.2924999.2925-.2924999.765001 0 1.057501l3.667501 3.6675-3.667501 3.6675c-.2924999.2925-.2924999.765 0 1.0575.2925.29246.765001.29246 1.057501 0l3.6675-3.6675 3.6675 3.6675c.2925.29246.765.29246 1.0575 0 .29246-.2925.29246-.765 0-1.0575l-3.6675-3.6675 3.6675-3.6675c.28496-.285.28496-.765001 0-1.050001z"
        fill="#000"
      />
    </svg>
  )

  const selectedTags = props.heroTags?.filter((t) => t.selected)
  // filter master cards based on tags selection
  let filteredMasterCards =
    selectedTags?.length > 0
      ? props.masterCards.filter((c) =>
          selectedTags.map((t) => t.id).every((t) => c.tags.includes(t))
        )
      : props.masterCards

  // filter master cards based on showMyCardsToggle
  if (showMyCardsToggle) {
    filteredMasterCards = filteredMasterCards.filter((c) => !isMasterCard(c))
  }

  const activeCards = props.data.filter((c) => isMasterCard(c) || !c.hideCard)

  return (
    <div className="hero-cards-container">
      <div>
        <div id="group-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Home Page Carousel</h2>
          <p className="sidebar-para">
            Click on the slide options below to preview the full-size slide.
            Drag and drop the slides to re-order them in your carousel. Select
            the three dots menu to remove a slide from the carousel. Scroll down
            to see a library of available slides and click the “+” to add to
            your carousel.
          </p>
        </div>
        <div className="pb-5">
          <span
            onClick={props.openRequestForm}
            style={{ color: '#006F85', cursor: 'pointer', fontWeight: 700 }}
          >
            Click here to request a custom slide.
          </span>
        </div>
        <div id="group-groups" style={{ marginBottom: '40px' }}>
          <h6 className="text-lg font-semibold mb-2">Current Selections:</h6>
          {props.data.map((item, key) => {
            if (item) {
              return (
                <SimpleDragItem
                  key={key}
                  keyIndex={key}
                  itemKey={item.id}
                  item={item}
                  title={item.headline}
                  onDragOver={onDragOver}
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  moveOnClick={moveOnClick}
                  lastItemKey={props.data.length - 1}
                  hideRemove
                  hideEdit
                  hideHide={activeCards.length === 1}
                  onHideClick={() => props.hideCard(item)}
                  img={item.img}
                  imgAltText={item.imgAltText}
                  onEditClick={props.onCardClick}
                  isSelected={props.previewCard?.id === item.id}
                />
              )
            }
          })}
        </div>
        {props.masterCards.length ? (
          <div id="hero-cards-available" style={{ marginBottom: '40px' }}>
            <h6 className="text-lg font-semibold mb-2">Available Cards:</h6>
            {/* <div className="flex">
              <CustomSwitch
                checked={showMyCardsToggle}
                onClick={() => {
                  setShowMyCardsToggle(!showMyCardsToggle)
                }}
                className="mb-3 mr-3"
              />
              <div>My Cards Only</div>
            </div> */}
            {/* <div className="font-bold">Filter by Category:</div> */}
            <div className="my-4 flex overflow-x-scroll pb-2 hero-cards-tags flex-wrap gap-y-3">
              <div
                key={'custom-slide'}
                style={{
                  background: showMyCardsToggle ? '#07AAB1' : '#D3F6F8',
                  padding: '6px 12px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  setShowMyCardsToggle(!showMyCardsToggle)
                  props.addTag({ id: null })
                }}
                className="mr-3 flex"
              >
                My Custom Slides
                {showMyCardsToggle && (
                  <span
                    className="ml-2"
                    onClick={(ev) => {
                      setShowMyCardsToggle(!showMyCardsToggle)
                      ev.stopPropagation()
                    }}
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>
              {props.heroTags.map((tag) => (
                <div
                  key={tag.id}
                  style={{
                    background: tag.selected ? '#07AAB1' : '#D3F6F8',
                    padding: '6px 12px',
                    cursor: 'pointer',
                    borderRadius: '8px',
                  }}
                  onClick={() => {
                    props.addTag(tag)
                    setShowMyCardsToggle(false)
                  }}
                  className="mr-3 flex"
                >
                  {tag.name}
                  {tag.selected && (
                    <span
                      className="ml-2"
                      onClick={(ev) => {
                        props.removeTag(tag)
                        ev.stopPropagation()
                      }}
                    >
                      <CloseIcon />
                    </span>
                  )}
                </div>
              ))}
            </div>

            {filteredMasterCards.map((item, key) => {
              return (
                <SimpleListItem
                  key={key}
                  itemKey={item.id}
                  item={item}
                  title={item.headline}
                  img={item.img}
                  imgAltText={item.imgAltText}
                  onPlusClick={() => props.addCard(item)}
                  onClick={props.onCardClick}
                  isSelected={props.previewCard?.id === item.id}
                />
              )
            })}
            {selectedTags.length > 0 && filteredMasterCards.length === 0 ? (
              <div>
                No cards matching these filters. Please remove one or more to
                update results.
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {props.showErrorMessage && (
        <div
          className="text-center font-semibold mt-2"
          style={{ color: '#DC2626', fontSize: '17px' }}
          ref={props.errorRef}
        >
          Your changes were not saved. A maximum of 5 cards are allowed in the
          carousel. Please remove one or more cards.
        </div>
      )}
    </div>
  )
}

export default CarouselCards
