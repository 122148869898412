import React, { useState, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PawLoader from 'src/components/PawLoader/PawLoader'
// import { useQuery } from '@redwoodjs/web'
import { getUserByEmail, createUser } from '../src/api/users'
import { Context } from '../Store';

const PrivateRoute = ({ account, component: Component, ...rest }) => {
  const {user} = useContext(Context);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // useParams is not working and not sure why
  const editId =
    rest &&
    rest.computedMatch &&
    rest.computedMatch.params &&
    parseInt(rest.computedMatch.params.id);

  if (loading) {
    return <PawLoader isBigVersion />
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          account ? (
            <Component
              user={user}
              userId={user && user?.id}
              editId={editId || user?.id}
              role={user && user?.roleId}
              {...props}
              account={account}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )
  }
}

export default PrivateRoute
