// import UsersLayout from 'src/layouts/UsersLayout'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
// import NewUser from 'src/components/NewUser'
import NewUserRest from 'src/components/NewUserRest'

import { Link } from 'react-router-dom'

const NewUserPage = ({ role, userid, user }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout user={user}>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout user={user}>
      {/* <NewUser /> */}
      <Link
        to={'/users'}
        className="bg-teal-500 text-white p-2 cursor-pointer px-4 py-1 ml-3"
      >Back</Link>
      <NewUserRest id={userid} />
    </UpdatedMainLayout>
  )
}

export default NewUserPage
