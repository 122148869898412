/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import UserActionLog from 'src/components/UserActionLog'
import {
  getUserActionLogsByUser,
  deleteActionLogById,
  updateUserActionLogs,
  updateContentfulLogAsPublishedBySiteID,
  getUnpublishedContentfulLogsByUserIDAndSiteIdV2,
  getUnpublishedContentfulLogsByUserID,
  updateContentfulLog,
  getUserActionLogs,
} from '../../api/userActionLogs'
import { getUserById } from '../../api/users'

import { useCMS } from 'tinacms'
import { prepareLogHelper } from '../UserActionLog/helper'
import { parseUserActionLogChanges, formatEmergencyBanner } from './helper'
import * as _ from 'lodash'
import { prepareYextDataForUpdate } from './helper'
import { ModalContext } from '../../context/ModalContext'
import * as Promise from 'bluebird'
import { Context } from '../../../Store'
import MaterialTable from 'material-table'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {
  setIsBannerUpdated,
  setIsEBDeleted,
  setIsHoursDeleted,
  setIsHoursUpdated,
  setUpdateBannerMsg,
  setUpdatedHours,
  useGlobalState,
} from 'src/hooks/state/state'
import { indexOf } from 'lodash'
import ReactGA from 'react-ga'
import { userMessages } from '../../constants/messages/en'
import {
  deletePublishLogById,
  getLatestEBUpdate,
  getLatestHOOUpdate,
  getLatestHoursUpdates,
  getlatestHoursUpdatesByPublishId,
  getlatestContactInfoUpdatesByPublishId,
  getUnpublishedLogsByUser,
  getUnpublishedLogsByUserIdAndSiteId,
  getlatestEBUpdatesByPublishId,
  updatePublished,
  updateEmailSent,
  getUnpublishedLogsByUserAndSiteId,
  updatePublishedByUserIdAndSiteId,
} from 'src/api/publishLogs'
import PawLoader from '../PawLoader/PawLoader'

const UserActionLogsByUserCellRest = ({ email, user }) => {
  const [logsForTableWithChanges, setLogsForTableWithChanges] = useState([])
  const [publishLogs, setPublishLogs] = useState([])
  const [allLogsToUpdate, setAllLogsToUpdate] = useState([])
  const [contentfulLogs, setContentfulLogs] = useState()
  const [isLoading, setLoadingStatus] = useState(true)
  const cms = useCMS()
  const { toggle, savingPublishedChanges } = useContext(ModalContext)
  const {setIsAlertActive} = useContext(Context)
  const [websitePublishData, setWebsitePublishData] = useState('')
  const [siteURL, setSiteURL] = useState()
  const [currYextId, setYextId] = useState()
  const [websiteStatus, setWebsiteStatus] = useState()
  const [userData, setUserData] = useState()
  const [yextResponse] = useGlobalState('yextResponse')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo');
  const [orchestratorSiteInfo] = useGlobalState('orchestratorSiteInfo');
  let { siteId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [globalCurrentYextId] = useGlobalState('currentYextId')

  const timerLength = 5

  const getUserRestAndYextData = useCallback(async () => {
    const id = user.id
    const usersRest = await getUserById({ id })
    const { data } = usersRest
    const userObj = data.userByID
    setUserData(userObj)
    const foundYextId = userObj.groups.filter((group) => siteId == group.siteId)
    if (
      foundYextId &&
      foundYextId.length > 0 &&
      foundYextId[0] &&
      foundYextId[0].siteURL &&
      foundYextId[0].siteURL
    ) {
      setSiteURL(foundYextId[0].siteURL.replace(/#.*$/, ''))
      const validYextId =
        foundYextId[0].yextId.toString().length < 4
          ? '0' + foundYextId[0].yextId
          : foundYextId[0].yextId
      setYextId(validYextId)
    }
  })

  /**
   * Remove publish log entry from the db.
   *
   * @param {Number} rowDataId publish log id
   */
  const undoPublishLog = async (rowDataId, updateType) => {
    if (confirm(userMessages.CONFIRM_DELETE_ENTRY)) {
      try {
        // delete log entry
        const result = await deletePublishLogById(parseInt(rowDataId, 10))

        // if deleted, refetch logs and display
        if (result.data.deletePublishLogById.numRows == 1) {
          // Go back to /main
          if (
            location.pathname.includes('/about') ||
            location.pathname.includes('/hours') ||
            location.pathname.includes('/multi-location-hours')
          ) {
            history.goBack()
          }

          await getPublishLogsRest()
          setIsAlertActive(false)

          if (updateType && updateType === 'Emerg Banner') {
            const response = await getLatestEBUpdate(userData.id, siteId)
            if (response.data.latestEBUpdate[0]) {
              setIsEBDeleted(true)
              setIsBannerUpdated(true)
              setUpdateBannerMsg(
                response.data.latestEBUpdate[0].newValue.replace(/\\/g, '')
              )
            } else {
              // Default to original yext data
              setIsEBDeleted(true)
              setIsBannerUpdated(true)
              setUpdateBannerMsg(yextResponse.c_websiteEmergencyBanner)
            }
          } else {
            const hoursContent = {
              hours: JSON.parse(JSON.stringify(yextResponse.hours)),
              additionalHoursText: yextResponse.additionalHoursText,
              c_extendedHours: yextResponse.c_extendedHours
                ? JSON.parse(JSON.stringify(yextResponse.c_extendedHours))
                : {},
              c_extendedAdditionalHoursText:
                yextResponse.c_extendedAdditionalHoursText,
            }

            const hooResponse = await getLatestHoursUpdates(
              userData.id,
              siteId,
              globalCurrentYextId
            )
            const toggleResponse = await getLatestHOOUpdate(
              'Ext Hrs',
              'toggle',
              null,
              userData.id,
              siteId,
              globalCurrentYextId
            )
            const haveExtendedHours =
              toggleResponse.data.latestHOOUpdate &&
              toggleResponse.data.latestHOOUpdate.length > 0
                ? toggleResponse.data.latestHOOUpdate[0].newValue == 'false'
                  ? false
                  : true
                : yextResponse.c_extendedHours
                ? true
                : false

            if (hooResponse.data.latestHoursUpdates.length > 0) {
              hooResponse.data.latestHoursUpdates.forEach((item) => {
                if (item.type == 'Hours') {
                  switch (item.hoo_fieldName) {
                    case 'interval':
                      let intervals = []

                      item.openIntervals.map((interval) =>
                        intervals.push({
                          start: interval.newStart,
                          end: interval.newEnd,
                        })
                      )

                      hoursContent.hours[`${item.hoo_day}`]
                        ? !hoursContent.hours[`${item.hoo_day}`].isClosed
                          ? (hoursContent.hours[
                              `${item.hoo_day}`
                            ].openIntervals = intervals)
                          : ''
                        : (hoursContent.hours[`${item.hoo_day}`] = {
                            openIntervals: intervals,
                          })
                      break
                    case 'status':
                      hoursContent.hours[`${item.hoo_day}`]
                        ? item.newValue == 'Closed'
                          ? (hoursContent.hours[`${item.hoo_day}`] = {
                              isClosed: item.newValue == 'Open' ? false : true,
                            })
                          : (hoursContent.hours[`${item.hoo_day}`].isClosed =
                              item.newValue == 'Open' ? false : true)
                        : (hoursContent.hours[`${item.hoo_day}`] = {
                            isClosed: item.newValue == 'Open' ? false : true,
                          })

                      break
                    case 'text':
                      hoursContent.additionalHoursText = item.newValue
                      break
                    default:
                      break
                  }
                } else if (item.type == 'Ext Hrs' && haveExtendedHours) {
                  switch (item.hoo_fieldName) {
                    case 'interval':
                      let intervals = []

                      item.openIntervals.map((interval) =>
                        intervals.push({
                          start: interval.newStart,
                          end: interval.newEnd,
                        })
                      )

                      hoursContent.c_extendedHours[`${item.hoo_day}`]
                        ? !hoursContent.c_extendedHours[`${item.hoo_day}`]
                            .isClosed
                          ? (hoursContent.c_extendedHours[
                              `${item.hoo_day}`
                            ].openIntervals = intervals)
                          : ''
                        : (hoursContent.c_extendedHours[`${item.hoo_day}`] = {
                            openIntervals: intervals,
                          })

                      break
                    case 'status':
                      hoursContent.c_extendedHours[`${item.hoo_day}`]
                        ? item.newValue == 'Closed'
                          ? (hoursContent.c_extendedHours[`${item.hoo_day}`] = {
                              isClosed: item.newValue == 'Open' ? false : true,
                            })
                          : (hoursContent.c_extendedHours[
                              `${item.hoo_day}`
                            ].isClosed = item.newValue == 'Open' ? false : true)
                        : (hoursContent.c_extendedHours[`${item.hoo_day}`] = {
                            isClosed: item.newValue == 'Open' ? false : true,
                          }).openIntervals
                      break
                    case 'text':
                      hoursContent.c_extendedAdditionalHoursText = item.newValue
                      break
                    default:
                      break
                  }
                }
              })

              if (!haveExtendedHours) {
                hoursContent.c_extendedHours = {}
              }
            }

            setIsHoursUpdated(true)
            setUpdatedHours(hoursContent)
            setIsHoursDeleted(true)
          }

          ReactGA.event({
            category: 'Content',
            action: `${userData?.userByID?.name} Deleted a saved entry for site ${siteId}`,
            label: `User ID: ${user.id}`,
          })
          return cms.alerts.success('Action log entry succesfully discarded!')
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  /**
   * Remove action log entry for the db.
   *
   * @param {Number} rowDataId action log id
   */
  const undoActioLogCallback = async (rowDataId, rowData) => {
    if (confirm(userMessages.CONFIRM_DELETE_ENTRY)) {
      try {
        // delete log entry
        const deletedActionLogEntry = await deleteActionLogById(
          parseInt(rowDataId, 10)
        )

        // if deleted, refetch logs and display
        if (deletedActionLogEntry.data.deleteActionLogById == null) {
          // Go back to /main

          if (
            location.pathname.includes('/about') ||
            location.pathname.includes('/hours')
          ) {
            history.goBack()
          }

          await getLogsRest()
          setIsAlertActive(false)

          if (
            rowData &&
            rowData.update &&
            rowData.update === 'Emergency Banner'
          ) {
            const response = await getUserActionLogs()
            const eblogs = response.data.userActionLogs.filter((log) => {
              return (
                log.websiteID == siteId &&
                log.isEntryPublished == 0 &&
                log.userID == userData.id &&
                log.contentfulEntryName.includes('oldEmergencyBanner')
              )
            })

            if (eblogs.length > 0) {
              eblogs.reverse()
              // Only refresh global state when the deleted EB update is the most recent one
              if (eblogs[0].id < rowDataId) {
                const bannerObj = formatEmergencyBanner(
                  eblogs[0].contentfulEntryName.trim(),
                  '%',
                  eblogs[0].id
                )

                if (bannerObj.modifiedEmergencyBanner) {
                  setIsEBDeleted(true)
                  setIsBannerUpdated(true)
                  setUpdateBannerMsg(
                    typeof bannerObj.modifiedEmergencyBanner !== 'undefined'
                      ? bannerObj.modifiedEmergencyBanner.replace(/\\/g, '')
                      : ''
                  )
                }
              }
            } else {
              // Default to original yext data
              setIsEBDeleted(true)
              setIsBannerUpdated(true)
              setUpdateBannerMsg(yextResponse.c_websiteEmergencyBanner)
            }
          } else {
            const response = await getUserActionLogs()
            const hoologs = response.data.userActionLogs.filter((log) => {
              return (
                log.websiteID == siteId &&
                log.isEntryPublished == 0 &&
                log.userID == userData.id &&
                !log.contentfulEntryName.includes('oldEmergencyBanner')
              )
            })

            const hoursContent = {
              hours: { ...yextResponse.hours },
              additionalHoursText: yextResponse.additionalHoursText,
              c_extendedHours: { ...yextResponse.c_extendedHours },
              c_extendedAdditionalHoursText:
                yextResponse.c_extendedAdditionalHoursText,
            }

            if (hoologs && hoologs.length > 0) {
              // Loop through the logs and update hours from earliest to most recent
              for (let log of hoologs) {
                if (log.contentfulEntryName.includes('originalHours')) {
                  // parse hours and additional text to yext format
                  const hoursObj = parseUserActionLogChanges(
                    log.contentfulEntryName.trim(),
                    '^^',
                    log.id
                  )

                  if (hoursObj.modifiedHoursTable) {
                    hoursObj.modifiedHoursTable.map((item) => {
                      hoursContent.hours[item.day].isClosed =
                        item.isClosedNew == 'true' ? true : false
                      if (!hoursContent.hours[item.day].openIntervals) {
                        hoursContent.hours[item.day].openIntervals = [
                          { start: item.startNew, end: item.endNew },
                        ]
                      } else {
                        hoursContent.hours[item.day].openIntervals[0].start =
                          item.startNew
                        hoursContent.hours[item.day].openIntervals[0].end =
                          item.endNew
                      }
                    })
                  }
                }
              }

              // Loop through the logs and update extended hours from earliest to most recent
              for (let log of hoologs) {
                if (log.contentfulEntryName.includes('originalExtendedHours')) {
                  // parse hours and additional text to yext format
                  const extHoursObj = parseUserActionLogChanges(
                    log.contentfulEntryName.trim(),
                    '^^',
                    log.id
                  )

                  if (extHoursObj.modifiedExtendedHoursTable) {
                    if (Object.keys(hoursContent.c_extendedHours).length == 0) {
                      // init ext hours if yext has no entry for it and it has entries in publish logs
                      const initDayValue = {
                        isClosed: true,
                        openIntervals: [
                          {
                            start: '00:00',
                            end: '23:59',
                          },
                        ],
                      }

                      const initHours = {
                        monday: initDayValue,
                        tuesday: initDayValue,
                        wednesday: initDayValue,
                        thursday: initDayValue,
                        friday: initDayValue,
                        saturday: initDayValue,
                        sunday: initDayValue,
                      }

                      hoursContent.c_extendedHours = JSON.parse(
                        JSON.stringify(initHours)
                      )
                    }

                    extHoursObj.modifiedExtendedHoursTable.map((item) => {
                      hoursContent.c_extendedHours[item.day].isClosed =
                        item.isClosedNew == 'true' ? true : false
                      if (
                        !hoursContent.c_extendedHours[item.day].openIntervals
                      ) {
                        hoursContent.c_extendedHours[item.day].openIntervals = [
                          { start: item.startNew, end: item.endNew },
                        ]
                      } else {
                        hoursContent.c_extendedHours[
                          item.day
                        ].openIntervals[0].start = item.startNew
                        hoursContent.c_extendedHours[
                          item.day
                        ].openIntervals[0].end = item.endNew
                      }
                    })
                  }
                }
              }

              // Reverse the array so that the latest is first
              hoologs.reverse()

              // Get the first log that contains haveExtendedHours value
              for (let log of hoologs) {
                if (log.contentfulEntryName.includes('haveExtendedHours')) {
                  // parse hours and additional text to yext format
                  const haveExtendedHoursObj = parseUserActionLogChanges(
                    log.contentfulEntryName.trim(),
                    '^^',
                    log.id
                  )

                  if (
                    haveExtendedHoursObj.modifiedHaveExtendedHours === 'false'
                  ) {
                    hoursContent.c_extendedHours = {}
                  }
                  break
                }
              }

              // Get the first log that contains hours text
              for (let log of hoologs) {
                if (log.contentfulEntryName.includes('oldmsg')) {
                  // parse hours and additional text to yext format
                  const textObj = parseUserActionLogChanges(
                    log.contentfulEntryName.trim(),
                    '^^',
                    log.id
                  )

                  if (textObj.modifiedAdditionalText) {
                    hoursContent.additionalHoursText =
                      textObj.modifiedAdditionalText
                  }
                  break
                }
              }

              // Get the first log that contains extended hours text
              for (let log of hoologs) {
                if (log.contentfulEntryName.includes('oldExtendedmsg')) {
                  // parse hours and additional text to yext format
                  const extTextObj = parseUserActionLogChanges(
                    log.contentfulEntryName.trim(),
                    '^^',
                    log.id
                  )

                  if (extTextObj.modifiedExtendedAdditionalText) {
                    hoursContent.c_extendedAdditionalHoursText =
                      extTextObj.modifiedExtendedAdditionalText
                  }
                  break
                }
              }
            }

            setIsHoursUpdated(true)
            setUpdatedHours(hoursContent)
            setIsHoursDeleted(true)
          }

          ReactGA.event({
            category: 'Content',
            action: `${userData?.userByID?.name} Deleted a saved entry for site ${siteId}`,
            label: `User ID: ${user.id}`,
          })
          return cms.alerts.success('Action log entry succesfully discarded!')
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  useEffect(() => {
    checkSiteStatus()
  }, [])

  //Build EB object to push to yext

  let buildEBObj = async (publishLogId) => {
    const ebResponse = await getlatestEBUpdatesByPublishId(publishLogId)
    if (
      ebResponse.data.latestEBUpdatesByPublishId != null &&
      ebResponse.data.latestEBUpdatesByPublishId.length > 0
    ) {
      return {
        c_websiteEmergencyBanner:
          ebResponse.data.latestEBUpdatesByPublishId[0].newValue,
      }
    } else {
      return null
    }
  }

  //build hours object to push to yext

  let buildHoursObj = (log, toggleResponse) => {
    let yextUpdateArray = []
    //hours
    const hours = log.filter(
      (item) => item.type == 'Hours' && item.hoo_fieldName !== 'text'
    )

    const weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ]

    const oldHolidayHours =
      yextResponse.holidayHours ?? yextResponse.hours.holidayHours ?? []

    if (hours.length > 0) {
      let hoursObj = {}
      let holidayHours = []
      hours.map((interval) => {
        let hoursArray = []
        if (interval.hoo_fieldName == 'status') {
          if (weekdays.includes(interval.hoo_day)) {
            hoursObj[interval.hoo_day]
              ? interval.newValue == 'Closed'
                ? (hoursObj[interval.hoo_day] = {
                    isClosed: interval.newValue == 'Open' ? false : true,
                  })
                : (hoursObj[interval.hoo_day].isClosed =
                    interval.newValue == 'Open' ? false : true)
              : (hoursObj[interval.hoo_day] = {
                  isClosed: interval.newValue == 'Open' ? false : true,
                  openIntervals: [{ start: '00:00', end: '23:59' }],
                })
          } else {
            const hh = holidayHours.find((d) => d.date === interval.hoo_day)
            hh
              ? (hh.isClosed = true)
              : interval.newValue == 'Closed'
              ? holidayHours.push({
                  date: interval.hoo_day,
                  isClosed: true,
                })
              : null
          }
        } else if (interval.hoo_fieldName != 'holiday') {
          interval.openIntervals.map((intervalHours) => {
            hoursArray.push({
              start: intervalHours.newStart,
              end: intervalHours.newEnd,
            })
          })
          if (weekdays.includes(interval.hoo_day)) {
            hoursObj[interval.hoo_day]
              ? !hoursObj[interval.hoo_day].isClosed
                ? (hoursObj[interval.hoo_day].openIntervals = hoursArray)
                : ''
              : (hoursObj[interval.hoo_day] = { openIntervals: hoursArray })
          } else {
            const hh = holidayHours.find((d) => d.date === interval.hoo_day)
            hh
              ? (holidayHours[
                  holidayHours.indexOf((d) => d.date === interval.hoo_day)
                ] = { date: interval.hoo_day, openIntervals: hoursArray })
              : holidayHours.push({
                  date: interval.hoo_day,
                  openIntervals: hoursArray,
                })
          }
        } else if (interval.hoo_fieldName == 'holiday') {
          const indexOfElement = oldHolidayHours.findIndex(
            (item) => item.date == interval.hoo_day
          )
          indexOfElement > -1 && oldHolidayHours.splice(indexOfElement, 1)
        }
      })


      const combinedHolidayHours = holidayHours.reduce((tmp, x) => {
        const found = tmp.find(y => y.date == x.date);
        if (found) {
          if(x.openIntervals){
            found.openIntervals = x.openIntervals;
          }
          found.isClosed = x.isClosed
        } else {
            tmp.push(x);
        }
        return tmp;
      }, oldHolidayHours);

      combinedHolidayHours.forEach((element) => {
        if (element.isClosed) {
          delete element.openIntervals
        }
      })
      yextUpdateArray.push({
        hours: {
          ...hoursObj,
          holidayHours: combinedHolidayHours,
        },
      })
    }

    let additionalHrsTxt = log.filter(
      (item) => item.type == 'Hours' && item.hoo_fieldName == 'text'
    )
    // text
    if (additionalHrsTxt.length > 0) {
      yextUpdateArray.push({
        additionalHoursText: additionalHrsTxt[0].newValue,
      })
    }

    // ext hours
    if (
      toggleResponse.data.latestHOOUpdate.length != 0 &&
      toggleResponse.data.latestHOOUpdate[0].newValue == 'false'
    ) {
      yextUpdateArray.push({
        c_extendedHours: null,
        c_extendedAdditionalHoursText: '',
      })
    } else {
      let extHours = log.filter(
        (item) =>
          item.type == 'Ext Hrs' &&
          item.hoo_fieldName !== 'text' &&
          item.hoo_fieldName != 'toggle'
      )

      if (extHours.length > 0) {
        let extHoursObj = {}
        extHours.map((interval) => {
          if (interval.hoo_fieldName == 'status') {
            extHoursObj[interval.hoo_day]
              ? interval.newValue == 'Closed'
                ? (extHoursObj[interval.hoo_day] = {
                    isClosed: interval.newValue == 'Open' ? false : true,
                  })
                : (extHoursObj[interval.hoo_day].isClosed =
                    interval.newValue == 'Open' ? false : true)
              : (extHoursObj[interval.hoo_day] = {
                  isClosed: interval.newValue == 'Open' ? false : true,
                  openIntervals: [{ start: '00:00', end: '23:59' }],
                })
          } else {
            // eslint-disable-next-line no-unused-expressions
            extHoursObj[interval.hoo_day]
              ? !extHoursObj[interval.hoo_day].isClosed
                ? (extHoursObj[interval.hoo_day].openIntervals = [
                    {
                      start: interval.openIntervals[0].newStart,
                      end: interval.openIntervals[0].newEnd,
                    },
                  ])
                : ''
              : (extHoursObj[interval.hoo_day] = {
                  openIntervals: [
                    {
                      start: interval.openIntervals[0].newStart,
                      end: interval.openIntervals[0].newEnd,
                    },
                  ],
                })
          }
        })

        yextUpdateArray.push({ c_extendedHours: extHoursObj })
      }

      // text
      const extHrsText = log.filter(
        (item) => item.type == 'Ext Hrs' && item.hoo_fieldName == 'text'
      )

      if (extHrsText.length > 0) {
        yextUpdateArray.push({
          c_extendedAdditionalHoursText: extHrsText[0].newValue,
        })
      }
    }

    return yextUpdateArray
  }

  // yext API call

  let yextAPICall = async (yextId, yextObject) => {
    try {
      // update the hours, text or emerg banner in yext for each log item
      let apiCall = `${process.env.EE_REST_YEXT_PUT_ENDPOINT}?yextID=${yextId}`
      await fetch(apiCall, {
        method: 'POST',
        body: JSON.stringify(yextObject),
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
        },
      }).then((response) => console.log('response: ', response))
    } catch (error) {
      throw new Error(error)
    }
  }

  const formatContactInfo = (value) => {
    if (!value) {
      return null;
    }
    const contactInfo = JSON.parse(value.replaceAll("'", '"'));
    return {
      c_textPhone: contactInfo?.text,
      mainPhone: contactInfo?.phone,
      fax: contactInfo?.fax
    };
  }

  const publishYextLogs = async (unpublishedLogs) => {

    let yextIdObj = {};

    unpublishedLogs.forEach((item) => {
      yextIdObj[item.yextId]
        ? (yextIdObj[item.yextId] =
            yextIdObj[item.yextId] + ',' + item.idpublish_logs)
        : (yextIdObj = Object.assign(yextIdObj, {
            [item.yextId]: item.idpublish_logs,
          }))
    })

    //publish emergency banner
    for (const yextId of Object.keys(yextIdObj)) {
      // Get latest EB
      const ebResponse = await buildEBObj(yextIdObj[yextId])

      let modifiedYextId =
        yextId.toString().length < 4 ? `0${yextId}` : yextId;

      if (ebResponse){
        await yextAPICall(modifiedYextId, ebResponse);
      }
    };

    //publish Hours
    for (const yextId of Object.keys(yextIdObj)) {
      const hooResponse = await getlatestHoursUpdatesByPublishId(
        yextIdObj[yextId]
      )

      if (
        hooResponse.data.latestHoursUpdatesByPublishId &&
        hooResponse.data.latestHoursUpdatesByPublishId.length > 0
      ) {
        const toggleResponse = await getLatestHOOUpdate(
          'Ext Hrs',
          'toggle',
          null,
          userData.id,
          siteId,
          yextId.toString()
        )

        let hoursObj = buildHoursObj(
          hooResponse.data.latestHoursUpdatesByPublishId,
          toggleResponse
        )

        let yextObject = {}

        hoursObj.map((item) => {
          let key = Object.keys(item)[0]
          let value = Object.values(item)[0]
          yextObject[key] = value
        })

        let modifiedYextId =
          yextId.toString().length < 4 ? `0${yextId}` : yextId

        await yextAPICall(modifiedYextId, yextObject);
      }
    };


    //publish Contact Info
    for (const yextId of Object.keys(yextIdObj)) {
      const contactInfoFromDB = await getlatestContactInfoUpdatesByPublishId(
        yextIdObj[yextId]
      );
      if(!contactInfoFromDB?.data?.latestContactInfoUpdatesByPublishId?.length){
        return;
      }
      const contactInfoFormated = formatContactInfo(contactInfoFromDB?.data?.latestContactInfoUpdatesByPublishId?.[0]?.newValue);
      if(!contactInfoFormated){
        return;
      }
      let modifiedYextId =
        yextId.toString().length < 4 ? `0${yextId}` : yextId;

      await yextAPICall(modifiedYextId, contactInfoFormated);
      setContactPublishTimeOnLocalStorage();
    };
  }

  const invokeSyncApp = async (siteId) => {
    //TODO: implemento the request to the orchestrator to sync app
    const subscriptionKey = process.env.OCP_APIM_SUBSCRIPTION_KEY;
    const myHeaders = new Headers();
    myHeaders.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "siteId": siteId,
      "userEmail": email,
      "process": "SYNC_APP",
      "platform": "EASY_EDITOR"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const orchestratorURL = process.env.ORCHESTRATOR_SYNC_REVALIDATE_SITE;
    const fetchResponse = await fetch(orchestratorURL, requestOptions);
    if (!fetchResponse.status === 200) {
      throw new Error(`HTTP error! status: ${fetchResponse.status}`);
    }
    return fetchResponse.json();
  }

  const invokeRevalidatePath = async (siteId, path) => {
    //TODO: implemento the request to the orchestrator to revalidate path
    //TODO: implemento the request to the orchestrator to sync app
    const subscriptionKey = process.env.OCP_APIM_SUBSCRIPTION_KEY;
    const myHeaders = new Headers();
    myHeaders.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "siteId": siteId,
      "userEmail": email,
      "process": "REVALIDATE_PATH",
      "path": path,
      "platform": "EASY_EDITOR"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const orchestratorURL = process.env.ORCHESTRATOR_SYNC_REVALIDATE_SITE;
    const fetchResponse = await fetch(orchestratorURL, requestOptions);
    if (!fetchResponse.status === 200) {
      throw new Error(`HTTP error! status: ${fetchResponse.status}`);
    }
    return fetchResponse.json();

  }

  const publishContentfulLogs = async () => {
    const eb = contentfulLogs.find((log) => log.updateType === 'Emerg Banner');
    if(eb){
      await invokeSyncApp(siteId);
      return [];
    }
    const staff = contentfulLogs.find((log) => log.updateType === 'Staff' && log.verbalDescription === 'New Staff');
    if(staff){
      await invokeSyncApp(siteId);
      return [];
    }
    const pathsRevalidate = [];
    const addPathRevalidate = (path) => {
      if(!path){
        return;
      }
      let pathFormated = path;
      if(!pathFormated.endsWith('/')){
        pathFormated = pathFormated + "/";
      }
      if(pathsRevalidate.includes(pathFormated)){
        return;
      }
      pathsRevalidate.push(pathFormated);
    }

    const carouselLog = contentfulLogs.find((log) => log.updateType === 'Hero Carousel');
    if(carouselLog){
      addPathRevalidate("/");
    }

    const serivcesLog = contentfulLogs.find((log) => log.updateType === 'Services');
    if(serivcesLog){
      const servicesURL = selectedSiteInfo.sitePages.find(
        (page) => page.fields.pageType === 'Services'
      )
      addPathRevalidate(servicesURL?.fields?.url);
    }
    const staffLog = contentfulLogs.find((log) => log.updateType === 'Staff');
    if(staffLog){
      const staffLogs = selectedSiteInfo.sitePages.find(
        (page) => page.fields.pageType === 'Staff'
      )
      addPathRevalidate(staffLogs?.fields?.url);
    }

    for (const log of contentfulLogs){
      addPathRevalidate(log.path);
    }

    console.log("pathsRevalidate", pathsRevalidate);

    const allRevalidationProcess = [];
    for (const path of pathsRevalidate){
      allRevalidationProcess.push(invokeRevalidatePath(siteId, path));
    }
    await Promise.all(allRevalidationProcess);
    return pathsRevalidate;

  }

  const publishIsrUpdates = async ({URL}) => {
    savingPublishedChanges('publishing');
    console.log("contentfulLogs", contentfulLogs);
    console.log("selectedSiteInfo", selectedSiteInfo);

    let pathsRevalidated = [];
    if(contentfulLogs?.length > 0){
      pathsRevalidated = await publishContentfulLogs();
      await markContentfulEntriesAsPublished();
    }

    //get unpublishedLogs
    const unpublishedLogs = await getUnpublishedLogsByUserIdAndSiteId({
      userId: userData.id,
      siteId: siteId
    });

    if (unpublishedLogs?.length > 0) {
      //publish hours
      await publishYextLogs(unpublishedLogs);

      await updatePublishedByUserIdAndSiteId({userId: userData.id, siteId});
      setIsAlertActive(false);
    }
    if (pathsRevalidated.length > 0 || (unpublishedLogs?.length > 0 && !contentfulLogs?.length > 0)){
      await sendYextEmail();
    }
    savingPublishedChanges(true);
  }

  /**
   * Publish final action log changes to yext
   */
  const onPublishClickCallback = async () => {
    ReactGA.event({
      category: 'Publish',
      action: `${userData?.userByID?.name} published site ${siteId}`,
      label: `User ID: ${user.id}`,
    })

    if (orchestratorSiteInfo?.DeployMode === 'ISR_SITE') {
      await publishIsrUpdates(orchestratorSiteInfo);
      return;
    }

    localStorage.setItem(
      `publishTimer-${siteId}`,
      new Date().getTime()
    )

    savingPublishedChanges('publishing')
    contentfulLogs && contentfulLogs.length > 0 && buildWebsite()

    contentfulLogs &&
      contentfulLogs.length > 0 &&
      await markContentfulEntriesAsPublished();

    //get unpublishedLogs
    const unpublishedLogs = await getUnpublishedLogsByUserIdAndSiteId({
      userId: userData.id,
      siteId: siteId
    });

    let isSendEmail = false;

    if (!(contentfulLogs?.length > 0)){
      isSendEmail = true;
    }

    if (
      unpublishedLogs &&
      unpublishedLogs.length > 0
    ) {
      //publish hours
      await publishYextLogs(unpublishedLogs);

      await updatePublishedByUserIdAndSiteId({userId: userData.id, siteId})
      if (isSendEmail) {
        await sendYextEmail()
      }
      setIsAlertActive(false)
    }
    savingPublishedChanges(true)
  }

  const setContactPublishTimeOnLocalStorage = () => {
    localStorage.setItem(
      `lastPublishContactInfoTime-${siteId}`,
      new Date().getTime()
    );
  }

  const sendYextEmail = async () => {
    const url = process.env.SERVICE_EMAIL_ENDPOINT
    const data = {
      email: user.email,
      eeUserId: user.id,
      name: user.name,
      message: 'website Staged',
      siteUrl: siteURL,
      siteId: siteId,
      result: 'success',
      EEUserId: user.id,
    }
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(function (response) {})
  }

  /**
   * Get a publish logs from REST API.
   */
  const getPublishLogsRest = useCallback(async () => {
    let response;
    if(siteId){
      response = await getUnpublishedLogsByUserAndSiteId({ email, siteId })
    }else{
      response = await getUnpublishedLogsByUser({ email })
    }
    setPublishLogs(response)

    setLoadingStatus(false)
  }, [email])

  /**
   * Get a logs from REST API.
   */
  const getLogsRest = useCallback(async () => {
    // get action logs from state
    const response = await getUserActionLogsByUser({
      email,
    })

    const data = response.data.userActionLogsByUser
    if (data && data.length > 0) {
      const out = []
      const logsForTableTemp = []
      const logsForTableWithDataTemp = []

      // loop through all logs
      data.forEach((log) => {
        // only get action logs which are not published
        if (log.isEntryPublished === 0) {
          // parse hours and additional text to yext format

          const hoursAndTextObj = parseUserActionLogChanges(
            log.contentfulEntryName.trim(),
            '^^',
            log.id
          )

          // parse emergency banner text to yext format
          const banner = formatEmergencyBanner(
            log.contentfulEntryName.trim(),
            '%',
            log.id
          )

          // dont include any empty data
          if (!_.isEmpty(hoursAndTextObj)) out.push(hoursAndTextObj)
          if (!_.isEmpty(banner)) out.push(banner)

          // push each log into a temp array
          const parsedLog = prepareLogHelper(log)
          logsForTableTemp.unshift(parsedLog)

          let complexLog = {}

          // merge hours and additional text changes into common object
          complexLog = {
            ...complexLog,
            ...hoursAndTextObj,
          }

          // merge emergency banner changes into common object
          complexLog = {
            ...complexLog,
            ...banner,
          }

          // merge parsed log data and yextid into common object
          complexLog = {
            ...complexLog,
            ...parsedLog,
          }

          logsForTableWithDataTemp.unshift(complexLog)
        }
      })

      setLogsForTableWithChanges(logsForTableWithDataTemp)
      setAllLogsToUpdate(out)
    }

    setLoadingStatus(false)
  }, [email])

  useEffect(() => {
    getPublishLogsRest()
    //getLogsRest()
    getContentfulLogsRest()
    getUserRestAndYextData()
  }, [getPublishLogsRest])

  const getContentfulLogsRest = useCallback(async () => {
    setLoadingStatus(true)
    let response;
    if(siteId){
      response = await getUnpublishedContentfulLogsByUserIDAndSiteIdV2({ ID: user.id, siteId: siteId })
    }else{
      response = await getUnpublishedContentfulLogsByUserID({ ID: user.id })
    }
    setContentfulLogs(response)
    setLoadingStatus(false)
  })

  const markContentfulEntriesAsPublished = async () => {
    await updateContentfulLogAsPublishedBySiteID({siteId})
    savingPublishedChanges('loading')
    setIsAlertActive(false)
  }

  const buildWebsite = () => {
    fetch(
      `${process.env.SITE_PUBLISHER_URL}/stage-and-publish/${siteId}?email=true&EEUserId=${user.id}`,
      {
        method: 'PUT',
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setWebsitePublishData(data))
      .catch((error) => {
        setWebsitePublishData({ error: 'Website building already in progress' })
      })
  }

  const checkSiteStatus = () => {
    fetch(`${process.env.SITE_PUBLISHER_URL}/status/${siteId}`, {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setWebsiteStatus(data)
      })
      .catch((error) => {
        //
      })
  }

  return isLoading ? (
    <PawLoader />
  ) : (
    <>
      <UserActionLog
        publishLogs={publishLogs}
        undoPublishLog={undoPublishLog}
        siteProcess={websiteStatus}
        contentfulLogs={contentfulLogs}
        undoActioLogCallback={undoActioLogCallback}
        onPublishClickCallback={onPublishClickCallback}
      />
    </>
  )
}

export default UserActionLogsByUserCellRest
