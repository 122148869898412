import React, { useState, useEffect } from 'react'
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg'
import {
  getHoursAccordionData,
  getServiceAccordionData,
  getStaffAccordionData,
  getContactInfoAccordionData,
} from './helper'
import { SectionLink } from 'src/pages/WebsitePagePage/WebsitePageHoursEditForm.styles'
import { Link } from 'react-router-dom'
import { useGlobalState } from 'src/hooks/state/state'

const MultiLocationSelection = ({
  accordionFor,
  title,
  description,
  onClick,
}) => {
  const [localData, setLocalData] = useState({})
  const [multiLocations] = useGlobalState('multiLocations')
  const [globalStaffPages] = useGlobalState('staffPages')
  const [globalServicesPages] = useGlobalState('servicesPages')
  const [selectedSite] = useGlobalState('selectedSite')

  useEffect(() => {
    if (accordionFor && selectedSite) {
      if (accordionFor == 'multiLocationHours') {
        setLocalData(
          sortAccordionData(
            getHoursAccordionData(
              accordionFor,
              multiLocations,
              selectedSite,
              onClick
            ),
            'name',
            'options'
          )
        )
      } else if (accordionFor == 'multiLocationStaff') {
        setLocalData(
          sortAccordionData(
            getStaffAccordionData(
              accordionFor,
              globalStaffPages,
              selectedSite,
              onClick
            ),
            'name',
            'options'
          )
        )
      } else if (accordionFor == 'multiLocationService') {
        setLocalData(
          sortAccordionData(
            getServiceAccordionData(
              accordionFor,
              globalServicesPages,
              selectedSite,
              onClick
            ),
            'name',
            'options'
          )
        )
      } else if (accordionFor == 'multiLocationContactInfo') {
        setLocalData(
          sortAccordionData(
            getContactInfoAccordionData(
              accordionFor,
              multiLocations,
              selectedSite,
              onClick
            ),
            'name',
            'options'
          )
        );
      }
    }
  }, [accordionFor, globalStaffPages, multiLocations, globalServicesPages])

  const sortAccordionData = (array, field, fieldList) => {
    if (
      array &&
      array.options &&
      Array.isArray(array.options) &&
      array.options.length > 0
    ) {
      array[fieldList] = array[fieldList].sort((a, b) =>
        a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
      )
    }
    return array
  }

  return (
    <div className="staff-form">
      <div>
        <div id="group-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">{title}</h2>
          <p className="sidebar-para mt-0">{description}</p>
        </div>

        {localData.options &&
          localData.options.length > 0 &&
          localData.options.map((option, index) => {
            return (
              <div id="group-add" style={{ marginBottom: '20px' }} key={index}>
                <SectionLink>
                  <div
                    onClick={option.onClick}
                    className="flex flex-col p-3  justify-between cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <span
                        className="font-medium pl-3"
                        style={{ flexGrow: 1, fontSize: '22px' }}
                      >
                        {option.name}
                      </span>
                      <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                    </div>
                  </div>
                </SectionLink>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default MultiLocationSelection
