import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getUserById, updateUser } from '../../api/users'
import UserForm from 'src/components/UserForm'
import * as _ from 'lodash'
import { getAllGroups } from '../../api/groups'
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom'

const EditUserCellRest = ({ id }) => {
  const cms = useCMS()
  const history = useHistory()

  const [userToEdit, setUserToEdit] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)
  const [error, setError] = useState(null)
  const [groups, setGroups] = useState([])

  /**
   * Create new user using REST query.
   * @param {Object} data User form data
   */
  const updateExistingUser = async (data) => {
    try {
      setLoadingStatus(true)
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      await updateUser(id, query)
      setLoadingStatus(false)

      cms.alerts.info('User Updated')
      history.push('/users')
    } catch (e) {
      setError(e)
    }
  }

  /**
   * Get a user by id from REST API that we want to edit.
   */
  const getUserRest = useCallback(async () => {
    let userObj = {}
    // get user
    const usersRest = await getUserById({ id })
    const { data } = usersRest

    userObj = data.userByID

    setUserToEdit(userObj)
    setLoadingStatus(false)
  }, [id])

  /**
   * Get all available groups
   */
  const getAllGroupsRest = useCallback(async () => {
    const groupsRest = await getAllGroups()
    const { data } = groupsRest
    setGroups(data.groups)
    setLoadingStatus(false)
  }, [])

  useEffect(() => {
    getUserRest()
    getAllGroupsRest()
  }, [getUserRest, getAllGroupsRest])

  const onSave = (data) => {
    updateExistingUser(data)
  }

  return (
    <Fragment>
      {!_.isEmpty(userToEdit) && groups.length > 0 && (
        <div className="container mx-auto grid rw-segment mt-6">
          <header className="rw-segment-header">
            <h2 className="rw-heading rw-heading-secondary">
              Edit User {userToEdit.id}
            </h2>
          </header>
          <div className="rw-segment-main">
            <UserForm
              onSave={onSave}
              error={error}
              userToEdit={userToEdit}
              groups={groups}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default EditUserCellRest
