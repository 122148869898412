import React, { useState, useEffect } from 'react'
import PawLoader from '../../PawLoader'
import './Groups.css'
import SimpleDragItem from 'src/components/SimpleDragItem/SimpleDragItem'
import SimpleListItem from 'src/components/SimpleListtem/SimpleListItem'

const Groups = (props) => {
  const [availableGroups, setAvailableGroups] = useState(null);

  const getAvailableGroups = () => {
    const hiddenGroups = props.data?.filter((g) => g?.hideBlade);
    const masterGroups = props.masterData?.filter(
      (ms) => !props
        .data?.filter(s => s.isService).map((s) => s?.groupName?.toString()?.toLocaleLowerCase())
              .includes(ms?.groupName?.toString()?.toLocaleLowerCase())
    );
    setAvailableGroups([...hiddenGroups, ...masterGroups]);
  }


  useEffect(() => {
    if (Array.isArray(props.data) && Array.isArray(props.masterData)) {
      getAvailableGroups();
    }
  }, [props])

  var enterTarget = null;

  const onDragEnter = (ev) => {
    if (ev.target.closest(".drag-container")) {
      enterTarget = ev.target
      ev.target.closest(".drag-container").classList.add("showDropAreas-green")
    }
  }

  const onDragLeave = (ev) => {
    if (ev.target.closest(".drag-container") && enterTarget == ev.target.closest(".drag-container")) {
      ev.target.closest(".drag-container").classList.remove("showDropAreas-green")
    }
  }

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    ev.target.closest(".drag-container").classList.remove("showDropAreas-green")
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderGroups(sourceKey, destKey)
  }

  const moveOnClick = (sourceKey, destKey) => {
    if (destKey >= 0 && destKey < props?.data?.length) {
      props.reorderGroups(sourceKey, destKey)
    }
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  return (
    <div className="staff-form">
      <div>
        <div id="group-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Services</h2>
          <p className="sidebar-para mt-0">
            {`Use the menu or drag and drop to re-order your current selections. `}
          </p>
        </div>

        {/* <div id="group-add" style={{ marginBottom: '20px' }}>
          <button
            onClick={() => props.addGroup()}
            className={`new-button ${
              props.addButtonDisabled ? 'disabled' : ''
            }`}
          >
            <span style={{ textDecoration: 'none', color: 'white' }}>
              + Create New Group
            </span>
          </button>
        </div> */}

        <div id="group-groups" style={{ marginBottom: '40px' }}>
          <span className="text-lg font-semibold mb-2">
            Current Selections:
          </span>

          {(()=>{
            if(props.addButtonDisabled){
              return <PawLoader />
            }
            if(!props.data){
              return null;
            }
            let groupIndex = 0;
            return props.data.map((item, key) => {
              if (!(!item.deleted && item.isService && !item.hideBlade)) {
                return null;
              }
              const COMPONENT = <SimpleDragItem
                key={key}
                itemKey={key}
                item={item}
                title={item.groupName}
                description={item.services
                  ?.filter((s) => !s.hideService)
                  ?.map((service) => service.name)
                  .join(', ')}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragStart={onDragStart}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                sendMsg={sendMsg}
                onEditClick={props.goGroup}
                moveOnClick={moveOnClick}
                lastItemKey={props.data?.filter((item) => !item.deleted && item.isService && !item.hideBlade)?.length - 1}
                firstVisibleItemKey={props.data.findIndex(
                  (i) => !i.hideBlade
                )}
                onHideClick={() => props.deleteGroup(item.key)}
                keyIndex={groupIndex}
                hideRemove
              />
              groupIndex++;
              return COMPONENT;
            })
          })()}
        </div>
        <div id="group-services-available" style={{ marginBottom: '40px' }}>
          <span className="text-lg font-semibold mb-2">
            Available Service Groups:
          </span>

          {availableGroups
            ? availableGroups.map((item, key) => {
                return (
                  <SimpleListItem
                    key={key}
                    itemKey={key}
                    item={item}
                    title={item.groupName}
                    description={item.services
                      ?.filter((s) => !s.hideService)
                      ?.map((service) => service.name)
                      .join(', ')}
                    sendMsg={sendMsg}
                    onClick={() => props.addGroup(item)}
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default Groups
