import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getGroupByGroupId, updateGroup } from '../../api/groups'
import WebsiteForm from 'src/components/WebsiteForm'
import * as _ from 'lodash'
import { useCMS } from 'tinacms'
import { useHistory } from 'react-router-dom'

const EditUserCellRest = ({ id }) => {
  const cms = useCMS()
  const history = useHistory()

  const [websiteToEdit, setWebsiteToEdit] = useState([])
  const [error, setError] = useState(null)

  const updateExistingWebsite = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      let updateErr = await updateGroup(id, query)
      if (updateErr.errors && updateErr.errors.length > 0) {
        updateErr = { ...updateErr, graphQLErrors: updateErr.errors }
        setError(updateErr)
      } else {
        cms.alerts.info('Website Updated')
        history.push('/websites')
      }
    } catch (e) {
      setError(e)
    }
  }

  const getWebsiteRest = useCallback(async () => {
    let websiteObj = {}
    const websitesRest = await getGroupByGroupId({ id })
    const { data } = websitesRest

    websiteObj = data.groupByID

    setWebsiteToEdit(websiteObj)
  }, [id])

  useEffect(() => {
    getWebsiteRest()
  }, [getWebsiteRest])

  const onSave = (data) => {
    updateExistingWebsite(data)
  }

  return (
    <Fragment>
      {!_.isEmpty(websiteToEdit) && (
        <div className="container mx-auto grid rw-segment mt-6">
          <header className="rw-segment-header">
            <h2 className="rw-heading rw-heading-secondary">
              Edit Website {websiteToEdit.id}
            </h2>
          </header>
          <div className="rw-segment-main">
            <WebsiteForm
              onSave={onSave}
              error={error}
              websiteToEdit={websiteToEdit}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default EditUserCellRest
