import React, { useEffect, useState } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { Paragraph } from '../../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'
import './ServicesDetails.css'

const ServiceDetails = (props) => {
  const [serviceOverview, setServiceOverview] = useState('')
  const [serviceDetails, setServiceDetails] = useState('')
  const [localCopy, setLocalCopy] = useState('')

  useEffect(() => {
    setServiceOverview(
      props.data[props.group].services[props.service].serviceOverview
        ? props.data[props.group].services[props.service].serviceOverview.json
        : ''
    )

    setServiceDetails(
      props.data[props.group].services[props.service].serviceDetails
        ? props.data[props.group].services[props.service].serviceDetails.json
        : ''
    )

    setLocalCopy(
      props.data[props.group].services[props.service].localCopy
        ? documentToHtmlString(
            props.data[props.group].services[props.service].localCopy.json
          )
        : ''
    )
  }, [])

  return (
    <div className="services-form">
      <div>
        <div id="service-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">{`Edit ${
            props.data[props.group].services[props.service].internalName ||
            props.data[props.group].services[props.service].name
          } details`}</h2>
          <Paragraph>
            Optional. Add any additional information for this service you would
            like to appear at the bottom of this detail page.
          </Paragraph>
        </div>

        <div id="service-local" style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6 className="text-lg font-semibold mb-3 mt-6">
              Location Specific Information
            </h6>
          </div>
          <div>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ['heading', 'bold', 'italic'],
              }}
              data={localCopy}
              onChange={(event, editor) => {
                const data = editor.getData()
                props.updateLocalCopy(data)
              }}
              className="border-0 hello"
            />
          </div>
        </div>

        <div
          id="service-overview"
          style={{
            marginBottom: '20px',
            color: 'rgba(144,144,144,1)',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6 className="text-lg font-semibold mb-3 mt-6">Overview</h6>
          </div>
          <div
            className="text-container"
            style={{ borderColor: 'rgba(144,144,144,1)' }}
          >
            {documentToReactComponents(serviceOverview)}
          </div>
        </div>
      </div>
      <div
        id="service-details"
        style={{ marginBottom: '20px', color: 'rgba(144,144,144,1)' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="text-lg font-semibold mb-3 mt-6">Details</h6>
        </div>
        <div
          className="text-container"
          style={{ borderColor: 'rgba(144,144,144,1)' }}
        >
          {documentToReactComponents(serviceDetails)}
        </div>
      </div>
    </div>
  )
}

export default ServiceDetails
