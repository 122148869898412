import React, { useContext, useState, useEffect } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { MarkdownFieldPlugin } from 'react-tinacms-editor'
import { useCMS, useForm, usePlugin, withTina } from 'tinacms'
import { createPublishLog, getLatestEBUpdate } from '../../api/publishLogs'
import { Paragraph } from './WebsitePageHoursEditForm.styles.js'
import './WebsitePageMain.css'
import './WebsitePageHoursEditForm.css'
import './WebsiteEmergencyBannerEditForm.css'
import ArrowLeft from '-!svg-react-loader?name=Icon!../../static/images/arrow.svg'
// import VideoModal from '../../components/VideoModal'
import { Context } from '../../../Store'
import Showdown from 'showdown'
import TurndownService from 'turndown'
import { bannerMessages } from '../../constants/messages/en'
import { cma } from '../../../../api/src/lib/contentful'
import { createContentfulLog } from 'src/api/userActionLogs'

import {
  setIsBannerUpdated,
  setInitialBannerMsg,
  setUpdateBannerMsg,
  setShowBannerState,
  setBannerBackgroundColor,
  useGlobalState,
  setIsEBDeleted,
  setReRenderStaff,
} from '../../hooks/state/state'
import { updateHours } from './helper'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'

const WebsiteEmergencyBannerEditForm = ({ routingInfo, user }) => {
  const [firstLoadDone, setFirstLoadDone] = useState(false)
  const history = useHistory()
  const [iframeView, setIframeView] = useState('large')
  const {setIsAlertActive} = useContext(Context)
  const [sidebarToggleBtn, setSidebarToggleBtn] = useState(false)
  const [toggle, setToggle] = useState()
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isUpdated] = useGlobalState('isBannerUpdated')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [isEBDeleted] = useGlobalState('isEBDeleted')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [selectedSite] = useGlobalState('selectedSite')
  const [yextResponse] = useGlobalState('yextResponse')
  const [websiteTheme] = useGlobalState('websiteTheme')
  const [selectedSiteInfo] = useGlobalState('selectedSiteInfo')
  const [bannerText, setBannerText] = useState(
    isUpdated ? descriptionUpdated : descriptionInitial.text
  )

  /* format description */
  let converter = new Showdown.Converter()
  const turndownService = new TurndownService()
  const truncateNode = (paraElem, limit) => {
    if (paraElem.nodeType === Node.TEXT_NODE) {
      paraElem.textContent = paraElem.textContent.substring(0, limit)
      return limit - paraElem.textContent.length
    }
    paraElem.childNodes.forEach((child) => {
      limit = truncateNode(child, limit)
    })
    return limit
  }
  const getTruncatedHTMLOrMarkdown = (text, limit, returnMarkdown = false) => {
    if (text && limit) {
      let formattedText = converter.makeHtml(text)
      let docNode = new DOMParser().parseFromString(formattedText, 'text/html')
      let paraElem = docNode.body.firstElementChild
      truncateNode(paraElem, limit)
      if (paraElem) {
        if (returnMarkdown) {
          let dummyDiv = document.createElement('div')
          dummyDiv.appendChild(paraElem)
          let markdownText = turndownService.turndown(dummyDiv.innerHTML)
          return markdownText
        }
        return paraElem
      }
    }
    return ''
  }

  let description = isUpdated ? descriptionUpdated : descriptionInitial.text
  let trimmedMarkdown = getTruncatedHTMLOrMarkdown(description, 500, true)

  let initialValues = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    author: {
      description: trimmedMarkdown,
    },
  }

  const initial = isHoursUpdated ? updatedHours : initialHours

  const initialHoursValues = {
    mainHours: {
      hours: updateHours(initial.hours),
      additionalHoursText: initial.additionalHoursText,
    },
    extendedHours: {
      extendedHours: updateHours(initial.c_extendedHours),
      c_extendedAdditionalHoursText: initial.c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      typeof initial.c_extendedHours !== 'undefined' &&
      Object.keys(initial.c_extendedHours).length > 0,
  }

  window.onbeforeunload = function () {
    if (isFormDirty && !isEBDeleted) return ''
    else return undefined
  }

  useEffect(() => {
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'

    cms.events.subscribe('sidebar:opened', () => {
      setInitialBannerMsg('text', descriptionInitial.text)
    })
    setSidebarToggleBtn(
      document.querySelector('[aria-label="toggles cms sidebar"]')
    )

    setToggle(showBannerState)
  }, [])

  useEffect(() => {
    setBannerStyles()
  }, [showBannerState])

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const msg = JSON.parse(JSON.stringify(jsonMsg))
    if (msg) {
      if (msg.author && msg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(msg.author.description)
        let regex = /<\/?p[^>]*>/gi
        msg.author.description = formattedText.replace(regex, '')
        setBannerText(formattedText.replace(regex, ''))
      }
      const strMsg = JSON.stringify(msg)
      win.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsgOnToggle = () => {
    setTimeout(() => {
      document.getElementById('websitePreviewFrame').style['pointer-events'] =
        'auto'
      let bannerData = {
        showBanner: showBannerState,
        bannerBackgroundColor,
        websiteTheme,
        author: {
          description: bannerText,
        },
      }
      sendMsg(bannerData)
      sendMsg(initialHoursValues)
    }, 2000)
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      let frame = document.getElementById('websitePreviewFrame')
      if (frame) {
        frame.style['pointer-events'] = 'auto'
        sendMsg(initialValues)
        sendMsg(initialHoursValues)
      }
    }, 2000)
  }

  function setBannerStyles() {
    let textArea = document.querySelector('div > [contenteditable]')
    if (textArea && toggle) {
      textArea.blur()
      textArea.disabled = !toggle
      textArea.style.boxShadow = ''
    }

    return <div>{null}</div>
  }

  function HeadingText(props) {
    return (
      <div className="headingText fp-2 mt-5 mb-5 section-header">
        <h2 className="text-teal-600 text-3xl">{props.field.label}</h2>
        <Paragraph>
          {props.field.textMain}
          <br />
          {props.field.textHelper}
        </Paragraph>
      </div>
    )
  }

  const createPublishLogRest = async (data) => {
    try {
      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      await createPublishLog(query)
    } catch (e) {
      console.error('Exc caught while createPublishLogRest():', e)
    }
  }

  const createContentfulLogRest = async (entryID, verbalDescription) => {
    const input = {
      contentfulId: entryID,
      isPublished: 0,
      userId: user.id,
      verbalDescription,
    }

    ReactGA.event({
      category: 'Content - Site',
      action: `User ${user.id} Made Changes to ShowEmergencyBanner`,
      label: `Value: ${verbalDescription}`,
    })

    try {
      const query = JSON.stringify(input).replace(/"([^"]+)":/g, '$1:')
      await createContentfulLog(query)
    } catch (e) {
      setError(e)
    }
  }

  const updateBannerToggleOrColor = async (bannerVal, bannerColor) => {
    if (bannerVal != undefined && descriptionInitial['contentfulEntry']) {
      let isbannerValChanged, isbannerColorChanged
      cma
        .getSpace(process.env.REDWOOD_ENV_CONTENTFUL_SPACE)
        .then((space) => {
          space
            .getEnvironment(process.env.CONTENTFUL_ENV)
            .then((env) => {
              env
                .getEntry(descriptionInitial['contentfulEntry'])
                .then((entry) => {
                  isbannerValChanged =
                    bannerVal == entry.fields['showEmergencyBanner']['en-US']
                  isbannerColorChanged =
                    bannerColor ==
                    entry.fields['emergencyBannerBackgroundColour']['en-US']
                  entry.fields['showEmergencyBanner'] = { 'en-US': bannerVal }
                  entry.fields['emergencyBannerBackgroundColour'] = {
                    'en-US': bannerColor,
                  }
                  return entry.update()
                })
                .then((entry) => entry.publish())
                .then((entry) => {
                  createContentfulLogRest(
                    entry.sys.id,
                    `${
                      isbannerValChanged &&
                      `EmergencyBanner toggle set to ${bannerVal}`
                    } ${isbannerColorChanged && isbannerValChanged && 'and'} ${
                      isbannerColorChanged &&
                      `BannerBackgroundColour changed to ${bannerColor}`
                    }`
                  )
                })
                .catch((err) => {
                  console.error('Error in updating the banner', err)
                })
            })
            .catch((err) => {
              console.error('Error in getting contentful environment', err)
            })
        })
        .catch((err) => {
          console.error('Error in getting contentful space', err)
        })
    }
  }

  const getTextFromHTML = (text) => {
    if (text && text.length > 0) {
      let formattedText = converter.makeHtml(text)
      var DOM = new DOMParser().parseFromString(formattedText, 'text/html')
      if (DOM.body.firstChild) {
        DOM.body.firstChild.style.whiteSpace = 'pre'
      }
      return DOM.body.innerText
    }
    return ''
  }

  const formConfig = {
    id: 'emergencyBanner',
    initialValues: initialValues,
    label: '',
    fields: [
      {
        name: 'linktoBack',
        linkText: 'Back',
        component: () => (
          <button
            onClick={() => {
              setReRenderStaff(true)
              history.goBack()
            }}
            className="focus:outline-none flex flex-row py-2 items-center"
            style={{
              borderBottom: '1px solid var(--tina-color-grey-2)',
              width: '100%',
            }}
          >
            <ArrowLeft className="w-4 mr-2 transform rotate-180 text-gray-500" />{' '}
            Back
          </button>
        ),
      },
      {
        name: 'lblBanner',
        label: 'Emergency Banner',
        textMain:
          "Edit your Emergency banner text below. This banner appears at the top of every page on your location's website.",
        textHelper:
          'For messages longer than 2 lines, the emergency banner will have an option to expand the banner and show the full message. Please include the most important information at the beginning of your message. You can add a hyperlink to your message by highlighting text and clicking on the hyperlink icon.',
        component: HeadingText,
      },
      {
        name: 'showBanner',
        component: 'toggle',
        label: 'Emergency banner off/on',
      },
      {
        name: 'bannerBackgroundColor',
        component: 'select',
        options: ['Red', 'Grey', 'Theme Color'],
        label: 'Choose a banner background color',
      },
      {
        label: 'Emergency Message',
        name: 'author.description',
        component: 'markdown',
      },
      {
        name: 'bannerTextLength',
        component: (props) => {
          const stateData = props && props.form && props.form.getState()
          return (
            <p style={{ color: 'unset', textAlign: 'right' }}>{`${
              getTextFromHTML(stateData.values.author.description).length
            }/500`}</p>
          )
        },
      },
    ],
    onSubmit: async (formData) => {
      try {
        const newValue = formData.author.description.replace(/\\/g, '')

        // Get latest EB value
        const response = await getLatestEBUpdate(user.id, selectedSite.siteId)
        await updateBannerToggleOrColor(
          formData.showBanner,
          formData.bannerBackgroundColor
        )
        const oldValue =
          response.data &&
          response.data.latestEBUpdate &&
          response.data.latestEBUpdate.length > 0
            ? response.data.latestEBUpdate[0].newValue
            : yextResponse.c_websiteEmergencyBanner

        // If previously saved value is diff from new value, add to publish log
        if (oldValue !== newValue) {
          const input = {
            userId: routingInfo.user?.id,
            userEmail: routingInfo.account.userName,
            websiteId: selectedSite.siteId.toString(),
            yextId: yextResponse.meta.id.toString(),
            websiteName: selectedSite.name,
            updateType: 'Emerg Banner',
            savedUpdates: [
              {
                type: 'Emerg Banner',
                oldValue: oldValue,
                newValue: newValue,
              },
            ],
            businessLine: selectedSiteInfo.websiteType,
            divisionId: yextResponse.meta?.folderId,
          }
          await createPublishLogRest(input)
        }

        setIsEBDeleted(false)
        setIsFormDirty(false)
        setIsAlertActive(true)
        setShowBannerState(formData.showBanner)
        setBannerBackgroundColor(formData.bannerBackgroundColor)
        setUpdateBannerMsg(formData.author.description)
        setIsBannerUpdated(true)

        ReactGA.event({
          category: 'Content - Banner',
          action: `User ${routingInfo.user?.id} saved Emergency Banner`,
          label: `Value: ${formData.author.description}`,
        })
      } catch (error) {
        console.error(error)
      }
    },
    onChange: (formData) => {
      let innerTextLength = getTextFromHTML(formData.values.author.description)
        .length
      if (innerTextLength > 500) {
        let tinaMarkdownBox = document.querySelector('.ProseMirror p')
        if (tinaMarkdownBox) {
          let truncatedPara = getTruncatedHTMLOrMarkdown(
            formData.values.author.description,
            500
          )
          tinaMarkdownBox.innerHTML = truncatedPara.innerHTML
        }
      }
      if (innerTextLength <= 500) {
        setIsFormDirty(true)
        setToggle(formData.values.showBanner)
        sendMsg(formData.values)
      }
    },
  }

  const cms = useCMS()
  cms.plugins.add(MarkdownFieldPlugin)
  const [values, form] = useForm(formConfig)
  usePlugin(form)

  if (!cms.sidebar.isOpen && !firstLoadDone && sidebarToggleBtn) {
    sidebarToggleBtn.click()
    setFirstLoadDone(true)
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <Prompt
        when={isFormDirty && !isEBDeleted}
        message={() => bannerMessages.CONFIRM_LOSE_UNSAVED_BANNER}
      />
      {!toggle && (
        <style jsx>{`
          input[class^='BaseTextField-sc'] {
            background-color: darkgray !important;
            pointer-events: none;
            color: gray;
          }
        `}</style>
      )}
      <DesktopMobileToggle
        setIframeView={setIframeView}
        iframeView={iframeView}
        sendOnLoadMsg={sendOnLoadMsgOnToggle}
      />{' '}
      <PreviewFrame
        siteName={selectedSite.name}
        selectedSite={selectedSite.siteURL.split('#')[0]}
        // selectedSite="http://localhost:9000"
        sendOnLoadMsg={sendOnLoadMsg}
        iframeView={iframeView}
        cms={cms}
      />
    </div>
  )
}

export default withTina(WebsiteEmergencyBannerEditForm, {
  enabled: true,
  sidebar: true,
})
